import * as d3 from 'd3';
import Utilities from "./utilities";
import Node from "./node";
import {NODE_TEMPORAL_TYPE, NODE_TYPE} from "./constantsMapping";
import Tooltip from "./tooltip";
import Geometric from "./geometric";
import Network from "./network";
import RightColumn from "./rightColumn";
import NodeShape from "./nodeShape";

/* global d3, Utilities, Node, NODE_TEMPORAL_TYPE, Tooltip, NODE_TYPE, Geometric, Network, RightColumn, NodeShape */

var GenieSymbols = {
    StatusNodeIcon: {
        SYMBOL_BORDER_COLOR: '#000080',
        widthRect: 10,
        heightRect: 10,
        /**
         * computing best point on ellipse for symbols
         * @param {number} width - width of the ellipse
         * @param {number} height - height of the ellipse
         * @returns {Array} - point [x,y]
         */
        ellipseNicePoint: function (width, height) {
            var a = (width + 1) / 2;
            var b = (height + 1) / 2;
            return [(4 * a / 5), (3 * b / 5)];
        },
        /**
         * Removing all symbols from nodes
         */
        removeAllSymbol: function () {
            var d = d3.select(`#${Network.getSvgContentId()}`);
            d.selectAll(Utilities.getGTypeSelector(Node.TYPE) + " g[id=groundSymbol]").remove();
            d.selectAll(Utilities.getGTypeSelector(Node.TYPE) + " g[id=targetSymbol]").remove();
            d.selectAll(Utilities.getGTypeSelector(Node.TYPE) + " g[id=symbols]").remove();
            d.selectAll(Utilities.getGTypeSelector(Node.TYPE) + " g[id=checkSymbol]").remove();
            d.selectAll(Utilities.getGTypeSelector(Node.TYPE) + " g[id=questionSymbol]").remove();
            d.selectAll(Utilities.getGTypeSelector(Node.TYPE) + " g[id=noteSymbol]").remove();
            d.selectAll(Utilities.getGTypeSelector(Node.TYPE) + " g[id=deMorganSymbols]").remove();
        },
        /**
         *  Create question mark symbol
         * @param {number} widthRect - rectangle width
         * @param {number} heightRect - rectangle height
         * @param {String} fillRect - rectangle fill color
         * @param {D3 data Object} nodeD3Self - single node
         * @param {number} strokeWidth - line size
         * @returns {D3 Object} - svg group
         */
        drawQuestionSymbol: function (widthRect, heightRect, fillRect, nodeD3Self, strokeWidth) {
            var shape_rendering = "inherit";
            var questionGroup = nodeD3Self.append('g').attr('id', 'questionSymbol');
            questionGroup.append("rect").attr('x', '0')
                    .attr('y', '0')
                    .attr('width', widthRect)
                    .attr('height', heightRect)
                    .attr("fill", fillRect)
                    .attr("stroke", this.SYMBOL_BORDER_COLOR)
                    .attr("stroke-width", strokeWidth)
                    .attr('shape-rendering', shape_rendering);
            questionGroup.append("text")
                    .attr('x', '1.5')
                    .attr('y', '9')
                    .style("font-style", "normal")
                    .style("font-weight", "bold")
                    .style("font-size", "11px")
                    .style("line-height", "1.25")
                    .style("font-family", "sans-serif")
                    .style("fill", "#1ca883")
                    .style("fill-opacity", "1")
                    .style("stroke-width", "1")
                    .text("?");

            return questionGroup;
        },
        /**
         *  Create check symbol
         * @param {number} widthRect - rectangle width
         * @param {number} heightRect - rectangle height
         * @param {String} fillRect - rectangle fill color
         * @param {String} fillPath - path fill color
         * @param {D3 data Object} nodeD3Self - single node
         * @param {number} strokeWidth - line size
         * @returns {D3 Object} - svg group
         */
        drawCheckSymbol: function (widthRect, heightRect, fillRect, fillPath, nodeD3Self, strokeWidth) {
            var callback = function (d) {
                if (d.temporalType === NODE_TEMPORAL_TYPE.PLATE && !d.checkType(NODE_TYPE.EQUATION)) {
                    Tooltip.temporalAreaChartTooltip("#tooltipValue", d);
                } else if (d.checkType(NODE_TYPE.EQUATION)) {
                    if (d.isValueDiscretized === false && d.isConstantEquation === false) {
                        Tooltip.showHistogram("#tooltipValue", d, "300", "", "");
                    } else {
                        Tooltip.createContentTooltipValue(d);
                    }
                } else {
                    Tooltip.createContentTooltipValue(d);
                }
            };
            var shape_rendering = "inherit";
            var checkGroup = nodeD3Self.append('g').attr('id', 'checkSymbol');
            checkGroup.append("rect").attr('x', '0')
                    .attr('y', '0')
                    .attr('width', widthRect)
                    .attr('height', heightRect)
                    .attr("fill", fillRect)
                    .attr("stroke", this.SYMBOL_BORDER_COLOR)
                    .attr("stroke-width", strokeWidth)
                    .attr('shape-rendering', shape_rendering)
                    .on("mouseover", d => Tooltip.setMouseover(callback, d))
                    .on("mouseout", () => Tooltip.setMouseout());
            checkGroup.append("path").attr('d', 'M 2,6 5,8 8,2')
                    .attr("stroke", fillPath)
                    .attr("stroke-width", "2")
                    .attr("fill", "none")
                    .attr('stroke-linecap', "butt")
                    .attr('stroke-linejoin', 'miter')
                    .attr('stroke-opacity', 1)
                    .attr('shape-rendering', shape_rendering)
                    .on("mouseover", d => Tooltip.setMouseover(callback, d))
                    .on("mouseout", () => Tooltip.setMouseout());

            return checkGroup;
        },
        /**
         * Create sticky notes symbol
         * @param {number} widthRect - rectangle width
         * @param {number} heightRect - rectangle height
         * @param {String} fillRect - rectangle fill color
         * @param {D3 data Object} nodeD3Self - single node
         * @param {number} strokeWidth - line size
         * @returns {D3 Object} - svg group
         */
        drawStickyNotesSymbol: function (widthRect, heightRect, fillRect, nodeD3Self, strokeWidth) {
            var callback = function (d) {
                Tooltip.showTooltipStickyNote(d.stickyNotes);
            };
            var shape_rendering = "inherit";
            var noteGroup = nodeD3Self.append('g').attr('id', 'noteSymbol');
            noteGroup.append("rect").attr('x', '0')
                    .attr('y', '0')
                    .attr('width', widthRect)
                    .attr('height', heightRect)
                    .attr("fill", fillRect)
                    .attr("stroke", this.SYMBOL_BORDER_COLOR)
                    .attr("stroke-width", strokeWidth)
                    .attr('shape-rendering', shape_rendering)
                    .on("mouseover", d => Tooltip.setMouseover(callback, d))
                    .on("mouseout", () => Tooltip.setMouseout());
            noteGroup.append("path").attr('d', 'M 6,10 V 6 h 4 z')
                    .attr("stroke", this.SYMBOL_BORDER_COLOR)
                    .attr("stroke-width", strokeWidth)
                    .attr("fill", "none")
                    .attr('stroke-linecap', "butt")
                    .attr('stroke-linejoin', 'round')
                    .attr('stroke-opacity', 1)
                    .attr('shape-rendering', shape_rendering)
                    .on("mouseover", d => Tooltip.setMouseover(callback, d))
                    .on("mouseout", () => Tooltip.setMouseout());

            return noteGroup;
        },
        /**
         * Draw ground symbol in selected D3 group
         * @param {D3 Object} groundGroup - place of adding elements
         * @param {number} widthRect - rectangle width
         * @param {number} heightRect - rectangle height
         * @param {String} fillRect - rectangle fill color
         * @param {number} strokeWidth - line size
         * @param {boolean} isTooltip - add tooltip [TRUE/FALSE]
         * @returns {D3 Object} - svg group
         */
        drawGroundSymbolElements: function (groundGroup, widthRect, heightRect, fillRect, strokeColor, strokeWidth, isTooltip) {
            var callback = function (d) {
                if (isTooltip) {
                    if (d.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                        Tooltip.temporalAreaChartTooltip("#tooltipValue", d);
                    } else {
                        Tooltip.createContentTooltipValue(d);
                    }
                }
            };
            var shape_rendering = "inherit";
            groundGroup.append("rect").attr('x', '0')
                    .attr('y', '0')
                    .attr('width', widthRect)
                    .attr('height', heightRect)
                    .attr("fill", fillRect)
                    .attr("stroke", this.SYMBOL_BORDER_COLOR)
                    .attr("stroke-width", strokeWidth)
                    .attr('shape-rendering', shape_rendering)
                    .on("mouseover", d => Tooltip.setMouseover(callback, d))
                    .on("mouseout", () => Tooltip.setMouseout());
            groundGroup.append("path").attr('d', 'M 5,1 V 3 M 1,3 H 9 M 3,5 H 7 M 6,7 H 4')
                    .attr("stroke", strokeColor)
                    .attr("stroke-width", strokeWidth)
                    .attr("fill", "none")
                    .attr('stroke-linecap', "butt")
                    .attr('stroke-linejoin', 'miter')
                    .attr('stroke-opacity', 1)
                    .attr('shape-rendering', shape_rendering)
                    .on("mouseover", d => Tooltip.setMouseover(callback, d))
                    .on("mouseout", () => Tooltip.setMouseout());
            return groundGroup;
        },
        /**
         *  Create ground symbol
         * @param {number} widthRect - rectangle width
         * @param {number} heightRect - rectangle height
         * @param {String} fillRect - rectangle fill color
         * @param {D3 data Object} nodeD3Self - single node
         * @param {number} strokeWidth - line size
         * @returns {D3 Object} - svg group
         */
        drawGroundSymbol: function (widthRect, heightRect, fillRect, strokeColor, nodeD3Self, strokeWidth) {
            var groundGroup = nodeD3Self.append('g').attr('id', 'groundSymbol');
            return this.drawGroundSymbolElements(groundGroup, widthRect, heightRect, fillRect, strokeColor, strokeWidth, true);
        },
        /**
         *  Draw atarget symbol in selected D3 group
         * @param {type} targetGroup - place of adding elements
         * @param {number} widthRect - rectangle width
         * @param {number} heightRect - rectangle height
         * @param {number} strokeWidth - line size
         * @returns {D3 Object} - svg group
         */
        drawTargetSymbolElements: function (targetGroup, widthRect, heightRect, strokeWidth) {
            var shape_rendering = "inherit";
            targetGroup.append("rect").attr('x', '0')
                    .attr('y', '0')
                    .attr('width', widthRect)
                    .attr('height', heightRect)
                    .attr("fill", "white")
                    .attr("stroke", this.SYMBOL_BORDER_COLOR)
                    .attr("stroke-width", strokeWidth)
                    .attr('shape-rendering', shape_rendering);
            targetGroup.append("path").attr('d', 'M 5,0 V 3 M 0,5 H 3 M 5,10 V 7 M 10,5 H 7')
                    .attr("stroke", "#ff0000")
                    .attr("stroke-width", strokeWidth)
                    .attr("fill", "none")
                    .attr('stroke-linecap', "butt")
                    .attr('stroke-linejoin', 'miter')
                    .attr('stroke-opacity', 1)
                    .attr('shape-rendering', shape_rendering);
            targetGroup.append("circle").attr('cx', widthRect / 2)
                    .attr('cy', heightRect / 2)
                    .attr('r', heightRect / 2 - 1)
                    .attr("fill", "none")
                    .attr("stroke", "#ff0000")
                    .attr("stroke-width", strokeWidth)
                    .attr("stroke-dasharray", 2);
            return targetGroup;
        },
        /**
         * Create target symbol
         * @param {number} widthRect - rectangle width
         * @param {number} heightRect - rectangle height
         * @param {D3 Object data} nodeD3Self - single node
         * @param {number} strokeWidth - line size
         */
        drawTargetSymbol: function (widthRect, heightRect, nodeD3Self, strokeWidth) {
            //var targetGroup = d3.select(`#${Network.getSvgContentId()}`).select("#"+d.id).append('g').attr('id', 'targetSymbol');
            var targetGroup = nodeD3Self.append('g').attr('id', 'targetSymbol');
            return this.drawTargetSymbolElements(targetGroup, widthRect, heightRect, strokeWidth);
        },
        deMorgan: {
            uncheckedColor: "#b0b0b0",
            checkedColor: "black",
            clearDeMorganEvidence: function (data, index, divs) {
                d3.event.stopPropagation();
                if (data.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                    Node.Evidence.clearTemporalEvidence(data);
                } else {
                    Node.Evidence.clearEvidence(d3.select(divs[index]));
                }
            },
            setDeMorganEvidence: function (evidence) {
                return function (data, index, divs) {
                    d3.event.stopPropagation();
                    if (data.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                        Node.Evidence.setTemporalEvidence(data, evidence ? [[0, 0]] : [[0, 1]]);
                    } else {
                        Node.Evidence.setEvidence(d3.select(divs[index]), evidence ? data.outcome[0] : data.outcome[1]);
                    }
                };
            },
            drawEvidenceSymbol: function (text, fontColor, widthRect, heightRect, fillRect, nodeD3Self, strokeWidth, evidenceFunction) {
                var shape_rendering = "inherit";
                var questionGroup = nodeD3Self.append('g').attr('id', 'deMorganSymbols');
                questionGroup.append("rect").attr('x', '0')
                        .attr('y', '0')
                        .attr('width', widthRect)
                        .attr('height', heightRect)
                        .attr("fill", fillRect)
                        .attr("stroke", GenieSymbols.StatusNodeIcon.SYMBOL_BORDER_COLOR)
                        .attr("stroke-width", strokeWidth)
                        .attr('shape-rendering', shape_rendering)
                        .style("cursor", "pointer");
                questionGroup.append("text")
                        .attr('x', '1')
                        .attr('y', '9')
                        .style("font-style", "normal")
                        .style("font-weight", "900")
                        .style("font-size", "11px")
                        .style("line-height", "1.25")
                        .style("font-family", "sans-serif")
                        .style("fill", fontColor)
                        .style("fill-opacity", "1")
                        .style("stroke-width", "1")
                        .text(text)
                        .style("cursor", "pointer");
                questionGroup.on("click", evidenceFunction);
                return questionGroup;
            },
            trueUnchecked: function (widthRect, heightRect, fillRect, nodeD3Self, strokeWidth) {
                return this.drawEvidenceSymbol("T", this.uncheckedColor, widthRect, heightRect, fillRect, nodeD3Self, strokeWidth, this.setDeMorganEvidence(true));
            },
            falseUnchecked: function (widthRect, heightRect, fillRect, nodeD3Self, strokeWidth) {
                return this.drawEvidenceSymbol("F", this.uncheckedColor, widthRect, heightRect, fillRect, nodeD3Self, strokeWidth, this.setDeMorganEvidence(false));
            },
            trueChecked: function (widthRect, heightRect, fillRect, nodeD3Self, strokeWidth) {
                return this.drawEvidenceSymbol("T", this.checkedColor, widthRect, heightRect, fillRect, nodeD3Self, strokeWidth, this.clearDeMorganEvidence);
            },
            falseChecked: function (widthRect, heightRect, fillRect, nodeD3Self, strokeWidth) {
                return this.drawEvidenceSymbol("F", this.checkedColor, widthRect, heightRect, fillRect, nodeD3Self, strokeWidth, this.clearDeMorganEvidence);
            },
            setEvidenceSymbol: function (widthRect, heightRect, fillRect, nodeD3Self, strokeWidth) {
                var symbols = [];
                symbols.push(this.trueUnchecked(widthRect, heightRect, fillRect, nodeD3Self, strokeWidth));
                symbols.push(this.falseUnchecked(widthRect, heightRect, fillRect, nodeD3Self, strokeWidth));
                return symbols;

            },
            setEvidenceFalseSymbol: function (widthRect, heightRect, fillRect, nodeD3Self, strokeWidth) {
                var symbols = [];
                symbols.push(this.trueUnchecked(widthRect, heightRect, fillRect, nodeD3Self, strokeWidth));
                symbols.push(this.falseChecked(widthRect, heightRect, fillRect, nodeD3Self, strokeWidth));
                return symbols;
            },
            setEvidenceTrueSymbol: function (widthRect, heightRect, fillRect, nodeD3Self, strokeWidth) {
                var symbols = [];
                symbols.push(this.trueChecked(widthRect, heightRect, fillRect, nodeD3Self, strokeWidth));
                symbols.push(this.falseUnchecked(widthRect, heightRect, fillRect, nodeD3Self, strokeWidth));
                return symbols;
            }
        },
        /**
         * Round for 0,5 or 1 depends on stroke-width. Preventing blur
         * @param {Object} point - [x,y]
         * @param {number} stroke_width - strocke width
         * @returns {Array} - [x,y]
         */
        roundForWholePixel: function (point, stroke_width) {
            //even stroke_width
            var x = parseInt(point[0]);
            var y = parseInt(point[1]);
            if (stroke_width % 2 !== 0) {//odd stroke_width
                x += 0.5;
                y += 0.5;
            }
            return [x, y];
        },
        updateSymbols: function (node) {
            var symbols;
            var symbolsHtml = [];
            var symbol_stroke_width = node.borderWidth / 2;
            var nodeD3Self = Node.selectNodeFromObject(node);
            //targets
            if (node.isTarget) {
                symbolsHtml.push(this.drawTargetSymbol(this.widthRect, this.heightRect, nodeD3Self, symbol_stroke_width));
            }
            //not updated
            if (typeof node.values === 'undefined') {
                symbolsHtml.push(this.drawQuestionSymbol(this.widthRect, this.heightRect, "white", nodeD3Self, symbol_stroke_width));
            }
            //not propagated, constant equation node without set evidence or not propagated other nodes without set evidence
            if (typeof node.values !== 'undefined' && !(typeof(node.evidence) !== "undefined" || (node.temporalEvidence && node.temporalEvidence.length > 0)) && !node.isPropagated && (!node.checkType(NODE_TYPE.EQUATION) || (node.checkType(NODE_TYPE.EQUATION) && node.isConstantEquation))) {
                if (node.checkType(NODE_TYPE.DEMORGAN)) {
                    var symbols = this.deMorgan.setEvidenceSymbol(this.widthRect, this.heightRect, "white", nodeD3Self, symbol_stroke_width);
                    symbols.forEach(s => symbolsHtml.push(s));
                } else {
                    symbolsHtml.push(this.drawCheckSymbol(this.widthRect, this.heightRect, "white", "#1ca883", nodeD3Self, symbol_stroke_width));
                }
            }
            //not constant equation node without set evidence
            if (node.checkType(NODE_TYPE.EQUATION) && node.isConstantEquation === false && !(node.evidence || (node.temporalEvidence && node.temporalEvidence.length > 0))) {
                symbolsHtml.push(this.drawCheckSymbol(this.widthRect, this.heightRect, "white", "#1ca883", nodeD3Self, symbol_stroke_width));//when this situation is?
            }
            //propagated nodes
            if (node.isPropagated) {
                symbolsHtml.push(this.drawGroundSymbol(this.widthRect, this.heightRect, "yellow", "#000000", nodeD3Self, symbol_stroke_width));
            }
            //node with set evidence
            if (typeof(node.evidence) !== "undefined" || (node.temporalEvidence && node.temporalEvidence.length > 0)) {
                if (node.checkType(NODE_TYPE.DEMORGAN)) {
                    var symbols = [];
                    if (node.temporalEvidence && node.temporalEvidence.length > 0) {//dynamic evidence
                        if(node.temporalEvidence.length === 1 && node.temporalEvidence[0][0] === 0){
                            switch (node.temporalEvidence[0][1]) {
                                case 0:
                                    symbols = this.deMorgan.setEvidenceTrueSymbol(this.widthRect, this.heightRect, "white", nodeD3Self, symbol_stroke_width);
                                    break;

                                case 1:
                                    symbols = this.deMorgan.setEvidenceFalseSymbol(this.widthRect, this.heightRect, "white", nodeD3Self, symbol_stroke_width);
                                    break;
                            }
                        }
                    } else {
                        //true evidence
                        if (node.evidence === node.outcome[0]) {
                            symbols = this.deMorgan.setEvidenceTrueSymbol(this.widthRect, this.heightRect, "white", nodeD3Self, symbol_stroke_width);
                        } else {
                            symbols = this.deMorgan.setEvidenceFalseSymbol(this.widthRect, this.heightRect, "white", nodeD3Self, symbol_stroke_width);
                        }
                    }
                    symbols.forEach(s => symbolsHtml.push(s));

                } else if (Node.Evidence.isVirtualEvidence(node.evidence)) {//virtual evidence
                    symbolsHtml.push(this.drawGroundSymbol(this.widthRect, this.heightRect, "white", "gray", nodeD3Self, symbol_stroke_width));
                } else {
                    symbolsHtml.push(this.drawGroundSymbol(this.widthRect, this.heightRect, "white", "#000000", nodeD3Self, symbol_stroke_width));
                }
            }
            //node with sticky notes
            if (node.stickyNotes !== "") {
                symbolsHtml.push(this.drawStickyNotesSymbol(this.widthRect, this.heightRect, "yellow", nodeD3Self, symbol_stroke_width));
            }

            symbols = nodeD3Self.append('g').attr('id', 'symbols');
            for (var i = 0; i < symbolsHtml.length; i++) {
                symbols.node().appendChild(symbolsHtml[i].attr("transform", Utilities.getTransformString((i * this.widthRect + symbol_stroke_width / 2), symbol_stroke_width / 2)).node());
            }

            //translate symbols
            if (typeof symbols !== 'undefined') {
                symbols.attr("transform", nodeTransform => {
                    if (node.isBarChart) {
                        var tX = node.template.x + node.template.width - symbolsHtml.length * (this.widthRect + symbol_stroke_width / 2);
                        var tY = node.template.y + node.template.height - (this.heightRect + symbol_stroke_width / 2);
                        var point = this.roundForWholePixel([tX, tY], symbol_stroke_width);
                        return Utilities.getTransformString(point[0], point[1]);
                    } else if (node.checkType(NODE_TYPE.DECISION)) {
                        var tX = node.x + node.width - symbolsHtml.length * (this.widthRect + symbol_stroke_width / 2);
                        var tY = node.y + node.height - (this.heightRect + symbol_stroke_width / 2);
                        var point = this.roundForWholePixel([tX, tY], symbol_stroke_width);
                        return Utilities.getTransformString(point[0], point[1]);
                    } else if (node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU])) {
                        var polygonPoints = Geometric.GetPolygonPoints(node.x, node.y, node.width, node.height);
                        var pStart = {x: polygonPoints[3].x, y: polygonPoints[3].y};
                        var pStop = {x: polygonPoints[2].x, y: polygonPoints[2].y};
                        var tX = pStart.x - (pStart.x - pStop.x) / 2 - symbolsHtml.length * (this.widthRect + symbol_stroke_width / 2) / 2;
                        var tY = pStop.y - (pStop.y - pStart.y) / 2 - (this.heightRect + symbol_stroke_width / 2) / 2;
                        var point = this.roundForWholePixel([tX, tY], symbol_stroke_width);
                        return Utilities.getTransformString(point[0], point[1]);
                    } else {//default -> ellipse
                        var point = this.ellipseNicePoint(node.width, node.height);
                        point = this.roundForWholePixel([(point[0] + node.x + node.width / 2 - this.widthRect * symbolsHtml.length / 2), (point[1] + node.y + node.height / 2 - this.heightRect / 2)], symbol_stroke_width);
                        return Utilities.getTransformString(point[0], point[1]);
                    }
                });
            }
        },
        /**
         * update all symbols
         */
        updateAllSymbols: function () {
            this.removeAllSymbol();
            var d = d3.select(`#${Network.getSvgContentId()}`);
            d.selectAll(Utilities.getGTypeSelector(Node.TYPE)).each(data => {
                this.updateSymbols(data);
            });
        }

    },
    StatusRColumnIcon: {
        propagatedSymbolHTML: null,
        targetSymbolHTML: null,
        evidenceSymbolHTML: null,
        virtualEvidenceSymbolHTML: null,
        /**
         * Create all symbols for later use
         * Dynamic download of icons HTML code based on drawGroundSymbolElements and drawTargetSymbolElements function from drawD3.
         * Using for static text like symbols in right column.
         */
        initHTMLsymbols: function () {
            const HEIGHT_ICON = 12;
            const WIDTH_ICON = 12;

            let cssSelector = Network.getSVG_CSS_SELECTOR();
            let tmpG = d3.select(cssSelector).append("g").attr("id", "tmpSymbol").attr('visibility', 'hidden');
            let tmpSvgPlate = null;
            if(tmpG.size() === 0){
                cssSelector = "body";
                tmpSvgPlate = d3.select(cssSelector).append("svg");
                tmpG = tmpSvgPlate.append("g").attr("id", "tmpSymbol").attr('visibility', 'hidden');
            }

            GenieSymbols.StatusNodeIcon.drawGroundSymbolElements(d3.select(cssSelector).select("#tmpSymbol"), 10, 10, '#FFFF00', "#000000", 0.5, false);
            let symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.propagatedSymbolHTML = '<svg class="propSymbRColumn" viewBox="0 0 ' + HEIGHT_ICON + ' ' + WIDTH_ICON + '" style="overflow: visible;width: 1em;vertical-align: -.252em;height: 1em;font-size: inherit;display: inline-block;">' + symbolHTML + '</svg>';

            GenieSymbols.StatusNodeIcon.drawTargetSymbolElements(d3.select(cssSelector).select("#tmpSymbol"), 10, 10, 0.5);
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.targetSymbolHTML = '<svg class="tarSymbRColumn" viewBox="0 0 ' + HEIGHT_ICON + ' ' + WIDTH_ICON + '" style="overflow: visible;width: 1em;vertical-align: -.252em;height: 1em;font-size: inherit;display: inline-block;">' + symbolHTML + '</svg>';

            GenieSymbols.StatusNodeIcon.drawGroundSymbolElements(d3.select(cssSelector).select("#tmpSymbol"), 10, 10, '#FFFFFF', "#000000", 0.5, false);
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.evidenceSymbolHTML = '<svg class="evidSymbRColumn" viewBox="0 0 ' + HEIGHT_ICON + ' ' + WIDTH_ICON + '" style="overflow: visible;width: 1em;vertical-align: -.252em;height: 1em;font-size: inherit;display: inline-block;">' + symbolHTML + '</svg>';

            GenieSymbols.StatusNodeIcon.drawGroundSymbolElements(d3.select(cssSelector).select("#tmpSymbol"), 10, 10, '#FFFFFF', "gray", 0.5, false);
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").remove();
            this.virtualEvidenceSymbolHTML = '<svg class="virtualEvidSymbRColumn" viewBox="0 0 ' + HEIGHT_ICON + ' ' + WIDTH_ICON + '" style="overflow: visible;width: 1em;vertical-align: -.252em;height: 1em;font-size: inherit;display: inline-block;">' + symbolHTML + '</svg>';

            if(tmpSvgPlate !== null){
                tmpSvgPlate.remove();
            }
        },
        /**
         * remove all symbol from right column
         */
        removeAll: function () {
            d3.select(RightColumn.CSSselector).selectAll(".evidSymbRColumn").remove();
            d3.select(RightColumn.CSSselector).selectAll(".propSymbRColumn").remove();
        },
        /**
         * Set symbols in right column for node
         * @param {D3 Object} node - node for set symbols
         */
        setSymbols: function (node) {
            if(this.propagatedSymbolHTML === null || this.targetSymbolHTML === null || this.evidenceSymbolHTML === null || this.virtualEvidenceSymbolHTML === null){
                this.initHTMLsymbols();
            }
            var isVirtualEvidence = Node.Evidence.isVirtualEvidence(node.evidence);
            var html = "";
            if(node.isTarget){
                html += this.targetSymbolHTML;
            }
            if(node.isPropagated){
                html += this.propagatedSymbolHTML;
            }
            if(typeof(node.evidence) !== "undefined" || (node.temporalEvidence && node.temporalEvidence.length > 0)){
                if (isVirtualEvidence) {
                    html += this.virtualEvidenceSymbolHTML;
                } else {
                    html += this.evidenceSymbolHTML;
                }
            }
            html += " ";
            html += node.name;
            d3.select("#atext_" + Node.getNodeIdFromObject(node)).html(html);
//            if (node.isPropagated && node.isTarget) {
//                d3.select("#atext_" + node.id).html(this.targetSymbolHTML + this.propagatedSymbolHTML + " " + node.name);
//            } else if ((node.evidence && node.isTarget) || ((node.temporalEvidence && node.temporalEvidence.length > 0) && node.isTarget)) {
//                if (isVirtualEvidence) {
//                    d3.select("#atext_" + node.id).html(this.targetSymbolHTML + this.virtualEvidenceSymbolHTML + " " + node.name);
//                } else {
//                    d3.select("#atext_" + node.id).html(this.targetSymbolHTML + this.evidenceSymbolHTML + " " + node.name);
//                }
//            } else if (node.isPropagated) {
//                d3.select("#atext_" + node.id).html(this.propagatedSymbolHTML + " " + node.name);
//            } else if (node.evidence || (node.temporalEvidence && node.temporalEvidence.length > 0)) {
//                if (isVirtualEvidence) {
//                    d3.select("#atext_" + node.id).html(this.virtualEvidenceSymbolHTML + " " + node.name);
//                } else {
//                    d3.select("#atext_" + node.id).html(this.evidenceSymbolHTML + " " + node.name);
//                }
//            }
        }
    },

    //###############################################################################
    //####### SMALL NODE SYMBOL USING IN BAR CHART MODE IMPORTANT VARIABLE ########
    //####### before node name because use it #######################################
    //###############################################################################
    BarChartSymbol: {
        smallSymbolWidth: 15,
        smallSymbolHeight: 10,
        smallMarginSymbolLeft: 1, //transformX
        smallSymbolStrokeWidth: 0.35,
        smallHexagon: function (x, y, nodeId, transformY) {
            this.smallHexagonElements(d3.select(NodeShape.BarChart.getNameSelector(nodeId)), x, y, transformY);
        },
        smallHexagonElements: function (targetGroup, x, y, transformY) {
            var hexagonPoints = Geometric.GetPolygonPoints(x, y, this.smallSymbolWidth, this.smallSymbolHeight);
            var hexagonPointsString = "";
            for (var i = 0; i < hexagonPoints.length; i++) {
                hexagonPointsString += hexagonPoints[i].x;
                hexagonPointsString += " ";
                hexagonPointsString += hexagonPoints[i].y;
                hexagonPointsString += " ";
            }
            targetGroup.append('polygon')
                    .attr('points', hexagonPointsString)
                    .style("fill", "blue")
                    .style("stroke-width", this.smallSymbolStrokeWidth)
                    .style("stroke", "black")
                    .attr("transform", Utilities.getTransformString(this.smallMarginSymbolLeft, transformY));
        },
        smallRectangle: function (x, y, nodeId, transformY) {
            this.smallRectangleElements(d3.select(NodeShape.BarChart.getNameSelector(nodeId)), x, y, transformY);
        },
        smallRectangleElements: function (targetGroup, x, y, transformY) {
            var internalMargin = 2;// IMPORTANT! attr("width", this.smallSymbolWidth-2)
            return targetGroup.append("rect")
                    .attr("x", x + internalMargin)
                    .attr("y", y)
                    .attr("width", this.smallSymbolWidth - internalMargin)
                    .attr("height", this.smallSymbolHeight)
                    .style("fill", "#78de43")
                    .style("stroke-width", this.smallSymbolStrokeWidth)
                    .style("stroke", "black")
                    .attr("transform", Utilities.getTransformString(this.smallMarginSymbolLeft, transformY));
        },
        smallRoundedRectangleElements: function (targetGroup, x, y, transformY, rx, ry, fill) {
            let smallRectangle = this.smallRectangleElements(targetGroup, x, y, transformY);
            smallRectangle.style("fill", fill)
                    .attr("rx", rx)
                    .attr("ry", ry)
                    .style("stroke-width", ".1em");
        },
        smallEllipse: function (x, y, nodeId, transformY) {
            this.smallEllipseElements(d3.select(NodeShape.BarChart.getNameSelector(nodeId)), x, y, transformY);
        },
        smallEllipseElements: function (targetGroup, x, y, transformY) {
            targetGroup.append("ellipse")
                    .attr("cx", x + this.smallSymbolWidth / 2)
                    .attr("cy", y + this.smallSymbolHeight / 2)
                    .attr("rx", this.smallSymbolWidth / 2 - 1)
                    .attr("ry", this.smallSymbolHeight / 2)
                    .style("fill", "white")
                    .style("stroke-width", this.smallSymbolStrokeWidth)
                    .style("stroke", "black")
                    .attr("transform", Utilities.getTransformString(this.smallMarginSymbolLeft, transformY));
        },
        smallRing: function (x, y, nodeId, transformY) {//for deterministic
            this.smallRingElements(d3.select(NodeShape.BarChart.getNameSelector(nodeId)), x, y, transformY);
        },
        smallRingElements: function (targetGroup, x, y, transformY) {//for deterministic
            targetGroup.append("ellipse")
                    .attr("cx", x + this.smallSymbolWidth / 2)
                    .attr("cy", y + this.smallSymbolHeight / 2)
                    .attr("rx", this.smallSymbolWidth / 2 - 2)
                    .attr("ry", this.smallSymbolHeight / 2 - 1)
                    .style("fill", "none")
                    .style("stroke-width", 4)
                    .style("stroke", "red")
                    .attr("transform", Utilities.getTransformString(this.smallMarginSymbolLeft, transformY));
        },
        smallEquationEllipse: function (x, y, nodeId, transformY) {
            var g = d3.select(NodeShape.BarChart.getNameSelector(nodeId))
                    .append("g");
            this.smallEquationEllipseElements(g, x, y, nodeId, transformY);
        },
        smallEquationEllipseElements: function (targetGroup, x, y, nodeId, transformY) {
            var innerMargin = 5;
            targetGroup.append("ellipse")
                    .attr("cx", x + this.smallSymbolWidth / 2)
                    .attr("cy", y + this.smallSymbolHeight / 2)
                    .attr("rx", this.smallSymbolWidth / 2 - 1)
                    .attr("ry", this.smallSymbolHeight / 2)
                    .style("fill", "green")
                    .style("stroke-width", this.smallSymbolStrokeWidth)
                    .style("stroke", "black")
                    .attr("transform", Utilities.getTransformString(this.smallMarginSymbolLeft, transformY));
            targetGroup.append("path")
                    .attr("d", "M 0,1 Q " + (this.smallSymbolHeight - innerMargin) / 2 + "," + (-1 - ((this.smallSymbolHeight - innerMargin) / 2)) + " " + (this.smallSymbolWidth - innerMargin) / 2 + ",0 T " + (this.smallSymbolWidth - innerMargin) + ",-1")
                    .style("fill", "transparent")
                    .style("stroke", "white")
                    .style("stroke-width", (innerMargin / 4) + "px")
                    .style("stroke-linecap", "butt")
                    .style("stroke-linejoin", "miter")
                    .style("stroke-opacity", "1")
                    .attr("class", typeof nodeId !== "undefined" ? "sinusEquationSymbol" + nodeId : "")
                    .attr("transform", Utilities.getTransformString((x + this.smallMarginSymbolLeft + innerMargin / 2), (y + transformY + this.smallSymbolHeight / 2)));
        },
        smallCircleWithColors: function (x, y, nodeId, transformY, nodeSelf) {
            var domElement = typeof nodeSelf === "undefined" ? d3.select(NodeShape.BarChart.getNameSelector(nodeId)) : nodeSelf;
            var deMorganColorsCircle = domElement
                    .append("g")
                    .classed("deMorganColorCircle", true)
                    .attr("transform", Utilities.getTransformString(this.smallMarginSymbolLeft, transformY));
            var path1 = `M${x + this.smallSymbolHeight / 2},${y + this.smallSymbolHeight / 2} l0,-${this.smallSymbolHeight / 2} A${this.smallSymbolHeight / 2},${this.smallSymbolHeight / 2} 0 0,1 ${x + this.smallSymbolHeight / 2},${y + this.smallSymbolHeight} z`;
            var path2 = `M${x + this.smallSymbolHeight / 2},${y + this.smallSymbolHeight / 2} l0,-${this.smallSymbolHeight / 2} A${this.smallSymbolHeight / 2},${this.smallSymbolHeight / 2} 0 0,0 ${x + this.smallSymbolHeight / 2},${y + this.smallSymbolHeight} z`;
            deMorganColorsCircle.append("path")
                    .classed("endColor", true)
                    .attr("d", path1)
                    .style("fill","transparent");
            deMorganColorsCircle.append("path")
                    .classed("startColor", true)
                    .attr("d", path2)
                    .style("fill","transparent");
        }
    },
    FilterGraphIcon: {
        hexagon: null,//utility, mau
        rectangle: null,//decision
        roundedRectangle: null,//submodel
        ellipse: null,//cpt, noisy, demorgan
        ring: null,//truth_table
        equationEllipse: null,//equation
        tableIcon: null,
        tableWithSelectedRowIcon: null,
        /**
         * Create all symbols for later use
         * Dynamic download of icons HTML code based on drawGroundSymbolElements and drawTargetSymbolElements function from drawD3.
         * Using for static text like symbols in right column.
         */
        initHTMLsymbols: function () {
            const svgStyle = [
                "overflow: visible",
                "width: 1em",
                "vertical-align: -.252em",
                "height: 1em",
                "font-size: inherit",
                "display: inline-block",
                "margin-bottom: .15em"
            ];
            const HEIGHT_ICON = GenieSymbols.BarChartSymbol.smallSymbolHeight;
            const WIDTH_ICON = GenieSymbols.BarChartSymbol.smallSymbolWidth;
            let cssSelector = Network.getSVG_CSS_SELECTOR();
            let tmpG = d3.select(cssSelector).append("g").attr("id", "tmpSymbol").attr('visibility', 'hidden');
            let tmpSvgPlate = null;
            if(tmpG.size() === 0){
                cssSelector = "body";
                tmpSvgPlate = d3.select(cssSelector).append("svg");
                tmpG = tmpSvgPlate.append("g").attr("id", "tmpSymbol").attr('visibility', 'hidden');
            }

            GenieSymbols.BarChartSymbol.smallHexagonElements(d3.select(cssSelector).select("#tmpSymbol"), 0, 0);
            let symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.hexagon = `<svg viewBox="0 0 ${WIDTH_ICON} ${HEIGHT_ICON}" style="${svgStyle.join(";")}">${symbolHTML}</svg>`;

            GenieSymbols.BarChartSymbol.smallRectangleElements(d3.select(cssSelector).select("#tmpSymbol"), 0, 0);
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.rectangle = `<svg viewBox="0 0 ${WIDTH_ICON} ${HEIGHT_ICON}" style="${svgStyle.join(";")}">${symbolHTML}</svg>`;

            GenieSymbols.BarChartSymbol.smallRoundedRectangleElements(d3.select(cssSelector).select("#tmpSymbol"), 0, 0, undefined, 2, 2, "#00ffff");
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.roundedRectangle = `<svg viewBox="0 0 ${WIDTH_ICON} ${HEIGHT_ICON}" style="${svgStyle.join(";")}">${symbolHTML}</svg>`;

            GenieSymbols.BarChartSymbol.smallEllipseElements(d3.select(cssSelector).select("#tmpSymbol"), 0, 0);
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.ellipse = `<svg viewBox="0 0 ${WIDTH_ICON} ${HEIGHT_ICON}" style="${svgStyle.join(";")}">${symbolHTML}</svg>`;

            GenieSymbols.BarChartSymbol.smallRingElements(d3.select(cssSelector).select("#tmpSymbol"), 0, 0);
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.ring = `<svg viewBox="0 0 ${WIDTH_ICON} ${HEIGHT_ICON}" style="${svgStyle.join(";")}">${symbolHTML}</svg>`;

            this.drawTableIcon(d3.select(cssSelector).select("#tmpSymbol"));
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.tableIcon = `<svg viewBox="0 0 ${WIDTH_ICON} ${HEIGHT_ICON}" style="${svgStyle.join(";")}">${symbolHTML}</svg>`;

            this.drawTableWithSelectedRowIcon(d3.select(cssSelector).select("#tmpSymbol"));
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").selectAll("*").remove();
            this.tableWithSelectedRowIcon = `<svg viewBox="0 0 ${WIDTH_ICON} ${HEIGHT_ICON}" style="${svgStyle.join(";")}">${symbolHTML}</svg>`;

            GenieSymbols.BarChartSymbol.smallEquationEllipseElements(d3.select(cssSelector).select("#tmpSymbol"), 0, 0, undefined, HEIGHT_ICON / 2);
            symbolHTML = d3.select(cssSelector).select("#tmpSymbol").node().innerHTML;
            d3.select(cssSelector).select("#tmpSymbol").remove();
            this.equationEllipse = `<svg viewBox="0 0 ${WIDTH_ICON} ${HEIGHT_ICON}" style="${svgStyle.join(";")}">${symbolHTML}</svg>`;

            if(tmpSvgPlate !== null){
                tmpSvgPlate.remove();
            }
        },
        drawTableWithSelectedRowIcon: function (targetGroup) {
            let tableIcon = this.drawTableIcon(targetGroup);
            tableIcon.insideHRectangle.style("fill", "#7eff5a");
            tableIcon.topRectangle.style("fill", "rgb(44, 129, 238)");
        },
        drawTableIcon: function (targetGroup) {
            // _______
            //|       |
            // -------
            //|_|_|_|_|
            //|_|_|_|_|
            //|_|_|_|_|
            const totalW = GenieSymbols.BarChartSymbol.smallSymbolWidth;
            const totalH = GenieSymbols.BarChartSymbol.smallSymbolHeight;
            // -------
            //|       |
            //|       | outside rectangle
            //|_______|
            let outsideRectangle = targetGroup.append("rect")
                    .attr("x", 0)
                    .attr("y", totalH * 1 / 7)
                    .attr("width", totalW)
                    .attr("height", totalH - totalH * 1 / 7)
                    .style("fill", "white")
                    .style("stroke", "#868686")
                    .style("stroke-width", GenieSymbols.BarChartSymbol.smallSymbolStrokeWidth);
            // -------
            //|_______|
            //|_______| inside horizontal rectangle
            //|_______|
            const horizontalLineCount = 3;
            const lineHStep = (totalH - totalH * 1 / 7) / horizontalLineCount;
            let insideHRectangle = targetGroup.append("rect")
                    .attr("x", 0)
                    .attr("y", (totalH * 1 / 7) + lineHStep)
                    .attr("width", totalW)
                    .attr("height", lineHStep)
                    .style("fill", "white")
                    .style("stroke", "#868686")
                    .style("stroke-width", GenieSymbols.BarChartSymbol.smallSymbolStrokeWidth);
            // -------
            //|_|_|_|_|
            //|_|_|_|_| inside vertical lines
            //|_|_|_|_|
            const verticalLineCount = 4;
            const lineWStep = totalW / verticalLineCount;
            let lines = [];
            for (let i = 1; i <= verticalLineCount; i++) {
                let l = targetGroup.append("line")
                        .attr("x1", i * lineWStep)
                        .attr("y1", totalH * 1 / 7)
                        .attr("x2", i * lineWStep)
                        .attr("y1", totalH)
                        .style("stroke", "#868686")
                        .style("stroke-width", GenieSymbols.BarChartSymbol.smallSymbolStrokeWidth);
                lines.push(l);
            }
            // _______
            //|       | top rectangle
            // -------
            //|_|_|_|_|
            //|_|_|_|_|
            //|_|_|_|_|
            let topRectangle = targetGroup.append("rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("width", totalW)
                    .attr("height", totalH * 1 / 7)
                    .style("fill", "#2ccaee")
                    .style("stroke", "#2936ee")
                    .style("stroke-width", GenieSymbols.BarChartSymbol.smallSymbolStrokeWidth);

            return {
                outsideRectangle,
                insideHRectangle,
                lines,
                topRectangle
            };
        }
    }
};

export default GenieSymbols;
