/* global RESPONSE_MAPPING, Node, d3, NODE_TYPE, parseFloat */

import * as d3 from 'd3';
import {NODE_TYPE, RESPONSE_MAPPING} from "./constantsMapping";
import Node from "./node";

var UpdateAdapter = {
    /**
     * adapt response from server to client app
     * @param {String} json
     * @returns {JSON} - adapt json
     */
    adapter: function (json, netHandle) {
        var data = json.data;
        var adaptedData = [];
        for (var i = 0; i < data.length; i++) {
            if (!data[i]) {
                continue;
            }
            var tmpNode = new Object();
            tmpNode.node = i;
            tmpNode.values = [];
            var valuesData = data[i][RESPONSE_MAPPING.U_VALUE];
            var node = d3.select("#" + Node.getNodeIdFromHandle(parseInt(tmpNode.node, 10), netHandle)).data()[0];
            if (data[i][RESPONSE_MAPPING.U_DISCRETIZATION_INTERVAL]) {
                tmpNode.discretizationsInterval = data[i][RESPONSE_MAPPING.U_DISCRETIZATION_INTERVAL];
            } else {
                tmpNode.discretizationsInterval = [];
            }
            tmpNode.sampleStats = undefined;
            if (valuesData.length > 1 && data[i][RESPONSE_MAPPING.U_IS_DISCRETIZED] === 0) {
                tmpNode.values = this.getRangeEquation(valuesData, data[i][RESPONSE_MAPPING.U_RANGE]);
                tmpNode.sampleStats = data[i][RESPONSE_MAPPING.U_RANGE];
            } else if (node.checkType(NODE_TYPE.EQUATION) && data[i][RESPONSE_MAPPING.U_IS_DISCRETIZED] === 1 && data[i][RESPONSE_MAPPING.U_DISCRETIZATION_INTERVAL]) {
                tmpNode.values = this.getDiscretizedData(valuesData, tmpNode.node, tmpNode.discretizationsInterval, data[i][RESPONSE_MAPPING.U_RANGE], netHandle);
            } else {
                tmpNode.values = valuesData.map((val, idx) => ({
                    value: val,
                    outcomeIndex: idx
                }));
            }
            if (data[i][RESPONSE_MAPPING.U_IS_PROPAGATED]) {
                tmpNode.isPropagated = true;
            } else {
                tmpNode.isPropagated = false;
            }
            if (data[i][RESPONSE_MAPPING.U_INDEXING_PARENTS]) {
                tmpNode.indexingParentsIds = this.adapterIndexingParents(data[i][RESPONSE_MAPPING.U_INDEXING_PARENTS], i, data, netHandle);
            } else {
                tmpNode.indexingParentsIds = [];
            }

            tmpNode.isValueDiscretized = (data[i][RESPONSE_MAPPING.U_IS_DISCRETIZED] === 1);

            adaptedData.push(tmpNode);
        }
        return adaptedData;
    },
    /**
     * @private
     * Adapt indexing parents
     * @param {String} data
     * @returns {Array} - indexing parents
     */
    adapterIndexingParents: function (data, nodeHandle, responseNodes, netHandle) {
        var indexingP = [];
        data.push(nodeHandle);//add self node handle to array end
        for (var i = 0; i < data.length; i++) {
            var tmpIndexP = new Object();
            tmpIndexP.node = data[i];
            var indexIds = this.getStateTypeLabel(data, data[i], responseNodes, netHandle);
            if (indexIds) {
                tmpIndexP.outcomeIds = [indexIds];
            }
            indexingP.push(tmpIndexP);
        }
        return indexingP;
    },
    /**
     * @private
     * Get correct state type
     * @param {array} indexingParents - node indexing parents
     * @param {int} handle - node handle
     * @returns {String} \"Exp. utility\" if node has indexing parents and it is Utility or MAU node. \"Weights\" if node hasn't indexing parents and it is MAU node. \"Value\" otherwise.
     */
    getStateTypeLabel: function (indexingParents, handle, responseNodes, netHandle) {
        var node = d3.select("#" + Node.getNodeIdFromHandle(parseInt(handle, 10), netHandle)).data()[0];

        if (indexingParents.length > 0 && node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU])) {
            return "Exp. utility";
        }
        if (node.checkType(NODE_TYPE.MAU)) {
            return "Weights";
        }
        var valuesArray = responseNodes[handle][RESPONSE_MAPPING.U_VALUE];
        if (node.checkType(NODE_TYPE.EQUATION) && (node.isConstantEquation || node.evidence || (valuesArray.length === 1 && typeof boundary === 'undefined'))) {
            return "Value";
        }
        return 0; // TODO different return types are necessary now because of different return usage - it should be simplified

    },
    /**
     * @private
     * Get bins
     * @param {Array} samples
     * @param {Array} globalRange
     * @returns {Array}
     */
    getRangeEquation: function (samples, globalRange) {
        var min = globalRange[0];
        var max = globalRange[1];
        var bins = 20;//for hybrid networks
        var result = new Array(samples.length);
        var width = max - min;
        var interval = width / bins;
        //add to update node object
        for (var h = 0; h < samples.length; h++) {
            var minTmp = min + h * interval;
            var maxTmp = min + (h + 1) * interval;
            result[h] = {value: samples[h], range: {min: minTmp, max: maxTmp}};
        }
        return result;
    },
    parseDiscretizationInterval: function (discretizationsInterval, bounds) {
        var result = [];
        result[0] = {
            id: "",
            boundary: bounds[0]
        };
        for (var i = 0; i < discretizationsInterval.length; i++) {
            result.push({
                id: discretizationsInterval[i][0],
                boundary: discretizationsInterval[i][1]
            });
        }
        return result;
    },
    /**
     * @private
     * Get discretized
     * @param {array} values
     * @param {int} handle
     * @returns {Array}
     */
    getDiscretizedData: function (values, handle, discretizationsIntervalRaw, bounds, netHandle) {
        var node = d3.select("#" + Node.getNodeIdFromHandle(parseInt(handle, 10), netHandle)).data()[0];
        node.discretizationInterval = this.parseDiscretizationInterval(discretizationsIntervalRaw, bounds);
        var discretizationsInterval = node.discretizationInterval;
        var result = [];
        const stateLabels = Node.getStateLabels(node);
        for (var i = 0; i < values.length; i++) {
            var singleResult = new Object();
            singleResult.value = values[i];
            singleResult.outcomeId = stateLabels[i];
            if (discretizationsInterval[i + 1].id !== "") {
                singleResult.outcomeId = discretizationsInterval[i + 1].id;
            }
            result[i] = singleResult;
        }
        return result;
    }
};

export default UpdateAdapter;
