/*
 * IMPORTANT: Mark menu by 'user-menu' tag
 */
/* global d3, StartApp, Utilities, Request, Cases, Toasts, OAuth, BAYESBOX_MODE */

import * as d3 from 'd3';

import StartApp from "./startAppInfo";
import {BAYESBOX_MODE} from "./constantsMapping";
import Utilities from "./utilities";
import OAuth from "./oAuth";
import Cases from "./cases";
import Request from "./request";
import Toasts from "./toasts";

var User = (function () {
    var userName = null;
    var admin = false;
    var oAuth = false;
    var userID = "";
    var explicitCategoryAccess = false;
    var userMenu = null;
    var ChangePasswordErrorLocalization = {
        CURRETN_FIELD: "CurrentField",
        NEW_FIELD: "NewField"
    };

    function drawDropdownUserMenuTemplate(name) {
        var container = d3.select("*[user-menu]");
        if(container.selectAll("#userProfile").size() > 0){
            return container.select("#userName")
                .select(".dropdown-header");
        }
        userMenu = d3.select("*[user-menu]")
            .append("li")
            .attr("id", "userProfile")
            .classed("nav-item dropdown", true);
        userMenu.append("a")
            .classed("nav-link dropdown-toggle headerRight", true)
            .attr("id", "userName")
            .attr("href", "javascript:void(0)")
            .attr("data-toggle", "dropdown")
            .html('<i class="fas fa-user"></i>');
        var userDrpdownMenu = userMenu.append("div")
            .classed("dropdown-menu", true)
            .style("right", 0)
            .style("left", "auto")
            .attr("id", "userName");
        userDrpdownMenu.append("h6")
            .classed("dropdown-header", true)
            .text(name);
        return userDrpdownMenu;
    }

    function addLoggedUserMenu() {
        var name = userName;
        if(name === " " || name === ""){
            name = userID;
        }
        var userDrpdownMenu = drawDropdownUserMenuTemplate(name);
        if(admin || explicitCategoryAccess){
            if(window.location.pathname === "/bayesbox.html") {
                addDropdownItem(userDrpdownMenu, {
                    user: admin,
                    network: true,
                    cases: admin,
                    dashboards: true,
                    main_view: false,
                    change_password: true,
                    logout: true,
                    oauthLogIn: false
                });
            }
            if(window.location.pathname === "/users.html") {
                addDropdownItem(userDrpdownMenu, {
                    user: false,
                    network: true,
                    cases: admin,
                    dashboards: true,
                    main_view: true,
                    change_password: false,
                    logout: true,
                    oauthLogIn: false
                });
            }
            if(window.location.pathname === "/networks.html") {
                addDropdownItem(userDrpdownMenu, {
                    user: admin,
                    network: false,
                    cases: admin,
                    dashboards: true,
                    main_view: true,
                    change_password: true,
                    logout: true,
                    oauthLogIn: false
                });
            }
            if(window.location.pathname === "/cases.html") {
                addDropdownItem(userDrpdownMenu, {
                    user: admin,
                    network: true,
                    cases: false,
                    dashboards: true,
                    main_view: true,
                    change_password: false,
                    logout: true,
                    oauthLogIn: false
                });
            }
            if(window.location.pathname === "/dashboard.html") {
                addDropdownItem(userDrpdownMenu, {
                    user: admin,
                    network: true,
                    cases: admin,
                    dashboards: true,
                    main_view: true,
                    change_password: true,
                    logout: true,
                    oauthLogIn: false
                });
            }
            if(window.location.pathname === "/dashboards.html") {
                addDropdownItem(userDrpdownMenu, {
                    user: admin,
                    network: true,
                    cases: admin,
                    dashboards: false,
                    main_view: true,
                    change_password: true,
                    logout: true,
                    oauthLogIn: false
                });
            }
        } else {
            addDropdownItem(userDrpdownMenu, {
                user: false,
                network: false,
                cases: false,
                dashboards: false,
                main_view: false,
                change_password: oAuth ? false : true,
                logout: true,
                oauthLogIn: false
            });
        }
        $("nav a[href='/authentication/logout']").on("click", function (event) {
            event.preventDefault();
            event.stopPropagation();
            logout();
        });
    }

    function setCallbackAfterStandardLogIn(callback) {
        $('#standardLoginForm').unbind('submit');
        $("#standardLoginForm").submit(function (event) {
            event.preventDefault();
            var $form = $(this),
                email = $form.find('input[name="email"]').val(),
                password = $form.find('input[name="password"]').val(),
                url = $form.attr('action');

            var credentials = {email: email, password: password};
            $("#loginError").empty();
            let body = JSON.stringify(credentials);
            let done = (data, textStatus, jqXHR) => {
                let user = JSON.parse(data);
                setUser(user);
                StartApp.refreshProvidedFunctions();
                d3.select("#oAuthLoginModal").classed("fade", false);
                $("#oAuthLoginModal").modal("hide");
                d3.select("#oAuthLoginModal").classed("fade", true);
                if(Utilities.isFunction(callback)){
                    callback();
                }else{
                    console.error("Callback is not a function");
                }
            };
            let fail = (jqXHR) => {
                $("#loginError").append(jqXHR.responseText);
            };
            Request.login(body, done, fail);
        });
    }

    function logout() {
        let thenFunction = function (response) {
            let exceptParent = "authentication/login";
            if (response.redirected) {
                if (response.url.includes(exceptParent)) {
                    window.location.replace("bayesbox.html");
                } else {
                    window.location.replace(response.url);
                }
            } else if (response.ok) {
                if (window.location.pathname !== "/bayesbox.html") {
                    window.location.replace("login.html");
                }
                userMenu.remove();
                userName = null;
                admin = false;
                userID = "";
                userMenu = null;
                detectAndAddOauthLogIn();
                Cases.clean();
                StartApp.refreshProvidedFunctions();
            }
        };
        Request.logout(thenFunction);
    }
    function addChangePasswordModal() {
        var showPassword = function (selector, show) {
            show ? d3.select(selector).attr("type", "text") : d3.select(selector).attr("type", "password");
        };
        var content = d3.select("body")
            .append("div")
            .classed("modal fade", true)
            .attr("id", "changePasswordModal")
            .attr("tabindex", "-1")
            .attr("role", "dialog")
            .attr("aria-labelledby", "changePasswordLabel")
            .attr("aria-hidden", "true")
            .append("div")
            .classed("modal-dialog", true)
            .attr("role", "document")
            .append("div")
            .classed("modal-content", true);
        var header = content.append("div")
            .classed("modal-header", true);
        header.append("h5")
            .classed("modal-title", true)
            .attr("id", "changePasswordLabel")
            .text("Change password");
        var closeBtn = header.append("button")
            .classed("close", true)
            .attr("type", "button")
            .attr("data-dismiss", "modal")
            .attr("aria-label", "Close");
        closeBtn.append("span")
            .attr("aria-hidden", true)
            .html("&times;");
        var body = content.append("div")
            .classed("modal-body", true);

        body.append("div")
            .classed("form-group row text-center", true)
            .append("div")
            .classed("invalid-feedback", true)
            .attr("id", "generalChangeError")
            .text("");

        var email = body.append("div")
            .classed("form-group row d-none", true);
        email.append("label")
            .attr("for", "loginEmail")
            .classed("col-sm-5 col-form-label", true)
            .text("Email");
        var emailInput = email.append("div")
            .classed("input-group col-sm-7", true);
        emailInput.append("input")
            .attr("type", "text")
            .classed("form-control border-right-0 border", true)
            .attr("id","loginEmail");

        var oldPassword = body.append("div")
            .classed("form-group row", true);
        oldPassword.append("label")
            .attr("for", "currentPassword")
            .classed("col-sm-5 col-form-label", true)
            .text("Current Password");
        var oldPassInput = oldPassword.append("div")
            .classed("input-group col-sm-7", true);
        var oldPasswordField = oldPassInput.append("input")
            .attr("type", "password")
            .classed("form-control border-right-0 border", true)
            .attr("id","currentPassword");
        var showOldPasswordBtn = oldPassInput.append("span")
            .classed("input-group-append", true);
        showOldPasswordBtn.selectAll("button")
            .data([oldPasswordField])
            .enter()
            .append("button")
            .classed("btn border-left-0 border", true)
            .attr("type", "button")
            .on("mousedown", d => showPassword(d.node(), true))
            .on("mouseup", d => showPassword(d.node(), false))
            .append("i")
            .classed("fas fa-eye-solid btn-hover-black", true);
        oldPassInput.append("div")
            .classed("invalid-feedback", true)
            .attr("id", "currentPassErr")
            .text("");

        var newPassword = body.append("div")
            .classed("form-group row", true);
        newPassword.append("label")
            .attr("for", "newPassword")
            .classed("col-sm-5 col-form-label", true)
            .text("New Password");
        var newPassInput = newPassword.append("div")
            .classed("input-group col-sm-7", true);
        var newPassField = newPassInput.append("input")
            .attr("type", "password")
            .classed("form-control border-right-0 border", true)
            .attr("id","newPassword");
        var showNewPasswordBtn = newPassInput.append("span")
            .classed("input-group-append", true);
        showNewPasswordBtn.selectAll("button")
            .data([newPassField])
            .enter()
            .append("button")
            .classed("btn border-left-0 border", true)
            .attr("type", "button")
            .on("mousedown", d => showPassword(d.node(), true))
            .on("mouseup", d => showPassword(d.node(), false))
            .append("i")
            .classed("fas fa-eye-solid btn-hover-black", true);
        newPassInput.append("div")
            .classed("invalid-feedback", true)
            .attr("id", "newPassErr")
            .text("");

        var confirmPassword = body.append("div")
            .classed("form-group row", true);
        confirmPassword.append("label")
            .attr("for", "confirmPassword")
            .classed("col-sm-5 col-form-label", true)
            .text("Confirm New Password");
        var confirmPassInput = confirmPassword.append("div")
            .classed("input-group col-sm-7", true);
        var confirmPasswordField = confirmPassInput.append("input")
            .attr("type", "password")
            .classed("form-control border-right-0 border", true)
            .attr("id","confirmPassword");
        var showConfirmPasswordBtn = confirmPassInput.append("span")
            .classed("input-group-append", true);
        showConfirmPasswordBtn.selectAll("button")
            .data([confirmPasswordField])
            .enter()
            .append("button")
            .classed("btn border-left-0 border", true)
            .attr("type", "button")
            .on("mousedown", d => showPassword(d.node(), true))
            .on("mouseup", d => showPassword(d.node(), false))
            .append("i")
            .classed("fas fa-eye-solid btn-hover-black", true);
        confirmPassInput.append("div")
            .classed("invalid-feedback", true)
            .attr("id", "confirmPassErr")
            .text("Password doesn't match");

        var footer = content.append("div")
            .classed("modal-footer", true);
        footer.append("button")
            .attr("type", "button")
            .classed("btn btn-secondary", true)
            .attr("data-dismiss", "modal")
            .text("Cancel");
        footer.append("button")
            .attr("type", "button")
            .classed("btn btn-primary", true)
            .text("Change Password")
            .on("click", () => {
                if($("#newPassword").val() !== $("#confirmPassword").val()){
                    $("#confirmPassErr").show();
                } else {
                    $("#confirmPassErr").hide();
                    let body = JSON.stringify({
                        currentpass: $("#currentPassword").val(),
                        newpass: $("#newPassword").val()
                    });
                    let done = d => {
                        $('#changePasswordModal').modal('hide');
                        Toasts.add("Password", "Password was changed.");
                    };
                    let fail = (jqXHR, textStatus, errorThrown) => {
                        if (jqXHR.getResponseHeader("ErrorLocalization") !== null && jqXHR.getResponseHeader("ErrorLocalization") === ChangePasswordErrorLocalization.NEW_FIELD) {
                            $("#newPassErr").text(jqXHR.responseText);
                            $("#newPassErr").show();
                            $("#generalChangeError").hide();
                            $("#currentPassErr").hide();
                        } else if (jqXHR.getResponseHeader("ErrorLocalization") !== null && jqXHR.getResponseHeader("ErrorLocalization") === ChangePasswordErrorLocalization.CURRETN_FIELD) {
                            $("#currentPassErr").text(jqXHR.responseText);
                            $("#currentPassErr").show();
                            $("#newPassErr").hide();
                            $("#generalChangeError").hide();
                        } else {
                            $("#generalChangeError").text(jqXHR.responseText);
                            $("#generalChangeError").show();
                            $("#newPassErr").hide();
                            $("#currentPassErr").hide();
                        }
                    };
                    Request.changePassword(body, done, fail);
                }
            });
        $('#changePasswordModal').on('show.bs.modal', function (e) {
            $("#currentPassword").val("");
            $("#newPassword").val("");
            $("#confirmPassword").val("");
            $("#generalChangeError").hide();
            $("#currentPassErr").hide();
            $("#newPassErr").hide();
            $("#confirmPassErr").hide();
        });
    }
    function addDropdownItem(menu, itemsToAdd) {
        if(menu.selectAll(".dropdown-item").size() > 0){
            return;
        }
        var items = {
            user: {
                icon: "fas fa-users-cog",
                title: " Users",
                href: "/users.html"
            },
            network: {
                icon: "fas fa-database",
                title: " Networks",
                href: "/networks.html"
            },
            cases: {
                icon: "fas fa-briefcase",
                title: " Cases",
                href: "/cases.html"
            },
            dashboards: {
                icon: "fas fa-tachometer-alt-solid",
                title: " Dashboards",
                href: "/dashboards.html"
            },
            main_view: {
                icon: "fas fa-project-diagram",
                title: " Main view",
                href: "/bayesbox.html"
            },
            change_password: {
                icon: "fas fa-key-solid",
                title: " Change password",
                href: "javascript:void(0)",
                attrs: [["data-toggle", "modal"], ["data-target", "#changePasswordModal"]],
                postfunction: addChangePasswordModal
            },
            logout: {
                icon: "fas fa-power-off",
                title: " Log out",
                href: "/authentication/logout"
            },
            oauthLogIn: {
                icon: "",
                title: "Log In",
                href: "javascript:void(0)",
                onclick: function () {
                    OAuth.showButtons($("#oauthLoginButtons"), StartApp.getOAuthClients(), "");
                    User.setCallbackAfterStandardLogIn(function () {});
                    $('#oAuthLoginModal').modal("show");
                }
            }
        };
        var item = undefined;
        var currentItem = undefined;
        for (currentItem in itemsToAdd) {
            if (items.hasOwnProperty(currentItem) && itemsToAdd[currentItem]) {
                var menuItem = items[currentItem];
                item = menu.append("a")
                    .classed("dropdown-item", true)
                    .style("white-space", "normal")
                    .style("font-size", ".8rem")
                    .style("width", "auto")
                    .attr("href", menuItem.href);
                if (menuItem.attrs !== undefined) {
                    for (var i = 0; i < menuItem.attrs.length; i++) {
                        item.attr(menuItem.attrs[i][0], menuItem.attrs[i][1]);
                    }
                }
                item.append("i")
                    .classed(menuItem.icon, true);
                item.append("span")
                    .text(menuItem.title);
                if (menuItem.postfunction) {
                    menuItem.postfunction();
                }
                if(menuItem.onclick){
                    item.on("click", menuItem.onclick);
                }
            }
        }
        return item;
    }

    function setUser(data) {
        if(userMenu !== null){
            userMenu.remove();
        }
        if (Utilities.isEmptyObject(data)) {
            detectAndAddOauthLogIn();
        } else {
            userName = data.userName;
            admin = data.isAdmin;
            oAuth = data.oAuth;
            userID = data.userID;
            explicitCategoryAccess = typeof data.explicitCategoryAccess === "boolean" ? data.explicitCategoryAccess : false;
            addLoggedUserMenu();
        }
    }

    function detectAndAddOauthLogIn() {
        if(!BAYESBOX_MODE.isGraph()){
            return;
        }
        StartApp.runWhenReady(() => {
            if (!StartApp.isDashboardAdminOnly() && StartApp.getOAuthClients().length > 0) {
                addOAuthLogIn();
            }
        }, true);
    }

    function addOAuthLogIn() {
        let userDrpdownMenu = drawDropdownUserMenuTemplate("");
        addDropdownItem(userDrpdownMenu, {
            user: false,
            network: false,
            cases: false,
            dashboards: false,
            main_view: false,
            change_password: false,
            logout: false,
            oauthLogIn: true
        });
    }

    function runIfAdminOrExpCategoryAccess(fun) {
        if(loggedAndAdminOrExplicitCategoryAccess()){
            fun();
        }
    }

    function loggedAndAdminOrExplicitCategoryAccess() {
        return isLogged() && (admin || explicitCategoryAccess);
    }

    function isLogged() {
        return userID !== "";
    }

    return {
        setUser,
        runIfAdminOrExpCategoryAccess,
        loggedAndAdminOrExplicitCategoryAccess,
        setCallbackAfterStandardLogIn,
        getUserId: function () {
            return userID;
        },
        isAdmin: function () {
            return admin;
        },
        isLogged: function () {
            return isLogged();
        },
        isLoginFromOauth: function () {
            return StartApp.getStartAppNetwork() !== null || StartApp.getStartAppEvidence() !== null;
        },
        isExplicitCategoryAccess: function () {
            return explicitCategoryAccess;
        }
    };
})();

export default User;
