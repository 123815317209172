/* global BBTag */
import BBCodeParser from 'bbcode-parser';
import BBTag from 'bbcode-parser/bbTag';

var BayesBoxBBtags = (function () {
    var bbTags = BBCodeParser.defaultTags();
    bbTags["img"] = BBTag.createTag("img", function (tag, content, attr) {
        return "<img class='img-fluid' src=\"" + content + "\" />";
    }, true);

    return {
        get: function () {
            return bbTags;
        }
    };
})();

export default BayesBoxBBtags;
