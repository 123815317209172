import {BAYESBOX_MODE} from "./constantsMapping";
import PortraitAlert from "./portraitAlert";

var CustomClass = (function () {
    var modalButtonClass = "";
    var tableHeadClass = "";
    var tableClass = "";
    function setCustomClass() {
        var root = document.querySelector(':root');
        var rootStyles = getComputedStyle(root);
        modalButtonClass = rootStyles.getPropertyValue('--modalButtonsClass');
        tableHeadClass = rootStyles.getPropertyValue('--tableHeadColorClass');
        tableClass = rootStyles.getPropertyValue('--tableClass');
        var dropdownMenuNetworksClass = rootStyles.getPropertyValue('--leftColumnNetworkListButtonClass');
        var dropdownMenuNetworksCornerClass = rootStyles.getPropertyValue('--leftColumnNetworkListContentCorners');
        if (!BAYESBOX_MODE.isAdminPage() && PortraitAlert.isSmallMobile()) {
            let clearInputValue = document.getElementById("clearInputValue");
            let submitInputValue = document.getElementById("submitInputValue");
            if (clearInputValue !== null) {
                clearInputValue.className = modalButtonClass;
            }
            if (submitInputValue !== null) {
                submitInputValue.className = modalButtonClass;
            }
        }
        let clearTemporalEvidence = document.getElementById("clearTemporalEvidence");
        let submitTemporalEvidence = document.getElementById("submitTemporalEvidence");
        if (clearTemporalEvidence !== null) {
            clearTemporalEvidence.className = modalButtonClass;
        }
        if (submitTemporalEvidence !== null) {
            submitTemporalEvidence.className = modalButtonClass;
        }

        let clearVirtualEvidence = document.getElementById("clearVirtualEvidence");
        let submitVirtualEvidence = document.getElementById("submitVirtualEvidence");
        if (clearVirtualEvidence !== null) {
            clearVirtualEvidence.className = modalButtonClass;
        }
        if (submitVirtualEvidence !== null) {
            submitVirtualEvidence.className = modalButtonClass;
        }

        let dropdownMenuButton = document.getElementById("dropdownMenuButton");
        let dropdownMenuContent = document.getElementById("dropdownMenuContent");
        if (dropdownMenuButton !== null) {
            dropdownMenuButton.className = dropdownMenuNetworksClass + " dropdown-toggle";
        }
        if (dropdownMenuContent !== null) {
            dropdownMenuContent.className = dropdownMenuNetworksCornerClass + " dropdown-menu dropdownContentNetworks";
        }
    }
    ;
    return {
        setClass: function () {
            return setCustomClass();
        },
        getModalButtonClass: function () {
            return modalButtonClass;
        },
        getTableClass: function () {
            return tableClass;
        },
        getTableHeadClass: function () {
            return tableHeadClass;
        }
    };
})();

export default CustomClass;
