import * as d3 from 'd3';
import Utilities from "./utilities";
import Node from "./node";
import Network from "./network";

/* global d3, Utilities, Node, NODE_TYPE, Network */

var ProbabilityOfEvidence = (function () {
    var PROB_BODY = d3.select("#probEvBodyValue");
    var PROB_VALUE = d3.select("#probEvValue");
    var PROB_VALUE_LOG = d3.select("#probEvLogValue");
    var ERROR_BODY = d3.select("#probEvBodyError");
    var ERROR_VALUE = d3.select("#probEvError");
    var TABLE = d3.select("#currentEvidenceTable");
    var TABLE_BODY = d3.select("#currentEvidenceTbody");

    $('#probabilityOfEvidenceModal').on('show.bs.modal', function (e) {
        getProbabilityOfValue();
    });
    $('#probabilityOfEvidenceModal').on('hidden.bs.modal', function (e) {
        resetModal();
    });

    function resetModal() {
        PROB_BODY.classed("d-none", false);
        TABLE.classed("d-none", false);
        ERROR_BODY.classed("d-none", true);
        PROB_VALUE.text("Calculating...");
        PROB_VALUE_LOG.text("Calculating...");
    }

    function drawCurrentEvidence(evidences, propagated) {
        var evidencesWithoutVirtual = evidences.filter(e => !Array.isArray(e.evidence));
        TABLE_BODY.selectAll("tr").remove();
        var tr = TABLE_BODY.selectAll(".currEvidence")
                .data(evidencesWithoutVirtual)
                .enter()
                .append("tr")
                .attr("class","currentEvidence");
        tr.append("td")
                .text(d => {
                    var nodeId = Node.getNodeIdFromHandle(d.node);
                    var nodeName = d3.select("#" + nodeId).data()[0].name;
                    return nodeName;
                });
        tr.append("td")
                .text(d => {
                    var nodeId = Node.getNodeIdFromHandle(d.node);
                    return Node.getStateLabels(d3.select("#" + nodeId).data()[0])[d.evidence];
                });
        tr = TABLE_BODY.selectAll(".propEvidence")
                .data(propagated)
                .enter()
                .append("tr")
                .attr("class","propEvidence");
        tr.append("td")
                .text(d => {
                    var nodeId = Node.getNodeIdFromHandle(d.node);
                    var nodeName = d3.select("#" + nodeId).data()[0].name;
                    return nodeName;
                });
        tr.append("td")
                .text(d => d.evidence + " [p]");
    }

    function getProbabilityOfValue() {
        if (Utilities.isStaticChanceNetwork()) {
            let evidences = Node.Evidence.getAllEvidence();
            let propagated = Node.Evidence.getPropagatedEvidence();
            let containNormalEvidence = evidences.some(e => !Array.isArray(e.evidence));
            if (containNormalEvidence) {
                var dataUpdate = new Object();
                dataUpdate.networkHandle = Network.getCurrentNetwork();
                dataUpdate.evidences = Node.Evidence.getAllEvidence();
                let body = JSON.stringify(dataUpdate);
                let done = function (data) {
                    if (data.success && data.revision === Network.getRevision()) {
                        PROB_VALUE.text(data.probEvidence);
                        PROB_VALUE_LOG.text(Math.log(Number(data.probEvidence)));
                        drawCurrentEvidence(evidences, propagated);
                    } else if (!data.success) {
                        PROB_BODY.classed("d-none", true);
                        TABLE.classed("d-none", true);
                        ERROR_BODY.classed("d-none", false);
                        ERROR_VALUE.text(data.error);
                    } else {
                        PROB_BODY.classed("d-none", true);
                        TABLE.classed("d-none", true);
                        ERROR_BODY.classed("d-none", false);
                        ERROR_VALUE.text("Resource not available");
                    }
                };
                Request.getProbEvidence(body, done);
            } else {
                PROB_BODY.classed("d-none", true);
                TABLE.classed("d-none", true);
                ERROR_BODY.classed("d-none", false);
                ERROR_VALUE.text("No evidence set.");
            }
        } else {
            PROB_BODY.classed("d-none", true);
            TABLE.classed("d-none", true);
            ERROR_BODY.classed("d-none", false);
            ERROR_VALUE.text("P(e) is not supported in this type of network.");
        }
    }
})();

export default ProbabilityOfEvidence;
