import BayesBoxAjax from "./bayesboxAjax";
import JSON5 from 'json5';

/* global JSON5, BayesBoxAjax, Network */

var Request = (function () {

    let conveters = {
        'text json': function (result) {
            try {
                // First try to use native browser parsing
                if (typeof JSON === 'object' && typeof JSON.parse === 'function') {
                    return JSON.parse(result);
                } else {
                    // Fallback to jQuery's parser
                    return $.parseJSON(result);
                }
            } catch (e) {
                return JSON5.parse(result);
            }
        }
    };

    function getAppInfo(doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/start/appInfo",
            method: "GET",
            dataType: "json"
        }, doneFun, failFun, alwaysFun);
    }

    function getAuthenticationInfo(doneFun) {
        BayesBoxAjax.request({
            url: "/authentication/info",
            method: "GET",
            dataType: "json"
        }, doneFun);
    }

    function oauthInit(clientType, requestBody, doneFun, failFun) {
        BayesBoxAjax.request({
            url: "/oauth/init/" + clientType,
            method: "POST",
            dataType: "json",
            data: requestBody
        }, doneFun, failFun);
    }

    function getDefinition(networkHandle, nodeHandle, doneFun) {
        BayesBoxAjax.request({
            url: "/network/getDefinition/" + networkHandle + "/" + nodeHandle,
            method: "GET",
            dataType: "json"
        }, doneFun);
    }

    function update(body, isMultiupdate, doneFun, failFun, alwaysFun) {
        let url = isMultiupdate ? "/network/update?multiupdate=true" : "/network/update";
        BayesBoxAjax.request({
            url: url,
            type: "POST",
            data: body,
            contentType: "application/json",
            converters: conveters
        }, doneFun, failFun, alwaysFun);
    }

    function getProbEvidence(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/network/probEvidence",
            type: "POST",
            data: body,
            contentType: "application/json"
        }, doneFun, failFun, alwaysFun);
    }

    function getNetwork(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/network/get",
            data: body,
            dataType: 'json',
            method: "POST",
            converters: conveters
        }, doneFun, failFun, alwaysFun);
    }

    function getDashboard(queryString, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/dashboard?" + queryString,
            type: "GET",
            dataType: "json"
        }, doneFun, failFun, alwaysFun);
    }

    function addDashboard(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            type: "POST",
            url: "/dashboard",
            data: body
        }, doneFun, failFun, alwaysFun);
    }

    function deleteDashboard(dashboardId, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            type: "DELETE",
            url: "/admin/dashboards/"+dashboardId
        }, doneFun, failFun, alwaysFun);
    }

    function updateDashboard(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            type: "PUT",
            url: "/dashboard",
            data: body
        }, doneFun, failFun, alwaysFun);
    }

    function getDashboardsList(networkHandle, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            type: "GET",
            url: `/dashboard/getDashboardsList/${networkHandle}`
        }, doneFun, failFun, alwaysFun);
    }

    function addCase(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.jsonRequest({
            type: "POST",
            url: "/cases/manage/add",
        }, body, doneFun, failFun, alwaysFun);
    }

    function deleteCase(caseId, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            type: "DELETE",
            url: "/cases/manage/"+caseId
        }, doneFun, failFun, alwaysFun);
    }

    function updateCase(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.jsonRequest({
            type: "PUT",
            url: "/cases/manage/update",
        }, body, doneFun, failFun, alwaysFun);
    }

    function login(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/authentication/login",
            type: "POST",
            data: body,
            contentType: "application/json",
            dataType: "text"
        }, doneFun, failFun, alwaysFun);
    }

    function logout(thenFun) {
        fetch(BayesBoxAjax.fixUrl("/authentication/logout"), {
            redirect: 'follow'
        }).then(thenFun)
    }

    function changePassword(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/authentication/changepassword",
            type: 'POST',
            data: body
        }, doneFun, failFun, alwaysFun);
    }

    function addUser(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/admin/users",
            type: "POST",
            dataType: "json",
            data: body
        }, doneFun, failFun, alwaysFun);
    }

    function updateUser(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/admin/users",
            type: "PUT",
            dataType: "json",
            data: body
        }, doneFun, failFun, alwaysFun);
    }

    function deleteUser(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/admin/users",
            type: 'DELETE',
            data: body,
            dataType: "text"
        }, doneFun, failFun, alwaysFun);
    }

    function addNetwork(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            type: "POST",
            enctype: 'multipart/form-data',
            url: "/admin/networks",
            data: body,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000
        }, doneFun, failFun, alwaysFun);
    }

    function setCategoryAccess(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/admin/users/setCategoryAccess",
            type: 'PATCH',
            data: body,
            dataType: "text"
        }, doneFun, failFun, alwaysFun);
    }

    function setDefaultNetwork(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/admin/networks/setDefaultNetwork",
            type: 'POST',
            dataType: "json",
            data: body
        }, doneFun, failFun, alwaysFun);
    }

    function deleteNetwork(networkId, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/admin/networks/" + networkId,
            type: 'DELETE',
            dataType: "text"
        }, doneFun, failFun, alwaysFun);
    }

    function getCategories(doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/admin/categories",
            type: 'GET',
            dataType: "json"
        }, doneFun, failFun, alwaysFun);
    }

    function addCategory(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            type: "POST",
            url: "/admin/categories",
            data: body
        }, doneFun, failFun, alwaysFun);
    }

    function updateCategory(body, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/admin/categories",
            type: 'PUT',
            dataType: "json",
            data: body
        }, doneFun, failFun, alwaysFun);
    }

    function deleteCategory(categoryName, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/admin/categories/" + categoryName,
            type: 'DELETE',
            dataType: "json"
        }, doneFun, failFun, alwaysFun);
    }

    function loginTest(zone, doneFun, failFun, alwaysFun) {
        BayesBoxAjax.request({
            url: "/authentication/login-test",
            type: 'POST',
            contentType: "text",
            data: zone
        }, doneFun, failFun, alwaysFun);
    }

    function getCasesNetworks(params) {
        params.url = "/cases/" + Network.getCurrentNetwork();
        BayesBoxAjax.request(params);
    }

    function getAdminAllCases(params) {
        params.url = "/admin/cases";
        BayesBoxAjax.request(params);
    }

    function getAdminAllNetworks(params) {
        params.url = "/admin/networks";
        BayesBoxAjax.request(params);
    }

    function getAdminAllDashboards(params) {
        params.url = "/admin/dashboards";
        BayesBoxAjax.request(params);
    }

    function getAdminAllUsers(params) {
        params.url = "/admin/users";
        BayesBoxAjax.request(params);
    }
    return {
        getAppInfo,
        getAuthenticationInfo,
        oauthInit,
        getDefinition,
        update,
        getProbEvidence,
        getNetwork,
        getDashboard,
        addDashboard,
        updateDashboard,
        getDashboardsList,
        deleteDashboard,
        addCase,
        deleteCase,
        updateCase,
        login,
        logout,
        changePassword,
        addUser,
        updateUser,
        deleteUser,
        setCategoryAccess,
        addNetwork,
        setDefaultNetwork,
        deleteNetwork,
        getCategories,
        addCategory,
        updateCategory,
        deleteCategory,
        loginTest,
        getCasesNetworks,
        getAdminAllNetworks,
        getAdminAllCases,
        getAdminAllDashboards,
        getAdminAllUsers
    };
})();

export default Request;
