import * as d3 from 'd3';
import NodeShape from "./nodeShape";
import ZoomNetwork from "./zoomNetwork";
import Network from "./network";
import Submodel from "./submodel";
import DynamicBN from "./dynamicBN";
import Node from './node';
import * as d3plus from 'd3plus-text';
import {BAYESBOX_MODE, DECIMALS_IN_CHANCE_NODE_BARCHARTS, MIN_DBL, NODE_TYPE} from "./constantsMapping";

/* global parseFloat, d3plus, NodeShape, d3, ZoomNetwork, Network, Submodel, DynamicBN */

var Utilities = (function () {
    // Create a dummy g for calculation purposes only. This will never
    // be appended to the DOM and will be discarded once this function
    // returns.
    const dummyG = document.createElementNS("http://www.w3.org/2000/svg", "g");
    const ZERO_MATRIX = [0, 0, 1];
    const EPSILON = 0.000001;
    const FLOATING_POINT_PRECISION = 8;

    const fpFix = (n) => {
        const precision = Math.pow(10, FLOATING_POINT_PRECISION);
        return Math.round(n * precision) / precision;
    }
    //ignores the value modification to an object but there is no restriction on the binding.
    //https://alligator.io/js/const-vs-obj-freeze/
    Object.freeze(ZERO_MATRIX);

    return {
        convertNumberToShortestString(num, eps = EPSILON) {
            const integerPart = Math.trunc(num);
            const decimalPart = fpFix(num % 1);
            return Math.abs(decimalPart) > eps ? `${integerPart}.${String(decimalPart).split(".")[1]}` : `${integerPart}`;
        },

        difference: function (a1, a2) {
            var a2Set = new Set(a2);
            return a1.filter(function (x) {
                return !a2Set.has(x);
            });
        },

//        symmetricDifference: function(a1, a2) {
//            return difference(a1, a2).concat(difference(a2, a1));
//        },
        mapToJson: function (map) {
            return JSON.stringify([...map]);
        },
        jsonToMap: function (jsonStr) {
            return new Map(JSON.parse(jsonStr));
        },
        deepCopy: function (p, b) {
            var c = b || {};
            for (var i in p) {
                if (typeof p[i] === "object") {
                    if (p[i].constructor === Array)
                        c[i] = []; //assign empty table
                    else
                        c[i] = {}; // assign normal object
                    Utilities.deepCopy(p[i], c[i]);
                } else
                {
                    c[i] = p[i]; //immutable values
                }
            }
            return c;
        },
        isStaticChanceNetwork: function () {
            if (DynamicBN.isDynamicNetwork) {
                return false;
            }
            return d3.selectAll(Utilities.getGTypeSelector(Node.TYPE))
                    .filter(d => !d.checkType([NODE_TYPE.CPT, NODE_TYPE.NOISY_ADDER, NODE_TYPE.NOISY_MAX, NODE_TYPE.TRUTH_TABLE]))
                    .size() === 0;
        },
        /**
         * Get valid id based on function argument
         * @param {String} id - String for prepare id Edytuj
         * @returns {String} - valid CSS ID
         */
        getValidId: function (id) {
            if (id) {
                return id.split('.').join("").replace(/\s/g, "_");
            }
            return id;
        },
        /*
         * Choosing the best way to showing value
         * @param {number} value - value to fixed
         * @returns {Number} - float value (ex. 1.2) or exponential notation (ex. 1.2e-10)
         */
        getBestToRounding: function (value) {
            if (value.toString().includes("e")) {
                return value.toPrecision(DECIMALS_IN_CHANCE_NODE_BARCHARTS);
            } else {
                return parseFloat(value).toFixed(DECIMALS_IN_CHANCE_NODE_BARCHARTS);
            }
        },
        /*
         Get matrix from translation string
         @param {String} transform - translation string
         @returns {Array} - [translateX, translateY, scaleXY]
         */
        getTranslation: function (transform) {
            if (transform === null) {
                return ZERO_MATRIX;
            }
            // Set the transform attribute to the provided string value.
            dummyG.setAttribute("transform", transform);

            // consolidate the SVGTransformList containing all transformations
            // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
            // its SVGMatrix.
            var consolidate = dummyG.transform.baseVal.consolidate();
            if (consolidate === null) {
                return ZERO_MATRIX;
            }
            var matrix = consolidate.matrix;

            // [translateX, translateY, scaleXY]
            return [matrix.e, matrix.f, matrix.a];
        },
        addBreakLines: function (lines) {
            var text = "";
            for (var i = 0; i < lines.length - 1; i++) {
                text += lines[i];
                text += "\n";
            }
            text += lines[lines.length - 1];
            return text;
        },
        MEASURE_CONTEXT: undefined,
        getLazyMeasureContext: function () {
            if (typeof this.MEASURE_CONTEXT === 'undefined') {
                this.MEASURE_CONTEXT = document.createElement("canvas").getContext("2d");
                var style = Object.assign({
                    "font-size": 10,
                    "font-family": "sans-serif",
                    "font-style": "normal",
                    "font-weight": 400,
                    "font-variant": "normal"
                }, this.getDefaultFontStyles());

                var font = [];
                font.push(style["font-style"]);
                font.push(style["font-variant"]);
                font.push(style["font-weight"]);
                font.push(typeof style["font-size"] === "string" ? style["font-size"] : ((style["font-size"]) + "px"));
                font.push(style["font-family"]);

                this.MEASURE_CONTEXT.font = font.join(" ");
            }
            return this.MEASURE_CONTEXT;
        },
        removeMeasureContext: function () {
            this.MEASURE_CONTEXT = undefined;
        },
        lastFontString: '',
        //my d3plus.textWidth
        measure: function (text, context, style) {
            if (typeof style !== 'undefined') {
                var fontString = "";
                if (typeof style["font-style"] !== 'undefined') {
                    fontString += style["font-style"];
                    fontString += " ";
                }
                if (typeof style["font-variant"] !== 'undefined') {
                    fontString += style["font-variant"];
                    fontString += " ";
                }
                if (typeof style["font-weight"] !== 'undefined') {
                    fontString += style["font-weight"];
                    fontString += " ";
                }
                if (typeof style["font-size"] !== 'undefined') {
                    fontString += typeof style["font-size"] === "string" ? style["font-size"] : ((style["font-size"]) + "px");
                    fontString += " ";
                }
                if (typeof style["font-family"] !== 'undefined') {
                    fontString += style["font-family"];
                }
                if (fontString !== this.lastFontString) {
                    this.lastFontString = fontString;
                    context.font = fontString;
                }
            }

            if (text instanceof Array) {
                return text.map(function (t) {
                    return context.measureText(t).width;
                });
            }
            var textMetrics = context.measureText(text);
            var realBoxWidth = 0;
            if(typeof textMetrics.actualBoundingBoxLeft !== 'undefined' && typeof textMetrics.actualBoundingBoxRight !== 'undefined'){
                realBoxWidth = Math.ceil(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
            }
            return textMetrics.width > realBoxWidth ? Math.ceil(textMetrics.width) : realBoxWidth;

        },
        DEFAULT_FONT_STYLES: undefined,
        getDefaultFontStyles: function () {
            if (typeof this.DEFAULT_FONT_STYLES === 'undefined') {
                this.DEFAULT_FONT_STYLES = {
                    "font-size": NodeShape.FONT_SIZE,
                    "line-height": NodeShape.FONT_SIZE + NodeShape.TEXT_LINE_HEIGHT,
                    "font-weight": "normal",
                    "font-family": d3plus.fontExists(["sans-serif", "Arial", "Helvetica Neue", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"])
                };
            }
            return this.DEFAULT_FONT_STYLES;
        },
        geniePointsToPixels: function (size) {
            return Math.ceil(size * 4 / 3);
        },
        getFontStyles: function (fontSize, bold) {
            let style = this.deepCopy(this.getDefaultFontStyles());
            if (typeof fontSize === "undefined" || fontSize === null) {
                return style;
            }
            style["font-size"] = this.geniePointsToPixels(fontSize);
            style["line-height"] = style["font-size"] + NodeShape.TEXT_LINE_HEIGHT;
            if (typeof bold !== "undefined" && bold) {
                style["font-weight"] = "bold";
            }
            return style;
        },
        cutLongNameV3: function (text, maxWidth, isSingleWord, maxLines, fontStyles) {
            if (typeof text === "undefined" || text === null) {
                return {
                    lines: [],
                    fit: true,
                    count: 0
                };
            }
            maxWidth = Math.floor(maxWidth);
            if (typeof fontStyles === 'undefined') {
                fontStyles = this.getDefaultFontStyles();
            }
            if (typeof maxLines === 'undefined') {
                maxLines = -1;
            }
            if (this.measure(text, this.getLazyMeasureContext(), fontStyles) < maxWidth || text.length === 1) {
                if (maxLines > 0) {
                    return {
                        lines: [text],
                        fit: true,
                        count: text.length
                    };
                }
                return [text];
            }
            if (maxWidth <= 0) {
                if (maxLines > 0) {
                    return {
                        lines: [],
                        fit: false,
                        count: 0
                    };
                }
                return [];
            }
            if (typeof isSingleWord === 'undefined') {
                isSingleWord = false;
            }
            var wordsIndex = [];
            if (isSingleWord) {
                wordsIndex = this.getSingleWordIndexes(text);
            } else {
                wordsIndex = this.getWordsIndexes(text);
            }

            var lines = [];
            var linesIndex = -1;
            var middleWordStartIndex = -1;
            for (var i = 0; i < wordsIndex.length; i += 2) {
                linesIndex++;
                lines[linesIndex] = "";
                var lineFit = false;
                var stop = -1;
                var start = wordsIndex[i];
                if (middleWordStartIndex > start) {
                    start = middleWordStartIndex;
                }
                var last_i = i;
                //filing one line
                while (this.measure(lines[linesIndex], this.getLazyMeasureContext(), fontStyles) <= maxWidth) {
                    if (i >= (wordsIndex.length)) {
                        i += 2;
                        lineFit = true;
                        break;
                    }
                    stop = wordsIndex[i + 1];
                    lines[linesIndex] = text.substring(start, stop);
                    i += 2;
                }
                //if filled line is too long
                if (!lineFit) {
                    if ((i - last_i) === 2) {//if in line is only one long word
                        i -= 2;
                        stop = wordsIndex[i + 1];
                        if (!isSingleWord) {
                            var wordLines = this.cutLongNameV3(text.substring(start, stop), maxWidth, true, -1, fontStyles);
                            middleWordStartIndex = start;
                            for (var l = 0; l < wordLines.length - 1; l++) {
                                lines[linesIndex] = wordLines[l];
                                middleWordStartIndex += wordLines[l].length;
                                linesIndex++;
                                if (this.ifLastLine(maxLines, linesIndex)) {
                                    break;
                                }
                            }
                            if (wordLines.length === 1) {
                                lines[linesIndex] = wordLines[0];
                            } else {
                                linesIndex--;
                                i -= 2;
                            }
                        }
                    } else {//if in line is more than one word
                        if (this.ifLastLine(maxLines, linesIndex)) {//if maxLines is set and it it last line
                            var wordLines = this.cutLongNameV3(text.substring(start, stop), maxWidth, true, -1, fontStyles);
                            lines[linesIndex] = wordLines[0];
                            i -= 2;
                            break;
                        }
                        i -= 4;
                        stop = wordsIndex[i + 1];
                        lines[linesIndex] = text.substring(start, stop);
                    }
                }
                if (this.ifLastLine(maxLines, linesIndex)) {
                    break;
                }
            }
            if (maxLines > 0) {
                let allTextFit = false;
                let countLetters = 0;
                if (i >= wordsIndex.length) {
                    allTextFit = true;
                } else {
                    allTextFit = false;
                    let perfectDots = this.addPerfectSizeDots(lines[lines.length - 1], fontStyles);
                    lines[lines.length - 1] = perfectDots;
                    countLetters -= 3;
                }
                countLetters += lines.map(s => s.length).reduce(function (previousValue, currentValue, index, array) {
                    return previousValue + currentValue;
                });
                return {
                    lines: lines,
                    fit: allTextFit,
                    count: countLetters
                };
            }
            return lines;
        },
        ifLastLine: function (maxLines, currentLine) {
            return (maxLines > 0 && (maxLines - 1) <= currentLine);
        },
        getWordsIndexes: function (text) {
            var words = [];
            var boundary = [-1, -1];
            do {
                boundary = this.getBoundary(text, boundary[0], boundary[1]);
                words.push(boundary[0]);
                if (boundary[1] === -1) {
                    words.push(text.length);
                } else {
                    words.push(boundary[1]);
                }

            } while (boundary[1] !== -1);
            return words;
        },
        getSingleWordIndexes: function (word) {
            var chars = [];
            for (var i = 0; i < word.length; i++) {
                chars.push(i);
                chars.push(i + 1);
            }
            return chars;
        },
        /*
         *
         * @param {type} nodeList - d3 object
         * @returns {undefined}
         */
        targetsExist: function (nodeList) {
            return nodeList.filter(d => d.isTarget).size() > 0;
        },
        getBoundary: function (text, lastStart, lastStop) {
            var start = lastStop + 1;
            if (lastStart < 0) {
                start = 0;
            }
            //spaces
            while (text[start] === " ") {
                start++;
                if (start >= text.length) {
                    return [-1, -1];
                }
            }
            var stop = text.indexOf(" ", start);
            var newStart = 0;
            while ((stop - newStart) === 0) {
                newStart++;
                stop = text.indexOf(" ", newStart);
            }
            return [start, stop];
        },
        addPerfectSizeDots: function (text, fontStyles, maxWidth) {
            if (typeof fontStyles === 'undefined') {
                fontStyles = this.getDefaultFontStyles();
            }
            if (typeof maxWidth !== "undefined") {
                return this.fitDotsToMaxWidth(text, fontStyles, maxWidth);
            } else {
                return this.replaceLastCharsByDots(text, fontStyles);
            }
        },
        replaceLastCharsByDots: function (text, fontStyles) {
            let dotsSize = this.measure("...", this.getLazyMeasureContext(), fontStyles);
            let lastChars = 0;
            let lastCharSize = 0;
            while (lastCharSize < dotsSize && lastChars <= text.length) {
                lastChars++;
                lastCharSize = this.measure(text.substring(text.length - lastChars, text.length), this.getLazyMeasureContext(), fontStyles);
            }
            let textWithoutLastChars = text.substring(0, text.length - lastChars);

            return textWithoutLastChars + "...";
        },
        fitDotsToMaxWidth: function (text, fontStyles, maxWidth) {
            let textWithDotsSize = 0;
            let lastChars = -1;
            let threeDotsSize = this.measure("...", this.getLazyMeasureContext(), fontStyles);
            let textWithoutLastChars = "";
            do {
                lastChars++;
                textWithoutLastChars = text.substring(0, text.length - lastChars);
                let textSize = this.measure(textWithoutLastChars, this.getLazyMeasureContext(), fontStyles);
                textWithDotsSize = textSize + threeDotsSize;
            } while (textWithDotsSize > maxWidth && lastChars <= text.length);

            return textWithoutLastChars + "...";
        },
        getMinRectangleDescriptions: function () {
            var descMaxWidth = 0;
            var descMaxHeight = 0;
            var margin = 0;
            d3.select("#" + Submodel.SUBMODEL_PREFIX + Submodel.currentSubmodel).selectAll(this.getGTypeSelector("description"))
                    .nodes()
                    .forEach(d => {
                        var t = d3.select(d);
                        var size = t.node().getBBox();
                        var e = Utilities.getTranslation(t.attr("transform"));
                        var currW = (size.x + size.width + margin) * e[2];
                        if (currW > descMaxWidth) {
                            descMaxWidth = currW;
                        }
                        var currH = (size.y + size.height + margin) * e[2];
                        if (currH > descMaxHeight) {
                            descMaxHeight = currH;
                        }
                    });
            return {
                width: descMaxWidth,
                height: descMaxHeight
            };
        },
        getMinRectangleDynamic: function () {
            var width = 0;
            var height = 0;
            var margin = 0;
            if (DynamicBN.isDynamicNetwork) {
                var visibleSubmodel = d3.select("#" + Submodel.SUBMODEL_PREFIX + Submodel.currentSubmodel);
                var dynamicBBox = visibleSubmodel.node().getBBox();
                width = dynamicBBox.x + dynamicBBox.width + margin;
                height = dynamicBBox.y + dynamicBBox.height + margin;
            }
            return {
                width: width,
                height: height
            };
        },
        getMinRectangleNodes: function () {
            var visibleNodes = d3.select("#" + Submodel.SUBMODEL_PREFIX + Submodel.currentSubmodel).selectAll("g[type=node]").nodes();
            var zoomBox = {
                width: 0,
                height: 0
            };
            var margin = 0;
            visibleNodes.forEach(n => {
                var node = d3.select(n).data()[0];
                var t = d3.select(n);
                var e = Utilities.getTranslation(t.attr("transform"));
                var nodeW = node.x + e[0] + node.width + margin;
                var nodeH = node.y + e[1] + node.height + margin;
                if (typeof node.template !== "undefined") {
                    nodeW = node.x + e[0] + node.template.width + margin;
                    nodeH = node.y + e[1] + node.template.height + margin;
                }

                if (nodeW > zoomBox.width) {
                    zoomBox.width = nodeW;
                }
                if (nodeH > zoomBox.height) {
                    zoomBox.height = nodeH;
                }
            });

            return zoomBox;
        },
        getMinRectangleSubmodels: function () {
            var visibleSubmodelsRect = d3.select("#" + Submodel.SUBMODEL_PREFIX + Submodel.currentSubmodel).selectAll(`g[type=${Submodel.TYPE_RECTANGLE}]`).nodes();
            var zoomBox = {
                width: 0,
                height: 0
            };
            var margin = 0;
            visibleSubmodelsRect.forEach(n => {
                var node = d3.select(n).data()[0];
                var t = d3.select(n);
                var e = Utilities.getTranslation(t.attr("transform"));
                var nodeW = node.x + e[0] + node.width + margin;
                var nodeH = node.y + e[1] + node.height + margin;
                if (typeof node.template !== "undefined") {
                    nodeW = node.x + e[0] + node.template.width + margin;
                    nodeH = node.y + e[1] + node.template.height + margin;
                }

                if (nodeW > zoomBox.width) {
                    zoomBox.width = nodeW;
                }
                if (nodeH > zoomBox.height) {
                    zoomBox.height = nodeH;
                }
            });

            return zoomBox;
        },
        svgContentRect: {
            width: 0,
            height: 0
        },
        updateSVGContentRect: function () {
            var svgContent = d3.select(`#${Network.getSvgContentId()}`).node().getBoundingClientRect();
            Utilities.svgContentRect.width = svgContent.width;
            Utilities.svgContentRect.height = svgContent.height;
        },
        listenerResizeSvgcontent: window.addEventListener('resize', function (event) {
            Utilities.updateSVGContentRect();
        }),
        getSvgContentRect: function () {
            var startListener = this.svgContentRect.width === 0 && this.svgContentRect.height === 0;
            if (startListener) {
                this.updateSVGContentRect();
            }
            return this.svgContentRect;
        },
        breadcrumbRect: null,
        getBreadcrumbRect: function () {
            if (this.breadcrumbRect === null || this.breadcrumbRect.height === 0) {
                this.breadcrumbRect = d3.select("nav[aria-label=breadcrumb]");
                if (this.breadcrumbRect.size() > 0) {
                    this.breadcrumbRect = this.breadcrumbRect.node().getBoundingClientRect();
                } else {
                    this.breadcrumbRect = new DOMRect();
                }
                var svgplateMargin = Network.getSvgplateMargin();
                this.breadcrumbRect.height += svgplateMargin.top;
            }
            return this.breadcrumbRect;
        },
        addRect: function (acc, val) {
            if (val.width > acc.width) {
                acc.width = val.width;
            }
            if (val.height > acc.height) {
                acc.height = val.height;
            }
            return acc;
        },
        /*d3.max(d3.selectAll("g[type=node]").nodes(), n=>d3.select(n).data()[0].x)
         * Resize svg plate if necessary
         */
        updateSVGPlateSize: function () {
            var margin = 5;
            var minRect = {
                width: 0,
                height: 0
            };
            minRect = this.addRect(minRect, this.getMinRectangleNodes());
            minRect = this.addRect(minRect, this.getMinRectangleDescriptions());
            minRect = this.addRect(minRect, this.getMinRectangleDynamic());
            minRect = this.addRect(minRect, this.getMinRectangleSubmodels());

            minRect.width += margin;
            minRect.height += margin;

            var contentBox = this.getSvgContentRect();
            var breadcrumbBox = this.getBreadcrumbRect();
            var actualZoomWidth = minRect.width * ZoomNetwork.getLast_K();
            var actualZoomHeight = minRect.height * ZoomNetwork.getLast_K();
            var diffWidth = actualZoomWidth - contentBox.width;
            var diffHeight = actualZoomHeight - (contentBox.height - breadcrumbBox.height);

            if (diffWidth > 0) {
                d3.select(Network.getSVG_CSS_SELECTOR()).attr('width', actualZoomWidth);
            } else {
                d3.select(Network.getSVG_CSS_SELECTOR()).attr('width', contentBox.width);
            }
            if (diffHeight > 0) {
                d3.select(Network.getSVG_CSS_SELECTOR()).attr('height', actualZoomHeight);
            } else {
                d3.select(Network.getSVG_CSS_SELECTOR()).attr('height', contentBox.height - breadcrumbBox.height);
            }
        },
        /**
         * Get URL parameters
         * @param {String} name - parameter name
         * @returns {String}
         */
        getURLParameterByName: function (name) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(name);
        },
        hasURLParameterByName: function (name) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.has(name);
        },
        /*
         * calculating best position inside container
         * @param {type} containerSelector - div css selector where inside is div selected by divInsideSelector
         * @param {type} divInsideSelector - div inside containerSelector
         * @param {type} pageX - shift coordinate
         * @param {type} pageY - shift coordinate
         * @returns {fitToContainer.translate|Object} - new best coordinate
         */
        fitToContainer: function (containerSelector, divInsideSelector, pageX, pageY) {
            var marginPointer = 2;
            pageX = pageX + marginPointer;
            var area = $(containerSelector)[0];
            var tooltip = $(divInsideSelector)[0];
            var offsetHeight = area.offsetHeight;
            var offsetLeft = area.offsetLeft;
            var offsetTop = area.offsetTop;
            var offsetWidth = area.offsetWidth;
            var safeMargin = 1;
            if ((pageX - offsetLeft) <= 0) {
                pageX = offsetLeft + safeMargin;
            }
            if ((pageX + tooltip.offsetWidth) >= (offsetLeft + offsetWidth)) {
                pageX = (offsetLeft + offsetWidth) - tooltip.offsetWidth - safeMargin;
            }
            if ((pageY - offsetTop) <= 0) {
                pageY = offsetTop + safeMargin;
            }
            if ((pageY + tooltip.offsetHeight) >= (offsetTop + offsetHeight)) {
                pageY = (offsetTop + offsetHeight) - tooltip.offsetHeight - safeMargin;
            }
            var translate = new Object();
            translate.x = pageX;
            translate.y = pageY;
            return translate;
        },
        /**
         * Method joins the elements of an array into a string, and returns the string.
         * @param {Array} words - array with words to join
         * @returns {String} - joins the elements separated by "_"
         */
        joinUnderline: function (words) {
            if (!Array.isArray(words)) {
                return null;
            }
            var result = "";
            for (var i = 0; i < words.length; i++) {
                result += words[i];
                if ((i + 1) < words.length) {
                    result += "_";
                }
            }
            return result;
        },
        /**
         * Create selector example.: g[type=submodel]
         * @param {String} type - submodel, node, submodelNode, arc, description
         * @returns {String} g[type=type from arg]
         */
        getGTypeSelector: function (type) {
            return "g[type=" + type + "]";
        },
        getTransformString: function (translateX, translateY, scale) {
            //"translate(" + translateX + "," + translateY + ") scale(0.4)"
            var transform = "";
            if (typeof translateX !== 'undefined' && typeof translateY !== 'undefined') {
                transform += "translate(" + translateX + "," + translateY + ")";
            }
            if (typeof scale !== 'undefined') {
                transform += " scale(" + scale + ")";
            }
            return transform;
        },
        redirectToLogin: function (status) {
            if (status === 401) {
                window.location.replace("/login.html?parent=" + window.location.pathname);
            }
        },
        isFunction: function (functionToCheck) {
            if (typeof functionToCheck === "undefined" || functionToCheck === null) {
                return false;
            }
            return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
        },
        //function from https://stackoverflow.com/a/8630641
        createCSSSelector: function (selector, style) {
            if (!document.styleSheets)
                return;
            if (document.getElementsByTagName('head').length == 0)
                return;

            var styleSheet, mediaType;

            if (document.styleSheets.length > 0) {
                for (var i = 0, l = document.styleSheets.length; i < l; i++) {
                    if (document.styleSheets[i].disabled)
                        continue;
                    var media = document.styleSheets[i].media;
                    mediaType = typeof media;

                    if (mediaType === 'string') {
                        if (media === '' || (media.indexOf('screen') !== -1)) {
                            styleSheet = document.styleSheets[i];
                        }
                    } else if (mediaType == 'object') {
                        if (media.mediaText === '' || (media.mediaText.indexOf('screen') !== -1)) {
                            styleSheet = document.styleSheets[i];
                        }
                    }

                    if (typeof styleSheet !== 'undefined')
                        break;
                }
            }

            if (typeof styleSheet === 'undefined') {
                var styleSheetElement = document.createElement('style');
                styleSheetElement.type = 'text/css';
                document.getElementsByTagName('head')[0].appendChild(styleSheetElement);

                for (i = 0; i < document.styleSheets.length; i++) {
                    if (document.styleSheets[i].disabled) {
                        continue;
                    }
                    styleSheet = document.styleSheets[i];
                }

                mediaType = typeof styleSheet.media;
            }

            if (mediaType === 'string') {
                for (var i = 0, l = styleSheet.rules.length; i < l; i++) {
                    if (styleSheet.rules[i].selectorText && styleSheet.rules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
                        styleSheet.rules[i].style.cssText = style;
                        return;
                    }
                }
                styleSheet.addRule(selector, style);
            } else if (mediaType === 'object') {
                var styleSheetLength = (styleSheet.cssRules) ? styleSheet.cssRules.length : 0;
                for (var i = 0; i < styleSheetLength; i++) {
                    if (styleSheet.cssRules[i].selectorText && styleSheet.cssRules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
                        styleSheet.cssRules[i].style.cssText = style;
                        return;
                    }
                }
                styleSheet.insertRule(selector + '{' + style + '}', styleSheetLength);
            }
        },
        getIndexingParentsStates: function (node) {
            if (!node.checkType(NODE_TYPE.UTILITY)) {
                return new Set();
            }
            var parents = node.indexingParentsIds;
            var values = node.values;
            var maxmap = new Map();
            var states = [];

            for (var vidx = 0; vidx < values.length; vidx++) {
                var currentVal = values[vidx].value;
                if (currentVal === MIN_DBL) {
                    continue;
                }
                states = [];
                var multiplier = 1;
                for (var pidx = parents.length - 2; pidx >= 0; pidx--) {
                    var parent = d3.select("#" + Node.getNodeIdFromHandle(node.indexingParentsIds[pidx].node, node.network)).data()[0];
                    var outcomeCount = parent.outcome.length;
                    if (!parent.checkType(NODE_TYPE.DECISION)) {
                        var state = Math.floor(vidx / multiplier) % outcomeCount;
                        states.push(state);
                    }
                    multiplier *= outcomeCount;
                }
                // states vector may be empty at this point
                // this means that all parents are decision
                // nodes. All values from the grid will be mapped
                // onto one empty vector - highlighting will work OK
                var maxindices = maxmap.get(states.join("_"));
                if (!maxmap.has(states.join("_"))) {
                    maxindices = [];
                    maxmap.set(states.join("_"), maxindices);
                }
                if (maxindices.length === 0) {
                    maxindices.push(vidx);
                } else {
                    var maxVal = values[maxindices[0]].value;
                    if (currentVal === maxVal) {
                        maxindices.push(vidx);
                    } else if (currentVal > maxVal) {
                        maxindices.splice(0, maxindices.length);//clear array
                        maxindices.push(vidx);
                    }
                }
            }
            var maxIndexes = new Set();
            maxmap.forEach(m => m.forEach(i => maxIndexes.add(i)));
            return maxIndexes;
        },
        indexToCoordinates: function (node, theIndex) {
            if (theIndex >= node.values.length) {
                const error = new Error(`Value index ${theIndex} is out of range of array(max index = ${node.values.length - 1})`);
                console.error(error.message);
                return [];
            }
            let theDimensions = [];
            let indexingParentsDat = [];
            const net = typeof node.network === "undefined" ? Network.getCurrentNetwork() : node.network;
            [...node.indexingParentsIds.slice(0, -1), node].forEach((indexingParent) => {
                theDimensions.push(Node.getStateLabels(indexingParent).length);
                indexingParentsDat.push(indexingParent);
            });
            const numDimensions = theDimensions.length;
            let theCoordinates = [];
            const thePreProduct = this.calculatePreProd(theDimensions);
            for (let x = 0; x < numDimensions - 1; x++)  // skip last dimension, which is 1
            {
                const stateIdx = Math.floor(theIndex / thePreProduct[x]);
                theCoordinates[x] = {
                    stateIndex: stateIdx,
                    node: indexingParentsDat[x]
                };
                theIndex %= thePreProduct[x];
            }

            // and the last one (I skipped the division by 1)
            theCoordinates[numDimensions - 1] = {
                stateIndex: theIndex,
                node: node
            };
            return theCoordinates;
        },
        calculatePreProd: function (theDimensions) {
            let prod_acum = 1;
            let thePreProduct = [];
            const size = theDimensions.length;
            for (let x = size - 1; x >= 0; x--)
            {
                thePreProduct[x] = prod_acum;
                prod_acum *= theDimensions[x];
            }
            return thePreProduct;
        },
        isInfluenceDiagram: function () {
            var allNodes = null;
            allNodes = BAYESBOX_MODE.isGraph() ? allNodes = d3.selectAll("g[type='node'").data() : allNodes = d3.selectAll("div[type=nodeCard]").data();
            return allNodes.some(d => d.checkType([NODE_TYPE.DECISION, NODE_TYPE.MAU, NODE_TYPE.UTILITY]));
        },
        /*
         blend two colors to create the color that is at the percentage away from the first color
         this is a 5 step process
         1: validate input
         2: convert input to 6 char hex
         3: convert hex to rgb
         4: take the percentage to create a ratio between the two colors
         5: convert blend to hex
         @param: color1      => the first color, hex (ie: #000000)
         @param: color2      => the second color, hex (ie: #ffffff)
         @param: percentage  => the distance from the first color, as a decimal between 0 and 1 (ie: 0.5)
         @returns: string    => the third color, hex, represenatation of the blend between color1 and color2 at the given percentage
         */
        blend_colors: function (colors, percentage) {
            // check input
            let color1 = colors[0];
            let color2 = colors[1];
            let color3 = colors[2];
            percentage = typeof percentage === "undefined" ? 0.5 : percentage;

            // 1: validate input, make sure we have provided a valid hex
            if (color1.length !== 4 && color1.length !== 7)
                console.error('colors must be provided as hexes');

            if (color2.length !== 4 && color2.length !== 7)
                console.error('colors must be provided as hexes');

            if (colors.length === 3 && color3.length !== 4 && color3.length !== 7)
                console.error('colors must be provided as hexes');

            if (percentage > 1 || percentage < 0)
                console.error('percentage must be between 0 and 1');

            // 2: check to see if we need to convert 3 char hex to 6 char hex, else slice off hash
            //      the three character hex is just a representation of the 6 hex where each character is repeated
            //      ie: #060 => #006600 (green)
            if (color1.length === 4)
                color1 = color1[1] + color1[1] + color1[2] + color1[2] + color1[3] + color1[3];
            else
                color1 = color1.substring(1);
            if (color2.length === 4)
                color2 = color2[1] + color2[1] + color2[2] + color2[2] + color2[3] + color2[3];
            else
                color2 = color2.substring(1);
            if (colors.length === 3 && color3.length === 4)
                color3 = color3[1] + color3[1] + color3[2] + color3[2] + color3[3] + color3[3];
            else if (colors.length === 3)
                color3 = color3.substring(1);

            // 3: we have valid input, convert colors to rgb
            color1 = [parseInt(color1[0] + color1[1], 16), parseInt(color1[2] + color1[3], 16), parseInt(color1[4] + color1[5], 16)];
            color2 = [parseInt(color2[0] + color2[1], 16), parseInt(color2[2] + color2[3], 16), parseInt(color2[4] + color2[5], 16)];
            color3 = colors.length === 3 ? [parseInt(color3[0] + color3[1], 16), parseInt(color3[2] + color3[3], 16), parseInt(color3[4] + color3[5], 16)] : color3;

            // 4: blend
            var color4 = [];
            if (colors.length === 3 && percentage <= .5) {
                percentage = percentage / .5;
                color4 = [
                    (1 - percentage) * color1[0] + percentage * color2[0],
                    (1 - percentage) * color1[1] + percentage * color2[1],
                    (1 - percentage) * color1[2] + percentage * color2[2]
                ];
            } else if (colors.length === 3) {
                percentage = (percentage - .5) / .5;
                color4 = [
                    (1 - percentage) * color2[0] + percentage * color3[0],
                    (1 - percentage) * color2[1] + percentage * color3[1],
                    (1 - percentage) * color2[2] + percentage * color3[2]
                ];
            } else {
                color4 = [
                    (1 - percentage) * color1[0] + percentage * color2[0],
                    (1 - percentage) * color1[1] + percentage * color2[1],
                    (1 - percentage) * color1[2] + percentage * color2[2]
                ];
            }

            // 5: convert to hex
            color4 = '#' + this.int_to_hex(color4[0]) + this.int_to_hex(color4[1]) + this.int_to_hex(color4[2]);

            // return hex
            return color4;
        },

        /*
         convert a Number to a two character hex string
         must round, or we will end up with more digits than expected (2)
         note: can also result in single digit, which will need to be padded with a 0 to the left
         @param: num         => the number to conver to hex
         @returns: string    => the hex representation of the provided number
         */
        int_to_hex: function (num) {
            var hex = Math.round(num).toString(16);
            if (hex.length === 1)
                hex = '0' + hex;
            return hex;
        },
        checkNodeType: function (n, t) {
            return Array.isArray(t) ? t.indexOf(n.nodeType) >= 0 : n.nodeType === t;
        },
        moveToFrontBackInit: function () {
            //to front
            d3.selection.prototype.moveToFront = function () {
                return this.each(function () {
                    this.parentNode.appendChild(this);
                });
            };
            //to back
            d3.selection.prototype.moveToBack = function () {
                return this.each(function () {
                    var firstChild = this.parentNode.firstChild;
                    if (firstChild) {
                        this.parentNode.insertBefore(this, firstChild);
                    }
                });
            };
        },
        addTextBox: function (arrayData, textAnchor, verticalAlign, rect, fontStyles, color, selector, idFunction) {
            let ellipsis = (text, line) => {
                let resultText = text.substring(0, text.length - 1).trim();
                while (resultText.endsWith(".")) {
                    resultText = resultText.substring(0, resultText.length - 1);
                }
                return line ? resultText + "…" : "";
            };
            let textB = new d3plus.TextBox()
                    .data(arrayData)
                    .verticalAlign(typeof verticalAlign !== "undefined" && verticalAlign !== null ? verticalAlign : "middle")
                    .textAnchor(textAnchor)
                    .overflow(true)
                    .x(rect.x)
                    .y(rect.y)
                    .width(rect.width)
                    .height(rect.height)
                    .ellipsis(ellipsis)
                    .fontSize(fontStyles["font-size"])
                    .fontWeight(fontStyles["font-weight"])
                    .fontColor(color)
                    .select(selector);
            if (typeof fontStyles["line-height"] !== "undefined" && fontStyles["line-height"] !== null) {
                textB.lineHeight(fontStyles["line-height"]);
            }
            if (typeof idFunction !== "undefined" && this.isFunction(idFunction)) {
                textB.id(idFunction);
            }
            if (typeof fontStyles["font-family"] !== "undefined" && fontStyles["font-family"] !== null) {
                textB.fontFamily(fontStyles["font-family"]);
            }
            textB.render();
            return textB;
        },
        highlightOn: function (elm, strockeWidth) {
            const scale = 1.5;
            d3.select(elm).select("[highlight='true']").style("stroke-width", strockeWidth * scale);
        },
        highlightOff: function (elm, strockeWidth) {
            d3.select(elm).select("[highlight='true']").style("stroke-width", strockeWidth);
        },
        //https://stackoverflow.com/a/6860916
        getRandomId: function () {
            var S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return "R_" + (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
        },
        matcher: function (text, mask) {
            var textLC = text.toUpperCase();
            var maskLC = mask.toUpperCase();
            return (!maskLC.includes("*") && !maskLC.includes("?")) ? textLC.indexOf(maskLC) >= 0 : this.match(textLC, maskLC);
        },
        /**
         * @private
         * Glob pattern matcher.
         * It understands the glob characters `*' meaning `zero or more characters' and `?' meaning exactly one character, just like most Unix shells.
         * @param {type} text
         * @param {type} mask
         * @returns {Boolean}
         */
        match: function (text, mask) {
            if ("*" !== mask[0]) {
                if (text.length > 0) {
                    return ("?" === mask[0]) | (text[0] === mask[0]) && this.match(text.substr(1, text.length - 1), mask.substr(1, mask.length - 1));
                } else {
                    return mask.length <= 0;
                }
            }
            return this.match(text, mask.substr(1, mask.length - 1)) || text.length > 0 && this.match(text.substr(1, text.length - 1), mask);
        },
        fieldIsDefinietAndNotNull: function (field) {
            return typeof field !== "undefined" && field !== null;
        },
        // https://stackoverflow.com/a/359910
        executeFunctionByName: function (functionName, context /*, args */) {
            var args = Array.prototype.slice.call(arguments, 2);
            var namespaces = functionName.split(".");
            var func = namespaces.pop();
            for (var i = 0; i < namespaces.length; i++) {
                context = context[namespaces[i]];
            }
            return context[func].apply(context, args);
        },
        isEmptyObject: function (obj) {
            return Object.keys(obj).length === 0;
        },
    };
})();

export default Utilities;
