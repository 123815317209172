import * as d3 from 'd3';
import Node from "./node";

/* global d3, ScrollBar, Node */

var EvidenceSummary = (function () {
    var dropdownMenuTitle = d3.select("#evidenceListDropdown_title");
    var dropdownMenuList = d3.select("#evidenceListDropdown_list");
    var idPrefixItem = "summaryEvidenceDD";//DD - drop down

    function addToList(node) {
        var item = dropdownMenuList.selectAll("#" + idPrefixItem + node.handle);
        if (item.size() === 0) {
            item = item.data([node])
                    .enter()
                    .append("a")
                    .attr("class", "dropdown-item")
                    .attr("data-toggle", "collapse")
                    .attr("data-target", ".navbar-collapse.show")
                    .attr("id", d => idPrefixItem + d.handle)
                    .on("click", d => {
                        Node.blinkNode(d);
                    });
        }
        item.text(d => {
            var evidence = Node.Evidence.getEvidence(d);
            if (evidence.isDynamic) {
                return d.name + " - (dynamic)";
            }
            if (evidence.isVirtual) {
                return d.name + " - (virtual)";
            }
            if (evidence.evidenceType === "CONTINUOUS") {
                return d.name + " - " + evidence.evidence;
            }

            return d.name + " - " + Node.getStateLabels(node)[evidence.evidence];
        });
        setMenuTitle();
    }

    function removeFromList(node) {
        dropdownMenuList.select("#" + idPrefixItem + node.handle).remove();
        setMenuTitle();
    }

    function setMenuTitle() {
        var evidenceCount = dropdownMenuList.selectAll("a:not(.noremove)").size();
        if (evidenceCount === 0) {
            dropdownMenuTitle.text("Evidence: (none)");
            dropdownMenuList.classed("d-none",true);
        } else {
            dropdownMenuTitle.text("Evidence: " + evidenceCount + " node(s)");
            dropdownMenuList.classed("d-none",false);
        }
    }

    function clearAllMenu() {
        dropdownMenuList.selectAll("a:not(.noremove)").remove();
        setMenuTitle();
    }

    $(document).ready(function () {
        $("#clearAllEvidenceBtn").on("click", ()=>{
            Node.Evidence.clearAllEvidence();
            if(Network.isAutoUpdate()){
                Network.update();
            }
        });
    });

    return {
        addEvidence: function (node) {
            addToList(node);
        },
        removeEvidence: function (node) {
            removeFromList(node);
        },
        clearAll: function () {
            clearAllMenu();
        }
    };
})();

export default EvidenceSummary;
