import Network from './network';

var Logo = (function () {
    let parentDivSelector = '';
    var id = "bayesbox";
    var htmlBody = '<div id="'+id+'" style="position: absolute;right: 1px;bottom: 0;color: #fff;text-shadow: 0px 0px 5px #000;-webkit-font-smoothing: antialiased;font-size: 18px;z-index: -1000;">' +
            'Powered by BayesBox &nbsp;' +
            '</div>';
    function verification() {
        parentDivSelector = `#${Network.getSvgContentId()}`;
        var l = $("#" + id);
        if (l.length === 0) {//exist in DOM
            appendLogo();
        } else if($(parentDivSelector + " > #" + id).length === 0) {//exist but in incorrect place
            move(l);
        }
    }
    function appendLogo() {
        $(parentDivSelector).append(htmlBody);
    }
    function move(logo) {
        logo.detach().appendTo(parentDivSelector);
    }
    return {
        verify: function () {
            verification();
        }
    };
})();

export default Logo;
