var Geometric = {
    /*
     Calculate two points for line join two ellipse.
     How works: solve two system of equations:
     - linear equation with two ellpise center points and elipse equation A
     - linear equation with two ellpise center points and elipse equation B

     cX1,cY1 - center elpise A
     rX1,rY1 - radius elipse A
     cX2,cY2 - center elpise B
     rX2,rY2 - radius elipse B

     return [x,y,x,y]=[P1,P2]
     @deprecated use calculateArcPointsEllipse
     */
    /* global DECISION, NOISY_ADDER, CPT, EQUATION, NOISY_MAX, TRUTH_TABLE, NODE_TYPE */
    calculatePointsEllipse: function (cX1, cY1, rX1, rY1, cX2, cY2, rX2, rY2)
    {
        console.log('calculatePointsEllipse: This function is deprecated. Use calculateArcPointsEllipse');
        // ----------------[x,y,x,y]
        var outPoints = [-1, -1, -1, -1];
        // linear equation witch two elipse center points
        // first elipse point for line
        var resolvePoints = this.solveEqLineAndEllipse(cX1, cY1, rX1, rY1, cX2, cY2);
        outPoints[0] = resolvePoints[0];
        outPoints[1] = resolvePoints[1];
        // second elipse point for line
        resolvePoints = this.solveEqLineAndEllipse(cX2, cY2, rX2, rY2, cX1, cY1);
        outPoints[2] = resolvePoints[0];
        outPoints[3] = resolvePoints[1];
        return outPoints;
    },
    /*
     #########################################################
     ################## Ellipse ##############################
     #########################################################
     Calculating point for arc line for ellipse node
     width
     _______________
     |           #######
     |          #       #
     height|---#--(x,y)--#------------------------------(pointX, pointY)
     |          #       #                           point on the arc [usually center point in next node]
     |            #######
     */
    calculateArcPointsEllipse: function (x, y, width, height, pointX, pointY) {
        var rY = height / 2;
        var rX = width / 2;
        var resolvePoints = this.solveEqLineAndEllipse(x, y, rX, rY, pointX, pointY);
        return resolvePoints;
    },
    /*
     solve system of linear equation and ellipse equation
     cX1,cY1 - center elpise
     rX1,rY1 - radius elipse
     cX2,cY2 - point on straight line (linear equation creating two points:(cX1,cY1) and (cX2,cY2))
     */
    solveEqLineAndEllipse: function (cX1, cY1, rX, rY, cX2, cY2) {
        var outPoints = [-1, -1];
        var w = 0;
        var z = 0;
        var elipsePoints = [-1, -1, -1, -1];
        // linear equation witch two elipse center points
        // first elipse point for line
        // if cY1 == cY2 then linear equation: y=cY
        if (cY1 === cY2) {
            elipsePoints = this.solveSystemOfQuadraticEquations_OY(cX1, cY1, rX, rY);
        } else if (cX1 === cX2) { // if cX1 == cX2 then linear equation: x=cX
            elipsePoints = this.solveSystemOfQuadraticEquations_OX(cX1, cY1, rX, rY);
        } else { // cX1 != cX2 && cY1 != cY2 then linear equation: y=wx+z
            w = (cY1 - cY2) / (cX1 - cX2);
            z = cY1 - w * cX1;
            elipsePoints = this.solveSystemOfQuadraticEquations(cX1, cY1, rX, rY, w, z);
        }
        // distance between center elipse B and P1
        var distP1B = this.calculateDistancePoint(elipsePoints[0], elipsePoints[1], cX2, cY2);
        // distance between center elipse B and P2
        var distP2B = this.calculateDistancePoint(elipsePoints[2], elipsePoints[3], cX2, cY2);
        // get min distance point
        if (distP1B <= distP2B) {
            outPoints[0] = elipsePoints[0];
            outPoints[1] = elipsePoints[1];
        } else if (distP1B > distP2B) {
            outPoints[0] = elipsePoints[2];
            outPoints[1] = elipsePoints[3];
        }
        return outPoints;
    },
    /*
     Solve quadratic equation with elipse equation where x1!=x2 and y1!=y2
     cX, cY - center elipse points
     rX, rY - radius x and radius y elipse
     w,z - parameters linear function witch two elipse center points 'y=wx+z'
     return [x1,y1,x2,y2]=[P1,P2]
     */
    solveSystemOfQuadraticEquations: function (cX, cY, rX, rY, w, z) {
        var a = (rY * rY) / (w * w) + (rX * rX);
        var b = -2 * z * (rY * rY) / (w * w) - 2 * cX * (rY * rY) / w - 2 * (rX * rX) * cY;
        var c = (rY * rY) * (z * z) / (w * w) + 2 * cX * z * (rY * rY) / w + (cX * cX) * (rY * rY) + (rX * rX) * (cY * cY) - (rX * rX) * (rY * rY);
        var solveValues_Y = this.solveQuadraticEquation(a, b, c);
        if (solveValues_Y.length === 2) {
            var x1 = (solveValues_Y[0] - z) / w;
            var x2 = (solveValues_Y[1] - z) / w;
            return [x1, solveValues_Y[0], x2, solveValues_Y[1]];
        } else {
            return [null];
        }

    },
    /*
     Solve quadratic equation with elipse equation where y1==y2
     cX, cY - center elipse points
     rX, rY - radius x and radius y elipse
     y - linear function represented by constant 'y=cY'
     return [x1,y1,x2,y2]=[P1,P2]
     */
    solveSystemOfQuadraticEquations_OY: function (cX, cY, rX, rY) {
        var y = cY;
        var a = (rY * rY);
        var b = -2 * cX * a;
        var c = (cX * cX) * (rY * rY) + (rX * rX) * ((y - cY) * (y - cY)) - (rX * rX) * (rY * rY);
        var solveValues = this.solveQuadraticEquation(a, b, c);
        if (solveValues.length === 2) {
            return [solveValues[0], y, solveValues[1], y];
        } else {
            return [null];
        }

    },
    /*
     Solve quadratic equation with elipse equation where x1==x2
     cX, cY - center elipse points
     rX, rY - radius x and radius y elipse
     x - linear function represented by constant 'x=cX'
     return [x1,y1,x2,y2]=[P1,P2]
     example:
     cX = y  <-- linear Function
     ax^2+bx+c=y  <-- ellipse function
     */
    solveSystemOfQuadraticEquations_OX: function (cX, cY, rX, rY) {
        var x = cX;
        var a = (rX * rX);
        var b = -2 * cY * a;
        var c = (rX * rX) * (cY * cY) + (rY * rY) * ((x - cX) * (x - cX)) - (rX * rX) * (rY * rY);
        var solveValues = this.solveQuadraticEquation(a, b, c);
        if (solveValues.length === 2) {
            return [x, solveValues[0], x, solveValues[1]];
        } else {
            return [null];
        }

    },
    /*
     Solve quadratic equation ax^2+bx+c=0
     */
    solveQuadraticEquation: function (a, b, c) {
        var delta = (b * b) - 4 * a * c;
        if (delta > 0) {
            var sqrtDelta = Math.sqrt(delta);
            var numerator1 = ((-1 * b) - sqrtDelta);
            var numerator2 = ((-1 * b) + sqrtDelta);
            var denominator = 2 * a;
            var x1 = numerator1 / denominator;
            var x2 = numerator2 / denominator;
            return [x1, x2];
        } else if (delta === 0) {
            var denominator = 2 * a;
            var x = null;
            if (denominator !== 0) {
                x = (-1 * b) / denominator;
            }

            return [x];
        } else {
            return [null];
        }
    },
    /*
     Calculate distance between two points
     x1,y1 - first point
     x2,y2 - second point
     */
    calculateDistancePoint: function (x1, y1, x2, y2) {
        return Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)));
    },
    /*
     #########################################################
     ################## BAR CHART ############################
     #########################################################
     A1                      A2
     ___________           _____________
     |         |           |           |
     D1 | (xA,yA) | B1     D2 |  (xB,yB)  | B2
     |         |           |           |
     ----------            ------------
     C1                       C2
     @deprecated use calculateArcPointsRectangle
     */
    calculatePointsBarChart: function (xA, yA, widthA, heightA, xB, yB, widthB, heightB) {
        console.log('calculatePointsBarChart: This function is deprecated. Use calculateArcPointsRectangle');
        //prepare data
        var A1 = yA + (heightA / 2);
        var B1 = xA + (widthA / 2);
        var C1 = yA - (heightA / 2);
        var D1 = xA - (widthA / 2);
        var A2 = yB + (heightB / 2);
        var B2 = xB + (widthB / 2);
        var C2 = yB - (heightB / 2);
        var D2 = xB - (widthB / 2);
        //possible points node1
        var P11 = [this.calculatePoint(A1, xA, xB, yA, yB), A1];
        var P12 = [B1, this.calculatePoint(B1, yA, yB, xA, xB)];
        var P13 = [this.calculatePoint(C1, xA, xB, yA, yB), C1];
        var P14 = [D1, this.calculatePoint(D1, yA, yB, xA, xB)];
        //possible points node2
        var P21 = [this.calculatePoint(A2, xA, xB, yA, yB), A2];
        var P22 = [B2, this.calculatePoint(B2, yA, yB, xA, xB)];
        var P23 = [this.calculatePoint(C2, xA, xB, yA, yB), C2];
        var P24 = [D2, this.calculatePoint(D2, yA, yB, xA, xB)];
        //calculated points
        var pointsA = this.nodeRange(xA, yA, heightA, widthA, [P11, P12, P13, P14]);
        var pointsB = this.nodeRange(xB, yB, heightB, widthB, [P21, P22, P23, P24]);
        //distance points
        var minDistancePionts = [null, null];
        var minDistance = Number.MAX_SAFE_INTEGER;
        for (var i = 0; i < pointsA.length; i++) {
            for (var j = 0; j < pointsB.length; j++) {
                var tmpDistance = this.calculateDistancePoint(pointsA[i][0], pointsA[i][1], pointsB[j][0], pointsB[j][1]);
                if (tmpDistance <= minDistance) {
                    minDistance = tmpDistance;
                    minDistancePionts[0] = pointsA[i];
                    minDistancePionts[1] = pointsB[j];
                }
            }
        }

        return [minDistancePionts[0][0], minDistancePionts[0][1], minDistancePionts[1][0], minDistancePionts[1][1]];
    },
    /*
     #########################################################
     ################## Rectangle ############################
     #########################################################
     Calculating point for arc line for rectangle node
     A
     width
     ___________
     |         |
     --D-height-|--(x,y)--|--B---------------------------(pointX, pointY)
     |         |                             point on the arc [usually center point in next node]
     ----------
     C
     *
     * @param {type} x
     * @param {type} y
     * @param {type} width
     * @param {type} height
     * @param {type} pointX
     * @param {type} pointY
     * @returns {Array}
     */
    calculateArcPointsRectangle: function (x, y, width, height, pointX, pointY) {
        //prepare data
        var A = y + (height / 2);
        var B = x + (width / 2);
        var C = y - (height / 2);
        var D = x - (width / 2);
        //possible points node1
        var P1 = [this.calculatePoint(A, x, pointX, y, pointY), A];
        var P2 = [B, this.calculatePoint(B, y, pointY, x, pointX)];
        var P3 = [this.calculatePoint(C, x, pointX, y, pointY), C];
        var P4 = [D, this.calculatePoint(D, y, pointY, x, pointX)];
        //calculated points
        var pointsA = this.nodeRange(x, y, height, width, [P1, P2, P3, P4]);
        //distance points
        var minDistancePionts = [null, null];
        var minDistance = Number.MAX_SAFE_INTEGER;
        var lenPointsA = pointsA.length;
        for (var i = 0; i < lenPointsA; i++) {
            var tmpDistance = this.calculateDistancePoint(pointsA[i][0], pointsA[i][1], pointX, pointY);
            if (tmpDistance <= minDistance) {
                minDistance = tmpDistance;
                minDistancePionts = pointsA[i];
            }
        }

        return [minDistancePionts[0], minDistancePionts[1]];
    },
    calculatePoint: function (y, xA, xB, yA, yB) {
        if ((yB - yA) === 0) {
            return null;
        }
        return ((y - yA) * (xB - xA)) / (yB - yA) + xA;
    },
    /*
     Important for rectangle node!
     */
    nodeRange: function (x, y, height, width, point_tab) {
        //set range
        var x_min = x - (width / 2);
        var x_max = x + (width / 2);
        var y_min = y - (height / 2);
        var y_max = y + (height / 2);
        var result = [];
        for (var i = 0; i < point_tab.length; i++) {
            var point = point_tab[i];
            if (point !== null) {
                if (point[0] >= x_min && point[0] <= x_max) {
                    if (point[1] >= y_min && point[1] <= y_max) {
                        result.push(point);
                    }
                }
            }
        }
        return result;
    },
    /*
     calculate points arc between barChart and Ellipse
     ellCX, ellCY - ellipse center point
     ellRX, ellRY - ellipse width and height
     rectCX, rectCY - rectangle center point
     rect_width, rect_height - rectangle width and height

     return [ellipse point, rectangle point]=[ellX, ellY, rectX, rectY]
     @deprecated use calculateArcPointsEllipse or calculateArcPointsRectangle
     */
    calculatePointsMix: function (ellCX, ellCY, ellRX, ellRY, rectCX, rectCY, rect_width, rect_height) {
        console.log('calculatePointsMix: This function is deprecated.');
        //ellipse point
        // first elipse point for line
        var pointaEllipse = this.solveEqLineAndEllipse(ellCX, ellCY, ellRX, ellRY, rectCX, rectCY);
        //rectangle point
        var A1 = rectCY + (rect_height / 2);
        var B1 = rectCX + (rect_width / 2);
        var C1 = rectCY - (rect_height / 2);
        var D1 = rectCX - (rect_width / 2);
        //possible points rectangle
        var P11 = [this.calculatePoint(A1, ellCX, rectCX, ellCY, rectCY), A1];
        var P12 = [B1, this.calculatePoint(B1, ellCY, rectCY, ellCX, rectCX)];
        var P13 = [this.calculatePoint(C1, ellCX, rectCX, ellCY, rectCY), C1];
        var P14 = [D1, this.calculatePoint(D1, ellCY, rectCY, ellCX, rectCX)];
        //calculated points
        var pointsRect = this.nodeRange(rectCX, rectCY, rect_height, rect_width, [P11, P12, P13, P14]);
        //distance points
        var minDistancePiontRect;
        var minDistance = Number.MAX_SAFE_INTEGER;
        for (var i = 0; i < pointsRect.length; i++) {
            var tmpDistance = this.calculateDistancePoint(pointaEllipse[0], pointaEllipse[1], pointsRect[i][0], pointsRect[i][1]);
            if (tmpDistance <= minDistance) {
                minDistance = tmpDistance;
                minDistancePiontRect = pointsRect[i];
            }
        }

        return [pointaEllipse[0], pointaEllipse[1], minDistancePiontRect[0], minDistancePiontRect[1]];
    },
    /*
     Calculate Utility and NODE_TYPE.MAU (hexagon) points
     x,y - top left corner of the rectangle
     w - rectangle width
     h - rectangle height
     return - [{x,y},{x,y},{x,y},{x,y},{x,y},{x,y}]
     */
    GetPolygonPoints: function (x, y, w, h)
    {
        var delta = (w > h) ? h / 2 : w / 2;
        var polygon = [new Object(), new Object(), new Object(), new Object(), new Object(), new Object()];
        var left = x;
        var right = x + w;
        var bottom = y + h;
        var top = y;
        polygon[0].x = left;
        polygon[0].y = polygon[3].y = bottom - h / 2;
        polygon[1].x = polygon[5].x = left + delta;
        polygon[1].y = polygon[2].y = bottom;
        polygon[2].x = polygon[4].x = right - delta;
        polygon[3].x = right;
        polygon[4].y = polygon[5].y = top;
        return polygon;
    },
    ellipseCandidateRect: function (lx, ly, width, height, blockHeight) {
        var cx = lx + (width / 2);
        var cy = ly + (height / 2);
        var rx = width / 2;
        var ry = height / 2;
        //horizontal
        var s = (cy - blockHeight); //get point between <cy,cy-ry>
        var a = 1;
        var b = -2 * cx;
        var c = cx * cx + rx * rx * (s - cy) * (s - cy) / (ry * ry) - (rx * rx);
        var solveX = Geometric.solveQuadraticEquation(a, b, c);

        var x;//current x (left top rectangle)
        var w;//current width
        if (solveX[0] <= solveX[1]) {
            x = solveX[0];
            w = solveX[1] - solveX[0];
        } else {
            x = solveX[1];
            w = solveX[0] - solveX[1];
        }
        //vertical
        //compute y point (left top rectangle) based on X compute above
        a = 1;
        b = -2 * cy;
        c = cy * cy + ry * ry * (x - cx) * (x - cx) / (rx * rx) - ry * ry;
        var solveY = Geometric.solveQuadraticEquation(a, b, c);

        var y;//current x (left top rectangle)
        var h;//current height~
        if (solveY[0] <= solveY[1]) {
            y = solveY[0];
            h = solveY[1] - solveY[0];
        } else {
            y = solveY[1];
            h = solveY[0] - solveY[1];
        }
        return {
            x: x,
            y: y,
            w: w,
            h: h
        };
    },
    hexagonCandidateRect: function (lx, ly, width, height, blockHeight) {
        var centerX = lx + width / 2;
        var centerY = ly + height / 2;
        var a = width / 2;
        var y = 1 + blockHeight;
        var x = a - y;
        x = x - 1;
        // text will be clipped, but try to maximize rectangle area
//        var f = x * y;
        return {
            x: centerX - x - 1,
            y: centerY - y,
            w: 2 * x + 2,
            h: 2 * y + 1
        };
    },
    rectangleCandidateRect: function (lx, ly, width, height, blockHeight) {
        var centerY = ly + height/2;
        return {
            x: lx,
            y: centerY - blockHeight,
            w: width,
            h: blockHeight * 2
        };
    },
    IsBetweenZeroAndOne: function (num, denom)
    {
        if (denom > 0)
        {
            return (num >= 0) && (num <= denom);
        } else
        {
            return (num <= 0) && (num >= denom);
        }
    },
    LineSegmentIntersection: function (pts)
    {
        var num, denom;
        var ptIntersect = new Object();
        var bax = (pts[1].x - pts[0].x);
        var bay = (pts[1].y - pts[0].y);
        var dcx = (pts[3].x - pts[2].x);
        var dcy = (pts[3].y - pts[2].y);
        denom = bax * dcy - bay * dcx;
        if (0 === denom)
        {
            // lines are parallel or colinear
            ptIntersect.crossLine = false;
            return ptIntersect;
        }

        var acx = (pts[0].x - pts[2].x);
        var acy = (pts[0].y - pts[2].y);
        num = acy * dcx - acx * dcy;
        if (!this.IsBetweenZeroAndOne(num, denom)) {
            ptIntersect.crossLine = false;
            return ptIntersect;
        }
        num = acy * bax - acx * bay;
        if (!this.IsBetweenZeroAndOne(num, denom)) {
            ptIntersect.crossLine = false;
            return ptIntersect;
        }

        // intersection exists
        ptIntersect.x = pts[2].x + dcx * num / denom;
        ptIntersect.y = pts[2].y + dcy * num / denom;
        ptIntersect.crossLine = true;
        return ptIntersect;
    },
    /*
     #########################################################
     ################## Diamond (hexagon) ####################
     #########################################################
     Calculating point for arc line for diamond node
     width
     ---------------
     |    ______
     |  /       \
     height|/---(x,y)--\---------------------------(pointX, pointY)
     |\         /                          point on the arc [usually center point in next node]
     | \_______/

     */
    calculateArcPointsDiamond: function (x, y, width, height, pointX, pointY) //const CPoint &ptStart, const CRect &rcPosition
    {
        var ptPolygon = this.GetPolygonPoints((x - (width / 2)), (y - (height / 2)), width, height);
        var ptSegments = [new Object(), new Object(), new Object(), new Object()];
        var ptIntersect;
        ptSegments[2].x = pointX;
        ptSegments[2].y = pointY;
        ptSegments[3].x = x;
        ptSegments[3].y = y;
        ptSegments[0] = ptPolygon[0];
        ptSegments[1] = ptPolygon[1];
        ptIntersect = this.LineSegmentIntersection(ptSegments);
        if (ptIntersect.crossLine === true)
            return [ptIntersect.x, ptIntersect.y];
        ptSegments[0] = ptPolygon[2];
        ptIntersect = this.LineSegmentIntersection(ptSegments);
        if (ptIntersect.crossLine === true)
            return [ptIntersect.x, ptIntersect.y];
        ptSegments[1] = ptPolygon[3];
        ptIntersect = this.LineSegmentIntersection(ptSegments);
        if (ptIntersect.crossLine === true)
            return [ptIntersect.x, ptIntersect.y];
        ptSegments[0] = ptPolygon[4];
        ptIntersect = this.LineSegmentIntersection(ptSegments);
        if (ptIntersect.crossLine === true)
            return [ptIntersect.x, ptIntersect.y];
        ptSegments[1] = ptPolygon[5];
        ptIntersect = this.LineSegmentIntersection(ptSegments);
        if (ptIntersect.crossLine === true)
            return [ptIntersect.x, ptIntersect.y];
        ptSegments[0] = ptPolygon[0];
        ptIntersect = this.LineSegmentIntersection(ptSegments);
        if (ptIntersect.crossLine === true)
            return [ptIntersect.x, ptIntersect.y];
        ptIntersect = ptSegments[3];
        return [ptIntersect.x, ptIntersect.y];
    },
    /**
     * calculate new points for arc on parent node
     * @param {type} parentNode data
     * @param {type} childNode data
     * @param {type} parentTranslate
     * @param {type} childTranslate
     * @returns {x,y}
     */
    calculatePoints: function (parentNode, childNode, parentTranslate, childTranslate) {
        var X = 0;
        var Y = 1;
        var point = [];
        var parentWidth = parentNode.width;
        var parentHeight = parentNode.height;
        var childWidth = childNode.width;
        var childHeight = childNode.height;
        if (parentNode.isBarChart) {
            parentWidth = parentNode.template.width;
            parentHeight = parentNode.template.height;
        }
        if (childNode.isBarChart) {
            childWidth = childNode.template.width;
            childHeight = childNode.template.height;
        }
        // if submodel
        if (parentNode.submodelOfSubmodel) {
            point = this.calculateArcPointsRectangle(parentNode.x + parentWidth / 2 + parentTranslate[X],
                    parentNode.y + parentHeight / 2 + parentTranslate[Y],
                    parentWidth,
                    parentHeight,
                    childNode.x + childWidth / 2 + childTranslate[X],
                    childNode.y + childHeight / 2 + +childTranslate[Y]);
        } else {
            point = parentNode.flyweight.calculateArcPoints.call(Geometric,
                    parentNode.x + parentWidth / 2 + parentTranslate[X],
                    parentNode.y + parentHeight / 2 + parentTranslate[Y],
                    parentWidth,
                    parentHeight,
                    childNode.x + childWidth / 2 + childTranslate[X],
                    childNode.y + childHeight / 2 + +childTranslate[Y]);
        }
        return point;
    },
    ExternalEquations: {
        /*-------------------- Global Function Description Block ----------------------
         *
         *     ***QUARTIC************************************************25.03.98
         *     Solution of a quartic equation
         *     ref.: J. E. Hacke, Amer. Math. Monthly, Vol. 48, 327-328, (1941)
         *     NO WARRANTY, ALWAYS TEST THIS SUBROUTINE AFTER DOWNLOADING
         *     ******************************************************************
         *     dd(0:4)     (i)  vector containing the polynomial coefficients
         *     sol(1:4)    (o)  results, real part
         *     soli(1:4)   (o)  results, imaginary part
         *     Nsol        (o)  number of real solutions
         *     ==================================================================
         *  	17-Oct-2004 / Raoul Rausch
         *		Conversion from Fortran to C
         *
         *
         *-----------------------------------------------------------------------------
         */
        /**
         * Solution of a quartic equation
         * @param {type} dd - array containing the polynomial coefficients
         * @returns {quartic.solution} solution.sol = results, real part; solution.soli = results, imaginary part; solution.Nsol = number of real solutions
         */
        quartic: function (dd) {
            var solution = {
                sol: [], //results, real part
                soli: [], //results, imaginary part
                Nsol: -1//number of real solutions
            };
            var AA = [];
            var z = [];
            var a, b, c, d, f, p, q, r, zsol, xK2, xL, xK, sqp, sqm, ncube, i;
            if (dd[4] === 0) {
                return solution;
            }

            a = dd[4];
            b = dd[3];
            c = dd[2];
            d = dd[1];
            f = dd[0];
            p = (-3.0 * Math.pow(b, 2) + 8.0 * a * c) / (8.0 * Math.pow(a, 2));
            q = (Math.pow(b, 3) - 4.0 * a * b * c + 8.0 * d * Math.pow(a, 2)) / (8.0 * Math.pow(a, 3));
            r = (-3.0 * Math.pow(b, 4) + 16.0 * a * Math.pow(b, 2) * c - 64.0 * Math.pow(a, 2) * b * d + 256.0 * Math.pow(a, 3) * f) / (256.0 * Math.pow(a, 4));
            // Solve cubic resolvent
            AA[3] = 8.0;
            AA[2] = -4.0 * p;
            AA[1] = -8.0 * r;
            AA[0] = 4.0 * p * r - Math.pow(q, 2);
            {
                let cubicSol = this.cubic(AA);
                z = cubicSol.X;
                ncube = cubicSol.L;
            }

            zsol = -1.e99;
            for (i = 0; i < ncube; i++) {
                zsol = Math.max(zsol, z[i]);
            }
            z[0] = zsol;
            xK2 = 2.0 * z[0] - p;
            xK = Math.sqrt(xK2);
            xL = q / (2.0 * xK);
            sqp = xK2 - 4.0 * (z[0] + xL);
            sqm = xK2 - 4.0 * (z[0] - xL);
            for (i = 0; i < 4; i++) {
                solution.soli[i] = 0.0;
            }
            if ((sqp >= 0.0) && (sqm >= 0.0)) {
                solution.sol[0] = 0.5 * (xK + Math.sqrt(sqp));
                solution.sol[1] = 0.5 * (xK - Math.sqrt(sqp));
                solution.sol[2] = 0.5 * (-xK + Math.sqrt(sqm));
                solution.sol[3] = 0.5 * (-xK - Math.sqrt(sqm));
                solution.Nsol = 4;
            } else if ((sqp >= 0.0) && (sqm < 0.0)) {
                solution.sol[0] = 0.5 * (xK + Math.sqrt(sqp));
                solution.sol[1] = 0.5 * (xK - Math.sqrt(sqp));
                solution.sol[2] = -0.5 * xK;
                solution.sol[3] = -0.5 * xK;
                solution.soli[2] = Math.sqrt(-0.25 * sqm);
                solution.soli[3] = -Math.sqrt(-0.25 * sqm);
                solution.Nsol = 2;
            } else if ((sqp < 0.0) && (sqm >= 0.0)) {
                solution.sol[0] = 0.5 * (-xK + Math.sqrt(sqm));
                solution.sol[1] = 0.5 * (-xK - Math.sqrt(sqm));
                solution.sol[2] = 0.5 * xK;
                solution.sol[3] = 0.5 * xK;
                solution.soli[2] = Math.sqrt(-0.25 * sqp);
                solution.soli[3] = -Math.sqrt(-0.25 * sqp);
                solution.Nsol = 2;
            } else if ((sqp < 0.0) && (sqm < 0.0)) {
                solution.sol[0] = -0.5 * xK;
                solution.sol[1] = -0.5 * xK;
                solution.soli[0] = Math.sqrt(-0.25 * sqm);
                solution.soli[1] = -Math.sqrt(-0.25 * sqm);
                solution.sol[2] = 0.5 * xK;
                solution.sol[3] = 0.5 * xK;
                solution.soli[2] = Math.sqrt(-0.25 * sqp);
                solution.soli[3] = -Math.sqrt(-0.25 * sqp);
                solution.Nsol = 0;
            }
            for (i = 0; i < 4; i++) {
                solution.sol[i] -= b / (4.0 * a);
            }
            return solution;
        },
        /*-------------------- Global Function Description Block ----------------------
         *
         *     ***CUBIC************************************************08.11.1986
         *     Solution of a cubic equation
         *     Equations of lesser degree are solved by the appropriate formulas.
         *     The solutions are arranged in ascending order.
         *     NO WARRANTY, ALWAYS TEST THIS SUBROUTINE AFTER DOWNLOADING
         *     ******************************************************************
         *     A(0:3)      (i)  vector containing the polynomial coefficients
         *     X(1:L)      (o)  results
         *     L           (o)  number of valid solutions (beginning with X(1))
         *     ==================================================================
         *  	17-Oct-2004 / Raoul Rausch
         *		Conversion from Fortran to C
         *
         *
         *-----------------------------------------------------------------------------
         */
        /**
         * Solution of a cubic equation
         * @param {[]} A - array containing the polynomial coefficients
         * @returns {cubic.solution} solution.X = results; solution.L = number of valid solutions (beginning with X(1))
         */
        cubic: function (A) {//X[3] int* L
            var PI = 3.1415926535897932;
            var THIRD = 1 / 3;
            var W, P, Q, DIS, PHI, i;
            var U = [];
            var solution = {
                X: [], //results
                L: null //number of valid solutions (beginning with X(1))
            };
            // ====determine the degree of the polynomial ====
            if (A[3] !== 0) {
                //cubic problem
                W = A[2] / A[3] * THIRD;
                P = Math.pow((A[1] / A[3] * THIRD - Math.pow(W, 2)), 3);
                Q = -0.5 * (2.0 * Math.pow(W, 3) - (A[1] * W - A[0]) / A[3]);
                DIS = Math.pow(Q, 2) + P;
                if (DIS < 0) {
                    //three real solutions!
                    //Confine the argument of ACOS to the interval [-1;1]!
                    PHI = Math.acos(Math.min(1.0, Math.max(-1.0, Q / Math.sqrt(-P))));
                    P = 2.0 * Math.pow((-P), (5.e-1 * THIRD));
                    for (i = 0; i < 3; i++) {
                        U[i] = P * Math.cos((PHI + 2 * i * PI) * THIRD) - W;
                    }
                    solution.X[0] = Math.min(U[0], Math.min(U[1], U[2]));
                    solution.X[1] = Math.max(Math.min(U[0], U[1]), Math.max(Math.min(U[0], U[2]), Math.min(U[1], U[2])));
                    solution.X[2] = Math.max(U[0], Math.max(U[1], U[2]));
                    solution.L = 3;
                } else {
                    // only one real solution!
                    DIS = Math.sqrt(DIS);
                    solution.X[0] = this.CBRT(Q + DIS) + this.CBRT(Q - DIS) - W;
                    solution.L = 1;
                }
            } else if (A[2] !== 0) {
                // quadratic problem
                P = 0.5 * A[1] / A[2];
                DIS = Math.pow(P, 2) - A[0] / A[2];
                if (DIS > 0) {
                    // 2 real solutions
                    solution.X[0] = -P - Math.sqrt(DIS);
                    solution.X[1] = -P + Math.sqrt(DIS);
                    solution.L = 2;
                } else {
                    // no real solution
                    solution.L = 0;
                }
            } else if (A[1] !== 0) {
                //linear equation
                solution.X[0] = A[0] / A[1];
                solution.L = 1;
            } else {
                //no equation
                solution.L = 0;
            }
            //
            //     ==== perform one step of a newton iteration in order to minimize
            //          round-off errors ====
            //
            for (i = 0; i < solution.L; i++) {
                solution.X[i] = solution.X[i] - (A[0] + solution.X[i] * (A[1] + solution.X[i] * (A[2] + solution.X[i] * A[3]))) / (A[1] + solution.X[i] * (2.0 * A[2] + solution.X[i] * 3.0 * A[3]));
            }
            return solution;
        },
        CBRT: function (Z) {
            var ret;
            var THIRD = 1 / 3;
            ret = Math.abs(Math.pow(Math.abs(Z), THIRD)) * this.signR(Z);
            return ret;
        },
        signR: function (Z) {
            var ret = 0;
            if (Z > 0)
                ret = 1;
            if (Z < 0)
                ret = -1;
            if (Z === 0)
                ret = 0;
            return ret;
        }
    }
};

export default Geometric;
