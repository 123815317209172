/* global Utilities, Network, ZoomNetwork, d3, d3plus, NodeShape, Node, Arc, ScrollBar */

import * as d3 from 'd3';
import Network from "./network";
import ZoomNetwork from "./zoomNetwork";
import Utilities from "./utilities";
import {BAYESBOX_MODE} from "./constantsMapping";
import Arc from "./arc";
import Node from "./node";
import DynamicBN from "./dynamicBN";
import NodeShape from "./nodeShape";

var Submodel = {
    submodel_tree: new Map(),
    MAIN_SUBMODEL: null,
    SUBMODEL_PREFIX: "submodel_", //+submodel handle
    SUBMODEL_RECTANGLE_PREFIX: "submodelRectangleNode_",
    TYPE: "submodel",
    TYPE_RECTANGLE: "submodelNode",
    currentSubmodel: -1,
    colorSource: [],//deMorgan
    /**
     * Draw submodels group <g>
     * @param {type} data - json data from /network/get
     */
    addSubmodelsGroups: function (data) {
        this.MAIN_SUBMODEL = data.mainSubmodel;
        this.currentSubmodel = this.MAIN_SUBMODEL;
        var submodels = data.submodels;
        d3.select(Network.getSVG_CSS_SELECTOR()).select(ZoomNetwork.getCSSZoomPlaceSelector()).selectAll(Utilities.getGTypeSelector(this.TYPE))
                .data(submodels)
                .enter()
                .append("g")
                .attr("id", (d) => {
                    return this.SUBMODEL_PREFIX + d.handle;
                })
                .attr("type", this.TYPE)
                .attr("display", (d) => {
                    if (d.handle === this.MAIN_SUBMODEL) {
                        return "inline";
                    } else {
                        return "none";
                    }
                });
    },
    submodelRectangleExist: function (submodelHandle) {
        return d3.select(Network.getSVG_CSS_SELECTOR())
                .selectAll(Utilities.getGTypeSelector(this.TYPE_RECTANGLE))
                .data()
                .some(s => s.handle === submodelHandle);
    },
    /**
     * Draw submodel rectangle
     * @param {type} submodel - single submodel
     */
    addSubmodelRectangle: function (submodel) {
        if (submodel.handle === this.MAIN_SUBMODEL)
            return;
        submodel.flyweight = {
            circleCutArcs: DynamicBN.rectangleCircleCut
        };
        submodel.checkType = function (t) {
            return false;//for compatible with Smile nodes
        };
        d3.select(Network.getSVG_CSS_SELECTOR()).select("#" + this.SUBMODEL_PREFIX + submodel.submodelOfSubmodel).selectAll("#" + this.SUBMODEL_RECTANGLE_PREFIX + submodel.handle)
                .data([submodel])
                .enter()
                .append("g")
                .attr("id", this.SUBMODEL_RECTANGLE_PREFIX + submodel.handle)
                .attr("type", this.TYPE_RECTANGLE)
                .on('dblclick', () => {
                    this.viewSubmodel(submodel.handle);
                })
                .on("mouseover", function () {//node highlight ON
                    Utilities.highlightOn(this, submodel.borderWidth);
                })
                .on("mouseout", function () {//node highlight OFF
                    Utilities.highlightOff(this, submodel.borderWidth);
                })
                .style("font-style", submodel.italic ? "italic" : null)
                .append("rect")
                .attr("x", submodel.x)
                .attr("y", submodel.y)
                .attr("rx", 5)
                .attr("ry", 5)
                .attr("width", submodel.width)
                .attr("height", submodel.height)
                .style("fill", submodel.bgColor)
                .style("stroke-width", submodel.borderWidth)
                .style("stroke", submodel.borderColor)
                .attr('highlight', 'true');//node highlight when mouse over

        let fontSize = typeof submodel.fontSize !== "undefined" ? submodel.fontSize : NodeShape.FONT_SIZE;
        let fontStyles = Utilities.getFontStyles(fontSize);
        var name = Utilities.cutLongNameV3(submodel.name, submodel.width, false, -1, fontStyles);
        var textBoxData = [{
                "text": Utilities.addBreakLines(name),
                "name": name
            }];
        Utilities.addTextBox(textBoxData,
                "middle",
                "middle",
                submodel,
                fontStyles,
                submodel.textColor,
                "#" + this.SUBMODEL_RECTANGLE_PREFIX + submodel.handle);
    },
    /**
     * Prepare submodels tree structure
     * @param {type} submodels - array of submodels
     */
    buildSubmodelTree: function (submodels) {
        this.submodel_tree = new Map();
        for (var i = 0; i < submodels.length; i++) {
            var submodelData = {
                handle: submodels[i].handle,
                nextHandle: submodels[i].submodelOfSubmodel,
                name: submodels[i].name,
                id: submodels[i].id,
                nodes: submodels[i].nodes,
                propagatedColor: submodels[i].propagatedColor
            };
            this.submodel_tree.set(submodels[i].handle, submodelData);
        }
    },
    updateNodesInSubmodelsTree_BayesTeam: function () {
        d3.select(Network.getSVG_CSS_SELECTOR())
                .selectAll(Utilities.getGTypeSelector(this.TYPE))
                .each(s => {
                    var currentSubmodelData = d3.select(Network.getSVG_CSS_SELECTOR()).select("#" + this.SUBMODEL_PREFIX + s.handle).selectAll(Utilities.getGTypeSelector(Node.TYPE)).data();
                    Submodel.submodel_tree.get(s.handle).nodes = currentSubmodelData;
                });
    },
    /**
     * Show selected submodel and hide the others
     * @param {type} submodelHandle
     */
    viewSubmodel: function (submodelHandle) {
        d3.selectAll(Utilities.getGTypeSelector(this.TYPE))
                .attr("display", "none");
        d3.select("#" + this.SUBMODEL_PREFIX + submodelHandle)
                .attr("display", "inline");
        this.currentSubmodel = submodelHandle;
        this.setBreadcrumb(submodelHandle);
        Utilities.updateSVGPlateSize();
    },
    /**
     * Set submodel location in Breadcrumb
     * @param {type} submodelHandle
     */
    setBreadcrumb: function (submodelHandle) {
        if (BAYESBOX_MODE.isDashboard()) {
            return;
        }
        d3.select(".breadcrumb").selectAll(".breadcrumb-item").remove();
        var submodel = this.submodel_tree.get(submodelHandle);
        var locationTree = [];
        locationTree.push(submodel);
        while (submodel.nextHandle > 0) {
            submodel = this.submodel_tree.get(submodel.nextHandle);
            locationTree.push(submodel);
        }
        var b = d3.select(".breadcrumb").selectAll(".breadcrumb-item")
                .data(locationTree)
                .enter()
                .append("li")
                .attr("class", "breadcrumb-item");

        b.filter(function (d) {
            return (d.handle === submodelHandle);
        })
                .attr("class", "breadcrumb-item active")
                .attr("aria-current", "page")
                .text(function (d) {
                    return d.name;
                });
        b.filter(function (d) {
            return (d.handle !== submodelHandle);
        })
                .append("a")
                .attr("href", "#")
                .attr("onclick", function (d) {
                    return "Submodel.viewSubmodel(" + d.handle + ");return false;";
                })
                .text(function (d) {
                    return d.name;
                });

        //order https://github.com/d3/d3-selection/blob/master/README.md#selection_lower
        d3.select(".breadcrumb").selectAll(".breadcrumb-item").each(function () {
            this.parentNode.insertBefore(this, this.parentNode.firstChild);
        });
    },
    /**
     * Check if nodeHandle is in tree started from submodelHandle
     * @param {type} nodeHandle
     * @param {type} submodelHandle
     * @returns {null|submodel} - if node is in tree started from submodelHandle then return the submodel which contain this node else return null
     */
    BFSTree: function (nodeHandle, submodelHandle) {
        var queue = [];
        queue.push(d3.select("#" + this.SUBMODEL_PREFIX + submodelHandle).data()[0]);
        while (queue.length > 0) {
            var submodel = queue.shift();
            if (this.BFSTreeFindInSubmodel(nodeHandle, submodel)) {
                return submodel;
            }
            d3.select("#" + this.SUBMODEL_PREFIX + submodel.handle).selectAll(Utilities.getGTypeSelector(this.TYPE_RECTANGLE)).each(function (d) {
                queue.push(d);
            });
        }
        return null;
    },
    /**
     * @private
     * Search node in submodel
     * @param {type} nodeHandle
     * @param {type} submodel
     * @returns {Boolean}
     */
    BFSTreeFindInSubmodel: function (nodeHandle, submodel) {
        return submodel.nodes.filter(function (d) {
            return d.handle === nodeHandle;
        }).length > 0;
    },
    /**
     * Search nodeID in submodel
     * @param {JSON data} submodel
     * @param {String} nodeID
     * @returns {Boolean}
     */
    isInSubmodel: function (submodel, nodeID) {
        return submodel.nodes.some(n => n.id === nodeID);
    },
    /**
     * Search nodeID in submodels tree starting from submodel from function argument
     * @param {submodel handle} submodelHandle
     * @param {String} nodeID
     * @param {submodel handle} excludeSubmodelHandle - (OPTIONAL ARG) submodel excluded from searching
     * @returns {submodel.handle | null} - first submodel handle (starting from submodel from function argument) from submodel tree where is nodeID or null if nodeID dont exist in submodels
     */
    isInSubmodelOfSubmodel: function (submodelHandle, nodeID, excludeSubmodelHandle) {
        var wantedNode = d3.select(Network.getSVG_CSS_SELECTOR()).select("#" + nodeID).data()[0];
        var allSubmodelsInSubmodel = d3.select("#" + this.SUBMODEL_PREFIX + submodelHandle).selectAll(Utilities.getGTypeSelector(this.TYPE_RECTANGLE));
        if(typeof excludeSubmodelHandle !== 'undefined'){
            allSubmodelsInSubmodel = allSubmodelsInSubmodel.filter(s => s.handle !== excludeSubmodelHandle);
        }
        var returnHandle = null;
        allSubmodelsInSubmodel.each(submodelNode => {
            if (returnHandle === null && Submodel.BFSTree(wantedNode.handle, submodelNode.handle)) {
                returnHandle = submodelNode.handle;
            }
        });
        return returnHandle;
    },
    /**
     * Search nodeID in upper submodels
     * @param {submodel handle} submodelHandle
     * @param {node ID} nodeID
     * @returns {Object} - {
            fromArc: ,
            fromType: ,
            toArc: ,
            toType: ,
            submodel: ,
        };
     */
    isInUpperSubmodels: function (submodelHandle, nodeID) {
        var arc = {
            fromArc: null,
            fromType: null,
            toArc: null,
            toType: null,
            submodel: null
        };
        var downSubmodel = submodelHandle;
        while (this.submodel_tree.get(downSubmodel).nextHandle > 0) {
            var upSubmodelHandle = this.submodel_tree.get(downSubmodel).nextHandle;
            var upSubmodel = d3.select("#" + this.SUBMODEL_PREFIX + upSubmodelHandle).data()[0];
            // chcek nodes
            if (upSubmodel.nodes.some(n => n.id === nodeID)) {
                arc.fromArc = downSubmodel;
                arc.fromType = Node.Type.SUBMODEL;
                arc.toArc = nodeID;
                arc.toType = Node.Type.NODE;
                arc.submodel = upSubmodel;
                return arc;
            } else {// chcek submodels
                var toArc = this.isInSubmodelOfSubmodel(upSubmodelHandle, nodeID, upSubmodel.handle);
                if (toArc !== null) {
                    arc.fromArc = downSubmodel;
                    arc.fromType = Node.Type.SUBMODEL;
                    arc.toArc = toArc;
                    arc.toType = Node.Type.SUBMODEL;
                    arc.submodel = upSubmodel;
                    return arc;
                }
                downSubmodel = upSubmodel.handle;
            }
        }
        return arc;
    },
    /**
     * Detecting click on invisible arcs
     * @param {D3 data Object} node - node
     * @returns {Array|Submodel.submodelArrowMenu.menu|Boolean} - menu if click on invisible arc or false if clik another
     */
    detectSubmodelArrowMenu: function (self) {
        var nodeD3 = d3.select(self);
        var node = nodeD3.data()[0];
        var id = nodeD3.attr("id");
        var positionParent = d3.select("#" + id + ">path[type=" + Arc.Invisible.ARROW_PARENT_HTML_SELECTOR + "]").node();
        if (positionParent) {
            positionParent = positionParent.getBoundingClientRect();
            var polygon = Arc.Invisible.getArrowPolygon(positionParent);
            if (d3.polygonContains(polygon, [d3.event.pageX, d3.event.pageY])) {
                return Submodel.submodelArrowMenu(node, Arc.Invisible.INVISIBLE_PARETN);
            }
        }
        var positionChild = d3.select("#" + id + ">path[type=" + Arc.Invisible.ARROW_CHILD_HTML_SELECTOR + "]").node();
        if (positionChild) {
            positionChild = positionChild.getBoundingClientRect();
            var polygon = Arc.Invisible.getArrowPolygon(positionChild);
            if (d3.polygonContains(polygon, [d3.event.pageX, d3.event.pageY])) {
                return Submodel.submodelArrowMenu(node, Arc.Invisible.INVISIBLE_CHILD);
            }
        }
        return false;
    },
    /**
     * Arrow menu
     * @param {type} data - select.data()[0]
     * @param {type} invisibleType - parent or child
     * @returns {Array} - array menu
     */
    submodelArrowMenu: function (data, invisibleType) {
        var menu = [];
        var nodeList = null;
        if (invisibleType === Arc.Invisible.INVISIBLE_PARETN) {
            nodeList = data.invisibleParents;
        } else if (invisibleType === Arc.Invisible.INVISIBLE_CHILD) {
            nodeList = data.invisibleChildren;
        } else {
            return menu;
        }

        nodeList.forEach(d => {
            var node = d3.select("#" + d).data()[0];
            menu.push({
                title: node.name,
                action: () => {
                    Node.blinkNode(node);
                }
            });
        });
        return menu;
    },
    createDeMorganColorSource: function () {
        this.colorSource = [];
        Submodel.submodel_tree.forEach(s => {
            var propColorNode = s.nodes.find(n => n.propagatecolor);
            if (propColorNode) {
                var propSubmodels = [];
                var cs = {
                    node: propColorNode,
                    submodel: s.id,
                    propToParent: null
                };
                var nextPropSubmodel = Submodel.submodel_tree.get(s.handle);
                while (nextPropSubmodel.nextHandle > 0) {
                    if (nextPropSubmodel.propagatedColor) {
                        propSubmodels.push(nextPropSubmodel.handle);
                        nextPropSubmodel = Submodel.submodel_tree.get(nextPropSubmodel.nextHandle);
                    } else {
                        break;
                    }
                }
                cs.propToParent = propSubmodels;
                this.colorSource.push(cs);
            }
        });
        return this.colorSource;
    }
};

export default Submodel;
