import Network from "./network";
import {BAYESBOX_MODE} from "./constantsMapping";
import BBCodeParser from "bbcode-parser";
import BayesBoxBBtags from "./ownBBtags";
import * as d3 from "d3";

/* global d3, BayesBoxBBtags */

var Footnote = null;
if (BAYESBOX_MODE.isDashboard()) {
    Footnote = (function () {
        var defaultFontSize = null;//in px
        var bbcodeParser = new BBCodeParser(BayesBoxBBtags.get());
        var id = "dashboardFootnote";
        var htmlBody = '<div id="' + id + '" class="footnote footnote-size">' +
                '</div>';
        function verification() {
            var l = $("#" + id);
            if (l.length === 0) {//exist in DOM
                appendLogo();
            } else if ($(`#${Network.getSvgContentId()}` + " > #" + id).length === 0) {//exist but in incorrect place
                move(l);
            }
            hideIfEmpty();
        }
        function appendLogo() {
            $(`#${Network.getSvgContentId()}`).append(htmlBody);
        }
        function move(logo) {
            logo.detach().appendTo(`#${Network.getSvgContentId()}`);
        }
        function hideIfEmpty() {
            var l = $("#" + id);
            l.html() === "" ? l.css("display", "none") : l.css("display", "");
        }
        return {
            add: function () {
                verification();
            },
            set: function (content) {
                if (typeof content !== "undefined") {
                    d3.select(`#${Network.getSvgContentId()}` + " #" + id).html(bbcodeParser.parseString(content));
                } else {
                    d3.select(`#${Network.getSvgContentId()}` + " #" + id).html("");
                }
                hideIfEmpty();
            },
            getDefaultFontSize: function () {
                var footnoteFont = d3.select(`#${Network.getSvgContentId()}` + " #" + id);
                if (footnoteFont.size() > 0 && defaultFontSize === null) {
                    let fontCSS = $(footnoteFont.node()).css("font-size");
                    let defFont = parseFloat(fontCSS);
                    defaultFontSize = isNaN(defFont) ? null : defFont;
                }
                return defaultFontSize;
            },
            setFontSize: function (fontSize) {
                d3.select(`#${Network.getSvgContentId()}` + " #" + id).style("font-size", fontSize + "px");
                //set example in dashboard settings
                d3.select(".footnote-size").style("font-size", fontSize + "px");
            }
        };
    })();
}

export default Footnote;
