(function () {
    var InnerCooke = (function () {
        function set_cookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function get_cookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        function check_cookie(name) {
            var user = get_cookie(name);
            if (user !== "") {
                return true;
            } else {
                return false;
            }
        }
        return {
            setCookie: function (cname, cvalue, exdays) {
                set_cookie(cname, cvalue, exdays);
            },
            getCookie: function (name) {
                return get_cookie(name);
            },
            checkCookie: function (name) {
                return check_cookie(name);
            }
        };
    })();
    var div = null;
    var closableTemplate = [
        '<div style="display: block;position: absolute;left: 0px;top: 0px;width: 100vw;height: 100vh;z-index: 2500;">',
            '<div class="jumbotron" style="height: 100%; margin: 0;">',
                '<h1 class="display-4" style="text-align: center;">BayesBox does not support Internet Explorer.</h1>',
                '<p class="lead" style="text-align: center;">BayesBox does not support Internet Explorer, which has been discontinued by Microsoft.  We recommend browsers such as Google Chrome, Firefox or Safari.  While we have not encountered any compatibility issues with mobile browsers, for best results, we recommend devices with large screens.</p>',
                '<hr class="my-4">',
                '<div class="d-flex justify-content-center" style="color: gray;">',
                    '<i class="fab fa-internet-explorer fa-7x"></i>',
                    '</div>',
                    '<div class="form-check">',
                        '<input class="form-check-input" type="checkbox" value="" id="dontShowAgain">',
                        '<label class="form-check-label" for="defaultCheck1">',
                          'Do not shows this message again',
                        '</label>',
                    '</div>',
                '<a class="btn btn-primary btn-lg" id="closeSupportBrowserAlert" href="javascript:void(0)" role="button">Close</a>',
            '</div>',
        '</div>'
    ].join('');
    var solidTemplate = [
        '<div style="display: block;position: absolute;left: 0px;top: 0px;width: 100vw;height: 100vh;z-index: 2500;">',
            '<div class="jumbotron" style="height: 100%; margin: 0;">',
                '<h1 class="display-4" style="text-align: center;">BayesBox does not support Internet Explorer.</h1>',
                '<p class="lead" style="text-align: center;">BayesBox does not support Internet Explorer, which has been discontinued by Microsoft.  We recommend browsers such as Google Chrome, Firefox or Safari.  While we have not encountered any compatibility issues with mobile browsers, for best results, we recommend devices with large screens.</p>',
                '<hr class="my-4">',
                '<div class="d-flex justify-content-center" style="color: gray;">',
                    '<i class="fab fa-internet-explorer fa-7x"></i>',
                '</div>',
            '</div>',
        '</div>'
    ].join('');
    /**
     * detect IE
     * returns version of IE or false, if browser is not Internet Explorer
     */
    function detectIE() {
        var ua = window.navigator.userAgent;
        if ((ua.indexOf('MSIE ') > 0) || (ua.indexOf('Trident/') > 0)) {
            return "IE";
        }
        if ((ua.indexOf('Edge/') > 0)) {
            return "EDGE";
        }
        return "";
    }
    function showBrowserInfo(template) {
        div = document.createElement('div');
        div.style.display = 'block';
        div.style.position = 'absolute';
        div.style.left = '0px';
        div.style.top = '0px';
        div.style.width = '100vw';
        div.style.height = '100vh';
        div.style.zIndex = 2500;
        div.innerHTML = template;
        document.body.appendChild(div);
        document.getElementById("closeSupportBrowserAlert").onclick = removeBrowserInfo;
    }
    function removeBrowserInfo() {
        if(document.getElementById("dontShowAgain").checked){
            InnerCooke.setCookie("dontShowSupportBrowser", true, 10 * 365);
        }
        div.remove();
    }
    if (!InnerCooke.checkCookie("dontShowSupportBrowser")) {
        if (detectIE() === "IE") {
            showBrowserInfo(solidTemplate);
        }
    }
})();
