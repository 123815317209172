import * as d3 from 'd3';
import Network from "./network";
import Geometric from "./geometric";
import Submodel from "./submodel";
import {NODE_TYPE, DEMORGAN_PARENT_TYPE, BAYESBOX_MODE} from "./constantsMapping";
import NodeShape from "./nodeShape";
import ZoomNetwork from "./zoomNetwork";
import Tooltip from "./tooltip";
import Node from "./node";
import DynamicBN from "./dynamicBN";
import Utilities from "./utilities";

/* global d3, Network, Utilities, Geometric, Submodel, NODE_TYPE, d3plus, NodeShape, ZoomNetwork, Tooltip, Node, DynamicBN, DEMORGAN_PARENT_TYPE */

var Arc = {
    ARC_COLOR: 'rgb(0, 0, 128)',
    ARC_SIZE: 0.5,
    SINGLE_ARROW_END: 'arrowEnd',
    SINGLE_ARROW_SATRT: 'arrowStart',
    DOUBLE_ARROW: 'double_arrow',
    DOUBLE_ARROW_REVERSE: 'double_arrow_reverse',
    DYNAMIC_COLOR: "#8080ff",
    DYNAMIC_ARROW_START: "dynamicArrowStart",
    DYNAMIC_ARROW_END: "dynamicArrowEnd",
    DEMORGAN_CAUSE_ARROW: "demorganCause",
    DEMORGAN_BARRIER_ARROW: "demorganBarrier",
    DEMORGAN_REQUIREMENT_ARROW: "demorganRequirement",
    DEMORGAN_INHIBITOR_ARROW: "demorganInhibitor",
    TYPE: "arc",
    dynamicArowMarkerStart: function () {
        for (var i = 0; i < this.markerDataArc().length; i++) {
            if (this.markerDataArc()[i].name === this.DYNAMIC_ARROW_START) {
                return this.markerDataArc()[i];
            }
        }
    },
    dynamicArowMarkerEnd: function () {
        for (var i = 0; i < this.markerDataArc().length; i++) {
            if (this.markerDataArc()[i].name === this.DYNAMIC_ARROW_END) {
                return this.markerDataArc()[i];
            }
        }
    },
    markerDataArc: function () {
        var strockeWidth = this.ARC_SIZE;
        var triangle = `M ${strockeWidth}, ${strockeWidth} l 20 5 l -20 5 z`;
        var doubleTriangle = `M ${strockeWidth}, ${strockeWidth} l 20, 5 l 0, -5 l 20, 5 l -20, 5 l 0, -5 l -20, 5 z`;
        return [{id: 0,
                name: this.SINGLE_ARROW_END,
                path: triangle,
                width: 20 + strockeWidth * 2,
                height: 10 + strockeWidth * 2,
                fill: this.ARC_COLOR,
                stroke: this.ARC_COLOR,
                strockeWidth: strockeWidth,
                reverseId: this.SINGLE_ARROW_SATRT,
                orient: "auto"
            }, {
                id: 1,
                name: this.SINGLE_ARROW_SATRT,
                path: triangle,
                width: 20 + strockeWidth * 2,
                height: 10 + strockeWidth * 2,
                refY: (10 + strockeWidth * 2)/2,
                fill: this.ARC_COLOR,
                stroke: this.ARC_COLOR,
                strockeWidth: strockeWidth,
                reverseId: this.SINGLE_ARROW_END,
                orient: "auto-start-reverse"
            }, {
                id: 2,
                name: this.DOUBLE_ARROW,
                path: doubleTriangle,
                width: 40 + strockeWidth * 2,
                height: 10 + strockeWidth * 2,
                fill: this.ARC_COLOR,
                stroke: this.ARC_COLOR,
                strockeWidth: strockeWidth,
                reverseId: this.DOUBLE_ARROW_REVERSE,
                orient: "auto"
            }, {
                id: 22,
                name: this.DOUBLE_ARROW_REVERSE,
                path: doubleTriangle,
                width: 40 + strockeWidth * 2,
                height: 10 + strockeWidth * 2,
                fill: this.ARC_COLOR,
                stroke: this.ARC_COLOR,
                strockeWidth: strockeWidth,
                reverseId: this.DOUBLE_ARROW,
                orient: "auto-start-reverse"
            }, {
                id: 3,
                name: this.DYNAMIC_ARROW_START,
                path: triangle,
                width: 20 + strockeWidth * 2,
                height: 10 + strockeWidth * 2,
                refY: (10 + strockeWidth * 2)/2,
                fill: this.DYNAMIC_COLOR,
                stroke: this.DYNAMIC_COLOR,
                strockeWidth: strockeWidth,
                orient: "auto-start-reverse"
            }, {
                id: 4,
                name: this.DYNAMIC_ARROW_END,
                path: triangle,
                width: 20 + strockeWidth * 2,
                height: 10 + strockeWidth * 2,
                fill: this.DYNAMIC_COLOR,
                stroke: this.DYNAMIC_COLOR,
                strockeWidth: strockeWidth,
                orient: "auto"
            }];
    },
    deMorganMarkers: function () {
        var strockeWidth = this.ARC_SIZE;
        var triangle = `M ${strockeWidth}, ${strockeWidth} l 19 15  l -19 15 z`;
        var halfCircle = `M ${strockeWidth}, ${strockeWidth} a19,15 0 0,1 0,30 z`;
        var square = `M ${strockeWidth}, ${strockeWidth} l 20 0  l 0 20 l -20 0 z`;
        var circle = `M ${strockeWidth}, ${10 + strockeWidth} a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0`;
        var markers = [{
                name: this.DEMORGAN_CAUSE_ARROW,
                path: triangle,
                width: 19 + strockeWidth * 2,
                height: 30 + strockeWidth * 2,
                fill: this.ARC_COLOR,
                stroke: this.ARC_COLOR,
                strockeWidth: strockeWidth,
                orient: "auto"
            }, {
                name: this.DEMORGAN_BARRIER_ARROW,
                path: halfCircle,
                width: 19 + strockeWidth * 2,
                height: 30 + strockeWidth * 2,
                fill: this.ARC_COLOR,
                stroke: this.ARC_COLOR,
                strockeWidth: strockeWidth,
                orient: "auto"
            }, {
                name: this.DEMORGAN_REQUIREMENT_ARROW,
                path: square,
                width: 20 + strockeWidth * 2,
                height: 20 + strockeWidth * 2,
                fill: this.ARC_COLOR,
                stroke: this.ARC_COLOR,
                strockeWidth: strockeWidth,
                orient: "auto"
            }, {
                name: this.DEMORGAN_INHIBITOR_ARROW,
                path: circle,
                width: 20 + strockeWidth * 2,
                height: 20 + strockeWidth * 2,
                fill: this.ARC_COLOR,
                stroke: this.ARC_COLOR,
                strockeWidth: strockeWidth,
                orient: "auto"
            }];
        markers.push(this.markerDataArc().find(m => m.name === this.DOUBLE_ARROW));
        markers.push(this.markerDataArc().find(m => m.name === this.DOUBLE_ARROW_REVERSE));
        return markers;
    },
    removeDynamicMarkers: function () {
        d3.selectAll("marker[dynamicMarker='true']").remove();
    },
    uniqueMarkerId: (function () {
        var id = 0;
        return {
            get: function () {
                return id++;
            }
        };
    })(),
    markerDefine: function (data) {
        //arrow define
        var defs = d3.select(Network.getSVG_CSS_SELECTOR()).select('defs');
        if(defs.size() === 0){
            defs = d3.select(Network.getSVG_CSS_SELECTOR()).append('defs');
        }
        var marker = null;
        var uniqueId = "";
        if (typeof data === "undefined") {
            marker = defs.selectAll('marker')
                    .data(this.markerDataArc())
                    .enter()
                    .append('marker');
        } else {
            marker = defs.append('marker')
                    .attr("dynamicMarker", "true")
                    .datum(data);
            uniqueId = this.uniqueMarkerId.get();
        }
        marker.attr('id', function (data) {
                    return `marker_${data.name}${uniqueId}`;
                })
                .attr('markerHeight', (d) => d.height)
                .attr('markerWidth', (d) => d.width)
                .attr('markerUnits', 'strokeWidth')
                .attr('orient', (d) => d.orient)
                .attr('refX', (d) => typeof d.refX === "undefined" ? d.width : d.refX)
                .attr('refY', (d) => typeof d.refY === "undefined" ? d.height/2 : d.refY);
//                .attr('viewBox', function (data) {
//                    return data.viewbox;
//                })
//                .attr('refX', function (data) {
//                    return data.xPosition;
//                });

        marker.append('path')
                .attr('d', function (data) {
                    return data.path;
                })
                .attr('fill', data => data.fill)
                .attr('id', function (data) {
                    return `path_${data.name}${uniqueId}`;
                })
                .style("stroke", d => d.stroke)
                .attr('stroke-width', d => d.strockeWidth);
        return marker;
    },
    addAndGetMarkerId: function (markerName, overrideDat, arc) {
        var data = {
            from: null,
            to: [],
            fromArc: "",
            toArc:""
        };
        if(typeof arc !== "undefined"){
            data.from = arc.fromNodes;
            data.to = arc.toNodes;
            data.fromArc = arc.fromSelector;
            data.toArc = arc.toSelector;
        }
        let markerData = Utilities.deepCopy(Arc.deMorganMarkers().find(m => m.name === markerName));
        let prop = undefined;
        for (prop in overrideDat) {
            markerData[prop] = overrideDat[prop];
        }
        let marker = Arc.markerDefine(markerData);
        marker.datum(data);
        return marker.attr("id").replace("marker_", "");
    },
    /*
     * O <-----> O
     */
    addDoubleMarkerArc: function (arc1, arc2, submodel) {
        var markerEndId = arc1.fromNodes[0].checkType(NODE_TYPE.DEMORGAN) ? this.addAndGetMarkerId(arc1.type, arc1.markerColor, arc1) : arc1.type;
        typeof arc2.markerColor !== "undefined" ? null : arc2.markerColor = {};
        arc2.markerColor.orient = "auto-start-reverse";
        var markerStartId = arc2.fromNodes[0].checkType(NODE_TYPE.DEMORGAN) ? this.addAndGetMarkerId(arc2.type, arc2.markerColor, arc2) : this.markerDataArc().find(m => m.name === arc2.type).reverseId;

        var overrideArcColor = typeof arc1.arcColor === "undefined" ? arc2.arcColor : arc1.arcColor;
        var arc = this.addArc(arc1.fromSelector, arc1.toSelector, submodel, markerEndId, markerStartId, overrideArcColor);
        if(arc1.fromNodes[0].checkType(NODE_TYPE.DEMORGAN)){
            arc.on("mouseover", (d, index, divs) => {
                    var dataSelector1 = d3.select(divs[index]).select("line").attr("marker-end");
                    dataSelector1 = dataSelector1.slice(dataSelector1.indexOf("#"), dataSelector1.length - 1);
                    var dataSelector2 = d3.select(divs[index]).select("line").attr("marker-start");
                    dataSelector2 = dataSelector2.slice(dataSelector2.indexOf("#"), dataSelector2.length - 1);
                    Tooltip.deMorganMarkerTooltip(d3.select(dataSelector1).data()[0], d3.select(dataSelector2).data()[0]);
                })
                .on("mouseout", () => Tooltip.setMouseout());
        }
    },
    /*
     * O -----> O
     */
    addSingleMarkerArc: function (arc, submodel) {
        var markerEndId = arc.fromNodes[0].checkType(NODE_TYPE.DEMORGAN) ? this.addAndGetMarkerId(arc.type, arc.markerColor, arc) : arc.type;
        var arc_ = this.addArc(arc.fromSelector, arc.toSelector, submodel, markerEndId, undefined, arc.arcColor);
        if(arc.fromNodes[0].checkType(NODE_TYPE.DEMORGAN)){
            arc_.on("mouseover", (d, index, divs) => {
                    var dataSelector = d3.select(divs[index]).select("line").attr("marker-end");
                    dataSelector = dataSelector.slice(dataSelector.indexOf("#"), dataSelector.length - 1);
                    Tooltip.deMorganMarkerTooltip(d3.select(dataSelector).data()[0]);
                })
                .on("mouseout", () => Tooltip.setMouseout());
        }
    },
    /**
     * Add arc between two nodes
     * @param {String} parentNodeID - parent ID
     * @param {String} childNodeID - child Id
     * @param {Object} submodel - submodel d3 object
     * @param {String} markerEnd -  Arc.SINGLE_ARROW_END or Arc.DOUBLE_ARROW
     *
     */
    addArc: function (parentNodeID, childNodeID, submodel, markerEnd, markerStart, overrideArcColor) { //http://bl.ocks.org/dustinlarimer/5888271
        markerEnd = typeof markerEnd !== 'undefined' ? markerEnd : this.SINGLE_ARROW_END;
        var svg = d3.select(Network.getSVG_CSS_SELECTOR());
        //get parent and child obejcts
        var parentNode = svg.select("#" + parentNodeID);
        var childNode = svg.select("#" + childNodeID);
        //get properties parent and child nodes
        var parentNode_params = parentNode.data()[0];
        var childNode_params = childNode.data()[0];
        //transform
        var translateStringParent = parentNode.attr("transform");
        var translateStringChild = childNode.attr("transform");
        var matrixParent = Utilities.getTranslation(translateStringParent);
        var matrixChild = Utilities.getTranslation(translateStringChild);
        //arc points
        var pointA = Geometric.calculatePoints(parentNode_params, childNode_params, matrixParent, matrixChild);
        var pointB = Geometric.calculatePoints(childNode_params, parentNode_params, matrixChild, matrixParent);
        //add new arc group
        var arc = svg.select("#" + Submodel.SUBMODEL_PREFIX + submodel.handle).append('g')
                .attr('type', 'arc');
        //add line for arc group
        var line = arc.append('line')
                .attr("x1", pointA[0])
                .attr("y1", pointA[1])
                .attr("x2", pointB[0])
                .attr("y2", pointB[1])
                .attr('id', Utilities.joinUnderline([parentNodeID, childNodeID]))
                .attr('stroke-width', this.ARC_SIZE)
                .attr("stroke", typeof overrideArcColor === "undefined" ? this.ARC_COLOR : overrideArcColor)
                .attr('marker-end', 'url(#marker_' + markerEnd + ')');

        if(typeof markerStart !== "undefined"){
            line.attr('marker-start', 'url(#marker_' + markerStart + ')');
        }

        //dotted line
        if (childNode_params.checkType(NODE_TYPE.DECISION)) {
            arc.attr('stroke-dasharray', '3,3');
        }
        return arc;
    },
    Dynamic: {
        /**
         * Add all dynamic arcs between dynamic nodes
         * @param {Object} arc - {curvePerc,handle,node,order}
         * @param {Object} curvedGeometry - {ptArrow,ptCircle,ptFrom,ptTo,ptTangent,ptZenit,radCircle}
         * @param {Object} submodel - sybmodel d3.data()[0]
         * @returns {undefined}
         */
        addDynamicArcs: function (arc, curvedGeometry, submodel) {
            var textWidth = Utilities.measure(arc.order, Utilities.getLazyMeasureContext());
            var tempArcG = d3.select(ZoomNetwork.getCSSZoomPlaceSelector())
                    .select("#" + Submodel.SUBMODEL_PREFIX + submodel.handle)
                    .append("g")
                    .attr("type", "temporalArc");

            var distanceTo = Math.sqrt(Math.pow((curvedGeometry.ptTo.x - arc.node.x), 2) + Math.pow((curvedGeometry.ptTo.y - arc.node.y), 2));
            var distanceFrom = Math.sqrt(Math.pow((curvedGeometry.ptFrom.x - arc.node.x), 2) + Math.pow((curvedGeometry.ptFrom.y - arc.node.y), 2));
            var selfishArc = 0;
            if (arc.handle === arc.node.handle) {
                selfishArc = 1;
            }
            var toNode = d3.select(`#${arc.id}`).data()[0];
            var deMorganArcData = null;
            if (toNode.checkType(NODE_TYPE.DEMORGAN)) {
                let arcData = {
                    fromArc: arc.node.id,
                    fromOriginalNodeId: [arc.node.id],
                    fromType: Node.Type.NODE,
                    toArc: toNode.id,
                    toOriginalNodeId: [toNode.id],
                    toType: Node.Type.NODE,
                    submodel: submodel
                };
                if(arc.order !== 0){
                    arcData.order = arc.order;
                }
                deMorganArcData = Arc.getArcType(arcData, [submodel]);
            }
            tempArcG.append("path")
                    .attr("id", "path")
                    .attr("d", "M " + curvedGeometry.ptFrom.x + " " + curvedGeometry.ptFrom.y + " A " + curvedGeometry.radCircle + " " + curvedGeometry.radCircle + " 0 " + selfishArc + " 0 " + curvedGeometry.ptTo.x + " " + curvedGeometry.ptTo.y + "").attr("fill", "none")
                    .style("stroke-width", Arc.ARC_SIZE)
                    .style("stroke", () => {
                        if(deMorganArcData !== null){
                            return deMorganArcData.arcColor;
                        }
                        if (arc.order === 0) {
                            return Arc.ARC_COLOR;
                        }
                        if (distanceTo < distanceFrom) {
                            return Arc.dynamicArowMarkerStart().fill;
                        } else {
                            return Arc.dynamicArowMarkerEnd().fill;
                        }
                    });

            if (distanceTo < distanceFrom) {
                if(deMorganArcData !== null){
                    deMorganArcData.markerColor.orient = "auto-start-reverse";
                    let deMorganMarker = Arc.addAndGetMarkerId(deMorganArcData.type, deMorganArcData.markerColor, deMorganArcData);
                    tempArcG.attr("marker-start", "url(#marker_" + deMorganMarker + ")");
                } else if (arc.order === 0) {
                    tempArcG.attr("marker-start", "url(#marker_" + Arc.SINGLE_ARROW_SATRT + ")");
                } else {
                    tempArcG.attr("marker-start", "url(#marker_" + Arc.dynamicArowMarkerStart().name + ")");
                }
            } else {
                if(deMorganArcData !== null){
                    let deMorganMarker = Arc.addAndGetMarkerId(deMorganArcData.type, deMorganArcData.markerColor, deMorganArcData);
                    tempArcG.attr("marker-end", "url(#marker_" + deMorganMarker + ")");
                } else if (arc.order === 0) {
                    tempArcG.attr("marker-end", "url(#marker_" + Arc.SINGLE_ARROW_END + ")");
                } else {
                    tempArcG.attr("marker-end", "url(#marker_" + Arc.dynamicArowMarkerEnd().name + ")");
                }
            }
            //rectangle with order
            if (arc.order !== 0) {
                tempArcG.append("rect")
                        .attr("width", textWidth + 2 * NodeShape.TEXT_LINE_HEIGHT)
                        .attr("height", NodeShape.FONT_SIZE + NodeShape.TEXT_LINE_HEIGHT)
                        .attr("x", curvedGeometry.ptZenith.x - textWidth / 2)
                        .attr("y", curvedGeometry.ptZenith.y - (NodeShape.FONT_SIZE + NodeShape.TEXT_LINE_HEIGHT) / 2)
                        .style("fill", function () {
                            if (selfishArc === 1) {
                                return "#ccffcc";
                            }
                            return "#ffc0a0";
                        })
                        .style("stroke", "black")
                        .style("stroke-width", 0.5);
                tempArcG.append("text")
                        .attr("x", curvedGeometry.ptZenith.x - textWidth / 2 + NodeShape.TEXT_LINE_HEIGHT)
                        .attr("y", curvedGeometry.ptZenith.y - (NodeShape.FONT_SIZE + NodeShape.TEXT_LINE_HEIGHT) / 2 + NodeShape.FONT_SIZE)
                        .style("fill", "black")
                        .text(arc.order)
                        .style("stroke", "none")
                        .attr("font-size", NodeShape.FONT_SIZE);
                //tooltip with order
                tempArcG.on("mouseover", function (d) {
                    var div = d3.select("div.tooltip");
                    div.transition()
                            .duration(200)
                            .style("opacity", 1);
                    Tooltip.createContentTemporalOrder(arc.order);
                    var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, "#tooltipValue", d3.event.pageX, d3.event.pageY);
                    div.style("left", (translate.x) + "px")
                            .style("top", (translate.y) + "px");
                })
                        .on("mouseout", function (d) {
                            var div = d3.select("div.tooltip");
                            div.transition()
                                    .duration(200)
                                    .style("opacity", 0);
                        });
            }

        },
        /**
         * Select all temporal arcs and plain arc between two nodes
         * @param {type} tail - node from
         * @param {type} head - node to
         * @returns {Array|getArcsBetween.arcs} -
         *          {"handle": , "id": , "order": , "node": };
         *          "handle" and "id" - handle and ID head node
         *          "node" - data of tail node
         *          "order" - arc order
         */
        getArcsBetween: function (tail, head) {
            var arcs = [];
            {
                //tail -> head
                let tailHead = tail.temporalChildren
                        .filter(tempChildren => tempChildren.handle !== tail.handle)
                        .filter(tempChildren => tempChildren.handle === head.handle)
                        .map(tempChildren => {
                            tempChildren.node = tail;
                            return tempChildren;
                        });
                //head -> tail
                let headTail = head.temporalChildren
                        .filter(tempChildren => tempChildren.handle !== head.handle)
                        .filter(tempChildren => tempChildren.handle === tail.handle)
                        .map(tempChildren => {
                            tempChildren.node = head;
                            return tempChildren;
                        });
                arcs = tailHead.concat(headTail);
            }

            //plainArc = normalArc
            var plainArc = null;
            var plainArcTail = tail.children.filter(children => Node.getNodeIdFromObject(children.data()[0]) === Node.getNodeIdFromObject(head)).map(children => {
                plainArc = {"handle": head.handle, "id": children.data()[0].id, "order": 0, "node": tail};
                return plainArc;
            });
            var plainArcHead = head.children.filter(children => Node.getNodeIdFromObject(children.data()[0]) === Node.getNodeIdFromObject(tail)).map(children => {
                plainArc = {"handle": tail.handle, "id": children.data()[0].id, "order": 0, "node": head};
                return plainArc;
            });
            // make sure 0th element is the plain, nontemporal arc
            // (if it exists for given parent/child node pair)
            if ((plainArc !== null)) {
                if (plainArcHead.length === plainArcTail.length) {
                    console.log("ERROR: Between two nodes is two normal arcs!");
                    return [];
                }
                arcs.unshift(plainArc);
            }
            return arcs;
        }
    },
    /**
     * Move arc
     * @param {D3 Object data} parentNode - parent node object
     * @param {D3 Object data} childNode - child node object
     * @param {D3 Object data} arc - arc node object
     * @param {Object} parentTranslate - [translateX, translateY, scaleXY]
     * @param {Object} childTranslate - [translateX, translateY, scaleXY]
     */
    moveArc: function (parentNode, childNode, arc, parentTranslate, childTranslate) {
        if(arc.size() === 0){
            return null;
        }
        //get properties parent and child nodes
        var parentNode_params = parentNode.data()[0];
        var childNode_params = childNode.data()[0];
        //if parent or child don't exists
        if (typeof parentNode_params === 'undefined' || typeof childNode_params === 'undefined') {
            return null;
        }
        //points for node A
        var pointA = Geometric.calculatePoints(parentNode_params, childNode_params, parentTranslate, childTranslate);
        var pointB = Geometric.calculatePoints(childNode_params, parentNode_params, childTranslate, parentTranslate);

        if(typeof arc.attr("x1") === "undefined" || arc.attr("x1") === null || parseFloat(arc.attr("x1")) !== pointA[0]){
            arc.attr("x1", pointA[0]);
        }
        if(typeof arc.attr("y1") === "undefined" || arc.attr("y1") === null || parseFloat(arc.attr("y1")) !== pointA[1]){
            arc.attr("y1", pointA[1]);
        }
        if(typeof arc.attr("x2") === "undefined" || arc.attr("x2") === null || parseFloat(arc.attr("x2")) !== pointB[0]){
            arc.attr("x2", pointB[0]);
        }
        if(typeof arc.attr("y2") === "undefined" || arc.attr("y2") === null || parseFloat(arc.attr("y2")) !== pointB[1]){
            arc.attr("y2", pointB[1]);
        }
        //set new points for arc
//        arc.attr("x1", pointA[0])
//                .attr("y1", pointA[1])
//                .attr("x2", pointB[0])
//                .attr("y2", pointB[1]);

    },
    /*
     * Update arcs based on node
     * @param {D3 Object data} nodeD3Self - node data d3.select("#" + nodeId);
     */
    updateArc: function (nodeD3Self) {
        var node = nodeD3Self.data()[0];
        var nodeID = Node.getNodeIdFromObject(node);
        for (var i = 0; i < node.parents.length; i++) {
            let parent = node.parents[i];
            let parentID = Node.getNodeIdFromObject(parent.data()[0]);
            let child = nodeD3Self;

            let translateStringParent = parent.attr("transform");
            let translateStringChild = child.attr("transform");
            let matrixParent = Utilities.getTranslation(translateStringParent);
            let matrixChild = Utilities.getTranslation(translateStringChild);
            this.moveArc(parent, child, d3.select('#' + Utilities.joinUnderline([parentID, nodeID])), matrixParent, matrixChild);
        }
        for (var i = 0; i < node.children.length; i++) {
            let parent = nodeD3Self;
            let child = node.children[i];
            let childID = Node.getNodeIdFromObject(child.data()[0]);

            let translateStringParent = parent.attr("transform");
            let translateStringChild = child.attr("transform");
            let matrixParent = Utilities.getTranslation(translateStringParent);
            let matrixChild = Utilities.getTranslation(translateStringChild);
            this.moveArc(parent, child, d3.select('#' + Utilities.joinUnderline([nodeID, childID])), matrixParent, matrixChild);
        }
    },
    updateAllArc: function () {
        d3.selectAll(Utilities.getGTypeSelector(Node.TYPE)).each((node, index, divs)=>this.updateArc(d3.select(divs[index])));
    },
    convertToDBNArc: function (arc, order) {
        let tmp = {
            order: order
        };
        if (arc.fromType === Node.Type.NODE) {
            tmp.node = d3.select("#" + arc.fromArc).data()[0];
        } else {
            tmp.node = d3.select("#" + Submodel.SUBMODEL_PREFIX + arc.fromArc).data()[0];
        }
        if (arc.toType === Node.Type.NODE) {
            tmp.id = arc.toArc;
            tmp.handle = d3.select("#" + tmp.id).data()[0].handle;
        } else {
            let tmpSubmodel = d3.select("#" + Submodel.SUBMODEL_PREFIX + arc.toArc).data()[0];
            tmp.id = Submodel.SUBMODEL_RECTANGLE_PREFIX + tmpSubmodel.handle;
            tmp.handle = tmpSubmodel.handle;
        }
        return tmp;
    },
    getDeMorganTemporarParentLocalization: function (temporalParents, parentID, order) {
        for (var i = 0; i < temporalParents.length; i++) {
            for (var j = 0; j < temporalParents[i].length; j++) {
                if (temporalParents[i][j].id === parentID && temporalParents[i][j].order === order) {
                    return [i,j];
                }
            }
        }
        console.error(`Can't find temporal parent ${parentID} with order ${order}`);
        return [-1,-1];
    },
    getDeMorganArcProperty: function (parentID, nodeData, order) {
        var parentTypeInt = -100000;
        var weight = 0;
        if (typeof order !== "undefined") {
            var localization = this.getDeMorganTemporarParentLocalization(nodeData.temporalParents, parentID, order);
            if (localization[0] >= 0 && localization[1] >= 0) {
                parentTypeInt = nodeData.deMorganTemporalParentType[localization[0]][localization[1]];
                weight = nodeData.deMorganTemporalParentWeight[localization[0]][localization[1]];
            }
        } else {
            var parentIndex = nodeData.parents.findIndex(p => p.data()[0].id === parentID);
            if (parentIndex >= 0) {
                parentTypeInt = nodeData.deMorganParentType[parentIndex];
                weight = nodeData.deMorganParentWeight[parentIndex];
            }
        }
        var arcProperty = {};
        //MARTKER TYPE
        switch (parentTypeInt) {
            case DEMORGAN_PARENT_TYPE.BARRIER:
                arcProperty.type = Arc.DEMORGAN_BARRIER_ARROW;
                break;
            case DEMORGAN_PARENT_TYPE.CAUSE:
                arcProperty.type = Arc.DEMORGAN_CAUSE_ARROW;
                break;
            case DEMORGAN_PARENT_TYPE.INHIBITOR:
                arcProperty.type = Arc.DEMORGAN_INHIBITOR_ARROW;
                break;
            case DEMORGAN_PARENT_TYPE.REQUIREMENT:
                arcProperty.type = Arc.DEMORGAN_REQUIREMENT_ARROW;
                break;
            default:
                arcProperty.type = Arc.SINGLE_ARROW_END;
                break;
        }
        //MARKER COLOR
        var colors = Network.getDeMorganQualColors();
        switch (parentTypeInt) {
            case DEMORGAN_PARENT_TYPE.BARRIER:
            case DEMORGAN_PARENT_TYPE.CAUSE:
                arcProperty.markerColor = {
                    fill: Utilities.blend_colors([colors[1], colors[2]], weight)
                };
                break;
            default:
                arcProperty.markerColor = {
                    fill: Utilities.blend_colors([colors[1], colors[0]], weight)
                };
                break;
        }
        //ARC COLOR
        if (0.0 === weight) {
            arcProperty.color = "rgb(192, 192, 192)";
        } else if (1.0 === weight) {
            arcProperty.color = "rgb(0, 0, 255)";
        } else {
            arcProperty.color = "rgb(128, 128, 128)";
        }
        return arcProperty;
    },
    getArcType: function (arc, submodels) {
        var fromSelector, toSelector;
        fromSelector = arc.fromType === Node.Type.NODE ? arc.fromArc : Submodel.SUBMODEL_PREFIX + arc.fromArc;
        toSelector = arc.toType === Node.Type.NODE ? arc.toArc : Submodel.SUBMODEL_PREFIX + arc.toArc;
        if (arc.toOriginalNodeId.length === 1) {
            var fromNode = submodels.find(s => s.nodes.find(n => n.id === arc.fromOriginalNodeId[0])).nodes.find(n => n.id === arc.fromOriginalNodeId[0]);
            var toNode = submodels.find(s => s.nodes.find(n => n.id === arc.toOriginalNodeId[0])).nodes.find(n => n.id === arc.toOriginalNodeId[0]);
            if (toNode.checkType(NODE_TYPE.DEMORGAN)) {
                var arcProperty = this.getDeMorganArcProperty(arc.fromOriginalNodeId[0], toNode, arc.order);
                return {
                    type: arcProperty.type,
                    markerColor: arcProperty.markerColor,
                    arcColor: arcProperty.color,
                    fromSelector: fromSelector,
                    toSelector: toSelector,
                    fromNodes: [fromNode],
                    toNodes: [toNode]
                };
            } else {
                return {
                    type: Arc.SINGLE_ARROW_END,
                    fromSelector: fromSelector,
                    toSelector: toSelector,
                    fromNodes: [fromNode],
                    toNodes: [toNode]
                };
            }
        } else {
            let fromNodes = [];
            let toNodes = [];
            for (var i = 0; i < arc.toOriginalNodeId.length; i++) {
                let nTo = submodels.find(s => s.nodes.find(n => n.id === arc.toOriginalNodeId[i])).nodes.find(n => n.id === arc.toOriginalNodeId[i]);
                let nFrom = submodels.find(s => s.nodes.find(n => n.id === arc.fromOriginalNodeId[i])).nodes.find(n => n.id === arc.fromOriginalNodeId[i]);
                if (typeof nTo !== "undefined" && typeof nFrom !== "undefined") {
                    fromNodes.push(nFrom);
                    toNodes.push(nTo);
                }
            }
            return {
                type: Arc.DOUBLE_ARROW,
                fromSelector: fromSelector,
                toSelector: toSelector,
                fromNodes: fromNodes,
                toNodes: toNodes
            };
        }
    },
    stringToReference: function () {
        let selector = Utilities.getGTypeSelector(Node.TYPE);
        if(BAYESBOX_MODE.isDashboard()){
            selector = "div[type=nodeCard]";
        }
//        return;
        d3.selectAll(selector)
                .each((data, index, divs) => {
                    // children to reference
                    for (let i = 0; i < data.children.length; i++) {
                        if (typeof data.children[i] === "string") {
                            let findChild = null;
                            for (let j = 0; j < divs.length; j++) {
                                let child = d3.select(divs[j]);
                                if((child.data()[0].id === data.children[i] && child.data()[0].network && child.data()[0].network === data.network) || (child.data()[0].id === data.children[i] && !child.data()[0].network)){
                                    findChild = child;
                                }
                            }
                            if(findChild !== null){
                                data.children[i] = findChild;
                            }
                        }
                    }
                    // parents to reference
                    for (let i = 0; i < data.parents.length; i++) {
                        if (typeof data.parents[i] === "string") {
                            let findParent = null;
                            for (let j = 0; j < divs.length; j++) {
                                let parent = d3.select(divs[j]);
                                if((parent.data()[0].id === data.parents[i] && parent.data()[0].network && parent.data()[0].network === data.network) || (parent.data()[0].id === data.parents[i] && !parent.data()[0].network)){
                                    findParent = parent;
                                }
                            }
                            if(findParent !== null){
                                data.parents[i] = findParent;
                            }
                        }
                    }
                });
    },
    /**
     * Detect all (normal and dynamic) arcs and draw it
     * @param {type} submodels - all submodels
     */
    addAllArcs: function (submodels) {
        var arcMap = new Map();
        var temporalArc = new Map();
        submodels.forEach(submodel => {
            submodel.nodes.forEach(node => {
                var invisibleChildren = [];
                var invisibleParents = [];
                //detect arc in submodel
                //detect invisible children
                node.children.forEach(child => {
                    var arc = Arc.getArcProperty(Node.getNodeIdFromObject(node), Node.getNodeIdFromObject(child.data()[0]), submodel);
                    if (arc.invisibleNode !== null) {
                        invisibleChildren.push(arc.invisibleNode);
                    }
                    if (arcMap.has(Utilities.joinUnderline([arc.fromArc, arc.toArc, arc.submodel.handle]))) {
                        var tmpArc = arcMap.get(Utilities.joinUnderline([arc.fromArc, arc.toArc, arc.submodel.handle]));
                        tmpArc.fromOriginalNodeId.push(arc.fromOriginalNodeId[0]);
                        tmpArc.toOriginalNodeId.push(arc.toOriginalNodeId[0]);
                        arcMap.set(Utilities.joinUnderline([arc.fromArc, arc.toArc, arc.submodel.handle]), tmpArc);
                    } else {
                        arcMap.set(Utilities.joinUnderline([arc.fromArc, arc.toArc, arc.submodel.handle]), arc);
                    }
                });
                //detect temporal arc in submodel
                //detected invisible temporal children
                if (typeof node.temporalChildren !== "undefined") {
                    node.temporalChildren.forEach(child => {
                        var arc = Arc.getArcProperty(Node.getNodeIdFromObject(node), Node.getNodeIdFromParentsOrChild(child.id, node), submodel);
                        var tmp = Arc.convertToDBNArc(arc, child.order);
                        var arcRecord = temporalArc.get(Utilities.joinUnderline([arc.fromArc, arc.toArc, arc.submodel.handle]));
                        if (typeof arcRecord === "undefined") {
                            arcRecord = temporalArc.get(Utilities.joinUnderline([arc.toArc, arc.fromArc, arc.submodel.handle]));
                        }
                        if (typeof arcRecord === "undefined") {
                            temporalArc.set(Utilities.joinUnderline([arc.fromArc, arc.toArc, arc.submodel.handle]), {arcs: [tmp], submodel: submodel});
                        } else {
                            arcRecord.arcs.push(tmp);
                        }
                        if (arc.invisibleNode !== null) {
                            invisibleChildren.push(arc.invisibleNode);
                        }
                    });
                }
                //detected invisible parents
                node.parents.forEach((parent) => {
                    var parentID = Node.getNodeIdFromObject(parent.data()[0]);
                    if (!submodel.nodes.some(n => Node.getNodeIdFromObject(n) === parentID)) {
                        var wantedNode = parent.data()[0];
                        var inCurrentSubmodels = d3.select("#" + Submodel.SUBMODEL_PREFIX + submodel.handle).selectAll(Utilities.getGTypeSelector(Submodel.TYPE_RECTANGLE)).data().some((submodelNode) => Submodel.BFSTree(wantedNode.handle, submodelNode.handle));
                        if (!inCurrentSubmodels) {
                            invisibleParents.push(parentID);
                        }
                    }
                });
                //detected invisible temporal parents
                if (typeof node.temporalParents !== "undefined") {
                    node.temporalParents.forEach((parentsInOrder) => {
                        parentsInOrder.forEach(parent => {
                            var parentID = Node.getNodeIdFromParentsOrChild(parent.id, node);
                            if (!submodel.nodes.some(n => Node.getNodeIdFromObject(n) === parentID)) {
                                var wantedNode = d3.select(Network.getSVG_CSS_SELECTOR()).select("#" + parentID).data()[0];
                                var inCurrentSubmodels = d3.select("#" + Submodel.SUBMODEL_PREFIX + submodel.handle).selectAll(Utilities.getGTypeSelector(Submodel.TYPE_RECTANGLE)).data().some((submodelNode) => Submodel.BFSTree(wantedNode.handle, submodelNode.handle));
                                if (!inCurrentSubmodels) {
                                    invisibleParents.push(parentID);
                                }
                            }
                        });
                    });
                }
                var nodeID =Node.getNodeIdFromObject(node);
                if (invisibleChildren.length > 0) {
                    node.invisibleChildren = invisibleChildren;
                    Arc.Invisible.drawInvisibleArc(nodeID, Arc.Invisible.INVISIBLE_CHILD);
                }
                if (invisibleParents.length > 0) {
                    node.invisibleParents = invisibleParents;
                    Arc.Invisible.drawInvisibleArc(nodeID, Arc.Invisible.INVISIBLE_PARETN);
                }
            });
        });
        /*
         * Normal arcs finding in arcMap which should were in temporalArc
         */
        let normalNodesInTemporal = [];
        if (temporalArc.size < arcMap.size) {// time optimization: iterate the smaller map
            temporalArc.forEach((value, key) => {
                if (arcMap.has(key)) {//if key from temporalArc is in arcMap then add this key to the normalNodesInTemporal
                    normalNodesInTemporal.push(key);
                }
            });
            //add all normal arc which should were in temporalArc to temporalArc
            normalNodesInTemporal.forEach(k => {
                let tmpValue = arcMap.get(k);
                temporalArc.get(k).arcs.unshift(Arc.convertToDBNArc(tmpValue, 0));// make sure 0th element is the plain, nontemporal arc
            });
        } else {
            arcMap.forEach((value, key) => {
                let tmpValue = temporalArc.get(key);
                if (typeof tmpValue !== "undefined") {//if key from arcMap is in temporalArc then add this key to the normalNodesInTemporal and temporalArc
                    normalNodesInTemporal.push(key);
                    tmpValue.arcs.unshift(Arc.convertToDBNArc(value, 0));// make sure 0th element is the plain, nontemporal arc
                }
            });
        }
        //delete normal arcs from arcMap which should were in temporalArc
        normalNodesInTemporal.forEach(k => arcMap.delete(k));
        let flatArcs = function (arcs) {
            var flatMap = [];
            for (let v of arcs.values()) {
                flatMap.push(v);
            }
            var returnList = [];
            flatMap.forEach(a => {
                var theSameArc = flatMap.find(aa => aa.fromArc === a.toArc && aa.toArc === a.fromArc);
                if (typeof theSameArc === "undefined") {
                    returnList.push(a);
                } else {
                    var exist = returnList.filter(l => Array.isArray(l)).some(fArcs => (fArcs[0] === a && fArcs[1] === theSameArc) || (fArcs[0] === theSameArc && fArcs[1] === a));
                    if (!exist)
                        returnList.push([a, theSameArc]);
                }
            });
            return returnList;
        };
        var flatedArcs = flatArcs(arcMap);
        flatedArcs.forEach((arc) => {
            let arc1 = Array.isArray(arc) ? arc[0] : arc;
            let arc2 = Array.isArray(arc) ? arc[1] : undefined;

            let arc1Marker = this.getArcType(arc1, submodels);
            let arc2Marker = arc2 !== undefined ? this.getArcType(arc2, submodels) : arc2;

            if (arc2 === undefined) {
                Arc.addSingleMarkerArc(arc1Marker, arc1.submodel);
            } else {
                Arc.addDoubleMarkerArc(arc1Marker, arc2Marker, arc2.submodel);
            }
        });
        temporalArc.forEach(v => {
            var tail = d3.select("#" + v.arcs[0].id).data()[0];
            var head = v.arcs[0].node;
            var curves = DynamicBN.calcCurves(tail, head, v.arcs, Network.getSVG_CSS_SELECTOR());
            curves.forEach(c => {
                this.Dynamic.addDynamicArcs(c.arc, c.curvedGeometry, v.submodel);
            });
        });
    },
    /**
     * Get arc property. Function operate on data from /network/get [JSON]
     * @param {type} nodeIDs - node ID
     * @param {type} linkedToNodeIDs - node (submodel or node) linked with nodeIDs
     * @param {type} submodel - submodel, where is nodeIDs (submodel data()[0])
     * @returns {getArcProperty.arc}
     */
    getArcProperty: function (nodeIDs, linkedToNodeIDs, submodel) {
        var arc = {
            fromArc: null,
            fromType: null,
            fromOriginalNodeId: [nodeIDs],
            toArc: null,
            toType: null,
            toOriginalNodeId: [linkedToNodeIDs],
            submodel: null,
            invisibleNode: null
        };
        // if exist in current submodel [nodeIDs and linkedToNodeIDs are nodes in the same submodel]
        if (Submodel.isInSubmodel(submodel, linkedToNodeIDs)) {
            arc.fromArc = nodeIDs;
            arc.fromType = Node.Type.NODE;
            arc.toArc = linkedToNodeIDs;
            arc.toType = Node.Type.NODE;
            arc.submodel = submodel;
//                    Arc.addArc(arc.fromArc, arc.toArc, submodel);
        } else { // if exist in other submodel [nodeIDs is node linked with linkedToNodeIDs by submodel rectangle and exist in the same submodel]
            var toArc = Submodel.isInSubmodelOfSubmodel(submodel.handle, linkedToNodeIDs);
            if (toArc !== null) {
                arc.fromArc = nodeIDs;
                arc.fromType = Node.Type.NODE;
                arc.toArc = toArc;
                arc.toType = Node.Type.SUBMODEL;
                arc.submodel = submodel;
            } else {
                // if exist in other submodel in upper submodel [nodeIDs is node linked with linkedToNodeIDs by invisible arc]
                var arcTMP = Submodel.isInUpperSubmodels(submodel.handle, linkedToNodeIDs);
                arc.fromArc = arcTMP.fromArc;
                arc.fromType = arcTMP.fromType;
                arc.toArc = arcTMP.toArc;
                arc.toType = arcTMP.toType;
                arc.submodel = arcTMP.submodel;
                arc.invisibleNode = linkedToNodeIDs;
            }
        }
        return arc;
    },
    Invisible: {
        INVISIBLE_CHILD: 1,
        INVISIBLE_PARETN: 2,
        ARROW_PARENT_HTML_SELECTOR: "submodelArrowParent",
        ARROW_CHILD_HTML_SELECTOR: "submodelArrowChild",
        /**
         * Drawing small triangle in nodes where parents or children are in upper submodel
         * @param {type} nodeID - node id
         * @param {type} invisibleType - child or parent
         */
        drawInvisibleArc: function (nodeID, invisibleType) {
            var node = d3.select("#" + nodeID);
            var dataNode = node.data()[0];
            var translateX;
            var type = this.ARROW_PARENT_HTML_SELECTOR;
            if (invisibleType === this.INVISIBLE_PARETN) {
                translateX = dataNode.x;
            } else {
                type = this.ARROW_CHILD_HTML_SELECTOR;
                translateX = dataNode.x + dataNode.width;
            }
            var translateY = dataNode.y + dataNode.height / 2;
            if (dataNode.isBarChart) {
                translateY = dataNode.y + dataNode.template.height / 2;
                if (invisibleType === this.INVISIBLE_CHILD) {
                    translateX = dataNode.x + dataNode.template.width;
                }
            }
            node.append("path")
                    .attr('d', 'M -10 -5 l 20 5 l -20 5 z')
                    .attr('fill', Arc.ARC_COLOR)
                    .attr("transform", Utilities.getTransformString(translateX, translateY, 0.4))
                    .attr("type", type)
                    .on("mouseover", function () {
                        var div = d3.select("div.tooltip");
                        div.transition()
                                .duration(200)
                                .style("opacity", 1);
                        Tooltip.tooltipInvisibleNode(dataNode, invisibleType);
                        var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, "#tooltipValue", d3.event.pageX, d3.event.pageY);
                        div.style("left", (translate.x) + "px")
                                .style("top", (translate.y) + "px");
                    })
                    .on("mouseout", function () {
                        var div = d3.select("div.tooltip");
                        div.transition()
                                .duration(200)
                                .style("opacity", 0);
                    });
        },
        /**
         * Get arrow (triangle) points
         * @param {type} boundingClientRect - position from select.node().getBoundingClientRect();
         * @returns {Array|getArrowPolygon.polygon} - polygon points
         */
        getArrowPolygon: function (boundingClientRect) {
            var polygon = [];
            if (!boundingClientRect) {
                return polygon;
            }
            polygon.push([boundingClientRect.left, boundingClientRect.top]);
            polygon.push([boundingClientRect.left, boundingClientRect.bottom]);
            polygon.push([boundingClientRect.right, boundingClientRect.top + (boundingClientRect.height / 2)]);
            return polygon;
        }
    }
};

export default Arc;
