
var PortraitAlert = (function () {
    var alertShowed = false;
    var template = [
        '<div id="portraitModalBackground" class="modal-backdrop fade show" style="z-index: 2010;"></div>',
        '<div class="modal fade" id="portraitModal" tabindex="-1" role="dialog" aria-labelledby="portraitModalLabel" aria-hidden="true" style="z-index: 2020;">',
            '<div class="modal-dialog modal-dialog-centered" role="document">',
                '<div class="alert alert-info alert-dismissible fade show" role="alert">',
                    '<button type="button" class="close" data-dismiss="modal" aria-label="Close">',
                        '<span aria-hidden="true">&times;</span>',
                    '</button>',
                    ' <h4 id="portraitModalTitle" class="alert-heading">Hello!</h4>',
                    '<content id="portraitModalContent">This site works best in landscape orientation - please rotate your device.</content>',
                '</div>',
            '</div>',
        '</div>'
    ].join('');
    function showAlert() {
        alertShowed = true;
        $("body").append(template);
        $("#portraitModal").modal('show');
        $('#portraitModal').on('hidden.bs.modal', function (e) {
            $("#portraitModalBackground").remove();
            $("#portraitModal").remove();
        });
        $(window).on("orientationchange.portraitAlert", function (event) {
            $('#portraitModal').modal('hide');
            $(window).unbind('orientationchange.portraitAlert');
        });
    }
    function is_small_mobile() {
        //(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
        return (/android|iphone/i.test(navigator.userAgent.toLowerCase()));
    }
    function is_porttrait() {
        let windowHeight = $(window).height();
        let windowWidth = $(window).width();
        return (windowHeight > windowWidth);
    }
    $(document).ready(function () {
        if(is_small_mobile()){
//            hideLongHelloModalText();
            if(is_porttrait()){
                showAlert();
            }
        }
    });
    return {
        isAlertShowed: function () {
            return alertShowed;
        },
        isSmallMobile: function () {
            return is_small_mobile();
        },
        isPortrait: function () {
            return is_porttrait();
        }
    };
})();

export default PortraitAlert;
