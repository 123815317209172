/* global Network, d3plus, d3, ZoomNetwork, Submodel, Utilities */
import * as d3 from 'd3';
import Network from "./network";
import Submodel from "./submodel";
import Utilities from "./utilities";

/**
 * Descriptions inside network
 */
var Descriptions = {
    TYPE: "description",
    MAX_LINES: 20,
    /**
     * Add all description to the network
     * @param {Array} textboxes - array from /network/get
     */
    addAll: function (submodel) {
        if(typeof submodel.textboxes === "undefined" || submodel.textboxes === null){
            return;
        }
        var desc = d3.select(Network.getSVG_CSS_SELECTOR())
                .select("#" + Submodel.SUBMODEL_PREFIX + submodel.handle)
                .selectAll(Utilities.getGTypeSelector(this.TYPE))
                .data(submodel.textboxes)
                .enter()
                .append("g")//append group which represent node
                .attr('type', 'description')
                .attr("style", d => d.italic ? "font-style: italic" : "")
                .each(function (data, el) {
                    var lineCount = 0;
                    var descD3Obj = d3.select(this);
                    do {
                        descD3Obj.html("");
                        Utilities.addTextBox([data],
                                "left",
                                "top",
                                {
                                    x: data.x,
                                    y: data.y,
                                    width: data.width,
                                    height: data.height + lineCount * 100
                                },
                                Utilities.getFontStyles(data.font, data.bold),
                                data.color,
                                this);
                        lineCount++;
                    } while (lineCount < Descriptions.MAX_LINES);
                    if (lineCount > 0 && lineCount <= Descriptions.MAX_LINES) {
                        descD3Obj.data()[0].height = descD3Obj.node().getBBox().height;
                    }
                });
    }
};

export default Descriptions;
