/* global Utilities, d3, ScrollBar, hopscotch, Cooke */

import * as d3 from 'd3';
import HideColumns from "./hideColumns";
import Utilities from "./utilities";
import ScrollBar from "./scrollBar";
import StartApp from "./startAppInfo";
import Cooke from "./cooke";
import User from "./user";
import Node from "./node";
import hopscotch from "hopscotch/dist/js/hopscotch";
import {CASE_MANAGER_STATUS} from "./constantsMapping";

var Tutorial = (function () {
    var tutorialOn = false;
    var tour = {
        id: "hello-hopscotch",
        showCloseButton: true,
        showPrevButton: true,
        onStart: function () {
            HideColumns.openRight(0.0000001);
            HideColumns.openLeft(0.0000001);
        },
        steps: [
            {
                title: "Network",
                content: "You can click on any node to make an observation.",
                target: "",
                placement: "right"
            },
            {
                title: "Node List",
                content: "This panel shows the results of computation (marginal probability distributions or expected utilities of individual nodes). You can hide this panel by clicking on the side-arrow, which appears when you hover the mouse close to the panel. <p>Use the textbox at the top of the panel to filter nodes. Asterisks and question marks are treated as wildcards.</p>",
                target: "rightColumn",
                placement: "left",
                yOffset: "center"
            },
            {
                title: "Model List",
                content: "You can select another model from this list.",
                target: "dropdownMenuButton",
                placement: "right",
                yOffset: -15
            },
            {
                title: "Model Info",
                content: "<p>This panel shows basic information about the current model. You can hide it by clicking on the side-arrow, which appears when you hover the mouse close to the panel.</p><p>You can also download the model and use it with <a href='https://www.bayesfusion.com/genie/' target='_blank'>GeNIe Modeler</a>, our standalone tool for interactive model building and learning. <a href='https://www.bayesfusion.com/smile/' target='_blank'>SMILE Engine</a> can be used to integrate Bayesian technology into your software written in C++, Python, Java, .NET, R or Matlab.</p>",
                target: "leftColumn",
                placement: "right",
                yOffset: "center"
            }
        ]
    };
    function selectVisibleNode() {
        var filtered = d3.selectAll(Utilities.getGTypeSelector("submodel") + ":not([display='none'])")
                .selectAll(Utilities.getGTypeSelector("node"))
                .filter((n) => {
                    var position = ScrollBar.getInvisiblePosition(n);
                    return (position.horizontal === 0) && (position.vertical === 0);
        });
        //select top left corner of visible plate
        var visibleNode = filtered.nodes()
                .sort(function (a, b) {
                    var aPosition = d3.select(a).node().getBBox();
                    var bPosition = d3.select(b).node().getBBox();
                    var distanceOrginA = Math.sqrt((aPosition.x - 0) * (aPosition.x - 0) + (aPosition.y - 0) * (aPosition.y - 0));
                    var distanceOrginB = Math.sqrt((bPosition.x - 0) * (bPosition.x - 0) + (bPosition.y - 0) * (bPosition.y - 0));
                    return distanceOrginA - distanceOrginB;
                });
        return visibleNode[0];
    }
    function run() {
        if (tutorialOn) {
            var visibleNode = selectVisibleNode();
            for (var i = 0; i < tour.steps.length; i++) {
                if(tour.steps[i].target === ""){
                    tour.steps[i].target = Node.getNodeIdFromObject(visibleNode);
                    break;
                }
            }
            console.log("visible node:");
            console.log(visibleNode);
            console.log("tour:");
            console.log(tour);
            hopscotch.startTour(tour);
        }
    }
    function prepare_modal(showTutorailID, closeModalID) {
        if (tutorialOn) {
            var showButton = d3.select("#" + showTutorailID);
            showButton.attr("hidden", null);
            $("#" + showTutorailID).unbind("click");
            var caseManagerInfo = StartApp.getCasesManagerInfo().visible;
            if (caseManagerInfo === CASE_MANAGER_STATUS.ENABLED_ALL || (caseManagerInfo === CASE_MANAGER_STATUS.ENABLED_AUTHENTICATED && User.isLogged())) {
                var isAdded = false;
                for (var i = 0; i < tour.steps.length; i++) {
                    if (tour.steps[i].target === "caseManagerBtn") {
                        isAdded = true;
                    }
                }
                if (!isAdded) {
                    tour.steps.push({
                        title: "Case manager",
                        content: "Use Case Manager to store and retrieve cases (evidence sets).",
                        target: "caseManagerBtn",
                        placement: "bottom"
                    });
                }
            }
            if (Cooke.checkCookie("bayesboxShowedTutorial")) {
                d3.select("#" + closeModalID).attr("hidden", null);
                showButton.on("click", () => run());
                return null;
            }
            d3.select("#" + closeModalID).attr("hidden", true);
            Cooke.setCookie("bayesboxShowedTutorial", true, 10 * 365);
            return run;

        } else {
            d3.select("#" + showTutorailID).attr("hidden", true);
            d3.select("#" + closeModalID).attr("hidden", null);
        }
        return null;
    }
    return {
        start: function () {
            run();
        },
        activate: function (state) {
            tutorialOn = state;
        },
        prepareModal: function (showTutorialID, closeModalID) {
            return prepare_modal(showTutorialID, closeModalID);
        }
    };
})();

export default Tutorial;
