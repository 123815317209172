import * as d3 from 'd3';
import 'jquery-ui-dist/jquery-ui';
import {
    NODE_TYPE,
    NODE_TEMPORAL_TYPE,
    BAYESBOX_MODE,
    MIN_DBL,
    DECIMALS_IN_CHANCE_NODE_BARCHARTS
} from "./constantsMapping";
import Utilities from "./utilities";
import DynamicBN from "./dynamicBN";
import Network from "./network";
import RightColumn from "./rightColumn";
import Submodel from "./submodel";
import NodeShape from "./nodeShape";
import Log from "./log";
import CustomClass from "./customClass";
import EvidenceSummary from "./evidenceSummary";
import GenieSymbols from "./genieSymbols";
import PortraitAlert from "./portraitAlert";
import Arc from "./arc";
import ScrollBar from "./scrollBar";
import contextMenuFactory from 'd3-context-menu';
import {CurrentDashboard} from "./gauge";

/* global NODE_TYPE, d3, parseFloat, Utilities, NODE_TEMPORAL_TYPE, DynamicBN, Network, RightColumn, Submodel, NodeShape, d3plus, Log, CustomClass, EvidenceSummary, MODE, BAYESBOX_MODE, GenieSymbols */

var Node = {
    Type: {
        SUBMODEL: 1,
        NODE: 2
    },
    TYPE: "node",
    mappingNodes2D: new Map(),//nodeHandle -> networkHandle -> nodeID
    nodeUnion: new Map(),
    nestetMenuObserver: null,
    valueRangeDecision: {minUtility: 0, maxUtility: 0},
    getNodeIdFromObject: function (node) {
//        return node.id;
        return BAYESBOX_MODE.isDashboard() ? node.id + "-" + node.network : node.id;
    },
    getNodeIdFromString: function (id, networkHandle) {
//        return id;
        return BAYESBOX_MODE.isDashboard() ? id + "-" + networkHandle : id;
    },
    getNodeIdFromParentsOrChild: function (parentOrChild, node) {
        return node.network ? this.getNodeIdFromString(parentOrChild, node.network) : this.getNodeIdFromString(parentOrChild, Network.getCurrentNetwork());
    },
    selectNodeFromObject: function (node) {
        return d3.select("#" + this.getNodeIdFromObject(node));
    },
    selectNodeFromString: function (id, networkIDs) {
        return d3.select("#" + this.getNodeIdFromString(id, networkIDs));
    },
    selectNodeIdFromParentsOrChild: function (parentOrChild, node) {
        return node.network ? this.selectNodeFromString(parentOrChild, node.network) : this.selectNodeFromString(parentOrChild, Network.getCurrentNetwork());
    },
    getStateLabels(node) {
        let labelArray = [];
        const outcomesExist = typeof(node.outcome) !== "undefined" && node.outcome.length > 0;
        const intervalsExist = typeof(node.intervals) !== "undefined" && node.intervals.length > 0;
        const pointValuesExist = typeof(node.pointValues) !== "undefined" && node.pointValues.length > 0;
        const discretizationIntervalExist = typeof(node.discretizationInterval) !== "undefined" && node.discretizationInterval.length > 0;
        if (outcomesExist) {
            labelArray = [...node.outcome];
            if (intervalsExist) {
                for (let i = 0; i < node.intervals.length - 1; i++) {
                    labelArray[i] += ` (${node.intervals[i]}..${node.intervals[i+1]})`;
                }
            } else if (pointValuesExist) {
                node.pointValues.forEach((v, idx) => {
                    labelArray[idx] += ` (${v})`;
                });
            }
        } else if (intervalsExist) {
            for (let i = 0; i < node.intervals.length - 1; i++) {
                labelArray.push(`${node.intervals[i]}..${node.intervals[i+1]}`);
            }
        } else if (discretizationIntervalExist && !node.isConstantEquation) {
            for (let i = 0; i < node.discretizationInterval.length - 1; i++) {
                labelArray.push(`${Utilities.convertNumberToShortestString(node.discretizationInterval[i].boundary)}..${Utilities.convertNumberToShortestString(node.discretizationInterval[i + 1].boundary)}`);
            }
        }
        return labelArray;
    },
    getStateSelector(node, index) {
        return Utilities.getValidId(`${node.id}_state_${index}`);
    },
    Outcome: {
        /**
         * Check if node have multidimensional outcomes
         * @param {int} nodeType
         * @param {array} indexingParents
         * @returns {Boolean}
         */
        isMultidimensional: function (nodeType, indexingParents) {
            if ((nodeType === NODE_TYPE.UTILITY || nodeType === NODE_TYPE.MAU) && (indexingParents.length - 1) > 1) {
                return true;
            }
            if (nodeType !== NODE_TYPE.EQUATION && nodeType !== NODE_TYPE.UTILITY && nodeType !== NODE_TYPE.MAU && (indexingParents.length - 1) > 0) {
                return true;
            }
            return false;
        },
        /**
         *
         * @param {type} indexingParent - node handle of indexing parent
         * @returns {array} - indexing parets
         */
        getOutcomeIndexingParents: function (nodeData, index) {
            var indexingParent = nodeData.indexingParentsIds[index];
            var networkHandle = nodeData.network ? nodeData.network : Network.getCurrentNetwork();
            if (typeof indexingParent.outcomeIds === 'undefined') {
                var node = d3.select("#" + Node.getNodeIdFromHandle(parseInt(indexingParent.node, 10), networkHandle)).data()[0];

                return Node.getStateLabels(node);
            } else {
                return indexingParent.outcomeIds;
            }
        },
        getBoodyNoisyDefinitionTable: function (nodeState, parents, states, values) {
            var htmlCode = "<thead>";
            //begin th tag
            htmlCode += "<tr>";
            if (parents.length > 0) {
                //parents
                htmlCode += "<th class='multiDHeaderTable' scope='col'>Parent</th>";
                for (var i = 0; i < parents.length; i++) {
                    let colspan = states[i].length;
                    htmlCode += "<th class='multiDHeaderTable' scope='col' colspan='" + colspan + "'>" + parents[i] + "</th>";
                }
            } else {
                htmlCode += "<th class='multiDHeaderTable' scope='col'></th>";
            }
            //LEAK
            htmlCode += "<th class='multiDHeaderTable' scope='col' rowspan='2'>LEAK</th>";
            //end th tag
            htmlCode += "</tr>";
            if (parents.length > 0) {
                //begin th tag
                htmlCode += "<tr>";
                //states
                htmlCode += "<th class='multiDHeaderTable' scope='col'>State</th>";
                for (var i = 0; i < states.length; i++) {
                    for (var j = 0; j < states[i].length; j++) {
                        htmlCode += "<th class='multiDHeaderTable' scope='col'>" + states[i][j] + "</th>";
                    }
                }
                //end th tag
                htmlCode += "</tr>";
            }
            //end thead tag
            htmlCode += "</thead>";
            //values
            htmlCode += "<tbody><tr>";
            var htmlRow = new Array(nodeState.length);
            for (var i = 0; i < htmlRow.length; i++) {
                htmlRow[i] = "<th class='multiDHeaderTable' scope='row'>" + nodeState[i] + "</th>";
            }
            var currentRow = 0;
            for (var i = 0; i < values.length; i++) {
                let value = Number(values[i]).toFixed(DECIMALS_IN_CHANCE_NODE_BARCHARTS);
                if(isNaN(value)){
                    value = values[i];
                }
                htmlRow[currentRow % nodeState.length] += "<td>" + value + "</td>";
                currentRow++;
            }
            for (var i = 0; i < htmlRow.length; i++) {
                htmlRow[i] += "</tr>";
                htmlCode += htmlRow[i];
            }
            return htmlCode;
        },
        /**
         * @param {type} nodeState [State1, State2, ..., StateN]
         * @param {type} parents [name, name, name]
         * @param {type} states [[state1, state2],[state1, state2],[state1, state2]]
         * @param {type} values [number, number, number ...]
         * @param {type} valueFormat - d3.format ex. d3.format('.2') or null if value must be original
         * @param {String} state - (not required) show only selected state row
         * @returns {undefined}
         */
        getBodyMultidimensionalTable: function (nodeState, parents, states, values = [], valueFormat, boldedValuesIndex, state) {
            //assertion
            var expectedValuesCount = states.reduce((prevValue, currValue, index, array) => {
                return prevValue * currValue.length;
            }, nodeState.length);
            if ((expectedValuesCount !== values.length) && values.length !== 0) {
                console.error(`Number of 'values' in multidimensional table is different ${values.length} from expected ${expectedValuesCount}`);
                nodeState = ["Not implemented yet."];
                parents = [];
                states = [];
                values = [];
            }

            var htmlCode = "<thead>";
            //calculate colspan values
            var colspan = new Array(parents.length);
            colspan[colspan.length - 1] = 1;
            var allColumn = 1;
            for (var i = colspan.length - 1; i > 0; i--) {
                var outcomesTMP = states[i];
                colspan[i - 1] = colspan[i] * outcomesTMP.length;
                allColumn *= outcomesTMP.length;
            }
            var repeatCount = 1;
            for (var i = 0; i < parents.length; i++) {
                htmlCode += "<tr>";
                htmlCode += "<th class='multiDHeaderTable' scope='col'>" + parents[i] + "</th>";
                for (var x = 0; x < repeatCount; x++) {
                    for (var j = 0; j < states[i].length; j++) {
                        htmlCode += "<th class='multiDHeaderTable' scope='col' colspan='" + colspan[i] + "'>" + states[i][j] + "</th>";
                    }
                }
                repeatCount *= states[i].length;
                htmlCode += "</tr>";
            }
            htmlCode += "</thead><tbody>";
            var htmlRow = new Array(nodeState.length);
            for (var i = 0; i < htmlRow.length; i++) {
                htmlRow[i] = "<tr><th class='multiDHeaderTable' scope='row'>" + nodeState[i] + "</th>";
            }
            var currentRow = 0;
            for (var i = 0; i < values.length; i++) {
                let value = valueFormat !== null ? valueFormat(values[i]) : values[i];
                if(isNaN(value)){
                    value = values[i];
                }
                if(values[i] === MIN_DBL){
                    value = "<span style='color: red;'>impossible</span>";
                }
                htmlRow[currentRow % nodeState.length] += boldedValuesIndex.has(i) ? "<td><b>" + value + "</b></td>" : "<td>" + value + "</td>";
                currentRow++;
            }
            if (typeof state !== 'undefined' && state !== null) {
                for (var i = 0; i < nodeState.length; i++) {
                    if (nodeState[i] === state) {
                        htmlRow[i] += "</tr>";
                        htmlCode += htmlRow[i];
                    }
                }
            } else {
                for (var i = 0; i < htmlRow.length; i++) {
                    htmlRow[i] += "</tr>";
                    htmlCode += htmlRow[i];
                }
            }
            htmlCode += "</tbody>";
            return htmlCode;
        },
        /**
         * Create multidimensional table
         * @param {D3 Object data} data - node data
         * @param {String} state- (not required) show only selected state row
         * @returns {String} - html code
         */
        constructMultidimensionalTable: function (data, state) {
            var states = [];
            var parents = [];
            for (var i = 0; i < data.indexingParentsIds.length - 1; i++) {
                var outcomesTMP = this.getOutcomeIndexingParents(data, i);
                states.push(outcomesTMP);
            }
            var networkHandle = data.network ? data.network : Network.getCurrentNetwork();
            for (var i = 0; i < data.indexingParentsIds.length - 1; i++) {
                let node = d3.select("#" + Node.getNodeIdFromHandle(parseInt(data.indexingParentsIds[i].node, 10), networkHandle)).data()[0];
                parents.push(node.name);
            }
            var currentNodeState = ["Exp. utility"];
            if(data.indexingParentsIds.length !== 0){
                currentNodeState = this.getOutcomeIndexingParents(data, data.indexingParentsIds.length - 1);//last indexing parent is this node
            }
            var boldedValuesIndex = Utilities.getIndexingParentsStates(data);
            return this.getBodyMultidimensionalTable(currentNodeState, parents, states, data.values.map(d => d.value), (num) => Math.round((num + Number.EPSILON) * 100) / 100, boldedValuesIndex, state);
        },
        constructDefinitionTable: function (data, nodeState, parents, states, values, tableStyle) {
            d3.selectAll('.multiDDiv').remove();
            var generalDiv = d3.selectAll('.multiDDiv')
                    .data([{
                            "text": data.name
                        }])
                    .enter()
                    .append('div')
                    .attr('class', 'multiDDiv');
            var headerDiv = generalDiv.append('div')
                    .attr('class', 'multiDDivHeader');
            headerDiv.append('span')
                    .attr('class', 'multiDDivSpan')
                    .text(function (d) {
                        return 'Definition: ' + d.text;
                    });
            headerDiv.append('button')
                    .attr('class', 'multiDDivButton close')
                    .html('<span>×</span>')
                    .on('click', function (d) {
                        d3.selectAll('.multiDDiv').remove();
                        d3.selectAll("body").on('click', null);
                    });

            generalDiv.append('div')
                    .attr('class', 'multiDDivTable')
                    .append('table')
                    .attr('class', 'table-sm multiDTable')
                    .attr('style', tableStyle)
                    .html(() => {
                        if (data.checkType(NODE_TYPE.NOISY_MAX)) {
                            return this.getBoodyNoisyDefinitionTable(nodeState, parents, states, values);
                        }
                        var boldedValuesIndex = Utilities.getIndexingParentsStates(data);
                        return this.getBodyMultidimensionalTable(nodeState, parents, states, values, null, boldedValuesIndex, null);
                    });
            var tooltipWithContent = d3.selectAll(".multiDDiv, .multiDDiv *");
            d3.select("body").on("click", function () {
                var outside = tooltipWithContent.filter(this === d3.event.target).empty();
                if (outside) {
                    d3.selectAll('.multiDDiv').remove();
                    d3.selectAll("body").on('click', null);
                }
            });
            Node.setTheSameColWidth();
            return generalDiv;
        },
        appendTemporalDefinition: function (elm, data, nodeState, parents, states, values, tableStyle, order) {
            elm.select(".multiDDivTable")
                    .append('table')
                    .attr('class', 'table-sm multiDTable d-none')
                    .attr('style', tableStyle)
                    .attr('order', order)
                    .html(() => {
                        if (data.checkType(NODE_TYPE.NOISY_MAX)) {
                            return this.getBoodyNoisyDefinitionTable(nodeState, parents, states, values);
                        }
                        var boldedValuesIndex = Utilities.getIndexingParentsStates(data);
                        return this.getBodyMultidimensionalTable(nodeState, parents, states, values, null, boldedValuesIndex, null);
                    });
        },
        /**
         * Create multidimensional div
         * @param {D3 Object data} data - node data
         */
        constructMultidimensionalDiv: function (data) {
            d3.selectAll('.multiDDiv').remove();
            var generalDiv = d3.selectAll('.multiDDiv')
                    .data([{
                            "text": data.name
                        }])
                    .enter()
                    .append('div')
                    .attr('class', 'multiDDiv');
            var headerDiv = generalDiv.append('div')
                    .attr('class', 'multiDDivHeader');
            headerDiv.append('span')
                    .attr('class', 'multiDDivSpan')
                    .text(function (d) {
                        return 'Value: ' + d.text;
                    });
            headerDiv.append('button')
                    .attr('class', 'multiDDivButton close')
                    .html('<span>×</span>')
                    .on('click', function (d) {
                        d3.selectAll('.multiDDiv').remove();
                        d3.selectAll("body").on('click', null);
                    });

            generalDiv.append('div')
                    .attr('class', 'multiDDivTable')
                    .append('table')
                    .attr('class', 'table-sm multiDTable')
                    .html(this.constructMultidimensionalTable(data));
            var tooltipWithContent = d3.selectAll(".multiDDiv, .multiDDiv *");
            d3.select("body").on("click", function () {
                var outside = tooltipWithContent.filter(this === d3.event.target).empty();
                if (outside) {
                    d3.selectAll('.multiDDiv').remove();
                    d3.selectAll("body").on('click', null);
                }
            });
            Node.setTheSameColWidth();
        },
        /**
         * Get outcome name
         * @param {Object} nodeValue - single evidence value object returned from /network/update
         * @param {int} handle - node handle
         * @returns {String}-outcome name or ""
         */
        getOutcomeIds: function (nodeValue, nodeData) {
            var handle = nodeData.handle;
            var networkHandle = nodeData.network ? nodeData.network : Network.getCurrentNetwork();
            if (typeof nodeValue.outcomeId !== 'undefined') {
                return nodeValue.outcomeId;
            } else if (typeof nodeValue.outcomeIndex !== 'undefined') {
                var node = d3.select("#" + Node.getNodeIdFromHandle(parseInt(handle, 10), networkHandle)).data()[0];
                const labels = Node.getStateLabels(node);
                return labels[nodeValue.outcomeIndex];
            }
            return "";
        }
    },
    /**
     * calculating value range for utility node
     * @param {Object} node - node d3.data
     * @returns {Object} - {minUtility:? ,maxUtility:?}
     */
    minMaxUtilityHelper: function (node) {
        var definition = node.definitionMinMaxUtility;
        var minUtility = 0;
        var maxUtility = 0;
        var outputValue = new Object();
        if (node.checkType(NODE_TYPE.UTILITY)) {
            minUtility = Number.MAX_SAFE_INTEGER;
            maxUtility = Number.MIN_SAFE_INTEGER;
            var p = definition;
            var imax = p.length;
            for (var i = 0; i < imax; i++) {
                var v = p[i];
                minUtility = Math.min(minUtility, v);
                maxUtility = Math.max(maxUtility, v);
            }
        } else if (node.checkType(NODE_TYPE.MAU)) {
            if (node.isEquation === false) {
                minUtility = maxUtility = 0;
                var p = definition;
                var parents = node.parents;
                var imax = parents.length;
                for (var i = 0; i < imax; i++) {
                    var parentMin, parentMax;
                    var nodeParent = parents[i].data()[0];
                    outputValue = Node.minMaxUtilityHelper(nodeParent);
                    var weight = p[i];
                    if (weight < 0) {
                        var tmp = outputValue.minUtility;
                        outputValue.minUtility = outputValue.maxUtility;
                        outputValue.maxUtility = tmp;
                    }

                    minUtility += weight * outputValue.minUtility;
                    maxUtility += weight * outputValue.maxUtility;
                }
            }
        }
        outputValue.minUtility = minUtility;
        outputValue.maxUtility = maxUtility;
        return outputValue;
    },
    /**
     * Determine range for Decision nodes and save it in global variable
     */
    setRangeDecision: function () {
        //reset value range for NODE_TYPE.DECISION nodes
        this.valueRangeDecision.minUtility = 0;
        this.valueRangeDecision.maxUtility = 0;
        //setting data value before update graph
        //important for function minMaxUtilityHelper(node)
        var selector = Utilities.getGTypeSelector(this.TYPE);
        if(BAYESBOX_MODE.isDashboard()){
            selector = "div[type=nodeCard]";
        }
        d3.selectAll(selector).each((node, i) => {
            if (node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU])) {
                //get value range for NODE_TYPE.DECISION node
                if (node.children.length === 0) {
                    var tmpMinMax = Node.minMaxUtilityHelper(node);
                    this.valueRangeDecision.minUtility += tmpMinMax.minUtility;
                    this.valueRangeDecision.maxUtility += tmpMinMax.maxUtility;
                }
            }
        });
    },
    /**
     * Get node parameters defined view in right column and node bar chart
     * @param {D3 Object} node
     * @param {Integer} valueIndex - index of value
     * @returns {Node.getViewParameter.parameter}
     */
    getViewParameter: function (node, valueIndex) {
        //creating outcomes with percentage Math.floor((number1 / number2) * 100)
        //default values for ellipse
        var parameter = {};
        parameter.value = Utilities.getBestToRounding(node.values[valueIndex].value * 100);
        parameter.textValue = parameter.value;
        parameter.unit = "%";
        parameter.progressTitleOutcomeID = "";
        parameter.collapse = null;
        parameter.minMax = {minUtility: 0, maxUtility: 100};
        parameter.label = "Value";
        /*
         update progress bars in right column
         */
        if (!node.checkType(NODE_TYPE.EQUATION)) {
            parameter.label = Node.getStateLabels(node)[valueIndex];
        }
        if (node.isTarget) {
            parameter.collapse = d3.select('#targetsAccordion')
                    .select('#collapse_' + Node.getNodeIdFromObject(node));
            parameter.progressTitleOutcomeID = Node.getStateSelector(node, valueIndex);
            parameter.unit = "%";
        } else if (!node.isMultidimensional) {
            parameter.collapse = d3.select('#otherAccordion')
                    .select('#collapse_' + Node.getNodeIdFromObject(node));

            if (node.checkType(NODE_TYPE.DECISION)) {
                parameter.unit = "";
                parameter.value = 100 * (node.values[valueIndex].value - Node.valueRangeDecision.minUtility) / (Node.valueRangeDecision.maxUtility - Node.valueRangeDecision.minUtility);
                if (node.evidence === valueIndex) {
                    parameter.value = 100;
                }
                parameter.textValue = node.values[valueIndex].value;
                parameter.progressTitleOutcomeID = Node.getStateSelector(node, valueIndex);
            } else if (node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU]) && node.indexingParentsIds.length === 2) {
                parameter.minMax = Node.minMaxUtilityHelper(node);
                parameter.value = 100 * (node.values[valueIndex].value - parameter.minMax.minUtility) / (parameter.minMax.maxUtility - parameter.minMax.minUtility);
                var outcomeTMP = Node.Outcome.getOutcomeIndexingParents(node, 0);
                parameter.progressTitleOutcomeID = outcomeTMP[valueIndex];
                parameter.textValue = node.values[valueIndex].value;
                parameter.unit = "";
            } else if (node.checkType([NODE_TYPE.MAU, NODE_TYPE.UTILITY])) {
                parameter.minMax = Node.minMaxUtilityHelper(node);
                parameter.value = 100 * (node.values[valueIndex].value - parameter.minMax.minUtility) / (parameter.minMax.maxUtility - parameter.minMax.minUtility);
                parameter.textValue = node.values[valueIndex].value;
                parameter.unit = "";
                parameter.progressTitleOutcomeID = Node.Outcome.getOutcomeIds(node.values[valueIndex], node);
            } else if (node.checkType(NODE_TYPE.EQUATION)) {
                if (node.isValueDiscretized || node.values.length === 1) {
                    parameter.value = node.values[valueIndex].value * 100;
                    parameter.textValue = node.values[valueIndex].value;
                    if (node.isConstantEquation) {
                        parameter.textValue += " (constant)"
                    }
                    if (typeof node.evidence !== "undefined") {
                        parameter.textValue += " (evidence)"
                    }
                    var outcomeIdTMP = Node.Outcome.getOutcomeIds(node.values[valueIndex], node);
                    parameter.progressTitleOutcomeID = this.Evidence.PREFIX_DISCRETIZATION + Utilities.getValidId(outcomeIdTMP);
                    parameter.label = Node.Outcome.getOutcomeIds(node.values[valueIndex], node);
                    parameter.unit = "";
                }
                if (node.values.length === 1) {
                    parameter.label = "Value";
                }
            } else {//ellipse
                parameter.progressTitleOutcomeID = Node.getStateSelector(node, valueIndex);
                parameter.unit = "%";
            }
        }
        return parameter;
    },
    setTheSameColWidth: function () {
        var allHeadCol = d3.select(".multiDDivTable")
                .selectAll("table:not(.d-none)")
                .select("thead tr:last-child")
                .selectAll("th:not(:first-child)");
        var allHeadColWidth = allHeadCol.nodes()
                .map((currentValue, index, array) => $(currentValue)[0].getBoundingClientRect().width);
        var maxHeadWidth = d3.max(allHeadColWidth);
        allHeadCol.style("min-width", maxHeadWidth + "px");
    },
    addOrderSelector: function (orders) {
        var select = d3.select('.multiDDivTable')
                .insert("div").lower()
                .append("select")
                .attr("class","form-control form-control-sm mb-1")
                .style("width","auto")
                .style("font-size",".7rem")
                .style("display","inline-block")
                .on("change", function (event) {
                    var order = $('option:selected', this).attr('order');
                    d3.select(".multiDDivTable").selectAll("table").classed("d-none", true);
                    d3.select(".multiDDivTable").selectAll("table[order='" + order + "']").classed("d-none", false);
                    //col width
                    Node.setTheSameColWidth();
                    //position
                    var tablePosition = document.getElementsByClassName("multiDDiv")[0].getBoundingClientRect();
                    var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, ".multiDDiv", tablePosition.left, tablePosition.top);
                    $('.multiDDiv').css({
                        'left': translate.x,
                        'top': translate.y
                    });
                });
        d3.select(".multiDDivTable").selectAll("table:not([order])").attr("order",0);
        select.append("option")
                .attr("order",0)
                .text("T=0")
                .attr("selected","");
        for (var i = 0; i < orders.length; i++) {
            select.append("option")
                    .attr("order",orders[i])
                    .text("T=" + orders[i]);
        }
    },
    getStatesArray: function (parents) {
        let statesArray = [];
        for (var i = 0; i < parents.length; i++) {
            var parent = parents[i].data()[0];
            if (parent.checkType(NODE_TYPE.EQUATION)) {
                let discretizationOutcomes = [];
                for (var j = 0; j < parent.discretizationInterval.length - 1; j++) {
                    if (parent.discretizationInterval[j + 1].id !== "") {
                        discretizationOutcomes.push(parent.discretizationInterval[j + 1].id);
                    } else {
                        discretizationOutcomes.push(parent.discretizationInterval[j].boundary + "..." + parent.discretizationInterval[j + 1].boundary);
                    }
                }
                statesArray.push({
                    name: parent.name,
                    states: discretizationOutcomes
                });
            } else {
                const parentStates = Node.getStateLabels(parent);
                statesArray.push({
                    name: parent.name,
                    states: parentStates
                });
            }
        }
        return statesArray;
    },
    showDefinition: function (elm, pageX, pageY) {
        let done = (data, textStatus, jqXHR) => {
            // check network revision
            if (data.revision !== Network.getRevision()) {
                Log.setEvidenceFailed("Error!", "The network is no longer available.");
                return;
            }
            var nodeState = [];
            var definition = [];
            var parents = [];
            var states = [];
            var tableStyle = "";
            var elmID = Node.getNodeIdFromObject(elm);

            function getParenstArray() {
                let parentsArray = [];
                for (var i = 0; i < elm.parents.length; i++) {
                    var parent = elm.parents[i].data()[0];
                    parentsArray.push(parent.name);
                }
                return parentsArray;
            }
            let parentsDefinition = null;
            let elementStates = Node.getStateLabels(elm);
            switch (elm.nodeType) {
                case NODE_TYPE.CPT:
                case NODE_TYPE.TRUTH_TABLE:
                    nodeState = elementStates;
                    definition = data.definition;
                    parentsDefinition = Node.getStatesArray(elm.parents);
                    parents = parentsDefinition.map(p => p.name);
                    states = parentsDefinition.map(p => p.states);
                    break;
                case NODE_TYPE.UTILITY:
                    nodeState = ["Value"];
                    definition = data.definition;
                    parentsDefinition = Node.getStatesArray(elm.parents);
                    parents = parentsDefinition.map(p => p.name);
                    states = parentsDefinition.map(p => p.states);
                    break;
                case NODE_TYPE.DECISION:
                    parents = [];
                    states = [];
                    nodeState = elementStates;
                    definition = data.definition;
                    break;
                case NODE_TYPE.EQUATION:
                    parents = [];
                    definition = [];
                    states = [];
                    nodeState = [data.equation];
                    tableStyle = "font-size: 1rem;";
                    break;
                case NODE_TYPE.MAU:
                    parents = [];
                    states = [];
                    nodeState = [];
                    definition = data.definition;
                    for (var i = 0; i < elm.parents.length; i++) {
                        let parentNode = elm.parents[i].data()[0];
                        if (Node.isMAUParentDiscrete(parentNode)) {
                            parents.push(parentNode.name);
                            states.push(parentNode.outcome);
                        } else {
                            nodeState.push(parentNode.name);
                        }
                    }
                    if (data.isMAUEquation) {
                        nodeState = ["Expression"];
                        definition = data.mauExpressions;
                    } else if (nodeState.length === 0) {
                        nodeState.push("MAU node without utility parents - there are no weights to show.");
                        definition = [];
                        parents = [];
                    }
                    break;
                case NODE_TYPE.NOISY_MAX:
                    nodeState = elementStates;
                    definition = data.definition;
                    parents = [];
                    states = data.noisyStates;
                    var networkHandle = elm.network ? elm.network : Network.getCurrentNetwork();
                    for (var i = 0; i < data.noisyParents.length; i++) {
                        let parentId = Node.getNodeIdFromHandle(data.noisyParents[i], networkHandle);
                        var parent = d3.select("#" + parentId).data()[0];
                        parents.push(parent.name);
                    }
                    break;
                default:
                    parents = [];
                    states = [];
                    nodeState = ["Not implemented yet."];
                    definition = [];
                    break;
            }
            var tooltip = Node.Outcome.constructDefinitionTable(elm, nodeState, parents, states, definition, tableStyle);
            //DBN
            if (data.orderDefinitions) {
                if (NODE_TYPE.EQUATION === elm.nodeType) { // TODO - jsmile needs temporal definition getter for equation nodes
                    parents = [];
                    states = [];
                    nodeState = data.temporalEquation;
                    definition = [];
                    tooltip = Node.Outcome.constructDefinitionTable(elm, nodeState, parents, states, definition, tableStyle);
                } else {
                    let allOrders = [];
                    for (var i = 0; i < data.orderDefinitions.length; i++) {
                        let tableParents = [];
                        let tableStates = [];
                        let temporalDefinition = data.orderDefinitions[i].nodeTemporalDefinition;
                        ;
                        allOrders.push(data.orderDefinitions[i].order);
                        //temporal parents for current T
                        var parent;
                        var parentID = "";
                        for (var j = 0; j < data.orderDefinitions[i].temporalParents.length; j++) {
                            parent = data.orderDefinitions[i].temporalParents[j];
                            parentID = Node.getNodeIdFromString(parent.id, Network.getCurrentNetwork());
                            let d3Partent = d3.select("#" + parentID);
                            var d3PartentData = d3Partent.data()[0];
                            parentsDefinition = Node.getStatesArray([d3Partent]);
                            var parentStates = parentsDefinition.map(p => p.states);
                            if (elmID === parentID) {
                                tableParents.push("(Self)[t-" + parent.order + "]");
                            } else {
                                tableParents.push(d3PartentData.name + "[t-" + parent.order + "]");
                            }
                            tableStates.push(parentStates[0]);
                        }
                        //temporal parents before T>0
                        for (var f = (data.orderDefinitions.length - 1); f >= 0; f--) {
                            if (data.orderDefinitions[f].order < parent.order) {
                                for (var x = 0; x < data.orderDefinitions[f].temporalParents.length; x++) {
                                    let beforeParent = data.orderDefinitions[f].temporalParents[x];
                                    let beforeParentID = Node.getNodeIdFromString(beforeParent.id, Network.getCurrentNetwork());
                                    let beforeD3Partent = d3.select("#" + beforeParentID);
                                    let beforeD3PartentData = beforeD3Partent.data()[0];
                                    parentsDefinition = Node.getStatesArray([beforeD3Partent]);
                                    let beforeParentStates = parentsDefinition.map(p => p.states);
                                    if (elmID === parentID) {
                                        tableParents.unshift("(Self)[t-" + beforeParent.order + "]");
                                    } else {
                                        tableParents.unshift(beforeD3PartentData.name + "[t-" + beforeParent.order + "]");
                                    }
                                    tableStates.unshift(beforeParentStates[0]);
                                }
                            }
                        }
                        //normal parents T=0
                        for (var f = (elm.parents.length - 1); f >= 0; f--) {
                            let beforeD3Parent = elm.parents[f].data()[0];
                            if (beforeD3Parent.temporalType === NODE_TEMPORAL_TYPE.PLATE || beforeD3Parent.temporalType === NODE_TEMPORAL_TYPE.CONTEMPORAL) {
                                parentsDefinition = Node.getStatesArray([elm.parents[f]]);
                                let beforeParentStates = parentsDefinition.map(p => p.states);
                                tableParents.unshift(beforeD3Parent.name);
                                tableStates.unshift(beforeParentStates[0]);
                            }
                        }
                        Node.Outcome.appendTemporalDefinition(tooltip, elm, nodeState, tableParents, tableStates, temporalDefinition, tableStyle, data.orderDefinitions[i].order);
                    }
                    Node.addOrderSelector(allOrders);
                }

            }
            var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, ".multiDDiv", pageX, pageY);
            $('.multiDDiv').css({
                'left': translate.x,
                'top': translate.y
            });
        };
        Request.getDefinition(Network.getCurrentNetwork(), elm.handle, done);
    },
    /*
     definition menu elements
     */
    rightMenu: function (data) {
        if (data.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
            return DynamicBN.rightTemporalMenu();
        }
        var textClear = 'Clear evidence';
        var viewClearButton = true;
        var showSubmenuEvidences = true;
        var menu = [{
                title: 'Set Evidence'
            }];
        if (data.checkType(NODE_TYPE.DECISION)) {
            menu[0].title = 'Set Decision';
            textClear = 'Retract decision';
        } else if (data.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU]) && typeof data.indexingParentsIds !== 'undefined' && data.indexingParentsIds.length > 0) {
            //menu.splice(0,1);
            viewClearButton = false;
            showSubmenuEvidences = false;
            menu.push({
                title: 'Value',
                action: function (elm, d, title) {
                    Node.Outcome.constructMultidimensionalDiv(elm);
                    var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, ".multiDDiv", d3.event.pageX, d3.event.pageY);
                    $('.multiDDiv').css({
                        'left': translate.x,
                        'top': translate.y
                    });
                }
            });
        }
        if (!data.isPropagated === true) {
            let nestedMenuOutcome = [];
            const stateLabels = Node.getStateLabels(data);
            for (var j = 0; j < stateLabels.length; j++) {
                var nodeTitle = "";
                if (typeof data.values !== 'undefined') {
                    if (!data.checkType(NODE_TYPE.DECISION) && !data.checkType(NODE_TYPE.EQUATION)) {
                        nodeTitle = "<span class='val'>" +  data.values[j].value + "</span>  ";
                    }
                }
                let boldClass = "";
                if(data.evidence && data.evidence === j){
                    boldClass = "font-weight-bold";
                }
                nodeTitle += "<span class='" + boldClass + "' title='" + stateLabels[j] + "' >" + stateLabels[j] + "</span>";

                (function (nodeTitle,stateIndex, currentEvidence) {
                    nestedMenuOutcome.push({
                        title: nodeTitle,
                        action: function (elm, d, title) {
                            Node.Evidence.setEvidence(d3.select(this), stateIndex);
                        },
                        disabled: () => {
                            return stateIndex === currentEvidence ? true : false;
                        }
                    });
                })(nodeTitle, j, data.evidence);
            }
            menu[0].children = nestedMenuOutcome;
            const intervalsExist = typeof(data.intervals) !== "undefined" && data.intervals.length > 0;
            if (intervalsExist) {
                menu.push({
                    title: typeof data.evidence !== "undefined" ? "Change Evidence" : "Enter Evidence",
                    action: function (elm, d, title) {
                        Node.Evidence.InputEvidenceModal.showEv(d3.select(this), d3.event);
                    }
                });
            }
            if (data.checkType(NODE_TYPE.EQUATION)) {
                showSubmenuEvidences = false;
                if (typeof data.evidence !== 'undefined') {
                    menu.push({
                        title: "Change Value [<b>" + data.evidence + "</b>]",
                        action: function (elm, d, title) {
                            Node.Evidence.InputEvidenceModal.showEv(d3.select(this), d3.event);
                        }
                    });
                } else {
                    menu.push({
                        title: "Enter Evidence",
                        action: function (elm, d, title) {
                            Node.Evidence.InputEvidenceModal.showEv(d3.select(this), d3.event);
                        }
                    });
                }
            }
            if (data.checkType([NODE_TYPE.CPT, NODE_TYPE.NOISY_MAX, NODE_TYPE.NOISY_ADDER])) {
                menu.push({
                    divider: true
                });
                menu.push({
                    title: 'Virtual Evidence...',
                    action: function (elm, d, title) {
                        var probability = elm.values;
                        if(typeof probability !== "undefined" && Array.isArray(probability)){
                            probability = probability.map((v) => v.value);
                        }else{
                            probability = [];
                        }
                        if (Array.isArray(elm.evidence)) {
                            probability = elm.evidence;
                        }
                        var container = d3.select("#virtualEvidenceModalBody");
                        Node.Evidence.InputVirtualEvidenceModal.addOutcomes(elm.outcome, probability, container);
                        var nodeD3Self = d3.select(this);
                        d3.select("#clearVirtualEvidence").on("click", () => {
                            Node.Evidence.clearEvidence(nodeD3Self);
                            $('#virtualEvidenceModal').modal('hide');
                        });
                        d3.select("#submitVirtualEvidence").on("click", () => {
                            var modalContainer = d3.select("#virtualEvidenceModalBody");
                            var validSumProb = Node.Evidence.InputVirtualEvidenceModal.validSumOfProbabilities(modalContainer);
                            if (validSumProb) {
                                var v = Node.Evidence.InputVirtualEvidenceModal.getVirtualEvidence(modalContainer);
                                Node.Evidence.setEvidence(nodeD3Self, v, "VIRTUAL");
                                $('#virtualEvidenceModal').modal('hide');
                            } else {
                                modalContainer.select("#inputValueError").classed("d-none", false);
                                modalContainer.select("#inputValueError").text("Sum of probabilities should be equal to 1.");
                            }
                        });
                        $('#virtualEvidenceModal').modal('show');
                    }
                });
            }
            menu.push({
                divider: true
            });
            if (typeof data.evidence === 'undefined') {
                if (viewClearButton) {
                    menu.push({
                        title: textClear,
                        disabled: true,
                        action: function (elm, d, title) {}
                    });
                }
            } else {
                if (viewClearButton) {
                    menu.push({
                        title: textClear,
                        action: function (elm, d, title) {
                            Node.Evidence.clearEvidence(d3.select(this));
                        }
                    });
                }
            }

        } else {
            let nestedMenuOutcome = [];
            for (var j = 0; j < data.outcome.length; j++) {
                let title = data.outcome[j];
                if(data.propagatedOutcomeIds && data.propagatedOutcomeIds === data.outcome[j]){
                    title = "<span class='font-weight-bold'>" + data.outcome[j] + "</span>";
                }
                nestedMenuOutcome.push({
                    title: title,
                    disabled: true,
                    action: function (elm, d, title) {}
                });
            }
            menu[0].children = nestedMenuOutcome;
            menu.push({
                divider: true
            });
            if (viewClearButton) {
                menu.push({
                    title: textClear,
                    disabled: true,
                    action: function (elm, d, title) {}
                });
            }
        }

        if (!showSubmenuEvidences) {
            menu.shift();
        }

        if (data.isMultidimensional && !data.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU])) {
            menu.push({
                title: 'Value',
                action: function (elm, d, title) {
                    Node.Outcome.constructMultidimensionalDiv(elm);
                    var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, ".multiDDiv", d3.event.pageX, d3.event.pageY);
                    $('.multiDDiv').css({
                        'left': translate.x,
                        'top': translate.y
                    });
                }
            });
        }
        menu.push({
            divider: true
        });
        menu.push({
            title: 'Show Definition',
            action: function (elm) {
                Node.showDefinition(elm, Math.round(d3.event.pageX), Math.round(d3.event.pageY));
            },
            disabled: function (elm) {
                return elm.checkType(NODE_TYPE.DEMORGAN);
            }
        });
        menu.push({
            divider: true
        });
        var hasParents = (typeof data.parents !== 'undefined') && data.parents.length > 0;
        var locateParents = {
            title: 'Locate Parent',
            disabled: !hasParents
        };
        if (hasParents) {
            let parentsForMenu = [];
            data.parents.forEach(p => parentsForMenu.push(Node.getNodeIdFromObject(p.data()[0])));
            locateParents.children = this.getNestedMenuLocate(parentsForMenu);
        } else {
            locateParents.action = function (elm) {};
        }
        var hasChildren = (typeof data.children !== 'undefined') && data.children.length > 0;
        var locateChildren = {
            title: 'Locate Child',
            disabled: !hasChildren
        };
        if(hasChildren) {
            let childrenForMenu = [];
            data.children.forEach(p => childrenForMenu.push(Node.getNodeIdFromObject(p.data()[0])));
            locateChildren.children = this.getNestedMenuLocate(childrenForMenu);
        } else {
            locateChildren.action = function (elm) {
            };
        }
        menu.push(locateParents);
        menu.push(locateChildren);
        return menu;
    },
    getNestedMenuLocate: function (list) {
        var hasNodes = (typeof list !== 'undefined') && list.length > 0;
        var nodes = [];
        if (hasNodes) {
            for (var i = 0; i < list.length; i++) {
                var nodeData = d3.select("#" + list[i]).data()[0];
                (function (node) {
                    nodes.push({
                        title: "<span title='" + node.name + "' >" + node.name + "</span>",
                        action: function () {
                            Node.blinkNode(node);
                        }
                    });
                })(nodeData);
            }
        }
        return nodes;
    },
    isMAUParentDiscrete: function (node) {
        return node.checkType(NODE_TYPE.DECISION);
    },
    Evidence: {
        orderEvidence: 0,//for sort all set evidence
        PREFIX_DISCRETIZATION: "Discretization_",
        getPropagatedEvidenceIndex: function (values) {
            if (values.length === 0 || (typeof values[0].outcomeIndex === 'undefined')) {
                return "";
            }
            for (var i = 0; i < values.length; i++) {
                if(values[i].value === 1){
                    return values[i].outcomeIndex;
                }
            }
            return "";
        },
        detectUnionNodes: function (nodeDat) {
            var nodes = [];
            if (BAYESBOX_MODE.isDashboard() && Node.nodeUnion.has(nodeDat.id)) {
                var nodeUnion = Node.nodeUnion.get(nodeDat.id);
                for (var i = 0; i < nodeUnion.length; i++) {
                    nodes.push(Node.selectNodeFromObject(nodeUnion[i]).data()[0]);
                }
                return nodes;
            }
            return [nodeDat];
        },
        /*
         * Set evidence
         * @param {Object} node - node object d3.select(Network.getSVG_CSS_SELECTOR()).select("#"+node).data()[0]
         * @param {String} evidence - string name evidence
         */
        setEvidence: function (nodeD3Self, evidence, evidenceType = "DISCRETE", update = Network.isAutoUpdate()) {
            if (typeof update === 'undefined') {
                update = Network.isAutoUpdate();
            }
            var node = nodeD3Self.data()[0];
            var unionNodes = this.detectUnionNodes(node);
            for (var i = 0; i < unionNodes.length; i++) {
                unionNodes[i].evidence = evidence;
                unionNodes[i].evidenceType = evidenceType;
                unionNodes[i].orderEvidence = this.orderEvidence;
                this.orderEvidence++;
                if (!BAYESBOX_MODE.isDashboard()) {
                    EvidenceSummary.addEvidence(unionNodes[i]);
                    GenieSymbols.StatusNodeIcon.updateSymbols(unionNodes[i]);
                }
            }
            if (update) {
                BAYESBOX_MODE.isDashboard() ? Network.update(undefined, CurrentDashboard.update) : Network.update();
            }
        },
        /**
         * Clear evidences
         * @param {D3 Object} node - node object d3.select(Network.getSVG_CSS_SELECTOR()).select("#"+node).data()[0]
         */
        clearEvidence: function (nodeD3Self, update) {
            if (typeof update === 'undefined') {
                update = Network.isAutoUpdate();
            }
            var doUpdate = false;
            var node = nodeD3Self.data()[0];
            var unionNodes = this.detectUnionNodes(node);
            for (var i = 0; i < unionNodes.length; i++) {
                if (typeof unionNodes[i].evidence === 'undefined') {
                    return;
                }
                if (typeof unionNodes[i].evidence !== 'undefined') {
                    doUpdate = true;
                }
                delete unionNodes[i].evidence;
                delete unionNodes[i].evidenceType;
                delete unionNodes[i].orderEvidence;
                if (!BAYESBOX_MODE.isDashboard()) {
                    RightColumn.Accordion.clearProgressBar(unionNodes[i]);
                    EvidenceSummary.removeEvidence(unionNodes[i]);
                    GenieSymbols.StatusNodeIcon.updateSymbols(unionNodes[i]);
                }
            }
            if((doUpdate && update) && !BAYESBOX_MODE.isDashboard()){
                Network.update();
            }else if((doUpdate && update) && BAYESBOX_MODE.isDashboard()){
                Network.update(() => {
                    for (var i = 0; i < unionNodes.length; i++){
                        RightColumn.Accordion.refreshCard_G(unionNodes[i]);
                    }
                }, CurrentDashboard.update);
            }
        },
        /**
         * Clear evidences
         * @param {D3 Object} node - node object d3.select(Network.getSVG_CSS_SELECTOR()).select("#"+node).data()[0]
         */
        clearTemporalEvidence: function (node, update) {
            if (typeof node.temporalEvidence === 'undefined') {
                return;
            }
            if (typeof update === 'undefined') {
                update = Network.isAutoUpdate();
            }
            var doUpdate = false;
            if(node.temporalEvidence){
                doUpdate = true;
            }
            delete node.temporalEvidence;
            delete node.orderEvidence;
            if (BAYESBOX_MODE.isGraph()) {
                EvidenceSummary.removeEvidence(node);
                GenieSymbols.StatusNodeIcon.updateSymbols(node);
            }
            if(doUpdate && update){
                BAYESBOX_MODE.isDashboard() ? Network.update(undefined, CurrentDashboard.update) : Network.update();
            }
        },
        /**
         * Set temporal evidence in node data and update network
         * @param {type} node - d3.data()[0]
         * @param {type} evidences - Array: [[order,evidence],[order,evidence],[order,evidence]...]
         */
        setTemporalEvidence: function (node, evidences, update) {
            if (typeof update === 'undefined') {
                update = Network.isAutoUpdate();
            }
            //if nothing has changed then don't update
            if (typeof node.temporalEvidence !== 'undefined') {
                if ((JSON.stringify(node.temporalEvidence) === JSON.stringify(evidences)) || (node.temporalEvidence.length === 0 && evidences.length === 0)) {
                    return;
                }
            } else if (evidences.length === 0) {
                return;
            }
            node.temporalEvidence = evidences;
            node.evidenceType = "TEMPORAL";
            node.orderEvidence = this.orderEvidence;
            this.orderEvidence++;
            if (BAYESBOX_MODE.isGraph()) {
                EvidenceSummary.addEvidence(node);
                GenieSymbols.StatusNodeIcon.updateSymbols(node);
            }
            if (update) {
                BAYESBOX_MODE.isDashboard() ? Network.update(undefined, CurrentDashboard.update) : Network.update();
            }
        },
        clearAllEvidence: function () {
            var selector = Utilities.getGTypeSelector(Node.TYPE);
            if(BAYESBOX_MODE.isDashboard()){
                selector = "div[type=nodeCard]";
            }
            d3.selectAll(selector).each(function (node, i) {
                delete node.evidence;
                delete node.orderEvidence;
                delete node.temporalEvidence;
                RightColumn.Accordion.clearProgressBar(node);
            });
            if (!BAYESBOX_MODE.isDashboard()) {
                EvidenceSummary.clearAll();
                GenieSymbols.StatusNodeIcon.updateAllSymbols();
            }
        },
        getEvidence: function (node) {
            if (typeof node.evidence !== 'undefined') {
                return {
                    nodeID: Node.getNodeIdFromObject(node),
                    handle: node.handle,
                    evidence: node.evidence,
                    evidenceType: node.evidenceType,
                    orderEvidence: node.orderEvidence,
                    isDynamic: false,
                    isVirtual: Array.isArray(node.evidence)
                }
            } else if (typeof node.temporalEvidence !== 'undefined') {
                return {
                    isDynamic: true,
                    isVirtual: false,
                    nodeID: Node.getNodeIdFromObject(node),
                    handle: node.handle,
                    evidence: node.temporalEvidence,
                    orderEvidence: node.orderEvidence
                }
            }
            return null;
        },
        getAllEvidenceMulti: function (showIDs) {
            var multiEvidences = new Map();
            var selector = Utilities.getGTypeSelector(Node.TYPE);
            if(BAYESBOX_MODE.isDashboard()){
                selector = "div[type=nodeCard]";
            }
            d3.selectAll(selector).each(function (d, i) {
                var evidences = Node.Evidence.getEvidenceData(d, showIDs);
                if(evidences !== null){
                    if(typeof evidences.evidenceType === "undefined") {
                        evidences.evidenceType = "TEMPORAL";
                    }
                    if(multiEvidences.has(d.network)){
                        multiEvidences.get(d.network).push(evidences);
                    }else{
                        multiEvidences.set(d.network, [evidences]);
                    }
                }else if (!multiEvidences.has(d.network)){
                    multiEvidences.set(d.network, []);
                }
            });
            //ordering evidences
            var evidenceList = [];
            multiEvidences.forEach((value, key, map) => {
                value.sort(function (a, b) {
                    return a.orderEvidence - b.orderEvidence;
                });
                for (var i = 0; i < value.length; i++) {
                    delete value[i].orderEvidence;
                }
                evidenceList.push({
                    networkHandle: key,
                    evidences: value
                });
            });

            return evidenceList;
        },
        getEvidenceData: function (node, showIDs) {
            let nodeEvidence = Node.Evidence.getEvidence(node);
            if (nodeEvidence == null) {
                return null;
            }
            return {
                node: nodeEvidence.handle,
                nodeID: nodeEvidence.nodeID,
                evidence: nodeEvidence.evidence,
                evidenceType: nodeEvidence.evidenceType,
                orderEvidence: nodeEvidence.orderEvidence
            }
        },
        /**
         * Collect all evidence from visible network
         * @returns {Array|Node.Evidence.getAllEvidence.evidenceList}
         */
        getAllEvidence: function (showIDs) {
            var evidenceList = [];
            var selector = Utilities.getGTypeSelector(Node.TYPE);
            if(BAYESBOX_MODE.isDashboard()){
                selector = "div[type=nodeCard]";
            }
            d3.selectAll(selector).each(function (d, i) {
                var evidences = Node.Evidence.getEvidenceData(d, showIDs);
                if(evidences !== null){
                    if(typeof evidences.evidenceType === "undefined") {
                        evidences.evidenceType = "TEMPORAL";
                    }
                    evidenceList.push(evidences);
                }
            });
            //ordering evidences
            evidenceList.sort(function (a, b) {
                return a.orderEvidence - b.orderEvidence;
            });
            for (var i = 0; i < evidenceList.length; i++) {
                delete evidenceList[i].orderEvidence;
            }
            return evidenceList;
        },
        getPropagatedEvidence: function () {
            var propEvNodes = d3.selectAll(Utilities.getGTypeSelector(Node.TYPE)).filter(n=>n.isPropagated);
            var propEvList = [];
            propEvNodes.each(n=>{
                propEvList.push({
                    node: n.handle,
                    evidence: n.propagatedOutcomeIds
                });
            });
            return propEvList;
        },
        /**
         * //remove all evidence information from nodes
         */
        deleteAllEvidenceInfo: function () {
            d3.selectAll(Utilities.getGTypeSelector(Node.TYPE)).each(function (d, i) {
                delete d.isPropagated;
                delete d.values;
            });
        },
        retractLastEvidence: function () {
            var lastOrderEvidence = this.orderEvidence - 1;
            var nodeToRetract = false;
            var selector = Utilities.getGTypeSelector(Node.TYPE);
            if(BAYESBOX_MODE.isDashboard()){
                selector = "div[type=nodeCard]";
            }
            var nodeToRetractD3Self = null;
            d3.selectAll(selector).each(function (d, i, divs) {
                if (d.orderEvidence === lastOrderEvidence) {
                    nodeToRetract = d;
                    nodeToRetractD3Self = d3.select(divs[i]);
                }
            });
            if (nodeToRetract) {
                this.clearEvidence(nodeToRetractD3Self);
                if (!BAYESBOX_MODE.isDashboard()) {
                    this.clearTemporalEvidence(nodeToRetract);
                    EvidenceSummary.removeEvidence(nodeToRetract);
                }
            }
        },
        isVirtualEvidence: function (evidence) {
            return Array.isArray(evidence);
        },
        InputEvidenceModal: {
            inputModal_Selector: "#inputModal",
            evidenceValue_Selector: "#evidenceValue",
            inputValueError_Selector: "#inputValueError",
            clearInputValue_Selector: "#clearInputValue",
            submitInputValue_Selector: "#submitInputValue",
            inputEvidenceNodeID_Selector: "#inputEvidenceNodeID",
            createDialog: function (nodeID, nodeName) {
                d3.selectAll('.dialogInputEv').remove();
                var mainDiv = d3.selectAll('.dialogInputEv')
                        .data([{
                                "nodeID": nodeID,
                                "nodeName": nodeName
                            }])
                        .enter()
                        .append('div')
                        .attr('class', 'dialogInputEv')
                        .append("div")
                        .attr("class", "modal-content modalBayesbox");
                var header = mainDiv.append("div")
                        .attr("class", "modal-header text-center inputModalHeader");
                header.append("h5")
                        .attr("class", "modal-title w-100 font-weight-bold")
                        .text((d) => d.nodeName);
                header.append("button")
                        .attr("type", "button")
                        .attr("class", "close")
                        .attr("aria-label", "Close")
                        .append("span")
                        .attr("aria-hidden", "true")
                        .text("×")
                        .on("click", function () {
                            d3.selectAll('.dialogInputEv').remove();
                        });
                var body = mainDiv.append("form")
                        .attr("onsubmit", "return false")
                        .append("div")
                        .attr("class", "modal-body mx-3")
                        .style("padding-top", ".4rem")
                        .style("padding-bottom", "0")
                        .style("padding-left", "0")
                        .style("padding-right", "0");
                var inputBox = body.append("div")
                        .attr("class", "input-group input-group-sm")
                        .style("margin-bottom:", "0 !important");
                inputBox.append("div")
                        .attr("class", "input-group-prepend")
                        .append("span")
                        .attr("class", "input-group-text")
                        .attr("id", "inputGroup-sizing-sm")
                        .text("Evidence");
                inputBox.append("input")
                        .attr("type", "text")
                        .attr("class", "form-control validate")
                        .attr("id", "evidenceValue")
                        .attr("aria-label", "Evidence input")
                        .attr("aria-describedby", "inputGroup-sizing-sm")
                        .on("keyup", (d) => {
                            if (d3.event.keyCode === 13) {
                                Node.Evidence.InputEvidenceModal.setEv();
                            } else {
                                Node.Evidence.InputEvidenceModal.validateEv();
                            }
                        });
                inputBox.append("input")
                        .attr("type", "hidden")
                        .attr("id", "inputEvidenceNodeID")
                        .attr("value", nodeID);
                body.append("div")
                        .attr("class","md-form input-group input-group-sm")
                        .append("span")
                        .attr("id","inputValueError")
                        .style("color", "red")
                        .style("font-size", "0.7rem")
                        .style("display","none")
                        .text("Invalid value!");
                var footer = mainDiv.append("div")
                        .attr("class", "modal-footer d-flex justify-content-center")
                        .style("border-top", "0");
                footer.append("button")
                        .attr("id", "clearInputValue")
                        .attr("class", () => {
                            if (CustomClass.getModalButtonClass() !== "") {
                                return CustomClass.getModalButtonClass();
                            } else {
                                return "btn btn-secondary btn-sm";
                            }

                        })
                        .on("click", () => Node.Evidence.InputEvidenceModal.clearEv())
                        .text("Clear")
                        .attr("disabled", "");
                footer.append("button")
                        .attr("id", "submitInputValue")
                        .attr("class", () => {
                            if (CustomClass.getModalButtonClass() !== "") {
                                return CustomClass.getModalButtonClass();
                            } else {
                                return "btn btn-secondary btn-sm";
                            }

                        })
                        .on("click", () => Node.Evidence.InputEvidenceModal.setEv())
                        .text("OK")
                        .attr("disabled", "");

            },
            init: function () {
                if (PortraitAlert.isSmallMobile()) {
                    $(this.inputModal_Selector).on('shown.bs.modal', function (e) {
                        document.getElementById('evidenceValue').focus();
                    });
                    var inputModal = document.getElementById("inputModal");
                    inputModal.addEventListener("keydown", function (e) {
                        if (e.keyCode === 13) {
                            Node.Evidence.InputEvidenceModal.setEv();
                        }
                    });
                } else {
                    $(this.inputModal_Selector).remove();
                }
            },
            /**
             * Show window to enter value for equation nodes
             * @param {String} nodeID - node ID
             */
            showEv: function (nodeD3Self, event) {
                var node = nodeD3Self.data()[0];
                var nodeID = Node.getNodeIdFromObject(node);
                var nodeName = node.name;
                if (PortraitAlert.isSmallMobile()) {
                    //removing error info
                    $(this.evidenceValue_Selector).css('border-color', '');
                    $(this.evidenceValue_Selector).css('box-shadow', '');
                    $(this.inputValueError_Selector).css('display', 'none');
                } else {
                    this.createDialog(nodeID, nodeName);
                    var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, ".dialogInputEv", event.pageX, event.pageY);
                    $('.dialogInputEv').css({
                        'left': translate.x,
                        'top': translate.y
                    });
                }

                if (typeof node.evidence !== 'undefined') {
                    $(this.evidenceValue_Selector).val(node.evidence);//setting current value
                    $(this.clearInputValue_Selector).removeAttr('disabled');//disable CLEAR button
                } else {
                    $(this.evidenceValue_Selector).val("");
                    $(this.clearInputValue_Selector).prop('disabled', true);//enable CLEAR button
                    $(this.submitInputValue_Selector).prop('disabled', true);//enable OK button
                }
                $(this.inputEvidenceNodeID_Selector).val(nodeID);
                if (PortraitAlert.isSmallMobile()) {
                    $(".inputModalHeader h5").text(nodeName);
                    $(this.inputModal_Selector).modal('show');
                } else {
                    document.getElementById('evidenceValue').focus();
                }
            },
            /**
             * Set evidence in equation nodes
             */
            setEv: function () {
                $(this.inputModal_Selector).modal('hide');
                const nodeID = $(this.inputEvidenceNodeID_Selector).val();
                let nodeD3Self = d3.select(Network.getSVG_CSS_SELECTOR()).select("#" + nodeID);
                if (BAYESBOX_MODE.isDashboard()){
                    nodeD3Self = d3.select(RightColumn.CSSselector).select("#" + nodeID);
                }
                let evidence = d3.select(this.evidenceValue_Selector).node().value;
                if (!$.isNumeric(evidence)) {
                    return;
                }
                evidence = evidence.replace(",", ".");
                if (evidence.indexOf(".") === -1) {
                    evidence += ".0";
                }
                d3.selectAll('.dialogInputEv').remove();
                Node.Evidence.setEvidence(nodeD3Self, evidence, "CONTINUOUS");
            },
            /**
             * Clear evidence in equation nodes
             */
            clearEv: function () {
                $(this.inputModal_Selector).modal('hide');
                var nodeID = $(this.inputEvidenceNodeID_Selector).val();
                var nodeD3Self = d3.select(Network.getSVG_CSS_SELECTOR()).select("#" + nodeID);
                if (BAYESBOX_MODE.isDashboard()){
                    nodeD3Self = d3.select(RightColumn.CSSselector).select("#" + nodeID);
                }
                d3.selectAll('.dialogInputEv').remove();
                Node.Evidence.clearEvidence(nodeD3Self);
            },
            /**
             * Check input value in equation nodes
             */
            validateEv: function (e) {
                var value = $(this.evidenceValue_Selector + ":visible").val();
                if (!$.isNumeric(value)) {
                    //enable error info
                    $(this.evidenceValue_Selector).css('border-color', 'red');
                    $(this.evidenceValue_Selector).css('box-shadow', '0 0 0 .2rem rgba(255, 0, 0, 0.3)');
                    $(this.inputValueError_Selector).css('display', 'block');
                    //disable OK butto
                    $(this.submitInputValue_Selector).prop('disabled', true);
                } else {
                    //removing error info
                    $(this.evidenceValue_Selector).css('border-color', '');
                    $(this.evidenceValue_Selector).css('box-shadow', '');
                    $(this.inputValueError_Selector).css('display', 'none');
                    //enable OK button
                    $(this.submitInputValue_Selector).removeAttr('disabled');
                }
            }
        },
        InputTemporalEvidenceModal: {
            // Evidence seting modal
            showModal: (node) => {
              if (node.checkType(NODE_TYPE.EQUATION)) {
                  Node.Evidence.InputTemporalEvidenceModal.showContinuousModal(node);
              }  else {
                  Node.Evidence.InputTemporalEvidenceModal.showDiscreteModal(node);
              }
            },
            showDiscreteModal: function (node) {
                var steps = d3.range(0, DynamicBN.stepCount, 1);
                var outcomes = Node.getStateLabels(node);
                var data = steps.map((s)=>{
                    return {
                        time: s,
                        outcome: outcomes
                    };
                });
                d3.select("#DBNEvidenceForm").selectAll(".singleTimeEvidence").remove();
                var mainDiv = d3.select("#DBNEvidenceForm").selectAll(".singleTimeEvidence")
                        .data(data)
                        .enter()
                        .append("div")
                        .attr("class", "input-group input-group-sm mb-0 justify-content-center align-items-center singleTimeEvidence")
                        .style("width", "100%");
                mainDiv.append("div")
                        .attr("class", "input-group-prepend")
                        .style("padding", "0 !important")
                        .style("width", "20%")
                        .append("label")
                        .attr("class", "input-group-text justify-content-center align-items-center form-control form-control-sm orderNumberSytle")
                        .style("width", "100%")
                        .attr("for", d => "inputGroupOrder" + d.time)
                        .append("span")
                        .text(d => d.time);
                mainDiv.append("div")
                        .style("padding", "0 !important")
                        .style("width", "80%")
                        .append("select")
                        .on("change", function () {
                            var n = d3.select(this).data()[0];
                            var optionMainDiv = d3.select("#DBNEvidenceForm").selectAll(".singleTimeEvidence").nodes()[n.time];
                            if (this.value === "(virtual evidence)") {
                                if (d3.select(optionMainDiv).selectAll("#dbnVirtualEvidenceSlice" + n.time).size() > 0) {
                                    return;
                                }
                                var container = d3.select(optionMainDiv).append("div")
                                        .attr("id", "dbnVirtualEvidenceSlice" + n.time)
                                        .style("width", "100%")
                                        .style("margin-left", "20%");
                                Node.Evidence.InputVirtualEvidenceModal.addOutcomes(n.outcome, [], container);
                            } else {
                                d3.select(optionMainDiv).select("#dbnVirtualEvidenceSlice" + n.time).remove();
                            }
                        })
                        .attr("class", "form-control form-control-sm")
                        .style("max-width", "100% !important")
                        .attr("id", d => "inputGroupOrder" + d.time)
                        .each(d => {
                            let selectTag = d3.select("#inputGroupOrder" + d.time);
                            selectTag.append("option").text("");
                            var showVirtualEvidence = false;
                            if (node.checkType([NODE_TYPE.CPT, NODE_TYPE.NOISY_MAX, NODE_TYPE.NOISY_ADDER])){
                                selectTag.append("option").text("(virtual evidence)");
                                showVirtualEvidence = true;
                            }
                            for (var i = 0; i < node.outcome.length; i++) {
                                selectTag.append("option")
                                        .attr("value", node.outcome[i])
                                        .text(node.outcome[i]);
                            }
                            var selectedOrder = -2;
                            if (typeof node.temporalEvidence !== "undefined") {
                                var orderEvidence = node.temporalEvidence.filter(slice => slice[0] === d.time);
                                if (orderEvidence.length === 1) {
                                    selectedOrder = orderEvidence[0][1];
                                } else if (orderEvidence.length > 1) {
                                    console.log("Evidences for one order is more than one!");
                                }
                            }
                            if (Array.isArray(selectedOrder)) {
                                var optionMainDiv = d3.select("#DBNEvidenceForm").selectAll(".singleTimeEvidence").nodes()[d.time];
                                var container = d3.select(optionMainDiv).append("div")
                                        .attr("id", "dbnVirtualEvidenceSlice" + d.time)
                                        .style("width", "100%")
                                        .style("margin-left", "20%");
                                Node.Evidence.InputVirtualEvidenceModal.addOutcomes(d.outcome, selectedOrder, container);
                                $(selectTag.node()).find("option:contains('(virtual evidence)')").attr("selected", "");
                            } else {
                                selectTag.select(`option:nth-child(${showVirtualEvidence ? (selectedOrder + 3) : (selectedOrder + 2)})`).attr("selected", "");
                            }
                        });

                $("#setDBNEvidenceModNodeID").attr("forNodeID", Node.getNodeIdFromObject(node)).text(node.name);
                $('#setDBNEvidenceModal').modal('show');
            },
            showContinuousModal: function (node) {
                const steps = d3.range(0, DynamicBN.stepCount, 1);

                var outcomes = node.outcome;
                var data = steps.map((s)=>{
                    return {
                        time: s,
                        outcome: outcomes
                    };
                });
                d3.select("#DBNEvidenceForm").selectAll(".singleTimeEvidence").remove();
                var mainDiv = d3.select("#DBNEvidenceForm").selectAll(".singleTimeEvidence")
                    .data(data)
                    .enter()
                    .append("div")
                    .attr("class", "input-group input-group-sm mb-0 justify-content-center align-items-center singleTimeEvidence")
                    .style("width", "100%");
                mainDiv.append("div")
                    .attr("class", "input-group-prepend")
                    .style("padding", "0 !important")
                    .style("width", "20%")
                    .append("label")
                    .attr("class", "input-group-text justify-content-center align-items-center form-control form-control-sm orderNumberSytle")
                    .style("width", "100%")
                    .attr("for", d => "inputGroupOrder" + d.time)
                    .append("span")
                    .text(d => d.time);
                mainDiv.append("div")
                    .style("padding", "0 !important")
                    .style("width", "80%")
                    .append("input")
                    .attr("type", "text")
                    .attr("class", "form-control form-control-sm")
                    .style("max-width", "100% !important")
                    .style("text-align", "right")
                    .attr("id", d => "inputGroupOrder" + d.time);

                $("#setDBNEvidenceModNodeID").attr("forNodeID", Node.getNodeIdFromObject(node)).text(node.name);
                $('#setDBNEvidenceModal').modal('show');
            },
            /**
             * OK button support in modal
             * Get evidence from modal and set it
             */
            setTemporalEvidenceFromModal: function () {
                let nodeID = d3.select("#setDBNEvidenceModNodeID").attr("forNodeID");
                let node = d3.select("#" + nodeID).data()[0];
                let evidences = [];
                var allIsValid = true;
                if (node.checkType(NODE_TYPE.EQUATION)) {
                    d3.select("#DBNEvidenceForm").selectAll("input").nodes().filter(n => n.value !== "").forEach(n => {
                        const order = d3.select("#" + n.id).data()[0].time;
                        const evidence = {
                            evidence: n.value,
                            evidenceType: "CONTINUOUS",
                            node: node.handle,
                            nodeID: nodeID
                        }
                        evidences.push({order, evidence});
                    });
                }  else {
                    d3.select("#DBNEvidenceForm").selectAll("select").nodes().filter(n => n.value !== "").forEach(n => {
                        let order = d3.select("#" + n.id).data()[0].time;
                        let evidence = {
                            node: node.handle,
                            nodeID: nodeID
                        };
                        if (n.value === "(virtual evidence)") {
                            let virtualContainer = d3.select("#dbnVirtualEvidenceSlice" + order);
                            var validSumProb = Node.Evidence.InputVirtualEvidenceModal.validSumOfProbabilities(virtualContainer);
                            if (validSumProb) {
                                evidence.evidence = Node.Evidence.InputVirtualEvidenceModal.getVirtualEvidence(virtualContainer);
                                evidence.evidenceType = "VIRTUAL";
                                virtualContainer.select("#inputValueError").classed("d-none", true);
                            } else {
                                allIsValid = false;
                                virtualContainer.select("#inputValueError").classed("d-none", false);
                                virtualContainer.select("#inputValueError").text("Sum of probabilities should be equal to 1.");
                            }
                        } else {
                            const stateLabels = Node.getStateLabels(node);
                            if (stateLabels.length > 0) {
                                for (var h = 0; h < stateLabels.length; h++) {
                                    if (stateLabels[h] === n.value) {
                                        evidence.evidence = h;
                                        evidence.evidenceType = "DISCRETE";
                                    }
                                }
                            }
                        }
                        evidences.push({order, evidence});
                    });
                }

                if (allIsValid) {
                    $('#setDBNEvidenceModal').modal('hide');
                    Node.Evidence.setTemporalEvidence(node, evidences);
                }
            },
            /**
             * Clear All button support in modal
             */
            clearAllTemporalEvidence: function () {
                $('#setDBNEvidenceModal').modal('hide');
                let nodeID = d3.select("#setDBNEvidenceModNodeID").attr("forNodeID");
                let node = d3.select("#" + nodeID).data()[0];
                Node.Evidence.clearTemporalEvidence(node);
            }
        },
        InputVirtualEvidenceModal: {
            addOutcomes: function (outcome, propability, container) {
                var data = [];
                if(propability.length > 0){
                    if(!(outcome.length === propability.length)){
                        console.log("outcome array size is different from probability array sizie");
                        return ;
                    }
                }
                for (var i = 0; i < outcome.length; i++) {
                    if(propability.length !== 0){
                        data.push({
                            outcome: outcome[i],
                            probability: propability[i],
                            container: container
                        });
                    }else{
                        data.push({
                            outcome: outcome[i],
                            probability: null,
                            container: container
                        });
                    }
                }
                container.selectAll("*").remove();;//remove unneeded options
                var mainBody = container.selectAll(".virtualEvidenceOption")
                        .data(data)
                        .enter()
                        .append("div")
                        .attr("class","input-group input-group-sm mb-3 virtualEvidenceOption");
                mainBody.append("div")
                        .attr("class","input-group-prepend")
                        .style("width","calc(50%)")
                        .append("span")
                        .attr("class","input-group-text text-truncate virtualEvidenceLabel")
                        .attr("title", d => d.outcome)
                        .text(d => d.outcome);
                mainBody.append("input")
                        .attr("class","form-control validate inputVirtualEv")
                        .attr("type","text")
                        .attr("aria-label","Evidence input")
                        .attr("value",(d, i, nodes)=>{
                            if(d.probability !== null){
                                return d.probability;
                            }
                            return 1/nodes.length;
                        })
                        .on("keyup", (d) => {
                            if (d3.event.keyCode === 13) {
                                console.log("Enter not implemented yet");
                            } else {
                                let DOMnode = $(d3.event.target);
                                let isNumber = Node.Evidence.InputVirtualEvidenceModal.isNumericVirtualEvidence(DOMnode.val());
                                if (!isNumber) {
                                    DOMnode.css('box-shadow', '0 0 0 .2rem rgba(255, 0, 0, 0.3)');
                                    DOMnode.addClass("incorrect");
                                    d.container.select("#inputValueError").classed("d-none", false);
                                    d.container.select("#inputValueError").text("Please enter a number.");
                                    //disable OK butto
                                    $("#submitVirtualEvidence").prop('disabled', true);
                                } else {
                                    DOMnode.css('box-shadow', '');
                                    DOMnode.removeClass("incorrect");
                                    if(d.container.selectAll(".incorrect").size() === 0){
                                        d.container.select("#inputValueError").classed("d-none", true);
                                        //enable OK butto
                                        $("#submitVirtualEvidence").removeAttr('disabled');
                                    }
                                }
                            }
                        });
                //validate information div
                container.append("div")
                        .attr("class","md-form input-group input-group-sm validateError")
                        .append("span")
                        .attr("id","inputValueError")
                        .attr("class","d-none")
                        .text("Please enter a number.");
            },
            isNumericVirtualEvidence: function (value) {
                return $.isNumeric(value);
            },
            resetValues: function (container) {
                container.selectAll(".inputVirtualEv")
                        .property("value", (d, i, nodes) => {
                            Node.Evidence.InputVirtualEvidenceModal.resetValueAlert(d.container);
                            if (d.probability === null) {
                                return 1 / nodes.length;
                            } else {
                                return d.probability;
                            }
                        });
            },
            resetValueAlert: function (container) {
                container.select("#inputValueError").classed("d-none", true);
                container.selectAll(".incorrect").classed("incorrect", false).style('box-shadow', '');
            },
            validSumOfProbabilities: function (container) {
                var expectValue = 1;
                var epsilon = 1e-6;
                var probSum = Node.Evidence.InputVirtualEvidenceModal.getVirtualEvidence(container)
                        .map(p => Number(p))
                        .reduce((a,b) => a + b, 0);
                return Math.abs(probSum - expectValue) < epsilon;
            },
            getVirtualEvidence: function (container) {
                return container.selectAll("input").nodes().map(function(node){return node.value;});
            }
        }
    },
    addGroupNode: function (dataset, submodel) {
        if (!Array.isArray(dataset)) {
            var oldDataset = d3.select(Network.getSVG_CSS_SELECTOR())
                    .select("#" + Submodel.SUBMODEL_PREFIX + submodel.handle)
                    .selectAll(Utilities.getGTypeSelector(Node.TYPE)).data();
            oldDataset.push(dataset);
            dataset = oldDataset;
        }
        var gNode =  d3.select(Network.getSVG_CSS_SELECTOR())
                .select("#" + Submodel.SUBMODEL_PREFIX + submodel.handle)
                .selectAll(Utilities.getGTypeSelector(Node.TYPE))
                .data(dataset)
                .enter()
                .append("g")//append group which represent node
                .attr('id', d => this.getNodeIdFromObject(d))//node id attr
                .attr('name', function (d) {
                    return d.name;
                })//node name attr
                .attr('type', 'node')
                .attr("style", d => d.italic ? "font-style: italic" : "")
                .each(d => {//type group
                    DynamicBN.detectDynamicNetwork(d);
                    d.checkType = function (t) {
                        return Utilities.checkNodeType(this, t);
                    };
                });
        Arc.stringToReference();
        if (typeof BayesBoxEngine === "undefined") {
            gNode = gNode.on('contextmenu click', contextMenuFactory(function (d) {
                let menu = Submodel.detectSubmodelArrowMenu(this);
                if (!menu) {
                    return Node.rightMenu(d);
                } else {
                    return menu;
                }
            }, {//function(d){return menuDynamic(d);}
                theme: "d3-context-menu-theme-bayesbox",
                onOpen: function (d) {
                    //bolding selected evidence
//                        $(".d3-context-menu > ul > li").filter(function () {
//                            return $(this).text().split(' ')[0] === d.evidence;//split function remove values from evidence name
//                        }).css("font-weight", "bold");
//                        if (d.isPropagated === true) {
//                            $(".d3-context-menu > ul > li").filter(function () {
//                                return $(this).text() === d.propagatedOutcomeIds;
//                            }).css("font-weight", "bold");
//                        }
                    var elements = document.querySelectorAll('.is-children');
                    if (window.IntersectionObserver) {
                        Node.nestetMenuObserver = new IntersectionObserver(function (entries) {
                            entries.forEach(entry => {
                                if (entry.intersectionRatio > 0) {
                                    //vertical position
                                    if (entry.intersectionRect.height !== entry.boundingClientRect.height) {
                                        let margin = 3;
                                        let top = entry.intersectionRect.height - entry.boundingClientRect.height - margin;
                                        entry.target.style.top = top + "px";
                                    }
                                    //horizontal position
                                    if (entry.intersectionRect.width !== entry.boundingClientRect.width) {
                                        entry.target.style.left = "-100%";
                                    }
                                }
                            });
                        }, {});
                        elements.forEach(nestMenu => Node.nestetMenuObserver.observe(nestMenu));
                    }
                },
                onClose: function () {
                    if (window.IntersectionObserver) {
                        Node.nestetMenuObserver.disconnect();
                    }
//                        console.log('closed!');
                },
                position: function (d, elm, i) {
                    //var bounds = elm.getBoundingClientRect();
                    //temporary showing hidden menu for get width and height
                    d3.select('.d3-context-menu').style('opacity', '0');
                    d3.select('.d3-context-menu').style('display', 'block');
                    var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, ".d3-context-menu", d3.event.pageX, d3.event.pageY);
                    //reset menu styles
                    d3.select('.d3-context-menu').style('opacity', null);
                    d3.select('.d3-context-menu').style('display', 'none');
                    return {
                        top: translate.y,
                        left: translate.x
                    };
                }
            }))
                .on('dblclick', function (d) {
                    if (d.isMultidimensional) {
                        $(".d3-context-menu").remove();
                        Node.Outcome.constructMultidimensionalDiv(d);
                        var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, ".multiDDiv", d3.event.pageX, d3.event.pageY);
                        $('.multiDDiv').css({
                            'left': translate.x,
                            'top': translate.y
                        });
                    }
                })
                .on("mouseover", function (d) {//node highlight ON
                    Utilities.highlightOn(this, d.borderWidth);
                })
                .on("mouseout", function (d) {//node highlight OFF
                    Utilities.highlightOff(this, d.borderWidth);
                });
        }
         return gNode;
    },
    /**
     *  Add all nodes to submodel based on json
     * @param {String} jsonData - json data with network
     * @param {Object} submodel - submodel D3 Object
     */
    addNodes: function (jsonData, submodel, groupNode) {
        if(!Submodel.submodelRectangleExist(submodel.handle)){
            Submodel.addSubmodelRectangle(submodel);
        }
        var node = typeof groupNode === "undefined" ? this.addGroupNode(jsonData, submodel) : groupNode;
        /*
         BarChart
         */
        var nodesBarChart = node.filter(d => d.isBarChart);
        NodeShape.BarChart.create(nodesBarChart);
        /*
         * not barchart nodes
         */
        var nodesWihoutBarcharts = node.filter(d => !d.isBarChart);
        //prevent blur
        nodesWihoutBarcharts.each((d, index, divs) => {
            //prevent blur
            //if(Utility || MAU || Decision)
            if (d.checkType([NODE_TYPE.DECISION, NODE_TYPE.UTILITY, NODE_TYPE.MAU])) {
                var preventBlur = NodeShape.BarChart.pixelSnapping({x: 0, y: 0}, d.borderWidth / 2);
                d3.select(divs[index]).attr("transform", Utilities.getTransformString(preventBlur.x, preventBlur.y));
            }
        });
        /*
         * DeMorgan BarChart
         */
        var demorganBarChart = nodesBarChart.filter(d => d.checkType(NODE_TYPE.DEMORGAN));
        NodeShape.BarChart.Decorator.deMorganColor(demorganBarChart);
        //add ellipse for single group
        /*
         Chance - General
         Chance - NoisyMax
         Chance - NoisyAdder
         Deterministic part 1 (one border)
         */
        var nodeEllipse = nodesWihoutBarcharts.filter(d => d.checkType([NODE_TYPE.CPT, NODE_TYPE.NOISY_MAX, NODE_TYPE.NOISY_ADDER, NODE_TYPE.TRUTH_TABLE, NODE_TYPE.EQUATION, NODE_TYPE.DEMORGAN]));
        NodeShape.Ellipse.create(nodeEllipse);

        /*
         Deterministic part 2 (double border) AND Equation deterministic
         */
        var additionalInnerEllipse = nodesWihoutBarcharts.filter(function (d) {
            return (d.checkType(NODE_TYPE.TRUTH_TABLE)) ||
                    (d.checkType(NODE_TYPE.EQUATION) && d.isDeterministicEquation);
        });
        NodeShape.Ellipse.Decorator.innerEllipse(additionalInnerEllipse);

        /*
         Equation CONSTANT
         */
        var eqConstant = nodesWihoutBarcharts.filter(function (d) {
            return d.checkType(NODE_TYPE.EQUATION) && d.isConstantEquation;
        });
        NodeShape.Ellipse.Decorator.equationTextConstant(eqConstant);

        /*
         Equation non const
         */
        var eqNonConst = nodesWihoutBarcharts.filter(function (d) {
            return d.checkType(NODE_TYPE.EQUATION) && !d.isConstantEquation;
        });
        NodeShape.Ellipse.Decorator.sinusSymbolEquationDeterministic(eqNonConst);
        /*
         * DeMorgan
         */
        var deMorganEllipse = nodesWihoutBarcharts.filter(d => d.checkType(NODE_TYPE.DEMORGAN));
        NodeShape.Ellipse.Decorator.deMorganEllipse(deMorganEllipse);
        /*
         Decision
         */
        var decisionNodes = nodesWihoutBarcharts.filter(d => d.checkType(NODE_TYPE.DECISION));
        NodeShape.Rectangle.create(decisionNodes);

        /*
         Utility
         NODE_TYPE.MAU
         */
        var utilityNodes = nodesWihoutBarcharts.filter(d => d.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU]));
        NodeShape.Polygon.create(utilityNodes);

        Node.addDescriptions(jsonData, submodel);

        //add drag and drop functions
        /*
         OFF FOR RIGHT CLICK ON NODE TO SELECT SET EVIDENCE MENU
         node.call(d3.drag()
         .on("start", DragNode.dragstarted)
         .on("drag", DragNode.dragged)
         .on("end", DragNode.dragended));
         */
    },
    blinkNode: function (node) {
        var submodel = Submodel.BFSTree(node.handle, Submodel.MAIN_SUBMODEL);
        Submodel.viewSubmodel(submodel.handle);
        let nodeD3 = Node.selectNodeFromObject(node);
        ScrollBar.schowDOMElement(nodeD3.node());
        this.alphaColor(nodeD3);
    },
    blinkElement: function (elm, targetElm) {
        if(elm.parentElement === null){
            console.error("Blink element isn't in submodel group");
            return;
        }
        targetElm = typeof targetElm === "undefined" ? elm : targetElm;
        let submodel = d3.select(elm.parentElement).data()[0];
        if(typeof submodel !== "undefined" && typeof submodel.submodelOfSubmodel !== "undefined"){
            Submodel.viewSubmodel(submodel.handle);
            ScrollBar.schowDOMElement(targetElm);
            this.alphaColor(d3.select(targetElm));
        } else {
            return this.blinkElement(elm.parentElement, targetElm);
        }
    },
    alphaColor: function (nodeD3) {
        if (d3.select(Network.getSVG_CSS_SELECTOR()).select("#colorAlpha").size() === 0) {
            d3.select(Network.getSVG_CSS_SELECTOR())
                    .append("filter")
                    .attr("id", "colorAlpha")
                    .append("feColorMatrix")
                    .attr("in", "SourceGraphic")
                    .attr("type", "luminanceToAlpha");
        }

        var delay = 300;
        nodeD3.transition().delay(delay).attr("filter", "url(#colorAlpha)")
                .transition().delay(delay).attr("filter", "")
                .transition().delay(delay).attr("filter", "url(#colorAlpha)")
                .transition().delay(delay).attr("filter", "")
                .transition().delay(delay).attr("filter", "url(#colorAlpha)")
                .transition().delay(delay).attr("filter", "")
                .transition().delay(delay).attr("filter", "url(#colorAlpha)")
                .transition().delay(delay).attr("filter", "");
    },
    /**
     * @private
     * add node description
     * @param {String} jsonData - json with nodes
     */
    addDescriptions: function (jsonData, submodel) {
        jsonData = Array.isArray(jsonData) ? jsonData : [jsonData];
        var textData = [];
        for (var s = 0; s < jsonData.length; s++) {
            var data = jsonData[s];
            if (data.isBarChart === false) {
                var fontSize = typeof data.fontSize !== "undefined" ? data.fontSize : NodeShape.FONT_SIZE;
                var name = data.name;
                var coordinate;
                var marginLeftRight = -4;
                let rawNode = this.selectNodeFromObject(data);
                let node = rawNode.data()[0];
                let textStyle = Utilities.getFontStyles(fontSize, node.bold);
                coordinate = NodeShape.getMaxPlace(node.flyweight.candidateRect, data.x, data.y, data.width, data.height, NodeShape.TEXT_LINE_HEIGHT, name, marginLeftRight, textStyle);
                //create node name based on new name (with extra spaces)
                var textWidth = coordinate.w - marginLeftRight;
                var singleTextData = {
                    "text": Utilities.addBreakLines(coordinate.lines),
                    "name": data.name
                };
                let position = {
                    x: coordinate.x + marginLeftRight / 2,
                    y: coordinate.y,
                    width: textWidth,
                    height: coordinate.h
                };
                var idFunction = () => Utilities.getValidId(this.getNodeIdFromObject(data));
                singleTextData.position = position;
                singleTextData.idFunction = idFunction;
                singleTextData.textStyle = textStyle;
                singleTextData.data = data;
                singleTextData.node = rawNode;
                singleTextData.moveToNodeGroup = true;
                textData.push(singleTextData);
            }
        }

        let textGroupTMPid = Utilities.getRandomId();
        let textGroupTMP = d3.select("#" + Submodel.SUBMODEL_PREFIX + submodel.handle)
                .append("g")
                .attr("id", textGroupTMPid);

        Utilities.addTextBox(textData,
                "middle",
                "middle",
                {
                    x: d => d.position.x,
                    y: d => d.position.y,
                    width: d => d.position.width,
                    height: d => d.position.height
                },
                {
                    "font-size": d => d.textStyle["font-size"],
                    "line-height": d => d.textStyle["line-height"],
                    "font-weight": d => d.textStyle["font-weight"]
                },
                d => d.data.textColor,
                "#" + textGroupTMPid,
                d => Utilities.getValidId(Node.getNodeIdFromObject(d.data)));
        // move text from submodel inside node
        d3.selectAll(".d3plus-textBox").filter(d => d.data.moveToNodeGroup)
                .each(function (d) {
                    delete d.data.moveToNodeGroup;
                    d.data.node.append(() => this).datum(d);
                });
        textGroupTMP.remove();
    },
    /**
     * Create new node map
     */
    remapHandleNodes: function () {
        var selector = Utilities.getGTypeSelector(Node.TYPE);
            if(BAYESBOX_MODE.isDashboard()){
                selector = "div[type=nodeCard]";
            }
        this.mappingNodes2D = new Map();
        d3.selectAll(selector).each((d, i) => {
            if (this.mappingNodes2D.has(d.handle)) {
                let mapWithTheSameHandle = this.mappingNodes2D.get(d.handle);
                let netHandle = d.network ? d.network : Network.getCurrentNetwork();
                mapWithTheSameHandle.set(netHandle, this.getNodeIdFromObject(d));
            } else {
                let handleMap = new Map();
                let netHandle = d.network ? d.network : Network.getCurrentNetwork();
                this.mappingNodes2D.set(d.handle, handleMap.set(netHandle, this.getNodeIdFromObject(d)));
            }
        });
    },
    getNodeIdFromHandle: function (nodeHandle, networkHandle) {
        networkHandle = typeof networkHandle === "undefined" && BAYESBOX_MODE.isGraph() ?  Network.getCurrentNetwork() : networkHandle;
        try {
            var ret = this.mappingNodes2D.get(nodeHandle).get(networkHandle);
            if(ret === undefined){
                throw new Error(`Node with node handle: ${nodeHandle} and network handle: ${networkHandle} doesn't exist.`);
            }
            return ret;
        } catch (e) {
            console.error(e.message);
        }
    },
    getMirrorNodes: function (original_id) {
        return d3.selectAll("div[type=nodeCard]").filter(d => d.id === original_id).data();
    },
};

export default Node;
