/* global MODE */

//node type from jSMILE
const NODE_TYPE = {
    CPT: 18, //Chance-general
    TRUTH_TABLE: 20, //Deterministic
    DECISION: 17, //Decision
    UTILITY: 8, //Utility
    MAU: 520, //Mau
    NOISY_MAX: 146, //Chance-NoisyMax
    NOISY_ADDER: 274, //Chance-NoisyAdder
    EQUATION: 4, //Equation
    DEMORGAN: 82//DeMorgan
};
// Temporal (DBN) node types
const NODE_TEMPORAL_TYPE = {
    CONTEMPORAL: 0,
    ANCHOR: 1,
    TERMINAL: 2,
    PLATE: 3
};
const DEMORGAN_PARENT_TYPE = {
    INHIBITOR: 0,
    REQUIREMENT: 1,
    CAUSE: 2,
    BARRIER: 3,
    getName: function (type) {
        switch (type) {
            case this.INHIBITOR:
                return "Inhibitor";
            case this.REQUIREMENT:
                return "Requirement";
            case this.CAUSE:
                return "Cause";
            case this.BARRIER:
                return "Barrier";
            default:
                console.error(`DeMorgan type ${type} don't exist`);
                return "";
        }
    }
};
//indexes mapping for update response
const RESPONSE_MAPPING = {
    U_VALUE: 0,
    U_IS_PROPAGATED: 1,
    U_INDEXING_PARENTS: 2,
    U_IS_DISCRETIZED: 3,
    U_DISCRETIZATION_INTERVAL: 4,
    U_RANGE: 5
};

const CASE_MANAGER_STATUS = {
    DISABLED: "DISABLED",
    ENABLED_ALL: "ENABLED_ALL",
    ENABLED_AUTHENTICATED: "ENABLED_AUTHENTICATED"
};

const BAYESBOX_MODE = {
    isDashboard: function () {
        return (typeof MODE !== "undefined") && (MODE === this.GAUGES);
    },
    isGraph: function () {
        return (typeof MODE !== "undefined") && (MODE === this.GRAPH);
    },
    isAdminPage: function () {
        return !this.isDashboard() && !this.isGraph();
    },
    GAUGES: "GAUGES",
    GRAPH: "GRAPH"
};
//card types from 'Add cards' modal
//saved in DB
const CARDS_TYPE = {
    TEXT: 1,
    TOPN: 2,
    SINGLE_STATE_NODE: 3,
    DISTRIBUTION: 4,
    CUMULATIVE: 5,
    UTILITY: 6
};
//how the node is displayed
//not saved in DB
const DISPLAY_NODE_TYPE = {
    RADIAL: 1,
    TABLE: 2,
    CONSTANT: 3,
    HORIZONTAL_BAR_PLOT: 4,
    HISTOGRAM: 5,
    PIE_CHART: 6,
    LINE_CHART: 7,
    AREA_CHART: 8
};

const LABEL_OPTION = {
    NODE_STATE: 0,
    NODE: 1,
    STATE: 2
};

const NODE_DIAG_TYPE = {
    FAULT: 0,
    OBSERVATION: 1,
    AUXILIARY: 2
};
const MIN_DBL = 4.450147717014403E-308;

const DEMORGAN_SLIDERS_DEF_COLORS = {
    LEFT: "#ff0000",
    CENTER: "#ffffff",
    RIGHT: "#00ff00"
};

const AUTHENTICATION_TYPE = {
    NONE: "NONE",
    ADMIN: "ADMIN",
    FULL: "FULL"
};

const DECIMALS_IN_CHANCE_NODE_BARCHARTS = 2;

export { NODE_TYPE,
    NODE_TEMPORAL_TYPE,
    DEMORGAN_PARENT_TYPE,
    RESPONSE_MAPPING,
    CASE_MANAGER_STATUS,
    BAYESBOX_MODE,
    CARDS_TYPE,
    DISPLAY_NODE_TYPE,
    LABEL_OPTION,
    NODE_DIAG_TYPE,
    MIN_DBL,
    DEMORGAN_SLIDERS_DEF_COLORS,
    AUTHENTICATION_TYPE,
    DECIMALS_IN_CHANCE_NODE_BARCHARTS
};
