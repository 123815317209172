/* global Cases */
import Cases from "./cases";
import Request from "./request";

var OAuth = (function () {
    const OAUTH_BTNS = new Map([["g", ".btn-google"],["i", ".btn-linkedin"], ["m", ".btn-microsoft"], ["f", ".btn-facebook"]]);

    function showOauthButtons($domElement, clientsList, callbackAfterCasesLoaded) {
        for (var i = 0; i < clientsList.length; i++) {
            let btnClass = OAUTH_BTNS.get(clientsList[i]);

            $domElement.find(btnClass).removeClass("d-none");
            $domElement.find(btnClass).off("click");
            $domElement.find(btnClass).on("click", {client: clientsList[i], callback: callbackAfterCasesLoaded}, loginFunction);
        }
        if (clientsList.length === 0) {
            $domElement.addClass("d-none");
        } else {
            $domElement.removeClass("d-none");
        }
    }
    function loginFunction(event) {
        let cases = Cases.constructCaseObject(true, "name", "category", "description");
        let body = JSON.stringify({
            cases: cases,
            callback: event.data.callback
        });
        let done = function (data) {
            if (data.redirect) {
                // data.redirect contains the string URL to redirect to
                window.location.href = data.redirect;
            }
        };
        let fail = function (e) {
            console.error(e);
        };
        Request.oauthInit(event.data.client, body, done, fail);
    }
    return {
        showButtons: function ($domElement, clientList, callbackAfterCasesLoaded) {
            showOauthButtons($domElement, clientList, callbackAfterCasesLoaded);
        }
    };
})();

export default OAuth;
