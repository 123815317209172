const WarningCloseBrowser = (function () {

    function callback(event) {
        event.preventDefault();
        event.returnValue = true;
    }

    function removeBeforeunloadEvent() {
        window.removeEventListener('beforeunload', callback);
    }

    function addBeforeunloadEvent() {
        window.addEventListener('beforeunload', callback);
    }

    return {
        enable: function () {
            removeBeforeunloadEvent();
            addBeforeunloadEvent();
        },
        disable: function () {
            removeBeforeunloadEvent();
        }
    };
})();

export default WarningCloseBrowser;
