var Cooke = (function () {
    function set_cookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        add(cname, cvalue, expires);
    }

    function set_cookie_milliseconds(cname, cvalue, milliseconds) {
        var d = new Date();
        d.setTime(d.getTime() + milliseconds);
        var expires = "expires=" + d.toUTCString();
        add(cname, cvalue, expires);
    }

    function set_session_cooke(cname, cvalue) {
        document.cookie = cname + "=" + cvalue + ";path=/;samesite=strict";
    }

    function add(cname, cvalue, expires) {
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;samesite=strict";
    }

    function get_cookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function check_cookie(name) {
        var user = get_cookie(name);
        if (user !== "") {
            return true;
        } else {
            return false;
        }
    }
    return {
        setCookie: function (cname, cvalue, exdays) {
            set_cookie(cname, cvalue, exdays);
        },
        setCookieMilliseconds: function (cname, cvalue, milliseconds) {
            set_cookie_milliseconds(cname, cvalue, milliseconds);
        },
        setSessionCooke: function (cname, cvalue) {
            set_session_cooke(cname, cvalue);
        },
        getCookie: function (name) {
            return get_cookie(name);
        },
        checkCookie: function (name) {
            return check_cookie(name);
        },
        deleteCooke: function (name) {
            set_cookie(name, "", -10);
        }
    };
})();

export default Cooke;
