/* global d3 */

import 'jquery-ui-dist/jquery-ui';
import * as d3 from 'd3';

var Toasts = (function () {
    var toastsContainer = null;
    var properties = {
        leftIcon: "fa fa-info"
    };
    function init() {
        if (toastsContainer !== null) {
            return;
        }
        toastsContainer = d3.select("body")
                .append("div")
                .lower()
                .attr("aria-live", "polite")
                .attr("aria-atomic", "true")
                .style("position", "fixed")
                .style("z-index", "9999999999")
                .style("margin-left", "50vw")
                .style("transform", "translateX(-50%)")
                .style("border", "0")
                .append("div")
                .style("display", "inline-block");
    }
    ;

    function add(title, text, textStyles) {
        var toastBody = toastsContainer.append("div")
                .classed("toast fade hide", true)
                .attr("role","alert")
                .attr("aria-live","assertive")
                .attr("aria-atomic","true");
        var toastHeader = toastBody.append("div")
                .classed("toast-header", true);
        toastHeader.append("i")
                .classed(properties.leftIcon, true)
                .attr("aria-hidden", "true")
                .style("margin-right",".7rem");
        toastHeader.append("strong")
                .classed("mr-auto", true)
                .text(" " + title);
//        toastHeader.append("small")
//                .classed("text-muted", true)
//                .text("just now");
        toastHeader.append("button")
                .attr("type", "button")
                .classed("ml-2 mb-1 close",true)
                .attr("data-dismiss","toast")
                .attr("aria-label","Close")
                .append("span")
                .attr("aria-hidden","true")
                .text("×");
        var textDiv = toastBody.append("div")
                .classed("toast-body", true)
                .text(text);
        if (typeof textStyles !== "undefined") {
            for (const [key, value] of Object.entries(textStyles)) {
                textDiv.style(key, value);
            }
        }
        $(toastBody.node()).on('hidden.bs.toast', function () {
            $(this).remove();
        });
    }
    function show(toastConfig) {
        var config = {
            animation: true,
            autohide: true,
            delay: 2000
        };
        var prop = undefined;
        for (prop in toastConfig) {
            config[prop] = toastConfig[prop];
        }
        $('.toast').toast(config);
        $('.toast').toast('show');
    }
    return {
        init: function () {
            init();
        },
        add: function (title, text, toastConfig, textStyles) {
            add(title, text, textStyles);
            show(toastConfig);
        }
    };
})();

export default Toasts;
