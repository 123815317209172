var Blur = (function () {
    var id = 'blurMask';

    function offUI() {
        if(document.getElementById(id) !== null){
            return;
        }
        var divBlur = document.createElement('div');
        divBlur.className = 'd-flex align-self-center justify-content-center';
        divBlur.id = id;
        divBlur.style.width = "100%";
        divBlur.style.height = "100%";
        divBlur.style['z-index'] = "20000";
        divBlur.style.opacity = "0.5";
        divBlur.style.background = "black";
        divBlur.style.color = "white";
        divBlur.style['font-size'] = "2rem";
        divBlur.style.position = "fixed";

        var spinnerContainer = document.createElement('div');
        spinnerContainer.className = 'align-self-center';
        var spinner = document.createElement('i');
        spinner.className = 'fas fa-spinner fa-pulse fa-3x';

        spinnerContainer.appendChild(spinner);
        divBlur.appendChild(spinnerContainer);
        document.body.prepend(divBlur);
    }

    function onUI() {
        document.getElementById(id).remove();
    }
    return {
        disableUI: function() {
            offUI();
        },
        enableUI: function() {
            onUI();
        },
        isBlured: function () {
            return document.getElementById(id) !== null;
        }
    };
})();

export default Blur;
