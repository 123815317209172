import 'jquery-ui-dist/jquery-ui';
import RightColumn from "./rightColumn";
import Network from "./network";
import Utilities from "./utilities";
import ScrollBar from "./scrollBar";
import LeftColumn from "./leftColumn";

/* global RightColumn, Network, Utilities, ScrollBar, LeftColumn */

var HideColumns = {
    _showLeft_: false,
    _showRight_: false,
    mouse_position: null,
    recalculateLeftScroll: false,
    recalculateRightScroll: false,

    get showLeft() {
        return this._showLeft_;
    },
    set showLeft(show) {
        this._showLeft_ = typeof show !== 'boolean' ? this._showLeft_ : show;
        var duration = 0.0000001;// 0 don't work
        this._showLeft_ ? this.openLeft(duration) : this.closeLeft(duration);
        this.recalculateLeftScroll = !this._showLeft_;
    },
    get showRight() {
        return this._showRight_;
    },
    set showRight(show) {
        this._showRight_ = typeof show !== 'boolean' ? this._showRight_ : show;
        var duration = 0.0000001;// 0 don't work
        this._showRight_ ? this.openRight(duration) : this.closeRight(duration);
        this.recalculateRightScroll = !this._showRight_;
    },
    /**
     * Show right column
     * @param {String | Number} duration - how long the animation will run
     */
    openRight: function (duration) {
        duration = typeof duration !== 'undefined' ? duration : 250;
        this._showRight_ = true;
        $(RightColumn.CSSselector).show("slide", {direction: "right"}, duration, () => {
            $("#openBtnRight").hide();
            var colMdFraction = 8 + 2 * HideColumns.getHideColumnCount();
            $(`#${Network.getSvgContentId()}`).removeClass("col-10");
            $(`#${Network.getSvgContentId()}`).removeClass("col-12");
            $(`#${Network.getSvgContentId()}`).addClass("col-" + colMdFraction.toString());
            HideColumns.positionRight();
            Utilities.updateSVGContentRect();
            Utilities.updateSVGPlateSize();
            if(HideColumns.recalculateRightScroll){
                HideColumns.recalculateRightScroll = false;
                ScrollBar.recalculate(3);
            }
        });
    },
    /**
     * Hide right column
     * @param {String | Number} duration - how long the animation will run
     */
    closeRight: function (duration) {
        duration = typeof duration !== 'undefined' ? duration : 250;
        this._showRight_ = false;
        $(RightColumn.CSSselector).hide("slide", {direction: "right"}, duration, () => {
            $("#closeBtnRight").hide();
            //$("#openBtnRight").show();
            var colMdFraction = 8 + 2 * HideColumns.getHideColumnCount();
            $(`#${Network.getSvgContentId()}`).removeClass("col-8");
            $(`#${Network.getSvgContentId()}`).removeClass("col-10");
            $(`#${Network.getSvgContentId()}`).addClass("col-" + colMdFraction.toString());
            Utilities.updateSVGContentRect();
            Utilities.updateSVGPlateSize();
        });
    },
    /**
     * Show left column
     * @param {String | Number} duration - how long the animation will run
     */
    openLeft: function (duration) {
        duration = typeof duration !== 'undefined' ? duration : 250;
        this._showLeft_ = true;
        $(LeftColumn.getCSSSelector()).show("slide", {}, duration, () => {
            $("#openBtnLeft").hide();
            var colMdFraction = 8 + 2 * HideColumns.getHideColumnCount();
            $(`#${Network.getSvgContentId()}`).removeClass("col-10");
            $(`#${Network.getSvgContentId()}`).removeClass("col-12");
            $(`#${Network.getSvgContentId()}`).addClass("col-" + colMdFraction.toString());
            HideColumns.positionLeft();
            Utilities.updateSVGContentRect();
            Utilities.updateSVGPlateSize();
            if(HideColumns.recalculateLeftScroll){
                HideColumns.recalculateLeftScroll = false;
                ScrollBar.recalculate(1);
            }
        });
    },
    /**
     * Hide left column
     * @param {String | Number} duration - how long the animation will run
     */
    closeLeft: function (duration) {
        duration = typeof duration !== 'undefined' ? duration : 250;
        this._showLeft_ = false;
        $(LeftColumn.getCSSSelector()).hide("slide", {}, duration, () => {
            $("#closeBtnLeft").hide();
            //$("#openBtnLeft").show();
            var colMdFraction = 8 + 2 * HideColumns.getHideColumnCount();
            $(`#${Network.getSvgContentId()}`).removeClass("col-8");
            $(`#${Network.getSvgContentId()}`).removeClass("col-10");
            $(`#${Network.getSvgContentId()}`).addClass("col-" + colMdFraction.toString());
            Utilities.updateSVGContentRect();
            Utilities.updateSVGPlateSize();
        });
    },
    /**
     * Update position of left and right column
     */
    position: function () {
        this.positionRight();
        this.positionLeft();
    },
    /**
     * Set position of left column using jquery-ui
     */
    positionLeft: function () {
        $("#closeBtnLeft").position({
            of: $(LeftColumn.getCSSSelector()),
            my: "left center",
            at: "right center",
            collision: "flip flip"
        });
    },
    /**
     * Set position of right column using jquery-ui
     */
    positionRight: function () {
        $("#closeBtnRight").position({
            of: $(RightColumn.CSSselector),
            my: "right center",
            at: "left center",
            collision: "flip flip"
        });
    },
    /**
     * Get number of hidden columns
     * @returns {Number}
     */
    getHideColumnCount: function () {
        var count = 0;
        if(!this._showLeft_){
            count++;
        }
        if(!this._showRight_){
            count++;
        }
        return count;
    },
    hideAllButtons: function () {
        if ($("#closeBtnRight").is(":visible")) {
            $("#closeBtnRight").hide();
        }
        if ($("#closeBtnLeft").is(":visible")) {
            $("#closeBtnLeft").hide();
        }
        if ($("#openBtnLeft").is(":visible")) {
            $("#openBtnLeft").hide();
        }
        if ($("#openBtnRight").is(":visible")) {
            $("#openBtnRight").hide();
        }
    },
    /**
     * slider buttons
     * @param {boolean} schowLeft - show button for hiding left column
     * @param {boolean} schowRight - show button for hiding right column
     */
    showButtonsForHiding: function (schowLeft, schowRight) {
        $(document).on("mousemove", function (e) {
            if (schowLeft) {
                //button open left
                if (HideColumns._showLeft_ === false) {
                    HideColumns.mouse_position = e.clientX;
                    if (HideColumns.mouse_position <= 100) {
                        if (!$("#openBtnLeft").is(":visible")) {
                            $("#openBtnLeft").show();
                        }
                    } else if (HideColumns.mouse_position > 100) {
                        if ($("#openBtnLeft").is(":visible")) {
                            $("#openBtnLeft").hide();
                        }
                    }
                } else {
                    if ($("#openBtnLeft").is(":visible")) {
                        $("#openBtnLeft").hide();
                    }
                }
                //button close left
                if (HideColumns._showLeft_ === true) {
                    HideColumns.mouse_position = e.clientX;
                    if (HideColumns.mouse_position < ($(LeftColumn.getCSSSelector()).width() + 100)) {
                        if (!$("#closeBtnLeft").is(":visible")) {
                            $("#closeBtnLeft").show();
                            HideColumns.positionLeft();
                        }
                    } else {
                        if ($("#closeBtnLeft").is(":visible")) {
                            $("#closeBtnLeft").hide();
                        }
                    }
                } else {
                    if ($("#closeBtnLeft").is(":visible")) {
                        $("#closeBtnLeft").hide();
                    }
                }
            }
            if (schowRight) {
                //button open right
                if (HideColumns._showRight_ === false) {
                    HideColumns.mouse_position = $(document).width() - e.clientX;
                    if (HideColumns.mouse_position <= 100) {
                        if (!$("#openBtnRight").is(":visible")) {
                            $("#openBtnRight").show();
                        }
                    } else if (HideColumns.mouse_position > 100) {
                        if ($("#openBtnRight").is(":visible")) {
                            $("#openBtnRight").hide();
                        }
                    }
                } else {
                    if ($("#openBtnRight").is(":visible")) {
                        $("#openBtnRight").hide();
                    }
                }
                //button close right
                if (HideColumns._showRight_ === true) {
                    HideColumns.mouse_position = $(document).width() - e.clientX;
                    if (HideColumns.mouse_position < ($(RightColumn.CSSselector).width() + 100)) {
                        if (!$("#closeBtnRight").is(":visible")) {
                            $("#closeBtnRight").show();
                            HideColumns.positionRight();
                        }
                    } else {
                        if ($("#closeBtnRight").is(":visible")) {
                            $("#closeBtnRight").hide();
                        }

                    }
                } else {
                    if ($("#closeBtnRight").is(":visible")) {
                        $("#closeBtnRight").hide();
                    }
                }
            }
        });
        $(window).on("orientationchange", function (event) {
            HideColumns.hideAllButtons();
        });
    }
};

export default HideColumns;
