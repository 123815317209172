import Utilities from "./utilities";

/* global Utilities, d3plus */

var Panels = (function () {
    var buttomTopRowMargin = 1;
    /**
     * nodeParameters = {
     *      x:,
     *      y:,
     *      width:,
     *      height:,
     *      border:,
     *      padding:,
     *      fontStyles: {
     *          "font-size":,
                "line-height":,
                "font-family":
     *      }
     * }
     */
    function fourPanels(nodeParameters, rowCount, symbolWidth) {
        if (rowCount == null) {//== is IMPORTANT
            rowCount = 0;
        }
        var template = Utilities.deepCopy(nodeParameters);
        template.buttomTopRowMargin = buttomTopRowMargin;
        template.rowHeight = template.fontStyles["line-height"] + buttomTopRowMargin;
        var h = calcMinHeight(template, rowCount);
        template.resizeH = false;
        if (h > template.height) {
            template.height = h;
            template.resizeH = true;
        }
        //order is important!
        template.leftBottomPanel = leftBottomPanelUsingPlace(template, rowCount);
        template.rightBottomPanel = rightBottomPanelUsingPlace(template);
        template.leftTopPanel = leftTopPanelUsingPlace(template, symbolWidth);
        template.rightTopPanel = rightTopPanelUsingPlace(template);
        template.horizontalLine = horizontalLine(template);
        template.verticalLine = verticalLine(template);

        return template;
    };
    function threePanels(nodeParameters, rowCount, symbolWidth, isConstantEquation) {
        var template = Utilities.deepCopy(nodeParameters);
        template.buttomTopRowMargin = buttomTopRowMargin;
        template.rowHeight = template.fontStyles["line-height"] + buttomTopRowMargin;
        //set new row count for histogram
        if (!isConstantEquation) {// rowCount from args is important in this section!
            rowCount = Math.floor(template.fontStyles["font-size"] / 2);
        }
        var h = calcMinHeight(template, rowCount);
        template.resizeH = false;
        if (h > template.height) {
            template.height = h;
            template.resizeH = true;
        }
        //order is important!
        template.bottomPanel = bottomPanelUsingPlace(template, rowCount);
        template.leftTopPanel = leftTopPanelUsingPlace(template, symbolWidth);
        template.rightTopPanel = rightTopPanelUsingPlace(template);
        template.horizontalLine = horizontalLine(template);

        return template;
    }
    function horizontalLine(template) {
        var horizontalL = {
            x1: template.x,
            y1: template.y + template.padding * 2 + template.leftTopPanel.height,
            x2: template.x + template.width,
            y2: template.y + template.padding * 2 + template.leftTopPanel.height
        };
        return horizontalL;
    };
    function verticalLine(template) {
        var verticalL = {
            x1: template.horizontalLine.x1 + template.padding * 2 + template.leftBottomPanel.width,
            y1: template.horizontalLine.y1,
            x2: template.horizontalLine.x1 + template.padding * 2 + template.leftBottomPanel.width,
            y2: template.y + template.height
        };
        return verticalL;
    }
    function bottomPanelUsingPlace(template, rowCount) {
        var usingHeight = getBottomPanelsUsingHeight(template, rowCount);
        var panel = {
            x: template.x + template.border + template.padding,
            y: template.y + template.height - template.border - template.padding - usingHeight,
            height: usingHeight,
            width: template.width - template.border * 2 - template.padding * 2,
            rows: []
        };
        for (var i = 0; i < rowCount; i++) {
            var y = panel.y + template.rowHeight * i;
            panel.rows[i] = {
                x: panel.x,
                y: y,
                width: panel.width,
                height: template.rowHeight
            };
        }
        return panel;
    }
    function leftBottomPanelUsingPlace(template, rowCount) {
        var usingHeight = getBottomPanelsUsingHeight(template, rowCount);
        var panel = {
            x: template.x + template.border + template.padding,
            y: template.y + template.height - template.border - template.padding - usingHeight,
            height: usingHeight,
            width: (template.width - template.border * 2 - template.padding * 4) / 2, //temporal
            rows: []
        };
        for (var i = 0; i < rowCount; i++) {
            var y = panel.y + template.rowHeight * i;
            panel.rows[i] = {
                x: panel.x,
                y: y,
                width: panel.width,
                height: template.rowHeight
            };
        }
        return panel;
    };
    /*
     * !Important! template - with 'leftBottomPanel' list from leftBottomPanelUsingPlace()
     */
    function rightBottomPanelUsingPlace(template) {
        var panel = {
            x: template.leftBottomPanel.x + template.leftBottomPanel.width + template.padding * 2,
            y: template.leftBottomPanel.y,
            width: template.width - template.leftBottomPanel.width - template.border * 2 - template.padding * 4,
            height: template.leftBottomPanel.height,
            rows: []
        };
        for (var i = 0; i < template.leftBottomPanel.rows.length; i++) {
            panel.rows[i] = {
                x: panel.x,
                y: template.leftBottomPanel.rows[i].y,
                width: panel.width,
                height: template.rowHeight
            };
        }
        return panel;
    };
    /*
     * !Important! template - with 'leftBottomPanel' or 'bottomPanel'
     */
    function leftTopPanelUsingPlace(template, symbolWidth) {
        var panel = {
            x: template.x + template.padding + template.border,
            y: template.y + template.padding + template.border,
            width: symbolWidth,
            height: getTopPanelsUsingHeight(template),
            rows: []
        };
        var rowCount = Math.floor(panel.height / template.rowHeight);
        var centerY = (panel.height - rowCount * template.rowHeight) / 2;
        for (var i = 0; i < rowCount; i++) {
            panel.rows[i] = {
                x: panel.x,
                y: panel.y + i * template.rowHeight + centerY,
                width: panel.width,
                height: template.rowHeight
            };
        }
        return panel;
    }
    /*
     * !Important! template - with 'leftTopPanel' list from leftBottomPanelUsingPlace()
     */
    function rightTopPanelUsingPlace(template) {
        var panel = {
            x: template.leftTopPanel.x + template.leftTopPanel.width + template.padding * 2,
            y: template.leftTopPanel.y,
            width: template.width - template.leftTopPanel.width - template.border * 2 - template.padding * 4,
            height: template.leftTopPanel.height,
            rows: []
        };


        for (var i = 0; i < template.leftTopPanel.rows.length; i++) {
            panel.rows[i] = {
                x: panel.x,
                y: template.leftTopPanel.rows[i].y,
                width: panel.width,
                height: template.rowHeight
            };
        }
        return panel;
    };
    function calcMinHeight(template, rowCount) {
        var bottomPanelsHeight = getBottomPanelsUsingHeight(template, rowCount);
        var topPanelsHeight = template.rowHeight;
        var borderSumHeight = template.border * 2;
        var paddingSumHeight = template.padding * 4;
        return bottomPanelsHeight + topPanelsHeight + borderSumHeight + paddingSumHeight;
    };
    function getBottomPanelsUsingHeight(template, rowCount) {
        return template.rowHeight * rowCount;
    };
    /*
     * !Important! template - with 'leftBottomPanel' or 'bottomPanel'
     */
    function getTopPanelsUsingHeight(template) {
        var bootomHeight = 0;
        if (template.leftBottomPanel == null) {// == is IMPORTANT!
            bootomHeight = template.bottomPanel.height;
        } else {
            bootomHeight = template.leftBottomPanel.height;
        }
        var bootomTotalHeight = bootomHeight + template.border + template.padding * 2;
        var topUsingHeight = template.height - bootomTotalHeight - template.border - template.padding * 2;
        return topUsingHeight;
    };
    /**
     *
     * @param {type} template
     * @param {type} outcomes - [{outcome: , value: },{outcome: , value: },...]
     */
    function adjust_vertical_lines(template, outcomes, textParameters) {
        var prop = 1 / 4;
        //################## Left and Right Bottom panels ###################
        var outcomesList = outcomes.map(d => d.outcome);
        var valueList = outcomes.map(d => {
            if (d.value === null) {
                return "";
            }
            return d.value;
        });
        var minOutcome = getMinWidth(outcomesList, textParameters);
        var minValue = getMinWidth(valueList, textParameters);

        var min = minOutcome + minValue;
        if(minOutcome < minValue){
            min = 2 * minValue;
        }
        var usingBottomWidth = (template.leftBottomPanel.width + template.rightBottomPanel.width);
        var minRD = usingBottomWidth * prop;
        var offerRDwidth = usingBottomWidth - min;
        // if width is the same for both panels
        if (usingBottomWidth / 2 > min) {
            template = setBetweenPanelsLine(template, usingBottomWidth / 2);
        } else if(offerRDwidth < minRD){// if text in left panel is too long
            template = setBetweenPanelsLine(template, minRD);
        } else {// if other
            template = setBetweenPanelsLine(template, offerRDwidth);
        }
        //######################## Left panel ##############################
        var valueArray = outcomes.map(d => d.value);
        var minValueWidth = getMinWidth(valueArray, textParameters);

        if (template.leftBottomPanel.width / 2 > minValueWidth) {
            template = setBetweenOutcomeValue(template, minValueWidth);
        } else {
            template = setBetweenOutcomeValue(template, template.leftBottomPanel.width / 2);
        }

        return template;
    }
    function setBetweenOutcomeValue(template, valueWidth) {
        for (var i = 0; i < template.leftBottomPanel.rows.length; i++) {
            template.leftBottomPanel.rows[i].left = {
                x: template.leftBottomPanel.rows[i].x,
                y: template.leftBottomPanel.rows[i].y,
                width: template.leftBottomPanel.rows[i].width - valueWidth,
                height: template.leftBottomPanel.rows[i].height
            };
            template.leftBottomPanel.rows[i].right = {
                x: template.leftBottomPanel.rows[i].x + template.leftBottomPanel.rows[i].left.width,
                y: template.leftBottomPanel.rows[i].y,
                width: valueWidth,
                height: template.leftBottomPanel.rows[i].height
            };
        }
        return template;
    }
    function setBetweenPanelsLine(template, RDwidth) {
        var usingBottomWidth = template.leftBottomPanel.width + template.rightBottomPanel.width;

        template.rightBottomPanel.width = RDwidth;
        template.rightBottomPanel.x = template.x + template.width - template.border - template.padding - template.rightBottomPanel.width;
        for (var i = 0; i < template.rightBottomPanel.rows.length; i++) {
            template.rightBottomPanel.rows[i].x = template.rightBottomPanel.x;
            template.rightBottomPanel.rows[i].width = template.rightBottomPanel.width;
        }

        template.leftBottomPanel.width = usingBottomWidth - RDwidth;
        for (var i = 0; i < template.leftBottomPanel.rows.length; i++) {
            template.leftBottomPanel.rows[i].x = template.leftBottomPanel.x;
            template.leftBottomPanel.rows[i].width = template.leftBottomPanel.width;
        }

        template.verticalLine = verticalLine(template);

        return template;
    }
    function getMinWidth(val, textParameters) {
        const MARGIN = 2;
        return Math.ceil(Math.max(...val.map(d => Utilities.measure(d, Utilities.getLazyMeasureContext(), textParameters) + MARGIN)));
    }
    return {
        getTemplate4P: function (nodeParameters, rowCount, symbolWidth) {
            return fourPanels(nodeParameters, rowCount, symbolWidth);
        },
        getTemplate3P: function (nodeParameters, rowCount, symbolWidth, isConstantEquation) {
            return threePanels(nodeParameters, rowCount, symbolWidth, isConstantEquation);
        },
        adjustVerticalLines: function (template, outcomes, textParameters) {
            return adjust_vertical_lines(template, outcomes, textParameters);
        }
    };
})();

export default Panels;
