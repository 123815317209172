
/* global Request */

import Request from "./request";

document.lastChild.style.opacity = 0;
var redirectDontNeed = function () {
    document.lastChild.style.opacity = null;
};

var redirectNeed = function (data) {
    switch (data.status) {
            case 401:
            case 403:
                window.location.replace("/login.html?parent=" + window.location.pathname + window.location.search);
                break;
            case 404:
                window.location.replace("/404.html");
                break;
            default:
                document.lastChild.style.opacity = null;
                break;
        }
};

Request.loginTest("", redirectDontNeed, redirectNeed);
