import 'jquery-ui';
import * as d3 from 'd3';
import ZoomNetwork from "./zoomNetwork";
import Log from "./log";
import LeftColumn from "./leftColumn";
import UpdateAdapter from "./updateAdapter";
import Node from "./node";
import GenieSymbols from "./genieSymbols";
import DynamicBN from "./dynamicBN";
import RightColumn from "./rightColumn";
import {
    NODE_TYPE,
    BAYESBOX_MODE,
    DEMORGAN_SLIDERS_DEF_COLORS,
    NODE_TEMPORAL_TYPE,
} from "./constantsMapping";
import Arc from "./arc";
import Utilities from "./utilities";
import Tooltip from "./tooltip";
import Descriptions from "./descriptions";
import Submodel from "./submodel";
import Cases from "./cases";
import StartApp from "./startAppInfo";
import Cooke from "./cooke";
import ScrollBar from "./scrollBar";
import Logo from "./logo";
import EvidenceSummary from "./evidenceSummary";
import Blur from "./blur";
import {CurrentDashboard, MultiDashboard} from "./gauge";
import NodeShape from "./nodeShape";
import DashboardsDropdownList from "./dashboardsDropdownList";
import Request from "./request";
import config from "./config";

/* global d3, ZoomNetwork, Log, LeftColumn, UpdateAdapter, Node, GenieSymbols, DynamicBN, RightColumn, NODE_TYPE, NodeShape, parseFloat, Arc, Utilities, Tooltip, Descriptions, Submodel, Cases, StartApp, Cooke, MODE, BAYESBOX_MODE, DEMORGAN_SLIDERS_DEF_COLORS, NODE_TEMPORAL_TYPE, User, AUTHENTICATION_TYPE, JSON5, ScrollBar, Blur, MultiDashboard, CurrentDashboard, OAuth, DashboardsDropdownList */
var Network = (function () {
    var oldCurrentNetwork = -1; //if in multidashboard is node conflcict then backup old network handle
    var mappingNetwork = new Map(); //handle-networkName
    var revision = 0;
    var SVG_ID = "svgplate";
    var SVG_CSS_SELECTOR = "#svgplate";
    var autoUpdate = true;
    var dashboards = [];
    var deMorganQualcolors = [];
    var REDIRECT_FROM_DASHBOARD_COOKE_NAME = "redirectFromDashboard";
    var name = "";//name from first submodel
    var svgPlateMargin = {
        top: d3.select('nav[aria-label="breadcrumb"]').size() > 0 ? parseFloat(d3.select('nav[aria-label="breadcrumb"]').style("font-size")) : 0
    };
    var svgcontent = "svgcontent";
    /**
     * 0 - stable
     * 1 - rendering
     */
    var renderStatus = 0;
    var USER_PREFERENCES_COOKE_NAME = "userPreferencesMap17022020";
    var CurrentNetwork = (function () {
        var currentNetwork = 0; //Small BNs/Coma.xdsl
        return {
            getCurrentNetwork: function () {
                return currentNetwork;
            },
            setCurrentNetwork: function (network) {
                if (Array.isArray(network)) {
                    currentNetwork = network.map(n => Number(n));
                } else if (BAYESBOX_MODE.isDashboard()) {
                    currentNetwork = [Number(network)];
                } else {
                    currentNetwork = Number(network);
                }
                if (BAYESBOX_MODE.isDashboard()) {
                    MultiDashboard.fillReturnNetworks(currentNetwork);
                }
            }
        };
    })();
    function redirectToDashboard(dashboardID) {
        let evidence = Node.Evidence.getAllEvidence();
        Cooke.setSessionCooke("network", CurrentNetwork.getCurrentNetwork(), 1);
        Cooke.setSessionCooke("evidence", JSON.stringify(evidence), 1);
        if (typeof dashboardID === 'undefined') {
            if (dashboards.length > 0) {
                window.location.replace("/dashboard.html?id=" + dashboards[0].id);
            } else {
                window.location.replace("/dashboard.html");
            }
        } else if (dashboardID === null) {
            window.location.replace("/dashboard.html");
        } else {
            window.location.replace("/dashboard.html?id=" + dashboardID);
        }
    }

    function redirectToGraph_G(redirectNetwork) {
        let evidence = Node.Evidence.getAllEvidence();
        var net = typeof redirectNetwork !== "undefined" ? redirectNetwork : Network.getCurrentNetwork();
        Cooke.setSessionCooke("network", net, 1);
        Cooke.setSessionCooke("evidence", JSON.stringify(evidence), 1);
        Cooke.deleteCooke("userPreferencesMap");
        Cooke.setSessionCooke(REDIRECT_FROM_DASHBOARD_COOKE_NAME, true);
        window.location.replace("/");
    }

    function addSVG(containerId) {
        svgcontent = typeof containerId === "undefined" ? svgcontent : containerId;
        //cros browser width and height
        var svgWidth = $(svgcontent).width();
        var breadcrumb = d3.select("nav[aria-label=breadcrumb]");
        if (breadcrumb.size() > 0) {
            breadcrumb = breadcrumb.node().getBoundingClientRect();
        } else {
            breadcrumb = {height: 0};
        }
        var svgHeight = $(`#${Network.getSvgContentId()}`).height() - breadcrumb.height - svgPlateMargin.top;

        var d = d3.select(`#${Network.getSvgContentId()}`)
                .append("svg")
                .attr("width", svgWidth + "px")
                .attr("height", svgHeight + "px")
                .attr('id', SVG_ID)
                .attr('class', 'noselect')
                .style("margin-top", `${svgPlateMargin.top}px`)
                .call(ZoomNetwork.getD3Zoom());
//                .on("touchmove", function (){
//                    console.log(d3.event);
//                    if (d3.event.targetTouches.length > 1) {
//                        d3.event.preventDefault();
//                        d3.event.stopPropagation();
//                        Log.write("stop propagation");
//                    }else{
//                        Log.clean();
//                    }
//                });
        return d;
    }
    /**
     * Remove current network graph
     */
    function deleteNetwork() {
        ZoomNetwork.removeZoomPlace();
        ZoomNetwork.addZoomPlace();
        Cases.clean();
        EvidenceSummary.clearAll();
        Arc.removeDynamicMarkers();
        DynamicBN.removeDynamicGradient();
    }
    function getDefaultNetwork() {
        if (StartApp.getDefaultNetwork() === null) {
            Log.showErrorModal("Error", "The default network isn't available.");
            Log.clean();
        } else {
            CurrentNetwork.setCurrentNetwork(StartApp.getDefaultNetwork());
            drawNetwork(CurrentNetwork.getCurrentNetwork(), autoUpdate);
            LeftColumn.setDropdownMenuTitle(getNetworkName(parseInt(CurrentNetwork.getCurrentNetwork(), 10)));
        }
    }

    /**
     * Draw new network
     * @param {int} network - network handle
     */
    function drawNetwork(network) {
        /*
         Network structure
         */
        console.time("Render network");
        ScrollBar.removeScrollBars();
        d3.select("#targetAccordionSearch").classed("show", false);
        d3.select("#otherAccordionSearch").classed("show", false);
        Log.write("Downloading the network...");
        $("#waiting").show();
        Logo.verify();
        let body = JSON.stringify({networkHandle: network});
        let done = function (data) {
//            ZoomNetwork.zoomManual(1, 0);
            deleteNetwork();
            if (autoUpdate && StartApp.getStartAppEvidence() === null) {
                update(() => {
                    ScrollBar.initScrollBars();
                    ZoomNetwork.zoomFitBoundary();
                });
            }
            if (data.downloadable) {
                d3.select("download").style("display", null);
                $("#download").attr("href", config.serverUrl + "/download/" + network);
            } else {
                d3.select("download").style("display", "none");
            }
            /**
             Network structure
             **/
            Log.write("Downloading the network...");
            Initialization.getNetworkPart(data);
            if (!autoUpdate || StartApp.getStartAppEvidence() !== null) {
                ScrollBar.initScrollBars();
            }
        };
        let fail = function (data) {
            if (data.status == 410) {
                $("#waiting").hide();
                Log.showErrorModal("Error", "The selected network is no longer available");
                Log.clean();
            } else {
                Log.write("Fail connection...");
            }
            renderStatus = 0;
            ScrollBar.initScrollBars();
        };
        let always = function (data) {
            console.timeEnd("Render network");
        };
        Request.getNetwork(body, done, fail, always);
    }

    function drawNetwork_G(network, keepOldNodes, functionIfNoConflicts) {
        Blur.disableUI();
        let body = JSON.stringify({
            networkHandle: Array.isArray(network) ? network : [network],
            fromDashboard: true
        });
        let done = function (data) {
            var unionMap = MultiDashboard.detectUnion(data);
            var conflicts = MultiDashboard.detectConflicts(unionMap, keepOldNodes, data);
            if (conflicts.length > 0) {
                var conflictDetails = "</br>";
                conflicts.forEach(conflict => {
                    conflictDetails += "<b># </b>" + conflict;
                    conflictDetails += ";</br>";
                });
                conflictDetails = conflictDetails.substring(0, conflictDetails.lastIndexOf(";"));
                conflictDetails += ".";
                Log.showErrorModal("Conflict!", conflictDetails);
                CurrentNetwork.setCurrentNetwork(oldCurrentNetwork);
                renderStatus = 0;
                return;
            }
            if (typeof functionIfNoConflicts !== 'undefined' && Utilities.isFunction(functionIfNoConflicts)) {
                functionIfNoConflicts();
            }
            Node.nodeUnion = unionMap;
            var first = data[0];//first network
            revision = first.revision;
            RightColumn.Accordion.initAccordionCards(unionMap, keepOldNodes);
            Node.remapHandleNodes();
            const setForDashboardName = function (network) {
                Submodel.addSubmodelsGroups(network);
                Submodel.buildSubmodelTree(network.submodels);
            };
            setForDashboardName(first);
            MultiDashboard.hiddenMirroredNodes();
            Node.nodeUnion = MultiDashboard.detectUnionFromRightColumn();
            DynamicBN.detectDynamicNetwork_G(data);
            renderStatus = 0;
        };
        let fail = function (data) {
            if (data.status === 410) {
                $("#waiting").hide();
                Log.showErrorModal("Error", "The selected network is no longer available");
                Log.clean();
            } else {
                Log.write("Fail connection...");
            }
            renderStatus = 0;
        };
        let always = function () {
            Blur.enableUI();
        };

        Request.getNetwork(body, done, fail, always);
    }

    /**
     * Set current network
     * @param {int} handle - network handle
     * @param {String} fileName - network name
     */
    function setCurrentNetwork(handle, fileName) {
        if(typeof fileName === "undefined"){
            fileName = getNetworkName(parseInt(handle, 10));
        }
        CurrentNetwork.setCurrentNetwork(handle);
        LeftColumn.setDropdownMenuTitle(fileName);
        drawNetwork(CurrentNetwork.getCurrentNetwork());
    }

    /**
     * Set current network in dashboard
     * @param {int} handle - network handle
     * @param {boolean} keepOldNodes - set if you want keep old nodes
     */
    function setCurrentNetwork_G(handle, keepOldNodes, functionIfNoConflicts) {
        var nodesToDraw = Array.isArray(CurrentNetwork.getCurrentNetwork()) && Array.isArray(handle) ? Utilities.difference(handle, CurrentNetwork.getCurrentNetwork()) : handle;
        oldCurrentNetwork = CurrentNetwork.getCurrentNetwork();
        CurrentNetwork.setCurrentNetwork(handle);
        if (Array.isArray(nodesToDraw) && nodesToDraw.length === 0) {
            if (typeof functionIfNoConflicts !== 'undefined' && Utilities.isFunction(functionIfNoConflicts)) {
                renderStatus = 0;
                functionIfNoConflicts();
            }
            Node.nodeUnion = MultiDashboard.detectUnionFromRightColumn();
            MultiDashboard.hiddenMirroredNodes();
            DynamicBN.detectDynamicNetwork_G([]);
            return;
        }
        drawNetwork_G(nodesToDraw, keepOldNodes, functionIfNoConflicts);
    }
    function update_G(callbackDone, updateDashboardFunction) {
        $("#loading").css('display', 'block');
        Log.write("Updating...");
        Blur.disableUI();
        let body = JSON.stringify(Node.Evidence.getAllEvidenceMulti());
        let done = function (data) {
            d3.selectAll("div[type=nodeCard]").each(d => {
                if (d.values) {
                    delete d.values;
                }
            });
            Node.setRangeDecision();
            for (var i = 0; i < data.length; i++) {
                if (data[i].revision !== revision || data[i].data === null || data[i].success === false || data[i].network === null) {
                    if (data[i].revision !== revision) {
                        Log.setEvidenceFailed("Error!", "The network is no longer available.");
                        return;
                    }
                    Log.setEvidenceFailed("Set evidence failed!", data[i].data);
                    Node.Evidence.retractLastEvidence();
                    return;
                }
                var singleNetworkData = UpdateAdapter.adapter(data[i], data[i].network);
                for (var j = 0; j < singleNetworkData.length; j++) {
                    var singleNode = singleNetworkData[j];
                    var nodeID = Node.getNodeIdFromHandle(parseInt(singleNode.node, 10), data[i].network);
                    var node = d3.select("#" + nodeID).data()[0];
                    if (typeof node !== 'undefined') {
                        node.values = singleNode.values;
                        node.isPropagated = singleNode.isPropagated;
                        node.isValueDiscretized = singleNode.isValueDiscretized;
                        node.indexingParentsIds = singleNode.indexingParentsIds;
                        node.isMultidimensional = Node.Outcome.isMultidimensional(node.nodeType, node.indexingParentsIds);
                        if (singleNode.isPropagated) {
                            for (var k = 0; k < node.values.length; k++) {
                                if (node.values[k].value === 1) {
                                    node.propagatedOutcomeIds = Node.Outcome.getOutcomeIds(node.values[k], node);
                                }
                            }
                        }
                    }
                }
            }

//            CardsManager.updateAll();
            if (typeof updateDashboardFunction !== "undefined" && Utilities.isFunction(updateDashboardFunction)) {
                updateDashboardFunction();
            } else {
                CurrentDashboard.updateGlobal();
            }
            $("#loading").css('display', 'none');
            Log.clean();
            $("#waiting").hide();
            if (Utilities.isFunction(callbackDone)) {
                callbackDone();
            }
            RightColumn.Accordion.refreshCard_G();
            Blur.enableUI();
        };
        let fail = function (data) {
            var titleError;
            var messageError;
            switch (data.status) {
                case 0:
                    Log.setEvidenceFailed("Error!", "Connection failure!");
                    break;
                case 406:
                    Log.setEvidenceFailed("Oops!", "Set evidence failed!");
                    break;
                case 410:
                    titleError = "Error";
                    messageError = "The selected network is no longer available";
                    break;
                default:
                    titleError = "Oops!";
                    messageError = "Something went wrong and we couldn't process your request.";

            }
            $("#loading").css('display', 'none');
            if (data.status !== 406) {
                Log.showErrorModal(titleError, messageError);
            }
            Log.clean();
            Blur.enableUI();
        };
        Request.update(body, true, done, fail);
    }
    /**
     * Update network function
     */
    function update(callbackDone) {
        console.log("Update");
        console.time("Update network");
        $("#loading").css('display', 'block');
        Log.write("Updating...");
        $("#waiting").show();

        //create request json
        var dataUpdate = new Object();
        dataUpdate.networkHandle = CurrentNetwork.getCurrentNetwork();
        dataUpdate.evidences = Node.Evidence.getAllEvidence();

        let body = JSON.stringify(dataUpdate);
        let done = function (data) {
            if (data.revision !== revision || data.data === null || data.success === false) {
                if (data.revision !== revision) {
                    Log.setEvidenceFailed("Error!", "The network is no longer available.");
                    return;
                }
                Log.setEvidenceFailed("Set evidence failed!", data.data);
                Node.Evidence.retractLastEvidence();
                return;
            }
            data = UpdateAdapter.adapter(data, Network.getCurrentNetwork());
            Node.Evidence.deleteAllEvidenceInfo();
            GenieSymbols.StatusRColumnIcon.removeAll();
            //reset value range for NODE_TYPE.DECISION nodes
            Node.setRangeDecision();
            var lenData = data.length;
            if (!DynamicBN.isDynamicNetwork) {
                d3.select("#otherAccordion").selectAll("div[id^='outcomesBarChart']").style("padding-bottom", null);
                d3.select("#targetAccordion").selectAll("div[id^='outcomesBarChart']").style("padding-bottom", null);
            }
            for (var j = 0; j < lenData; j++) {
                var singleNode = data[j];
                var nodeID = Node.getNodeIdFromHandle(parseInt(singleNode.node, 10), Network.getCurrentNetwork());
                var nodeD3Self = d3.select("#" + nodeID);
                var node = nodeD3Self.data()[0];
                var outcomes = [];
                node.values = singleNode.values;
                node.indexingParentsIds = singleNode.indexingParentsIds;
                node.isMultidimensional = Node.Outcome.isMultidimensional(node.nodeType, node.indexingParentsIds);
                node.isValueDiscretized = singleNode.isValueDiscretized;
                if (singleNode.sampleStats === undefined) {
                    delete node.sampleStats;
                } else {
                    node.sampleStats = singleNode.sampleStats;
                }
                if (node.isMultidimensional) {
                    RightColumn.Accordion.addMultidimensionalCard(node);
                } else {
                    //removing node card if different from new node
                    var nodeCard = RightColumn.Accordion.removeCardIfNecessary(node);

                    //if node card don't present in DOM
                    if (nodeCard.size() === 0) {
                        RightColumn.Accordion.addCardIfNecessary(node);
                    }
                    var card = d3.select('#outcomesBarChart_' + nodeID);
                    card.selectAll('p').remove();
                    card.selectAll('.singleOutcomeBarChart').style("display", "block");
                }
                var minMax = {minUtility: 0, maxUtility: 100};
                for (var i = 0; i < node.values.length; i++) {
                    if (singleNode.isPropagated === true && node.values[i].value === 1) {
                        node.propagatedOutcomeIds = Node.Outcome.getOutcomeIds(node.values[i], node);
                    }

                    let nodeViewProperties = Node.getViewParameter(node, i);
                    if (nodeViewProperties.minMax.minUtility !== minMax.minUtility) {
                        minMax.minUtility = nodeViewProperties.minMax.minUtility;
                    }
                    if (nodeViewProperties.minMax.maxUtility !== minMax.maxUtility) {
                        minMax.maxUtility = nodeViewProperties.minMax.maxUtility;
                    }
                    if (node.isTarget) {
                        outcomes.push([nodeViewProperties.progressTitleOutcomeID, nodeViewProperties.value, nodeViewProperties.label]);
                    } else if (node.temporalType === NODE_TEMPORAL_TYPE.PLATE && !node.checkType(NODE_TYPE.EQUATION)) {
                        const stateLabels = Node.getStateLabels(node);
                        if (outcomes.length === 0) {
                            for (var n = 0; n < stateLabels.length; n++) {
                                outcomes[n] = [stateLabels[n], [], stateLabels[n]];
                            }
                        }
                        outcomes[i % stateLabels.length][1].push(node.values[i].value);
                    } else if (!node.isMultidimensional) {
                        //for node bar chart
                        outcomes.push([nodeViewProperties.progressTitleOutcomeID, nodeViewProperties.value, nodeViewProperties.label]);
                    }
                    /*
                     update progress bars in right column
                     */
                    if (!node.isMultidimensional || node.isTarget) {
                        if (!node.checkType(NODE_TYPE.EQUATION) || (node.checkType(NODE_TYPE.EQUATION) && (node.isValueDiscretized === true || node.values.length === 1))) {
                            RightColumn.Accordion.updateProgressBar(nodeViewProperties);
                        }
                    }
                }
                if ((node.checkType([NODE_TYPE.MAU, NODE_TYPE.UTILITY])) && outcomes.length === 0) {
                    outcomes.push(["Expected utility", null, "Expected utility"]);
                }
                node.isPropagated = singleNode.isPropagated;
                //set symbols in right column
                GenieSymbols.StatusRColumnIcon.setSymbols(node);

                //console.log(d3.select("#"+singleNode.nodeId).data()[0]);
                if (node.isBarChart) {
                    if (node.checkType(NODE_TYPE.DECISION)) {
                        NodeShape.BarChart.drawOutcomes(nodeD3Self, node.name, outcomes, {min: Node.valueRangeDecision.minUtility, max: Node.valueRangeDecision.maxUtility});
                    } else if (node.checkType([NODE_TYPE.MAU, NODE_TYPE.UTILITY])) {
                        NodeShape.BarChart.drawOutcomes(nodeD3Self, node.name, outcomes, {min: minMax.minUtility, max: minMax.maxUtility});
                    } else if (node.checkType(NODE_TYPE.EQUATION)) {
                        NodeShape.BarChart.drawOutcomes(nodeD3Self, node.name, outcomes);
                    } else {
                        NodeShape.BarChart.drawOutcomes(nodeD3Self, node.name, outcomes);
                    }
                    Arc.updateAllArc();
                }
            }
            updateDeMorganColors();
            RightColumn.Accordion.upateDeMorganValue();
            RightColumn.PhaseSpace.init();
            $("#loading").css('display', 'none');
            GenieSymbols.StatusNodeIcon.updateAllSymbols();
            Log.clean();
            $("#waiting").hide();
            if (Utilities.isFunction(callbackDone)) {
                callbackDone();
            }
        };

        let fail = function (data) {
            var titleError;
            var messageError;
            switch (data.status) {
                case 0:
                    Log.setEvidenceFailed("Error!", "Connection failure!");
                    break;
                case 406:
                    Log.setEvidenceFailed("Oops!", "Set evidence failed!");
                    break;
                case 410:
                    titleError = "Error";
                    messageError = "The selected network is no longer available";
                    break;
                default:
                    titleError = "Oops!";
                    messageError = "Something went wrong and we couldn't process your request.";

            }
            $("#loading").css('display', 'none');
            if (data.status !== 406) {
                Log.showErrorModal(titleError, messageError);
            }
            Log.clean();
            $("#waiting").hide();
        };

        let always = function (data) {
            console.timeEnd("Update network");
            Utilities.updateSVGPlateSize();
            Tooltip.addNodeNameTooltip();
        };

        Request.update(body, false, done, fail, always);
    }
    function updateDeMorganColors(nodes, valueFromPriorBelief){
        nodes = typeof nodes === "undefined" ? d3.selectAll(Utilities.getGTypeSelector(Node.TYPE)) : nodes;
        if(nodes.size() === 0){
            return;
        }
        valueFromPriorBelief = typeof valueFromPriorBelief === "undefined" ? false : valueFromPriorBelief;
        var barchart = nodes.filter(d => (d.isBarChart && d.checkType(NODE_TYPE.DEMORGAN) && d.temporalType !== NODE_TEMPORAL_TYPE.PLATE));
        var barchartInPlate = nodes.filter(d => (d.isBarChart && d.checkType(NODE_TYPE.DEMORGAN) && d.temporalType === NODE_TEMPORAL_TYPE.PLATE));
        var ellipse = nodes.filter(d => (!d.isBarChart && d.checkType(NODE_TYPE.DEMORGAN) && d.temporalType !== NODE_TEMPORAL_TYPE.PLATE));
        var ellipseInPlate = nodes.filter(d => (!d.isBarChart && d.checkType(NODE_TYPE.DEMORGAN) && d.temporalType === NODE_TEMPORAL_TYPE.PLATE));
        var submodels = d3.selectAll(Utilities.getGTypeSelector(Submodel.TYPE_RECTANGLE));
        var fillFunction = function (d) {
            var colors = Network.getDeMorganQualColors();
            var c1 = colors[0];
            var c2 = colors[1];
            var c3 = colors[2];
            var percentage = d.deMorganPriorBelief;
            if (!valueFromPriorBelief) {
                try {
                    percentage = d.values[0].value;
                } catch (e) {
                    console.error(`Node ${d.name} hasn't been updated yet. PriorBeliefe will used for DeMorgan color.`);
                }
            }
            if (d.reversecolor) {
                return Utilities.blend_colors([c3, c2, c1], percentage);
            }
            return Utilities.blend_colors([c1, c2, c3], percentage);
        };
        ellipse.select("ellipse")
                .style("fill", d => fillFunction(d));
        ellipseInPlate.select("ellipse")
                .style("fill", d => {
                    var gradient = DynamicBN.updateDeMorganGradient(d, Network.getDeMorganQualColors());
                    return gradient.gradientIsCreated ? `url(#${gradient.def.attr("id")})` : gradient.color;
                });
        barchart.select(".mainRect")
                .style("fill", d => fillFunction(d));
        barchartInPlate.select(".mainRect")
                .style("fill", "white");
        //update submodels
        var colorSourceList = Submodel.colorSource;
        colorSourceList.forEach(p => {
            var propColor = fillFunction(p.node);
            submodels.filter(s => s.id === p.submodel)
                    .select("rect")
                    .style("fill", propColor);
            //propagate color to parent submodels
            p.propToParent.forEach(parent => {
                let thisSubmodel = Submodel.submodel_tree.get(parent);
                let parentSubmodel = Submodel.submodel_tree.get(thisSubmodel.nextHandle);
                submodels.filter(s => s.id === parentSubmodel.id)
                        .select("rect")
                        .style("fill", propColor);
            });
        });
        //update symbols
        var nodesCirclesOutsidePlate = d3.selectAll(".deMorganColorCircle").filter(d => d.temporalType !== NODE_TEMPORAL_TYPE.PLATE);
        nodesCirclesOutsidePlate.on("mouseover", d => {
                    var leak, prior;
                    if(typeof d.parents !== "undefined" && d.parents.length > 0){
                        leak = d3.format(".0%")(d.deMorganPriorBelief);
                    }else{
                        prior = d3.format(".0%")(d.deMorganPriorBelief);
                    }
                    Tooltip.deMorganCircleTooltip(d3.format(".0%")(d.values[0].value), leak, prior);
                })
                .on("mouseout", () => Tooltip.setMouseout());
        nodesCirclesOutsidePlate.select(".startColor")
                .style("fill", d => {
                    var colors = Network.getDeMorganQualColors();
                    if(typeof d.values === "undefined"){
                        return "transparent";
                    }
                    var directionValue = d.reversecolor ? d.values[1].value : d.values[0].value;
                    if (directionValue < 0.5) {
                        return colors[0];
                    } else if (directionValue > 0.5) {
                        return colors[1];
                    } else {
                        return "transparent";
                    }
                });
        nodesCirclesOutsidePlate.select(".endColor")
                .style("fill", d => {
                    var colors = Network.getDeMorganQualColors();

                    if(typeof d.values === "undefined"){
                        return "transparent";
                    }

                    var directionValue = d.reversecolor ? d.values[1].value : d.values[0].value;
                    if (directionValue < 0.5) {
                        return colors[1];
                    } else if (directionValue > 0.5) {
                        return colors[2];
                    } else {
                        return "transparent";
                    }
                });
    }
    function findNetworkDatByHandle(networkHandle) {
        var networkObject = undefined;
        mappingNetwork.forEach((category, key) => {
            if(networkObject === undefined){
                networkObject = category.find(net => net.handle === networkHandle);
                if(networkObject !== undefined){
                    networkObject.category = key;
                }
            }
        });
        return networkObject;
    }

    function getNetworkName(networkHandle) {
        var networkObject = findNetworkDatByHandle(networkHandle);
        return networkObject !== undefined ? networkObject.filename.replace(".xdsl", "") : undefined;
    }

    var Initialization = {
        /**
         * Start rendering network. Set file size and Network.revision.
         * @param {JSON} data - JSON from /network/get
         */
        getNetworkPart: function (data) {
            Log.write("Rendering network...");
            //remove all cards
            d3.select("#otherAccordion").selectAll(".myCard").remove();
            d3.select("#targetsAccordion").selectAll(".myCard").remove();
            //revision info
            revision = data.revision;
            Initialization.addNetworkInfoPart(data);
//            setTimeout(Initialization.addNetworkInfoPart, 10, data);
        },
        /**
         * @private
         * Add network general description and characteristics in the left column
         * @param {JSON} data - JSON from /network/get
         */
        addNetworkInfoPart: function (data) {
            /*
             Add characteristics
             */
            var isOneTypeOfNode = Object.keys(data.characteristics.nodesType).length === 1;
            var characteristicContent = '<netProp>Node count: ' + data.characteristics.nodeCount + ' [' + data.characteristics.allParametersCount + ' parameters]' + '<br></netProp>';
            characteristicContent += '<ul>';
            Object.keys(data.characteristics.nodesType).forEach(function (key, index) {
                if (isOneTypeOfNode) {
                    characteristicContent = 'Nodes: ';
                } else {
                    characteristicContent += '<li>';
                }
                characteristicContent += key;
                characteristicContent += isOneTypeOfNode ? '-' : ':';
                characteristicContent += data.characteristics.nodesType[key];
                characteristicContent += isOneTypeOfNode ? ' ' : '<br/>';
                if (key !== "Equation") {
                    characteristicContent += '[' + data.characteristics.parameters[key] + ' parameters]';
                }
                characteristicContent += isOneTypeOfNode ? '<br/>' : '</li>';
            });
            characteristicContent += isOneTypeOfNode ? '' : '</ul>';

            characteristicContent += 'Arcs: ' + data.characteristics.arcCount + '<br>' +
                    'Mean indegree: ' + data.characteristics.meanIndegree + '<br>' +
                    'Max. indegree: ' + data.characteristics.maxIndegree + '<br>' +
                    'Avg. outcome count: ' + data.characteristics.avgOutcomeCount + '<br>' +
                    'Max. outcome count: ' + data.characteristics.maxOutcomeCount + '<br>';
            $('#characteristics').html(characteristicContent);
            $('#description').html('<p>' + data.characteristics.description + '</p>');
            $('#reference').html('<p>' + data.characteristics.reference.trim().replace(new RegExp('\r?\n','g'), '<br />') + '</p>');
            if (data.characteristics.description === "") {
                $("#toDescription").hide();
            } else {
                $("#toDescription").show();
            }
            if (data.characteristics.reference === "") {
                $("#toReference").hide();
            } else {
                $("#toReference").show();
            }
            if(StartApp.isPermalink()){
                $("#permaLinkURL").hide();
                d3.select("permalink #permaLinkURL")
                        .text(window.location.origin + "/network/permalink?net=" + data.permalink);
                d3.select("permalink .clickToCopyAction")
                        .attr("data-clipboard-text", window.location.origin + "/network/permalink?net=" + data.permalink);
            }
            if (data.deMorganQualColors) {
                deMorganQualcolors = data.deMorganQualColors.map(c => "#" + c);
            } else {
                deMorganQualcolors = [];
            }
            Initialization.addNodesPart(data);
            //save dashboards
            DashboardsDropdownList.fillList(CurrentNetwork.getCurrentNetwork());
            //add nodes
//            setTimeout(Initialization.addNodesPart, 20, data);
        },
        /**
         * @private
         * Render all nodes
         * @param {JSON} data - JSON from /network/get
         */
        addNodesPart: function (data) {
            Submodel.addSubmodelsGroups(data);
            //add nodes to submodels
            var submodels = data.submodels;
            Submodel.buildSubmodelTree(submodels);
            Submodel.setBreadcrumb(data.mainSubmodel);
            Submodel.createDeMorganColorSource();
            for (var i = 0; i < submodels.length; i++) {
                Node.addNodes(submodels[i].nodes, submodels[i]);
                Descriptions.addAll(submodels[i]);
            }
            Initialization.addArcPart(data);
            //add arcs
//            setTimeout(Initialization.addArcPart, 10, data);
        },
        /**
         * @private
         * Render all arcs
         * @param {JSON} data - JSON from /network/get
         */
        addArcPart: function (data) {
            Arc.addAllArcs(data.submodels);
            Initialization.updatePart(data);
//            setTimeout(Initialization.updatePart, 10, data);
            if (typeof data.temporalPlate !== "undefined") {
                DynamicBN.drawTemporalPlate(data.temporalPlate);
            }
        },
        /**
         * @private
         * Update new newtork
         * @param {JSON} data - JSON from /network/get
         */
        updatePart: function (data) {
            if(typeof RightColumn !== "undefined"){
                RightColumn.Accordion.initAccordionCards(data);//before update !Important!
            }
            renderStatus = 0;
            Node.remapHandleNodes();
            if (!autoUpdate)  {
                GenieSymbols.StatusNodeIcon.updateAllSymbols();
                Utilities.updateSVGPlateSize();
                Tooltip.addNodeNameTooltip();
                ZoomNetwork.zoomFitBoundary();
                $("#waiting").hide();
                if(typeof Log !== "undefined"){
                    Log.clean();
                }
            }
            //add all elements to one group. Important to zooming
            var elements = d3.select(SVG_CSS_SELECTOR).select(ZoomNetwork.getCSSZoomPlaceSelector());
            d3.select(`#${Network.getSvgContentId()}`).selectAll(Utilities.getGTypeSelector(Submodel.TYPE)).selectAll(function () {
                var node = d3.select(this);
                elements.node().appendChild(node.node());
            });
//    d3.select(`#${Network.getSvgContentId()}`).selectAll(Utilities.getGTypeSelector(Arc.TYPE)).selectAll(function () {
//        var arc = d3.select(this);
//        elements.node().appendChild(arc.node());
//    });
            d3.select(SVG_CSS_SELECTOR).selectAll(Utilities.getGTypeSelector(Node.TYPE)).moveToFront();
            d3.select(SVG_CSS_SELECTOR).selectAll(Utilities.getGTypeSelector(Submodel.TYPE_RECTANGLE)).moveToFront();
            ZoomNetwork.zoomManual(1, 0);//important while descriptions was added
        }
    };
    var toggleValue = false;
    $(document).ready(function () {
        $("#updateToggle").on('click',function () {
            toggleValue = !toggleValue;
            switch (toggleValue) {
                case true:
                    $("#updateToggle updateSelect").html('<i class="fas fa-toggle-on" style="color: #6eff00;"></i> Auto');
                    $("#updateManual a").addClass("disabled");
                    Network.update();
                    break;
                case false:
                    $("#updateToggle updateSelect").html('<i class="fas fa-toggle-off"></i> Manual');
                    $("#updateManual a").removeClass("disabled");
                    break;
            }
            Network.setAutoUpdate(toggleValue);
        });
    });
    return {
        getCurrentNetwork: function () {
            if(BAYESBOX_MODE.isDashboard() && !Array.isArray(CurrentNetwork.getCurrentNetwork())){
                return [CurrentNetwork.getCurrentNetwork()];
            }
            return CurrentNetwork.getCurrentNetwork();
        },
        setCurrentNetwork: function (handle, fileName, keepOldNodes, functionIfNoConflicts) {
            renderStatus = 1;
            if (!BAYESBOX_MODE.isDashboard()) {
                setCurrentNetwork(handle, fileName);
            } else {
                setCurrentNetwork_G(handle, keepOldNodes, functionIfNoConflicts);
            }
        },
        getCurrentNetworkID: function () {
            return getNetworkName(parseInt(CurrentNetwork.getCurrentNetwork(), 10));
        },
        getMappingNetwork: function () {
            return mappingNetwork;
        },
        setMappingNetwork: function (newMap) {
            mappingNetwork = newMap;
        },
        getNetworkName: function (networkHandle) {
            return getNetworkName(networkHandle);
        },
        getNetworkData: function (networkHandle) {
            return findNetworkDatByHandle(networkHandle);
        },
        getNetworkHandleData: function (category, filename) {
            var error = new Error(`Network: ${category} "/" ${filename} don't exist.`);
            try {
                var ret = mappingNetwork.get(category).find(c => c.filename === filename).handle;
                return ret;
            } catch (e) {
                console.error(error.message);
            }
        },
        getRevision: function () {
            return revision;
        },
        setRevision: function (rev) {
            revision = rev;
            return revision;
        },
        getSVG_CSS_SELECTOR: function () {
            return SVG_CSS_SELECTOR;
        },
        addSVG: function () {
            addSVG();
        },
        getDefaultNetwork: function () {
            renderStatus = 1;
            getDefaultNetwork();
        },
        update: function (callbackDone, updateDashboardFunction) {
            if (renderStatus === 0) {
                if (!BAYESBOX_MODE.isDashboard()) {
                    update(callbackDone);
                } else {
                    update_G(callbackDone, updateDashboardFunction);
                }
            } else {
                var renderStatusError = new Error(`RenderStatus =: ${renderStatus}, update isn't possible`);
                console.error(renderStatusError.message);
            }
        },
        runIfRendered: function (calback) {
            function run(calback) {
                if (renderStatus === 0) {
                    calback();
                } else {
                    setTimeout(run, 1000, calback);
                }
            };
            run(calback);
        },
        getRenderStatus: function () {
            return renderStatus;
        },
        updateMethodMenu: function (show) {
            if (show) {
                $("#updateManual").addClass("d-inline");
                $("#updateToggle").addClass("d-inline");
            } else {
                $("#updateManual").removeClass("d-inline");
                $("#updateToggle").removeClass("d-inline");
            }
        },
        setAutoUpdate: function (autoUpdateStatus) {
            autoUpdate = autoUpdateStatus;
        },
        isAutoUpdate: function () {
            return autoUpdate;
        },
        redirectToDashboard: function (id) {
            redirectToDashboard(id);
        },
        redirectToGraph: function (redirectNetwork) {
            redirectToGraph_G(redirectNetwork);
        },
        getUserPreferencesCookeName: function () {
            return USER_PREFERENCES_COOKE_NAME;
        },
        getRedirectFromDashboardCookeName: function () {
            return REDIRECT_FROM_DASHBOARD_COOKE_NAME;
        },
        getDeMorganQualColors: function () {
            var c1 = DEMORGAN_SLIDERS_DEF_COLORS.LEFT;
            var c2 = DEMORGAN_SLIDERS_DEF_COLORS.CENTER;
            var c3 = DEMORGAN_SLIDERS_DEF_COLORS.RIGHT;
            var customColors = deMorganQualcolors;
            if (customColors.length === 3) {
                c1 = customColors[0];
                c2 = customColors[1];
                c3 = customColors[2];
            }
            return [c1,c2,c3];
        },
        updateDeMorganColors: function(nodes, valueFromPriorBelief){
            updateDeMorganColors(nodes, valueFromPriorBelief);
        },
        getSvgplateMargin: function () {
            return svgPlateMargin;
        },
        getSvgContentId: function () {
            return svgcontent;
        },
        setSvgContentId: function (newSvgContentId) {
            svgcontent = newSvgContentId;
        },
        initBayesTeam: function (networkJson, containerId) {
            SimpleBar.removeObserver();
            Utilities.moveToFrontBackInit();
            autoUpdate = false;
            d3.select(`#${SVG_ID}`).remove();
            addSVG(containerId);
            ZoomNetwork.addZoomPlace();
            Arc.markerDefine();
            GenieSymbols.StatusRColumnIcon.initHTMLsymbols();
            Initialization.addNodesPart(networkJson);
            ScrollBar.initScrollBars([false, true, false]);
        },
        addNodeBayesTeam: function (templateData, submodel, group) {
            Node.addNodes(templateData, submodel, group);
            renderStatus = 0;
            GenieSymbols.StatusNodeIcon.updateAllSymbols();
            Utilities.updateSVGPlateSize();
            Tooltip.addNodeNameTooltip();
        },
        removeNodeBayesTeam: function (nodeData) {
            d3.select(`#${Node.getNodeIdFromObject(nodeData)}`).remove();
        },
        removeBodyNodeBayesTeam: function (nodeData) {
            d3.select(`#${Node.getNodeIdFromObject(nodeData)}`).selectAll("*").remove();
        }
    };
})();

export default Network;
