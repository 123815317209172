import * as d3 from 'd3';
import Network from "./network";
import Utilities from "./utilities";
import ScrollBar from "./scrollBar";


/* global d3, Network, ScrollBar */
var ZoomNetwork = (function () {
    var CSSZoomPlaceID = "zoomPlace";
    var CSSZoomPlaceSelector = "#zoomPlace";
    var last_K = 1; //Important for calculate resize svg plate in zoom
    var START_BOUNDARY_FIT = 1.2;
    var END_BOUNDARY_FIT = 3;
    /**
     zoom settings
     */
    var zoom = d3.zoom()
            .wheelDelta(function myDelta() {
                return -d3.event.deltaY * (d3.event.deltaMode ? 120 : 1) / 3000;
            })
            .scaleExtent([1 / Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER])
            .filter(function filter() {
                return !d3.event.button && d3.event.ctrlKey;
            })
            .touchable(function touchable() {
//                return "ontouchstart" in this;
                return false;
            })
            .on("zoom", function () {
                //zoom 5% - 500% ONLY
                if (d3.event.transform.k > 5) {
                    d3.event.transform.k = 5;
                }
                if (d3.event.transform.k < 0.05) {
                    d3.event.transform.k = 0.05;
                }
                var elementsTransorm = d3.event.transform;

                var scrollL = $(Network.getSVG_CSS_SELECTOR()).parent("div");
                var scrollT = $(Network.getSVG_CSS_SELECTOR()).parent("div").parent("div");
                var maxScrollLeftBefore = scrollL.get(0).scrollWidth - scrollL.get(0).clientWidth;
                var scrollLeftBefore = scrollL.scrollLeft();
                var maxScrollTopBefore = scrollT.get(0).scrollHeight - scrollT.get(0).clientHeight;
                var scrollTopBefore = scrollT.scrollTop();

//            elementsTransorm.x = 0;
//            elementsTransorm.y = 0;
                var zoomGroup = d3.select(CSSZoomPlaceSelector);
                zoomGroup.attr('transform', 'scale(' + elementsTransorm.k + ')');
                var d = d3.select(Network.getSVG_CSS_SELECTOR());
                var h = parseFloat(d.attr('height'));
                var w = parseFloat(d.attr('width'));
                $("#dropdownZoom > zoomvalue").text((elementsTransorm.k * 100).toFixed(0) + "%");
                //scale
                last_K = elementsTransorm.k;
                Utilities.updateSVGPlateSize();

                //bugfix- delete scrollbar if necessary
                if ($(`#${Network.getSvgContentId()} > div.simplebar-track`).css('visibility') === "hidden") {
                    $(`#${Network.getSvgContentId()} > div.simplebar-track > div.simplebar-scrollbar`).removeClass('visible');
                }
                var maxScrollLeftAfter = scrollL.get(0).scrollWidth - scrollL.get(0).clientWidth;
                var maxScrollTopAfter = scrollT.get(0).scrollHeight - scrollT.get(0).clientHeight;
                var scrollLeft = maxScrollLeftAfter * scrollLeftBefore / maxScrollLeftBefore;
                var scrollTop = maxScrollTopAfter * scrollTopBefore / maxScrollTopBefore;
                scrollT.scrollTop(scrollTop);
                scrollL.scrollLeft(scrollLeft);
            });

    /**
     * Fit to window
     */
    function zoomFit_(boundaryStart, boundaryEnd) {
        var duration = undefined;
        var networkProperty = d3.select(CSSZoomPlaceSelector).node().getBBox();
        var breadcrumbProperty = Utilities.getBreadcrumbRect();
        var svgplateMargin = Network.getSvgplateMargin();
        var scaleX = ($(`#${Network.getSvgContentId()}`).width() - ScrollBar.getScrollWidthHeight()) / (Math.ceil(networkProperty.width) + Math.ceil(networkProperty.x));
        var scaleY = ($(`#${Network.getSvgContentId()}`).height() - ScrollBar.getScrollWidthHeight() - breadcrumbProperty.height - svgplateMargin.top) / (Math.ceil(networkProperty.height) + Math.ceil(networkProperty.y));
        var scale = scaleX < scaleY ? scaleX : scaleY;
        var scaleFunction;
        if (typeof boundaryStart !== "undefined" && typeof boundaryEnd !== "undefined") {
            duration = 0;
            if (scale < boundaryStart) {
                return;
            } else if (scale > boundaryEnd) {
                scale = boundaryEnd;
            }
            scaleFunction = function (s) {
                return Math.floor(s * 10) / 10;
            };
        }
        zoomManual_(scale, duration, scaleFunction);
    }
    ;
    /*
     * Manual zoom
     * @param {type} scale
     * @param {type} duration[default=750]
     * @returns {undefined}
     */
    function zoomManual_(scale, duration, scaleFunction) {
        if (typeof scaleFunction !== "undefined") {
            if (Utilities.isFunction(scaleFunction)) {
                scale = scaleFunction(scale);
            } else {
                console.error("Parameter isn't function");
            }
        } else {
            scale = Math.floor(scale * 100) / 100;
        }
        duration = typeof duration !== 'undefined' ? duration : 750;
        var d = d3.select(Network.getSVG_CSS_SELECTOR());
        //zoomGroup.call(zoom.transform, t);
        d.transition()
                .duration(duration)
                .call(zoom.transform, d3.zoomIdentity.translate(0, 0).scale(parseFloat(scale)));
    }
    ;
    //zoom text from dropdown list
    var dropdownListZoom = $("#dropdownItemZoom a").click(function () {
        $("#dropdownZoom > zoomvalue").text($(this).data('value'));
    });
    /**
     * Remove zoom place
     */
    function removeZoomPlace_() {
        d3.select(Network.getSVG_CSS_SELECTOR()).selectAll(CSSZoomPlaceSelector).remove();
    }
    ;
    /**
     * Add zoom place
     */
    function addZoomPlace_() {
        d3.select(Network.getSVG_CSS_SELECTOR()).append('g').attr('id', CSSZoomPlaceID);
    }
    ;

    return {
        getCSSZoomPlaceSelector: function () {
            return CSSZoomPlaceSelector;
        },
        getLast_K: function () {
            return last_K;
        },
        getD3Zoom: function () {
            return zoom;
        },
        zoomFit: function () {
            return zoomFit_();
        },
        zoomFitBoundary: function () {
            zoomFit_(START_BOUNDARY_FIT, END_BOUNDARY_FIT);
        },
        zoomManual: function (scale, duration) {
            return zoomManual_(scale, duration);
        },
        removeZoomPlace: function () {
            return removeZoomPlace_();
        },
        addZoomPlace: function () {
            return addZoomPlace_();
        }
    };
})();

export default ZoomNetwork;
