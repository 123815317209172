/* global d3, Node, NODE_TYPE, DynamicBN, Utilities, Histogram, Arc */

import * as d3 from 'd3';
import Node from "./node";
import Network from "./network";
import {
    DECIMALS_IN_CHANCE_NODE_BARCHARTS,
    DEMORGAN_PARENT_TYPE,
    NODE_TEMPORAL_TYPE,
    NODE_TYPE
} from "./constantsMapping";
import DynamicBN from "./dynamicBN";
import Utilities from "./utilities";
import Histogram from "./histogram";
import Arc from "./arc";
import Submodel from "./submodel";

var Tooltip = {
    tooltipSelector: "div.tooltip",
    getMainTooltip: function () {
        if(d3.select(this.tooltipSelector).size() === 0){
            this.createTemplateTooltip();
        }
        return d3.select(this.tooltipSelector);
    },
    /**
     * Creating tooltip with temporal order in dynamic arcs
     * @param {type} order - temporal arc order
     */
    createContentTemporalOrder: function (order) {
        var tooltipBox = this.getMainTooltip();
        var content = tooltipBox.html("")
                .append("div")
                .attr("class", "row");
        //title
        content.append("div")
                .attr("class", "col")
                .append("titletooltip")
                .html('<i class="fa fa-info-circle" aria-hidden="true"></i> Temporal order');
        //bootstrap grid divider
        content.append("div")
                .attr("class", "w-100");
        //outcomes with bar charts
        content.append("div")
                .attr("class", "col")
                .attr("id", "outcomesTooltip")
                .text(order);
    },
    /**
     * Creating tooltip with outcomes value
     * @param {D3 Object data} node - node data
     * @returns {String} - html code
     */
    createContentTooltipValue: function (node) {
        var values = node.values;
        var htmlCode = "<titleTooltip><i class='fa fa-info-circle' aria-hidden='true'></i> Value</titleTooltip><br/>";
        if(Node.Evidence.isVirtualEvidence(node.evidence)){
            values = node.evidence.map((d, index)=>{
                return {
                    outcomeIndex: index,
                    value: Number(d)
                };
            });
        }
        var tooltipBox = this.getMainTooltip();
        tooltipBox.html("");
        if (node.isMultidimensional) {
            htmlCode += 'The result is a matrix indexed by: ';
            var networkHandle = node.network ? node.network : Network.getCurrentNetwork();
            for (var x = 0; x < node.indexingParentsIds.length; x++) {
                htmlCode += Node.getNodeIdFromHandle(parseInt(node.indexingParentsIds[x].node, 10), networkHandle);
                htmlCode += " ";
            }
            htmlCode += "? (Click to open Value)";
            tooltipBox.html(htmlCode);
        } else {
            const stateLabels = Node.getStateLabels(node);
            if (stateLabels.length === 0) {
                if (node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU]) && node.indexingParentsIds.length === 2) {
                    var outcomeTMP = Node.Outcome.getOutcomeIndexingParents(node, 0);
                    var valuesTMP = new Array(outcomeTMP.length);
                    for (var i = 0; i < outcomeTMP.length; i++) {
                        htmlCode += outcomeTMP[i] + " = " + values[i].value + "<br/>";
                        valuesTMP[i] = {outcomeId: outcomeTMP[i], value: values[i].value};
                    }
                    values = valuesTMP;
                    //return htmlCode;
                }
            } else if (node.checkType(NODE_TYPE.DECISION) && (typeof node.evidence !== 'undefined')) {//show if node is NODE_TYPE.DECISION and decision isn't set yet
                htmlCode = "<titleTooltip><i class='fa fa-info-circle' aria-hidden='true'></i> Decision</titleTooltip><br/>";
                htmlCode += stateLabels[node.evidence] + "<br/>";
                tooltipBox.html(htmlCode);
                return htmlCode;
            }
//        for (var i = 0; i < values.length; i++) {
//            htmlCode += values[i].outcomeId + " = " + parseFloat(values[i].value.toFixed(2)) + "<br/>";
//        }
            var content = tooltipBox.html("")
                    .append("div")
                    .attr("class", "row");
            //title
            var titleTooltip = '<i class="fa fa-info-circle" aria-hidden="true"></i> Value';
            if(Node.Evidence.isVirtualEvidence(node.evidence)){
                titleTooltip = '<i class="fa fa-info-circle" aria-hidden="true"></i> Virtual Evidence';
            }
            content.append("div")
                    .attr("class", "col-12")
                    .append("titletooltip")
                    .html(titleTooltip);
            if (typeof node.sampleStats !== "undefined") {
                let statsContainer = content.append("div").attr("class", "col-12");
                const statsPrecision = 6;
                statsContainer.append("div").attr("id", `${node.id}-mean-stat`).text(`Mean=${node.sampleStats[0].toPrecision(statsPrecision)}`);
                statsContainer.append("div").attr("id", `${node.id}-stddev-stat`).text(`StdDev=${node.sampleStats[1].toPrecision(statsPrecision)}`);
            }
            //outcomes with bar charts
            var singleBar = content.append("div")
                    .attr("class", "col-12")
                    .attr("id", "outcomesTooltip")
                    .selectAll("div")
                    .data(values)
                    .enter()
                    .append("div");

            singleBar.append("span")
                    .attr("class", "progress-label")
                    .text(function (x, i) {
                        if (node.isConstantEquation) {
                            return "Value";
                        }
                        return stateLabels[i];
                    });
            singleBar.append("span")
                    .attr("class", "pull-right")
                    .text(function (x, i) {
                        return x.value;
                    });
            singleBar.append("div")
                .style("display", function(d) {
                    return (node.nodeType === NODE_TYPE.EQUATION) && (node.isConstantEquation || node.isDeterministicEquation) ? "none" : "";
                })
                    .attr("class", "progress")
                    .attr("class", "progressTooltip")
                    .append("div")
                    .attr("class", "progress-bar")
                    .attr("class", "progress-barTooltip")
                    .attr("role", "progressbar")
                    .attr("aria-valuemin", 0)
                    .attr("aria-valuemax", 100)
                    .style("width", function (x) {
                        if (node.checkType(NODE_TYPE.DECISION)) {
                            return (100 * (x.value - Node.valueRangeDecision.minUtility) / (Node.valueRangeDecision.maxUtility - Node.valueRangeDecision.minUtility)) + "%";
                        } else if (node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU]) && node.indexingParentsIds.length === 2) {
                            var minMax = Node.minMaxUtilityHelper(node);
                            return  (100 * (x.value - minMax.minUtility) / (minMax.maxUtility - minMax.minUtility)) + "%";
                        } else if (node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU])) {
                            var minMax = Node.minMaxUtilityHelper(node);
                            return (100 * (x.value - minMax.minUtility) / (minMax.maxUtility - minMax.minUtility)) + "%";
                        } else if (node.checkType(NODE_TYPE.EQUATION)) {
                            if (node.isValueDiscretized || node.values.length === 1) {
                                return (x.value * 100) + "%";
                            }
                        } else {//ellipse
                            return Math.round(x.value * 100) + "%";
                        }
                    });
        }
        return htmlCode;
    },
    lineBreaksToBr: function (text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
    /**
     * Creating tooltip with outcomes description
     * @param {String} value - content of sticky note
     * @returns {String} - html code
     */
    showTooltipStickyNote: function (value) {
        let div = this.getMainTooltip();
        var htmlCode = "<titleTooltip><i class='fa fa-info-circle' aria-hidden='true'></i> Annotation</titleTooltip><br/>";
        htmlCode += this.lineBreaksToBr(value) + "<br/>";
        div.html(htmlCode);
    },
    temporalAreaChartTooltip: function (tooltipSelector, node) {
        var tooltip = d3.select(tooltipSelector);
        tooltip.html("");
        tooltip.append("div")
                .attr("class", "col")
                .style("padding-left", 0)
                .style("padding-right", 0)
                .style("padding-bottom", "0.2rem")
                .append("titletooltip")
                .html('<i class="fa fa-info-circle" aria-hidden="true"></i> Value');
        var chartContainer = tooltip.append("div")
                .attr("id", "outcomesTooltip")
                .attr("class", "col")
                .style("padding-left", 0)
                .style("padding-right", 0);
        DynamicBN.AreaChart.createAreaChart(chartContainer, node, {
            isTooltip: true,
            showXAxis: false,
            width: 150,
            containerCouldInvisible: false,
            labelFont: {
                color: "black",
                size: "1em"
            }
        });
    },
    /**
     * Schow tooltip if mouse is in GenieSymbols.StatusNodeIcon symbol
     * @param {type} callback - function creating tooltip data
     * @param {type} d - data
     */
    setMouseover: function (callback, d) {
        var div = this.getMainTooltip();
        div.transition()
                .duration(200)
                .style("opacity", 1);
        if(typeof callback !== 'function'){
            callback = false;
        }
        if(callback){
            callback(d);
        }
        var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, "#tooltipValue", d3.event.pageX, d3.event.pageY);
        div.style("left", (translate.x) + "px")
                .style("top", (translate.y) + "px");
    },
    setMouseout: function () {
        var div = this.getMainTooltip();
        div.transition()
                .duration(200)
                .style("opacity", 0);
    },
    nodeNameTooltip: function (name) {
        var div = this.getMainTooltip();
        div.html("");
        div.text(name);
        div.transition()
                .duration(200)
                .style("opacity", 1);
        var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, "#tooltipValue", d3.event.pageX, d3.event.pageY);
        div.style("left", (translate.x) + "px")
                .style("top", (translate.y) + "px");
    },
    deMorganCircleTooltip: function (value, leak, prior) {
        var secondValueTitle = typeof leak !== "undefined" ? "Leak" : "Prior belief";
        var secondValue = typeof leak === "undefined" ? prior : leak;
        var div = this.getMainTooltip();
        div.html("");
        var htmlCode = `<titleTooltip><i class='fa fa-info-circle' aria-hidden='true'></i> DeMorgan</titleTooltip><br/>
                        Value = ${value}<br/>
                        ${secondValueTitle} = ${secondValue}`;
        div.html(htmlCode);
        div.transition()
                .duration(200)
                .style("opacity", 1);
        var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, "#tooltipValue", d3.event.pageX, d3.event.pageY);
        div.style("left", (translate.x) + "px")
                .style("top", (translate.y) + "px");
    },
    deMorganMarkerTooltip: function (data1, data2) {
        var div = this.getMainTooltip();
        let getDeMorganParentIndex = function (parentID, nodeData) {
            return nodeData.parents.findIndex(p => p.data()[0].id === parentID);
        };
        var title = data1.from.length > 1 ? `Multiple arcs (${data1.from.length})` : `Arc - ${DEMORGAN_PARENT_TYPE.getName(data1.to[0].deMorganParentType[getDeMorganParentIndex(data1.from[0].id, data1.to[0])])}`;
        if(typeof data2 !== "undefined"){
            if(data1.fromArc !== data2.toArc && data1.toArc !== data2.fromArc){
                console.error(`Arc data is incorrect.`);
                return;
            }
            let fromSubmodel = d3.select(`#${data1.fromArc}`).data()[0];
            let toSubmodel = d3.select(`#${data1.toArc}`).data()[0];
            title = `${toSubmodel.name} <--> ${fromSubmodel.name}`;
        }
        div.html("");
        var htmlCode = `<titleTooltip><i class='fa fa-info-circle' aria-hidden='true'></i> ${title}</titleTooltip><br/>`;
        var marker1 = ``;
        for (var i = 0; i < data1.from.length; i++) {
            let parentIndex = getDeMorganParentIndex(data1.from[i].id, data1.to[i]);
            marker1 += `${data1.from[i].name} -> ${data1.to[i].name}<br/>`;
            if(data1.from.length > 1 || typeof data2 !== "undefined"){
                marker1 += `${DEMORGAN_PARENT_TYPE.getName(data1.to[i].deMorganParentType[parentIndex])},`;
            }
            marker1 += `Weight: ${d3.format(".0%")(data1.to[i].deMorganParentWeight[parentIndex])}<br/><br/>`;
        }
        let marker2 = "";
        if (typeof data2 !== "undefined") {
            for (var i = 0; i < data2.from.length; i++) {
                let parentIndex = getDeMorganParentIndex(data2.from[i].id, data2.to[i]);
                marker2 += `${data2.from[i].name} -> ${data2.to[i].name}<br/>`;
                marker2 += `${DEMORGAN_PARENT_TYPE.getName(data2.to[i].deMorganParentType[parentIndex])},`;
                marker2 += `Weight: ${d3.format(".0%")(data2.to[i].deMorganParentWeight[parentIndex])}<br/><br/>`;
            }
        }
        if (marker2 !== "") {
            htmlCode = `${htmlCode} <div class="d-flex flex-row justify-content-around">
                        <div class="p-1">
                            ${marker2}
                        </div>
                        <div class="p-1">
                            ${marker1}
                        </div></div>`;
        } else {
            htmlCode = `${htmlCode} ${marker1}`;
        }
        div.html(htmlCode);
        div.transition()
                .duration(200)
                .style("opacity", 1);
        var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, "#tooltipValue", d3.event.pageX, d3.event.pageY);
        div.style("left", (translate.x) + "px")
                .style("top", (translate.y) + "px");
    },
    addNodeNameTooltip: function () {
        //node name tooltip
        let nodes = d3.selectAll(Utilities.getGTypeSelector(Node.TYPE));
        let submodels = d3.selectAll(Utilities.getGTypeSelector(Submodel.TYPE));
        nodes.selectAll("g[id^=d3plus-textBox-]")
                .on("mouseover", d => typeof d.data.name !== "undefined" ? this.nodeNameTooltip(d.data.name) : null)
                .on("mouseout", () => this.setMouseout());
        submodels.selectAll("g[id^=d3plus-textBox-]")
                .on("mouseover", d => typeof d.data.name !== "undefined" ? this.nodeNameTooltip(d.data.name) : null)
                .on("mouseout", () => this.setMouseout());
    },
    /*
     * Create histogram place in tooltip
     * @param {type} tooltipSelector - CSS selector where histogram will create
     * @param {type} nodeData - data fomr d3.select().data()[0]
     * @param {type} histogramWidth - histogram width
     * //optional args
     * @param {type} barsStyleCSS[default=""] - bars style ex.: "fill: white"
     * @param {type} axisClassCSS[default=""] - axis class with style
     */
    showHistogram: function (tooltipSelector, nodeData, histogramWidth, axisClassCSS, barsStyleCSS) {
        var theData = nodeData.values;
        // SETUP
        var container = d3.select(tooltipSelector);
        container.html("");

        var title = d3.select(tooltipSelector)
                .append("div")
                .append("titleTooltip");
        title.append("i")
                .attr("class", "fa fa-info-circle")
                .attr("aria-hidden", "true");
        title.append("span")
                .text(" Value");

        if (typeof nodeData.discretizationInterval !== "undefined") {
            const leftInterval = nodeData.discretizationInterval[0].boundary;
            const rightInterval = nodeData.discretizationInterval[nodeData.discretizationInterval.length - 1].boundary;
            container.append("div")
                .html(`<div class="d-flex flex-column">
                        <div class="mr-2"><b>Bounds: </b> (${leftInterval}, ${rightInterval})</div>
                      </div>`);
        }

        if (typeof nodeData.sampleStats !== "undefined") {
            let precision = 8;
            container.append("div")
                .html(`<div class="d-flex flex-column">
                        <div class="mr-2"><b>Mean = </b> ${nodeData.sampleStats[2].toPrecision(precision)}</div>
                        <div><b>StdDev = </b> ${nodeData.sampleStats[3].toPrecision(precision)}</div>
                        <div class="mr-2"><b>Min = </b> ${nodeData.sampleStats[0].toPrecision(precision)}</div>
                        <div><b>Max = </b> ${nodeData.sampleStats[1].toPrecision(precision)}</div>
                      </div>`);
        }

        var svg = container.append("div")
                .classed("d-flex justify-content-around", true)
                .append("svg")
                .attr("width", histogramWidth)
                .attr("height", "150px");

        var margin = {top: 20, right: 20, bottom: 20, left: 40};


        var g = svg.append("g")
                .attr("transform", Utilities.getTransformString(margin.left, margin.top));
        var bounds = svg.node().getBoundingClientRect(),
                width = bounds.width - margin.left - margin.right,
                height = bounds.height - margin.top - margin.bottom;
        if (nodeData.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
            Histogram.drawErrorBarChart(g, nodeData, width, height, axisClassCSS, barsStyleCSS);
        } else {
            Histogram.drawHistogram(g, theData, width, height, axisClassCSS, barsStyleCSS);
        }


    },
    /**
     * Filling tooltip about parents or children in other submodel
     * @param {type} node - d3.select().dat[0]
     * @param {type} invisibleType - parent or child
     */
    tooltipInvisibleNode: function (node, invisibleType) {
        var titleText = " Parents in other submodel";
        if (invisibleType === Arc.Invisible.INVISIBLE_CHILD) {
            titleText = " Children in other submodel";
        }
        var tooltipBox = d3.select("div.tooltip");
        tooltipBox.html("");
        var title = tooltipBox.append("titleTooltip");
        title.append("i").attr("class", "fa fa-info-circle").attr("aria-hidden", "true");
        title.append("span").text(titleText).append("br");
        if (invisibleType === Arc.Invisible.INVISIBLE_CHILD) {
            node.invisibleChildren.forEach((d) => tooltipBox.append("span").text(d3.select("#" + d).data()[0].name).append("br"));
        }
        if (invisibleType === Arc.Invisible.INVISIBLE_PARETN) {
            node.invisibleParents.forEach((d) => tooltipBox.append("span").text(d3.select("#" + d).data()[0].name).append("br"));
        }
    },
    createTemplateTooltip: function () {
        $('body').append('<div class="tooltip" id="tooltipValue" style="opacity: 0; left: 0px; top: 0px;"></div>');
    }
};

export default Tooltip;
