import { BAYESBOX_MODE } from "./constantsMapping";
import * as SimpleBar from './simplebar-5.0.4/simplebar';
import CustomClass from "./customClass";
import StartApp from "./startAppInfo";
import Arc from "./arc";
import LeftColumn from "./leftColumn";
import Utilities from "./utilities";
import GenieSymbols from "./genieSymbols";
import ScrollBar from "./scrollBar";
import Node from "./node";
import Network from "./network";
import User from "./user";
import Cooke from "./cooke";
import Blur from "./blur";
import Tutorial from "./tutorial";
import Cases from "./cases";
import {CardsManager} from "./gauge";
import config from "./config";
import Toasts from "./toasts";

/* global Network, d3, Arc, LeftColumn, Utilities, HideColumns, GenieSymbols, ScrollBar, Node, RightColumn, CustomClass, StartApp, SimpleBar, MODE, BAYESBOX_MODE, CardsManager */

/**
 * Function running after load all html document
 */
$(document).ready(function () {
    if (BAYESBOX_MODE.isDashboard()) {
        Blur.disableUI();
    }
    SimpleBar.removeObserver();
    CustomClass.setClass();
    if (!BAYESBOX_MODE.isDashboard()) {
        $('#helloModal').on('show.bs.modal', function (e) {
            var postFunction = Tutorial.prepareModal("startTutorialButton", "closeHelloModalButton");
            $('#helloModal').unbind('hidden.bs.modal');
            $('#helloModal').on('hidden.bs.modal', function (e) {
                if (postFunction !== null) {
                    postFunction();
                }
            });
        });
    }

    //set repo title adn hello description
    StartApp.loadAppInfo();
    Utilities.moveToFrontBackInit();
    if (!BAYESBOX_MODE.isDashboard()) {
        var d = Network.addSVG();

        //arrow define
        Arc.markerDefine();
        GenieSymbols.StatusRColumnIcon.initHTMLsymbols();
        GenieSymbols.FilterGraphIcon.initHTMLsymbols();
    }

    function runGraph() {
        Network.setAutoUpdate(!StartApp.showUpdate());
        Network.updateMethodMenu(StartApp.showUpdate());
        if (StartApp.getStartAppNetwork() !== null) {
            var startAppNetworkHandle = parseInt(StartApp.getStartAppNetwork(), 10);
            Network.setCurrentNetwork(startAppNetworkHandle, Network.getNetworkName(startAppNetworkHandle));
            Network.runIfRendered(function () {
                var startAppEvidence = StartApp.getStartAppEvidence();
                var cases = {
                    name: "",
                    id: "",
                    evidence: startAppEvidence
                };
                Cases.apply(cases, false, StartApp.getStartAppFunctionAfterEvidenceLoaded());
                StartApp.cleanStartAppNetworkInfo();
            });
        } else {
            StartApp.getPermalinkHandle() !== null ? Network.setCurrentNetwork(StartApp.getPermalinkHandle()) : Network.getDefaultNetwork();
        }
        LeftColumn.addNetworkList(StartApp.getNetworkList());
    }
    function runDashboard() {
        var emptyDashboardList = function () {
                CardsManager.init();
            };
        if (Utilities.hasURLParameterByName("id")) {
            $("#otherAccordion").hide();
            let dashboardID = Utilities.getURLParameterByName("id");
            let loadDefinedDashboard = function () {
                if (!CardsManager.dashboardNotExist()) {
                    let dashboardToLoad = CardsManager.getDashboardStructure();
                    var backupCooke = StartApp.getAllCookeValues();
                    let evidenceArray = JSON.parse(backupCooke.evidence);
                    StartApp.setStartAppData(dashboardToLoad.handle, Array.isArray(evidenceArray)? evidenceArray : []);
                    StartApp.setAppTitle(dashboardToLoad.name);
                }
                CardsManager.init();
            };
            CardsManager.loadDashboardStructure("id=" + dashboardID, loadDefinedDashboard, emptyDashboardList);
        } else {
            emptyDashboardList();
        }
    }

    StartApp.runWhenReady(function () {
        //if bayesbox is empty
        //redirect to networks.html if admin
        //redirect to nonetworks.html otherwise
        if (StartApp.getCategories().length === 0) {
            if ((User.isAdmin() || User.isExplicitCategoryAccess()) && window.pathname !== "/networks.html") {
                window.location.replace("/networks.html");
            } else if (window.pathname !== "/noNetworks.html") {
                window.location.replace("/noNetworks.html");
            }
        }
        Toasts.init();
        if (BAYESBOX_MODE.isDashboard()) {
            runDashboard();
        } else {
            runGraph();
        }
    }, true);

    if (BAYESBOX_MODE.isDashboard()) {
        ScrollBar.initScrollBars([false, true, true]);
    } else {
        LeftColumn.initHandlers();
    }
    Node.Evidence.InputEvidenceModal.init();
});
