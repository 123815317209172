import './redirectToLogin';
window.$ = $;
window.mode = "GRAPH";
import * as d3 from 'd3';
window.d3 = d3;
import 'bootstrap/dist/js/bootstrap';
import './notSupported';
import BayesBoxAjax from "./bayesboxAjax";
window.BayesBoxAjax = BayesBoxAjax;
import Request from "./request";
window.Request = Request;
import Blur from "./blur";
window.Blur = Blur;
import PortraitAlert from "./portraitAlert";
window.PortraitAlert = PortraitAlert;
import './utilities';
import './histogram';
import './nodeShape';
import './dragNode';
import './node';
import Node from "./node";
window.Node = Node;
import './geometric';
import Arc from "./arc";
window.Arc = Arc;
import './leftColumn';
import './dynamicBN';
import './log';
import './constantsMapping';
import DashboardsDropdownList from './dashboardsDropdownList';
window.DashboardsDropdownList = DashboardsDropdownList;
import './network';
import Network from "./network";
window.Network = Network;
import Submodel from "./submodel";
window.Submodel = Submodel;
import './descriptions';
import './genieSymbols';
import './updateAdapter';
import RightColumn from "./rightColumn";
window.RightColumn = RightColumn;
import HideColumns from "./hideColumns";
window.HideColumns = HideColumns;
import './startAppInfo';
import './logo';
import './footnote';
import './scrollBar';
import './customClass';
import './horizontalBarChart';
import './zoomNetwork';
import ZoomNetwork from "./zoomNetwork";
window.ZoomNetwork = ZoomNetwork;
import './tooltip';
import './user';
import './lineChart';
import './pieChart';
import './warningCloseBrowser';
import './gauge';
import './cooke';
import './tutorial';
import './barChartTemplate';
import './probabilityOfEvidence';
import './cases';
import './oAuth';
import './evidenceSummary';
import './toasts';
import './graphFilter';
import './serviceWorkerHandler'
import './drawD3';