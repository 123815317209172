import * as d3 from 'd3';
import Submodel from "./submodel";
import Utilities from "./utilities";
import Node from "./node";
import GenieSymbols from "./genieSymbols";
import {NODE_TYPE} from "./constantsMapping";

/* global Submodel, Utilities, d3, Node, NODE_TYPE, GenieSymbols */

var GraphFilter = (function () {
    let lastNetworkHandle = null;
    const Source = {
        NODE: 0,
        SUBMODEL: 1,
        OUTCOME: 2
    };
    const modalId = "graphFilterModal";
    const findBtnId = "findElementsBtn";
    const findInputId = "findElementsInput";
    const menuBtn = `<a class="nav-link headerRight" href="javascript:void(0)" data-toggle="modal" data-target="#${modalId}"><i class="fas fa-binoculars-solid"></i> Find</a>`;
    const btnLocalizationSelector = "#findInGraphBtn";
    const filterResultListId = "filterResultList";
    const notFoundId = "notFoundId";

    const modal = `<div class="modal fade" id="${modalId}" tabindex="-1" role="dialog" style="overflow-x: hidden;overflow-y: auto;">
                    <div class="modal-dialog modal-dialog-scrollable" role="document">
                        <div class="modal-content modalBayesbox">
                            <div class="modal-header">
                                <h5 class="modal-title">Find</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body mx-3">
                                <div class="text-center col-md-12">
                                    <h4>Find what:</h4>                                                   
                                </div>
                                <div class="col-md-12 global" id="filterBox">
                                    <!-- Search form -->
                                    <div class="input-group input-group-sm col-md-12 filterForm">
                                        <input id=${findInputId} class="form-control py-2 border-right-0 border" type="search" placeholder="search">
                                        <span class="input-group-append blueButtonGroup">
                                            <button id=${findBtnId} class="btn btn-outline-secondary border-left-0 border font-weight-bold" type="button">
                                                Find
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div id="${filterResultListId}">
                                </div>
                                <div class="text-center m-2" id="${notFoundId}">
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>`;

    $(() => {
        //document.querySelector(btnLocalizationSelector).insertAdjacentHTML("afterbegin", menuBtn);
        document.body.insertAdjacentHTML("afterbegin", modal);
    });

    d3.select(`#${findBtnId}`).on('click', () => {
        let searchText = d3.select(`#${findInputId}`).property("value");
        showFoundElements(searchText);
    });
    d3.select(`#${findInputId}`).on('keyup', () => {
        if (d3.event.keyCode === 13) {
            let searchText = d3.select(`#${findInputId}`).property("value");
            showFoundElements(searchText);
        }
    });
    //clear list if network was changed
    $(`#${modalId}`).on('show.bs.modal', () => {
        if(lastNetworkHandle === null || lastNetworkHandle !== Network.getCurrentNetwork()){
            clearList();
        }
        lastNetworkHandle = Network.getCurrentNetwork();
    });
    // focus to input
    $(`#${modalId}`).on('shown.bs.modal', () => {
        document.getElementById(findInputId).focus();
    });

    function showNotFound(searchText) {
        d3.select(`#${notFoundId}`)
                .append("span")
                .text(`'${searchText}' not found`);
    }

    function clearNotFound() {
        d3.select(`#${notFoundId}`).html("");
    }

    function clearList() {
        d3.select(`#${filterResultListId}`).html("");
    }

    function getIconByNodeType(type) {
        switch (type) {
            case NODE_TYPE.CPT:
            case NODE_TYPE.NOISY_MAX:
            case NODE_TYPE.NOISY_ADDER:
            case NODE_TYPE.DEMORGAN:
                return GenieSymbols.FilterGraphIcon.ellipse;
                break;
            case NODE_TYPE.DECISION:
                return GenieSymbols.FilterGraphIcon.rectangle;
                break;
            case NODE_TYPE.UTILITY:
            case NODE_TYPE.MAU:
                return GenieSymbols.FilterGraphIcon.hexagon;
                break;
            case NODE_TYPE.TRUTH_TABLE:
                return GenieSymbols.FilterGraphIcon.ring;
                break;
            case NODE_TYPE.EQUATION:
                return GenieSymbols.FilterGraphIcon.equationEllipse;
                break;
            default:
                console.error("Bad node type.");
                break;
        }
    }

    function showFoundElements(searchText) {
        clearNotFound();
        let elements = filter(searchText);
        clearList();
        if(elements.length === 0){
            showNotFound(searchText);
            return;
        }
        let infoCard = d3.select(`#${filterResultListId}`)
                .selectAll(".foundElement")
                .data(elements)
                .enter()
                .append("div")
                .classed("foundElement d-flex flex-column", true)
                .on('click', (d) => {
                    $(`#${modalId}`).modal('hide');
                    Node.blinkElement(d.self.node());
                    if(d.source === Source.OUTCOME){
                        let position = d.self.node().getBoundingClientRect();
                        Node.showDefinition(d.self.data()[0], position.x + position.width, position.y);
                    }
                });
        infoCard.append("div")
                .html(d => {
                    switch (d.source) {
                        case Source.SUBMODEL:
                            return `Text: ${GenieSymbols.FilterGraphIcon.roundedRectangle} ${d.text}`;
                            break;
                        case Source.NODE:
                            return `Text: ${getIconByNodeType(d.self.data()[0].nodeType)} ${d.text}`;
                            break;
                        case Source.OUTCOME:
                            return `Text: ${GenieSymbols.FilterGraphIcon.tableWithSelectedRowIcon} [${d.text}]`;
                            break;
                    }
                });
        infoCard.append("div")
                .classed("text-muted card-subtitle", true)
                .html(d => {
                    let d3Data = d.self.data()[0];
                    switch (d.source) {
                        case Source.SUBMODEL:
                            if (d3Data.submodelOfSubmodel === Submodel.MAIN_SUBMODEL) {
                                return `Location: ${GenieSymbols.FilterGraphIcon.roundedRectangle} (main model)`;
                            }
                            let submodelParent = Submodel.submodel_tree.get(d3Data.submodelOfSubmodel);
                            return `Location: ${GenieSymbols.FilterGraphIcon.roundedRectangle} ${submodelParent.name}`;
                            break;
                        case Source.NODE:
                            let submodel = Submodel.BFSTree(d3Data.handle, Submodel.MAIN_SUBMODEL);
                            if (submodel.handle === Submodel.MAIN_SUBMODEL) {
                                return `Location: ${GenieSymbols.FilterGraphIcon.roundedRectangle} (main model)`;
                            }
                            return `Location: ${GenieSymbols.FilterGraphIcon.roundedRectangle} ${submodel.name}`;
                            break;
                        case Source.OUTCOME:
                            return `Location: ${getIconByNodeType(d.self.data()[0].nodeType)} ${d3Data.name}`;
                            break;
                    }
                });
        infoCard.append("hr")
                .classed("m-0", true);
    }

    function nodeFilter(nodeDat, searchText) {
        let text = null;
        let searchTextLC = searchText.toLowerCase();
        let outcomeFilter = nodeDat.outcome.filter(o => Utilities.matcher(o.toLowerCase(), searchTextLC));
        if (Utilities.matcher(nodeDat.name.toLowerCase(), searchTextLC)) {
            text = nodeDat.name;
        } else if (Utilities.matcher(nodeDat.id.toLowerCase(), searchTextLC)) {
            text = nodeDat.id;
        } else if (outcomeFilter.length > 0) {
            text = outcomeFilter;
        }
        return text;
    }

    function submodelFilter(submodelDat, searchText) {
        let text = null;
        let searchTextLC = searchText.toLowerCase();
        if (Utilities.matcher(submodelDat.name.toLowerCase(), searchTextLC)) {
            text = submodelDat.name;
        } else if (Utilities.matcher(submodelDat.id.toLowerCase(), searchTextLC)) {
            text = submodelDat.id;
        }
        return text;
    }

    function filter(searchText) {
        let nodes = d3.selectAll(Utilities.getGTypeSelector(Node.TYPE))
                .filter(n => nodeFilter(n, searchText) !== null)
                .nodes()
                .map(n => {
                    let self = d3.select(n);
                    let foundText = nodeFilter(self.data()[0], searchText);
                    return {
                        source: Array.isArray(foundText) ? Source.OUTCOME : Source.NODE,
                        text: foundText.toString(),
                        self: self
                    };
                });
        let submodels = d3.selectAll(Utilities.getGTypeSelector(Submodel.TYPE_RECTANGLE))
                .filter(n => submodelFilter(n, searchText) !== null)
                .nodes()
                .map(n => {
                    let self = d3.select(n);
                    let foundText = submodelFilter(self.data()[0], searchText);
                    return {
                        source: Source.SUBMODEL,
                        text: foundText,
                        self: self
                    };
                });


        return d3.merge([nodes, submodels]);
    }
    return {
        filter
    };
})();

export default GraphFilter;
