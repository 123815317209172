
/* global Utilities, Geometric, d3, d3plus, GenieSymbols, Histogram, NODE_TYPE, Network, Panels, DynamicBN, NODE_TEMPORAL_TYPE, DEMORGAN_SLIDERS_DEF_COLORS */

import * as d3 from 'd3';
import Utilities from "./utilities";
import Network from "./network";
import GenieSymbols from "./genieSymbols";
import DynamicBN from "./dynamicBN";
import Geometric from "./geometric";
import Histogram from "./histogram";
import {NODE_TEMPORAL_TYPE, NODE_TYPE} from "./constantsMapping";
import Panels from "./barChartTemplate";
import Node from "./node";
import * as d3plus from 'd3plus-text';


var EllipseDecorator = {
    innerEllipseMargin: 3, //margin between ellipse and inner ellipse
    textWidthInnerEquationChar: 9,
    innerEllipse: function (nodes) {
        nodes.append("ellipse")
                .attr("cx", function (d) {
                    return (d.x + (d.width / 2));
                })
                .attr("cy", function (d) {
                    return (d.y + (d.height / 2));
                    ;
                })
                .attr("rx", function (d) {
                    return (d.width / 2) - EllipseDecorator.innerEllipseMargin;
                })
                .attr("ry", function (d) {
                    return (d.height / 2) - EllipseDecorator.innerEllipseMargin;
                })
                .style("fill", function (d) {
                    return d.bgColor;
                })
                .style("stroke-width", function (d) {
                    return d.borderWidth / 2;
                })
                .style("stroke", function (d) {
                    return d.borderColor;
                });
    },
    equationTextConstant: function (nodes) {
        nodes.append("text")
                .attr('x', '1.5')
                .attr('y', '9')
                .style("font-style", "normal")
                .style("font-weight", "bold")
                .style("font-size", EllipseDecorator.textWidthInnerEquationChar + "px")
                .style("line-height", "1.25")
                .style("font-family", "sans-serif")
                .style("fill", "black")
                .style("fill-opacity", "1")
                .style("stroke-width", "1")
                .text("C")
                .attr("transform", function (data) {
                    var transformX = data.x + data.width / 2 - EllipseDecorator.textWidthInnerEquationChar / 2;
                    var transformY = data.y + EllipseDecorator.innerEllipseMargin;
                    return Utilities.getTransformString(transformX, transformY);
                });
    },
    sinusSymbolEquationDeterministic: function (nodes) {
        const symbolEquationWidth = 10;
        nodes.append("path")
                .attr('d', 'M 0,' + symbolEquationWidth / 2 + ' V 0 H ' + symbolEquationWidth / 2 + ' V ' + symbolEquationWidth / 2 + ' H ' + symbolEquationWidth + ' V 0')
                .attr("stroke", "black")
                .attr("stroke-width", "1")
                .attr("fill", "none")
                .attr('stroke-linecap', "butt")
                .attr('stroke-linejoin', 'miter')
                .attr('stroke-opacity', 1)
                .attr("transform", function (data) {
                    var marginTop = 2;
                    var transformX = data.x + data.width / 2 - symbolEquationWidth / 2;
                    var transformY = data.y + EllipseDecorator.innerEllipseMargin + marginTop;
                    return Utilities.getTransformString(transformX, transformY);
                });
    },
    deMorganEllipse: function (nodes) {
        Network.updateDeMorganColors(nodes, true);
        nodes.each((d, index, divs) => {
            var x = (d.x + (d.width / 2) - GenieSymbols.BarChartSymbol.smallSymbolHeight / 2);
            var y = (d.y + 1);
            GenieSymbols.BarChartSymbol.smallCircleWithColors(x, y, d.id, 0, d3.select(divs[index]));
        });
        //remove unnecesary transform
        nodes.selectAll(".deMorganColorCircle").attr("transform",null);
    }
};
var BarChartDecorator = {
    deMorganColor: function (nodes) {
        Network.updateDeMorganColors(nodes, true);
    }
};
function Flyweight (type) {
    switch (type) {
        case FlyWeightFactory.FlyweightTypeEnum.ellipse:
            this.calculateArcPoints = Geometric.calculateArcPointsEllipse;
            this.circleCutSelfishArcs = DynamicBN.SelfishCurve.ellipseCircleCutSelfishArcs;
            this.circleCutArcs = DynamicBN.ellipseCircleCut;
            this.candidateRect = Geometric.ellipseCandidateRect;
            break;
        case FlyWeightFactory.FlyweightTypeEnum.rectangle:
            this.calculateArcPoints = Geometric.calculateArcPointsRectangle;
            this.circleCutSelfishArcs = DynamicBN.SelfishCurve.rectangleCircleCutSelfishArcs;
            this.circleCutArcs = DynamicBN.rectangleCircleCut;
            this.candidateRect = Geometric.rectangleCandidateRect;
            break;
        case FlyWeightFactory.FlyweightTypeEnum.diamond:
            this.calculateArcPoints = Geometric.calculateArcPointsDiamond;
            this.candidateRect = Geometric.hexagonCandidateRect;
            break;
    }
};
//https://www.dofactory.com/javascript/flyweight-design-pattern
var FlyWeightFactory = (function () {
    var flyweights = {};

    return {

        get: function (type) {
            if (!flyweights[type]) {
                flyweights[type] =
                    new Flyweight(type);
            }
            return flyweights[type];
        },

        FlyweightTypeEnum: {
           ellipse: "ellipse",
           rectangle: "rectangle",
           diamond: "diamond"
        },

        getCount: function () {
            var count = 0;
            for (var f in flyweights) count++;
            return count;
        }
    };
})();
var NodeShape = {
    FONT_SIZE: 11,
    TEXT_LINE_HEIGHT: 2, //NodeShape.FONT_SIZE+2
    MAIN_SHAPE_BAYES_TEAM_ATTR: "mainShape",
    /**
     *  calculate the best rectangle for text in ellipse
     *  compute rectangles for different points using step
     * @param {nuber} cx - center X of an ellipse
     * @param {nuber} cy - center Y of an ellipse
     * @param {nuber} rx - semi-major axis of the ellipse
     * @param {nuber} ry - semi-minor axis of the ellipse
     * @param {nuber} fontSize - font size using in ellipse
     * @param {String} name - text to wrapped
     * @returns {Array} - {x: , y: , w: , h: }
     */
    getMaxPlace: function (candidateFunc, lx, ly, width, height, safeTextMargin, name, margin, fontStyle) {
        if (typeof margin === 'undefined') {
            margin = 0;
        }
        var step = Math.ceil((fontStyle["line-height"] + safeTextMargin) / 2);
        var max = {x: -1, y: -1, w: -1, h: -1, lines: []};
        var maxCharCount = 0;
        var line = 0;
        for (var blockHeight = step; blockHeight < height / 2; blockHeight += step) {
            let candidate = candidateFunc(lx, ly, width, height, blockHeight);
            line++;
            var cutName = Utilities.cutLongNameV3(name, (candidate.w - margin), false, line, fontStyle);

            if (cutName.count > maxCharCount) {
                maxCharCount = cutName.count;
                max = {
                    x: candidate.x,
                    y: candidate.y,
                    w: candidate.w,
                    h: candidate.h,
                    lines: cutName.lines
                };
                if (cutName.fit) {
                    break;
                }
            }
        }
        return max;
    },
    Ellipse: {
        create: function (nodes) {
            var shapes = nodes.append("ellipse")
                    .attr("cx", function (d) {
                        var cx = (d.x + (d.width / 2));
                        return cx;
                    })
                    .attr("cy", function (d) {
                        var cy = (d.y + (d.height / 2));
                        return cy;
                    })
                    .attr("rx", function (d) {
                        return (d.width / 2);
                    })
                    .attr("ry", function (d) {
                        return (d.height / 2);
                    })
                    .style("fill", function (d) {
                        return d.bgColor;
                    })
                    .style("stroke-width", function (d) {
                        return d.borderWidth / 2;
                    })
                    .style("stroke", function (d) {
                        return d.borderColor;
                    })
                    .attr('highlight', 'true');//node highlight when mouse over
            if(typeof BayesBoxEngine !== "undefined"){
                shapes.attr(NodeShape.MAIN_SHAPE_BAYES_TEAM_ATTR, true);
            }
            //add geometric function in parameter for calculating arcs [FLYWEIGHT]
            nodes.each(n => n.flyweight = FlyWeightFactory.get(FlyWeightFactory.FlyweightTypeEnum.ellipse));
        },
        Decorator: EllipseDecorator
    },
    Rectangle: {
        create: function (nodes) {
            var shapes = nodes.append('rect')
                    .attr('x', function (data) {
                        return data.x;
                    })
                    .attr('y', function (data) {
                        return data.y;
                    })
                    .attr('width', function (data) {
                        return data.width;
                    })
                    .attr('height', function (data) {
                        return data.height;
                    })
                    .style("fill", function (d) {
                        return d.bgColor;
                    })
                    .style("stroke-width", function (d) {
                        return d.borderWidth / 2;
                    })
                    .style("stroke", function (d) {
                        return d.borderColor;
                    })
                    .attr('highlight', 'true');//node highlight when mouse over
            if(typeof BayesBoxEngine !== "undefined"){
                shapes.attr(NodeShape.MAIN_SHAPE_BAYES_TEAM_ATTR, true);
            }
            //add geometric function in parameter for calculating arcs [FLYWEIGHT]
            nodes.each(n => n.flyweight = FlyWeightFactory.get(FlyWeightFactory.FlyweightTypeEnum.rectangle));
        }
    },
    Polygon: {
        create: function (nodes) {
            var shapes = nodes.append('polygon')
                    .attr('points', function (d) {
                        var hexagonPoints = Geometric.GetPolygonPoints(d.x, d.y, d.width, d.height);
                        var hexagonPointsString = "";
                        for (var i = 0; i < hexagonPoints.length; i++) {
                            hexagonPointsString += hexagonPoints[i].x;
                            hexagonPointsString += " ";
                            hexagonPointsString += hexagonPoints[i].y;
                            hexagonPointsString += " ";
                        }
                        return hexagonPointsString;
                    })
                    .style("fill", function (d) {
                        return d.bgColor;
                    })
                    .style("stroke-width", function (d) {
                        return d.borderWidth / 2;
                    })
                    .style("stroke", function (d) {
                        return d.borderColor;
                    })
                    .attr('highlight', 'true');//node highlight when mouse over
            if(typeof BayesBoxEngine !== "undefined"){
                shapes.attr(NodeShape.MAIN_SHAPE_BAYES_TEAM_ATTR, true);
            }
            //add geometric function in parameter for calculating arcs [FLYWEIGHT]
            nodes.each(n => n.flyweight = FlyWeightFactory.get(FlyWeightFactory.FlyweightTypeEnum.diamond));
        }
    },
    BarChart: {
        MARGIN_BAR_TOP_BOTTOM: 3,//the margin of each bar in bar chart
        AREA_CHART_HEIGHT: 26,
        Decorator: BarChartDecorator,
        getDividerHorizontalNewId: function (id) {
            return "dividerHorizontal_" + id;
        },
        getDividerHorizontalSelector: function (id) {
            return "#" + this.getDividerHorizontalNewId(id);
        },

        getDividerVerticalNewId: function (id) {
            return "dividerVertical_" + id;
        },
        getDividerVerticalSelector: function (id) {
            return "#" + this.getDividerVerticalNewId(id);
        },
        getStatesId: function (nodeId) {
            return 'states_' + nodeId;
        },
        getStatesSelector: function (nodeId) {
            return "#" + this.getStatesId(nodeId);
        },
        getStateId: function (nodeId, stateIndex) {
            return 'state_' + nodeId + '_' + stateIndex;
        },
        getStateSelector: function (id, stateIndex) {
            return "#" + this.getStateId(id, stateIndex);
        },
        getStateValueId: function (nodeId, stateIndex) {
            return 'stateValue_' + nodeId + '_' + stateIndex;
        },
        getStateValueSelector: function (id, outcome) {
            return "#" + this.getStateValueId(id, outcome);
        },
        getMultidimensionalStateValueId: function (nodeId) {
            return 'stateValue_' + nodeId + '_multidimensional';
        },
        getMultidimensionalStateValueSelector: function (nodeId) {
            return "#" + this.getMultidimensionalStateValueId(nodeId);
        },

        getValueNewId: function (id) {
            return "value_" + id;
        },
        getValueSelector: function (id) {
            return "#" + this.getValueNewId(id);
        },

        getChartsNewId: function (id) {
            return 'charts_' + id;
        },
        getChartsSelector: function (id) {
            return "#" + this.getChartsNewId(id);
        },

        getChartNewId: function (id, outcome) {
            return 'chart_' + id + '_' + Utilities.getValidId(outcome);
        },

        getNameNewId: function (id) {
            return "name_" + id;
        },
        getNameSelector: function (id) {
            return "#" + this.getNameNewId(id);
        },

        getHistogramNewId: function (id) {
            return "histogram_" + id;
        },
        getHistogramSelector: function (id) {
            return "#" + this.getHistogramNewId(id);
        },

        getHistogramBackgroundNewClass: function (id) {
            return "histogramBackground_" + id;
        },
        getHistogramBackgroundSelector: function (id) {
            return "." + this.getHistogramBackgroundNewClass(id);
        },

        getRectangleSelector: function (id) {
            return "#" + id + ">rect";
        },

        /*
         * position similar to GeNie
         * @param {D3 Object data} node - node data[0]
         * @returns {Array} - left top corner [x,y]
         */
        getBarChartBestPosition: function (node) {
            var centerNode = [(node.x + node.width / 2), (node.y + node.height / 2)];
            var leftTopCorner = [(centerNode[0] - node.template.width / 2), (centerNode[1] - node.template.height / 2)];
            var preventBlur = this.pixelSnapping({x: leftTopCorner[0], y: leftTopCorner[1]}, node.template.border);
            leftTopCorner[0] = preventBlur.x;
            leftTopCorner[1] = preventBlur.y;
            return leftTopCorner;
        },
        /*
         * prevent blur
         * @param {type} translate {x: , y: }
         * @param {type} border - number
         * @returns translate {x: , y: }
         *
         */
        pixelSnapping: function (translate, border) {
            var partOfPixelX = parseFloat(((translate.x - Math.floor(translate.x) + border - Math.floor(border)).toFixed(1)));
            var partOfPixelY = parseFloat(((translate.y - Math.floor(translate.y) + border - Math.floor(border)).toFixed(1)));
            if(partOfPixelX < 1){
                translate.x += parseFloat((1 - partOfPixelX).toFixed(1));
                translate.x = parseFloat((translate.x).toFixed(1));
            }
            if(partOfPixelY < 1){
                translate.y += parseFloat((1 - partOfPixelY).toFixed(1));
                translate.y = parseFloat((translate.y).toFixed(1));
            }
            return translate;
        },
        /**
         * Draw number in constant equation
         * @param {D3 Object data} nodeD3Self - single node
         * @param {number} value - node value
         */
        drawConstantEquation: function (nodeD3Self, value) {
            var node = nodeD3Self.data()[0];
            var nodeID = Node.getNodeIdFromObject(node);
            this.refreshMainBarChartTemplate(node);
            //#####################################################
            //##################### NODE NAME #####################
            //#####################################################
            var name = Utilities.cutLongNameV3(node.name, node.template.rightTopPanel.width, false, -1, node.template.fontStyles);
            if (nodeD3Self.select('g#' + this.getNameNewId(nodeID)).size() === 0) {
                nodeD3Self.append('g').attr('id', this.getNameNewId(nodeID));
            }
            if (nodeD3Self.select('g#' + this.getValueNewId(nodeID)).size() === 0) {
                nodeD3Self.append('g').attr('id', this.getValueNewId(nodeID));
            }
            var textData = [{
                    "text": Utilities.addBreakLines(name),
                    "name": node.name
                }];
            Utilities.addTextBox(textData,
                    "middle",
                    "middle",
                    node.template.rightTopPanel,
                    node.template.fontStyles,
                    node.textColor,
                    this.getNameSelector(nodeID));
            //############################################################
            //##################### HORIZONTAL LINE ######################
            //############################################################
            //add horizontal line separate
            nodeD3Self.append('line')
                    .attr('x1', node.template.horizontalLine.x1)
                    .attr('y1', node.template.horizontalLine.y1)
                    .attr('x2', node.template.horizontalLine.x2)
                    .attr('y2', node.template.horizontalLine.y2)
                    .attr('id', this.getDividerHorizontalNewId(nodeID))
                    .style("stroke-width", node.template.border)
                    .style("stroke", node.borderColor);
            //############################################################
            //##################### Value ################################
            //############################################################
            textData = [{
                    "text": value,
                    "name": node.name
                }];
            Utilities.addTextBox(textData,
                    "middle",
                    "middle",
                    node.template.bottomPanel,
                    node.template.fontStyles,
                    node.textColor,
                    this.getValueSelector(nodeID));
            //############################################################
            //##################### SMALL NODE SYMBOL ####################
            //############################################################
            var transformY = (node.template.leftTopPanel.height - GenieSymbols.BarChartSymbol.smallSymbolHeight) / 2;
            GenieSymbols.BarChartSymbol.smallEquationEllipse(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeID, transformY);
            //#####################################################
            //####### BarChart improving position #################
            //#####################################################
            var newPosition = this.getBarChartBestPosition(node);
            var transformX = newPosition[0] - node.x;
            var transformY = newPosition[1] - node.y;
            nodeD3Self.attr("transform", Utilities.getTransformString(transformX, transformY));
        },
        drawErrorBarChart: function (nodeD3Self) {
            var node = nodeD3Self.data()[0];
            var nodeID = Node.getNodeIdFromObject(node);
            this.refreshMainBarChartTemplate(node);
            var name = Utilities.cutLongNameV3(node.name, node.template.rightTopPanel.width, false, -1, node.template.fontStyles);
            if (nodeD3Self.select('g#' + this.getNameNewId(nodeID)).size() === 0) {
                nodeD3Self.append('g').attr('id', this.getNameNewId(nodeID));
            }

            //create node name based on new name (with extra spaces)
            let textData = [{
                "text": Utilities.addBreakLines(name),
                "name": node.name
            }];
            Utilities.addTextBox(textData,
                "middle",
                "middle",
                node.template.rightTopPanel,
                node.template.fontStyles,
                node.textColor,
                this.getNameSelector(nodeID));
            // var histogramGradient = this.getBarChartGradient(0, {
            //     colors: [{start: 'RGB(0, 0, 0)', stop: "RGB(0, 255, 0)"}],
            //     direction: {
            //         x1: "0%",
            //         y1: "0%",
            //         x2: "0%",
            //         y2: "100%"
            //     },
            //     colorIdFunction: (prefix, number, direction) => "gradHistogram" + number + direction.x1.replace("%", "") + direction.y1.replace("%", "") + direction.x2.replace("%", "") + direction.y2.replace("%", "")
            // });
            if (nodeD3Self.select('g#' + this.getHistogramNewId(nodeID)).size() === 0) {
                nodeD3Self.append("rect").attr("class", this.getHistogramBackgroundNewClass(nodeID));
                nodeD3Self.append('g').attr('id', this.getHistogramNewId(nodeID));
            } else {
                var g = d3.select('g#' + this.getHistogramNewId(nodeID));
                Histogram.updateErrorBarChart(g, "", node, node.template.bottomPanel.width, node.template.bottomPanel.height);
                var transformY = (node.template.leftTopPanel.height - GenieSymbols.BarChartSymbol.smallSymbolHeight) / 2;
                GenieSymbols.BarChartSymbol.smallEquationEllipse(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeID, transformY);
                return;
            }
            // //**************************************************************************
            // //*****************SCALE AND POSITION **************************************
            // //**************************************************************************
            // //histogram background

            d3.select(this.getHistogramBackgroundSelector(nodeID))
                .attr("x", node.template.bottomPanel.x)
                .attr("y", node.template.bottomPanel.y)
                .attr("width", node.template.bottomPanel.width)
                .attr("height", node.template.bottomPanel.height)
                .style("fill", "white")
                .style("stroke-width", 0.5)
                .style("stroke", "black");
            var g = d3.select('g#' + this.getHistogramNewId(nodeID));
            Histogram.drawErrorBarChart(g, node, node.template.bottomPanel.width, node.template.bottomPanel.height, "axisBChartNode", "fill: #00F; stroke-width: .5; stroke: #000");
            var rectSize = d3.select(this.getHistogramBackgroundSelector(nodeID)).node().getBoundingClientRect();
            var histSize = d3.select(this.getHistogramSelector(nodeID)).node().getBoundingClientRect();
            var histogramMargin = 1;//histogram margin
            var scaleHisW = histSize.width / (rectSize.width + histogramMargin);
            if (histSize.width > rectSize.width) {
                scaleHisW = rectSize.width / (histSize.width + histogramMargin);
            }
            var scaleHisH = histSize.height / (rectSize.height + histogramMargin);
            if (histSize.height > rectSize.height) {
                scaleHisH = rectSize.height / (histSize.height + histogramMargin);
            }
            var scale = scaleHisW;
            if (scaleHisH < scaleHisW) {
                scale = scaleHisH;
            }
            g.attr("transform", Utilities.getTransformString(0, 0, scale));
            // //POSITION
            rectSize = d3.select(this.getHistogramBackgroundSelector(nodeID)).node().getBoundingClientRect();
            histSize = d3.select(this.getHistogramSelector(nodeID)).node().getBoundingClientRect();
            var centerHistogramX = (rectSize.width - histSize.width) / 2;//centering histogram in rectangle (node)
            var translateX = rectSize.left - histSize.left + centerHistogramX;
            var centerHistogramY = (rectSize.height - histSize.height) / 2;//centering histogram in rectangle (node)
            var translateY = rectSize.top - histSize.top + centerHistogramY;
            g.attr("transform", Utilities.getTransformString(translateX, translateY, scale));
            //############################################################
            //##################### SMALL NODE SYMBOL ####################
            //############################################################
            var transformY = (node.template.leftTopPanel.height - GenieSymbols.BarChartSymbol.smallSymbolHeight) / 2;
            GenieSymbols.BarChartSymbol.smallEquationEllipse(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeID, transformY);
            //#####################################################
            //####### BarChart improving position #################
            //#####################################################
            var newPosition = this.getBarChartBestPosition(node);
            var transformX = newPosition[0] - node.x;
            var transformY = newPosition[1] - node.y;
            nodeD3Self.attr("transform", Utilities.getTransformString(transformX, transformY));

            // //SCALE

        },
        /*
         * Draw histogram in equation node
         * @param {D3 Object data} nodeD3Self - node
         */
        drawHistogramEquation: function (nodeD3Self) {
            var node = nodeD3Self.data()[0];
            var nodeID = Node.getNodeIdFromObject(node);
            this.refreshMainBarChartTemplate(node);
            var name = Utilities.cutLongNameV3(node.name, node.template.rightTopPanel.width, false, -1, node.template.fontStyles);
            if (nodeD3Self.select('g#' + this.getNameNewId(nodeID)).size() === 0) {
                nodeD3Self.append('g').attr('id', this.getNameNewId(nodeID));
            }

            //create node name based on new name (with extra spaces)
            let textData = [{
                    "text": Utilities.addBreakLines(name),
                    "name": node.name
                }];
            Utilities.addTextBox(textData,
                    "middle",
                    "middle",
                    node.template.rightTopPanel,
                    node.template.fontStyles,
                    node.textColor,
                    this.getNameSelector(nodeID));
            var histogramGradient = this.getBarChartGradient(0, {
                colors: [{start: 'RGB(0, 0, 0)', stop: "RGB(0, 255, 0)"}],
                direction: {
                    x1: "0%",
                    y1: "0%",
                    x2: "0%",
                    y2: "100%"
                },
                colorIdFunction: (prefix, number, direction) => "gradHistogram" + number + direction.x1.replace("%", "") + direction.y1.replace("%", "") + direction.x2.replace("%", "") + direction.y2.replace("%", "")
            });
            if (nodeD3Self.select('g#' + this.getHistogramNewId(nodeID)).size() === 0) {
                nodeD3Self.append("rect").attr("class", this.getHistogramBackgroundNewClass(nodeID));
                nodeD3Self.append('g').attr('id', this.getHistogramNewId(nodeID));
            } else {
                var g = d3.select('g#' + this.getHistogramNewId(nodeID));
                Histogram.updateHistogram(g, "fill: " + histogramGradient.fill + "; stroke-width: .5; stroke: " + histogramGradient.stroke, node.values, node.template.bottomPanel.width, node.template.bottomPanel.height);
                var transformY = (node.template.leftTopPanel.height - GenieSymbols.BarChartSymbol.smallSymbolHeight) / 2;
                GenieSymbols.BarChartSymbol.smallEquationEllipse(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeID, transformY);
                return;
            }
            //**************************************************************************
            //*****************SCALE AND POSITION **************************************
            //**************************************************************************
            //histogram background
            d3.select(this.getHistogramBackgroundSelector(nodeID))
                    .attr("x", node.template.bottomPanel.x)
                    .attr("y", node.template.bottomPanel.y)
                    .attr("width", node.template.bottomPanel.width)
                    .attr("height", node.template.bottomPanel.height)
                    .style("fill", "white")
                    .style("stroke-width", 0.5)
                    .style("stroke", "black");
            var g = d3.select('g#' + this.getHistogramNewId(nodeID));
            Histogram.drawHistogram(g, node.values, node.template.bottomPanel.width, node.template.bottomPanel.height, "axisBChartNode", "fill: " + histogramGradient.fill + "; stroke-width: .5; stroke: " + histogramGradient.stroke);

            //SCALE
            var rectSize = d3.select(this.getHistogramBackgroundSelector(nodeID)).node().getBoundingClientRect();
            var histSize = d3.select(this.getHistogramSelector(nodeID)).node().getBoundingClientRect();
            var histogramMargin = 1;//histogram margin
            var scaleHisW = histSize.width / (rectSize.width + histogramMargin);
            if (histSize.width > rectSize.width) {
                scaleHisW = rectSize.width / (histSize.width + histogramMargin);
            }
            var scaleHisH = histSize.height / (rectSize.height + histogramMargin);
            if (histSize.height > rectSize.height) {
                scaleHisH = rectSize.height / (histSize.height + histogramMargin);
            }
            var scale = scaleHisW;
            if (scaleHisH < scaleHisW) {
                scale = scaleHisH;
            }
            g.attr("transform", Utilities.getTransformString(0, 0, scale));
            //POSITION
            rectSize = d3.select(this.getHistogramBackgroundSelector(nodeID)).node().getBoundingClientRect();
            histSize = d3.select(this.getHistogramSelector(nodeID)).node().getBoundingClientRect();
            var centerHistogramX = (rectSize.width - histSize.width) / 2;//centering histogram in rectangle (node)
            var translateX = rectSize.left - histSize.left + centerHistogramX;
            var centerHistogramY = (rectSize.height - histSize.height) / 2;//centering histogram in rectangle (node)
            var translateY = rectSize.top - histSize.top + centerHistogramY;
            g.attr("transform", Utilities.getTransformString(translateX, translateY, scale));
            //############################################################
            //##################### SMALL NODE SYMBOL ####################
            //############################################################
            var transformY = (node.template.leftTopPanel.height - GenieSymbols.BarChartSymbol.smallSymbolHeight) / 2;
            GenieSymbols.BarChartSymbol.smallEquationEllipse(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeID, transformY);
            //#####################################################
            //####### BarChart improving position #################
            //#####################################################
            var newPosition = this.getBarChartBestPosition(node);
            var transformX = newPosition[0] - node.x;
            var transformY = newPosition[1] - node.y;
            nodeD3Self.attr("transform", Utilities.getTransformString(transformX, transformY));
        },
        /**
         * Prepare node for drawing histogram
         * @param {D3 Object data} node - node data d3.select("#" + nodeId).data()[0];
         */
        clearNodeForHistogram: function (node) {
            var nodeID = Node.getNodeIdFromObject(node);
            d3.select(this.getDividerHorizontalSelector(nodeID)).remove();
            d3.select(this.getDividerVerticalSelector(nodeID)).remove();
            d3.select(this.getStatesSelector(nodeID)).remove();
            d3.select(this.getValueSelector(nodeID)).remove();
            d3.select(this.getChartsSelector(nodeID)).remove();//clear old charts
            d3.select(this.getNameSelector(nodeID)).remove();
            d3.select(this.getHistogramSelector(nodeID)).style("display", "block");
            d3.selectAll(this.getHistogramSelector(nodeID) + " > .bar").remove();
            d3.select(this.getHistogramBackgroundSelector(nodeID)).style("display", "block");
        },
        /**
         * Prepare node for drawing bar chart
         * @param {D3 Object data} node - node data d3.select("#" + nodeId).data()[0];
         */
        clearHistogram: function (node) {
            var nodeID = Node.getNodeIdFromObject(node);
            d3.select(this.getHistogramBackgroundSelector(nodeID)).style("display", "none");
            d3.select(this.getHistogramSelector(nodeID)).style("display", "none");
            d3.select(this.getValueSelector(nodeID)).remove();
            d3.select(this.getChartsSelector(nodeID)).remove();//clear old charts
        },
        /**
         * Get bar chart color like GeNie
         * @param {int} number - color number
         * @returns {String} - color code
         */
        getBarChartGradient: function (number, custom) {
            var colors = [
                {start: 'RGB(0, 0, 255)', stop: "RGB(0, 255, 255)"},
                {start: 'RGB(255, 128, 0)', stop: "RGB(255, 255, 0)"},
                {start: 'RGB(0, 160, 0)', stop: " RGB(0, 255, 0)"},
                {start: 'RGB(128, 0, 128)', stop: "RGB(255, 0, 255)"},
                {start: 'RGB(64, 0, 0)', stop: "RGB(255, 0, 0)"},
                {start: 'RGB(128, 128, 128)', stop: "RGB(255, 255, 255)"}
            ];
            var direction = {
                x1: "0%",
                y1: "0%",
                x2: "100%",
                y2: "0%"
            };
            var dealutProperties = {
                colors: colors,
                direction: direction,
                colorIdFunction: (prefix, number, direction) => prefix + number + direction.x1.replace("%","") + direction.y1.replace("%","") + direction.x2.replace("%","") + direction.y2.replace("%","")
            };

            var prop = undefined;
            for (prop in custom) {
                dealutProperties[prop] = custom[prop];
            }

            var defs = d3.select("defs");
            if (defs.size() === 0) {
                defs = d3.select("body")
                        .append("svg")
                        .style("width", "0")
                        .style("height", "0")
                        .style("position", "absolute");
            }
            var prefixID = "gradBarChart";
            number = number % (colors.length);
            var id = dealutProperties.colorIdFunction(prefixID, number, dealutProperties.direction);
            if (defs.select("#"+id).size() === 0) {
                var gradient = defs.append("linearGradient")
                        .attr("id", id)
                        .attr("gradientFor", "barChart")
                        .attr("x1", dealutProperties.direction.x1)
                        .attr("y1", dealutProperties.direction.y1)
                        .attr("x2", dealutProperties.direction.x2)
                        .attr("y2", dealutProperties.direction.y2);
                gradient.append("stop")
                        .attr("offset", "0%")
                        .style("stop-color", dealutProperties.colors[number].start)
                        .style("stop-opacity", "1");
                gradient.append("stop")
                        .attr("offset", "100%")
                        .style("stop-color", dealutProperties.colors[number].stop)
                        .style("stop-opacity", "1");
            }
            return {
                fill: "url(#" + id + ")",
                stroke: dealutProperties.colors[number].start
            };
        },
        refreshMainBarChartTemplate: function (node) {
            //############################################################################
            //##################### REFRESH RECTANGLE X,Y,WIDTH,HEIGHT ###################
            //############################################################################
            d3.select(this.getRectangleSelector(Node.getNodeIdFromObject(node)))
                    .attr('x', node.template.x)
                    .attr('y', node.template.y)
                    .attr('width', node.template.width)
                    .attr('height', node.template.height);
        },
        /*
         * Draw outcomes in bar chart mode
         * @param {String} nodeD3Self - node
         * @param {String} name - node name
         * @param {Array} outcomes - outcomes[[outcomeName,percent]]
         * @param {Object} rangeValue - Object {min: ,max: } for nodes where value isn't percent.
         */
        drawOutcomes: function (nodeD3Self, name, outcomes, rangeValue) {
            var node = nodeD3Self.data()[0];
            var nodeId = Node.getNodeIdFromObject(node);
            var allTextData = [];
            var param = {
                x: node.x,
                y: node.y,
                width: node.barChartRectWidth,
                height: node.barChartRectHeight,
                border: node.borderWidth / 2,
                padding: 1,
                fontStyles: Utilities.getFontStyles(node.fontSize, node.bold)
            };
            if (node.checkType(NODE_TYPE.EQUATION) && node.temporalType !== NODE_TEMPORAL_TYPE.PLATE) {
                if (node.isValueDiscretized === false) {
                    this.clearNodeForHistogram(node);
                    if (node.isConstantEquation === true || typeof node.evidence !== 'undefined' || (node.values.length === 1 && typeof node.values[0].outcomeIndex === 'undefined')) {
                        this.clearHistogram(node);
                        node.template = Panels.getTemplate3P(param, 1, GenieSymbols.BarChartSymbol.smallSymbolWidth, node.isConstantEquation);
                        this.drawConstantEquation(nodeD3Self, node.values[0].value);
                        return;
                    }
                    node.template = Panels.getTemplate3P(param, 1, GenieSymbols.BarChartSymbol.smallSymbolWidth, node.isConstantEquation);
                    this.drawHistogramEquation(nodeD3Self);
                    return;
                } else {
                    this.clearHistogram(node);
                }
            }
            d3.select(this.getChartsSelector(nodeId)).remove();//clear old charts
            d3.select(this.getNameSelector(nodeId)).remove();
            if (node.isMultidimensional === true && node.nodeType !== NODE_TYPE.UTILITY) {
                outcomes = Node.getStateLabels(node).map((state, index) => {
                    return [Node.getStateSelector(node, index), null, state];
                });
            }
            if (node.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                param.fontStyles["font-size"] = NodeShape.FONT_SIZE * 2;
                param.fontStyles["line-height"] = param.fontStyles["font-size"] + NodeShape.TEXT_LINE_HEIGHT;
                node.template = Panels.getTemplate4P(param, outcomes.length, GenieSymbols.BarChartSymbol.smallSymbolWidth);
                param.fontStyles["font-size"] = NodeShape.FONT_SIZE;
                param.fontStyles["line-height"] = NodeShape.FONT_SIZE + NodeShape.TEXT_LINE_HEIGHT;
                node.template.fontStyles["font-size"] = NodeShape.FONT_SIZE;
                node.template.fontStyles["line-height"] = NodeShape.FONT_SIZE + NodeShape.TEXT_LINE_HEIGHT;
                if (node.checkType(NODE_TYPE.EQUATION)) {
                    node.template = Panels.getTemplate3P(param, 1, GenieSymbols.BarChartSymbol.smallSymbolWidth, false);
                    this.drawErrorBarChart(nodeD3Self);
                    return
                }
            } else {
                node.template = Panels.getTemplate4P(param, outcomes.length, GenieSymbols.BarChartSymbol.smallSymbolWidth);
            }
            d3.select(this.getStatesSelector(nodeId)).remove();//clear old outcomes
            //############################################################################
            //##################### REFRESH RECTANGLE X,Y,WIDTH,HEIGHT ###################
            //############################################################################
            this.refreshMainBarChartTemplate(node);
            //############################################################################
            //##################### BALANCE WIDTH (OUTCOME | VALUE | CHARTS) #############
            //############################################################################
            var outcomeList = [];
            for (var j = 0; j < outcomes.length; j++) {
                var currentValue = "";
                if (node.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                    currentValue = "";
                } else if (outcomes[j][1] !== null && typeof rangeValue === 'undefined') {
                    currentValue = "" + outcomes[j][1] + "%";
                } else if (outcomes[j][1] !== null && typeof rangeValue !== 'undefined') {
                    currentValue = "" + outcomes[j][1];
                }
                outcomeList.push({
                    outcome: outcomes[j][2],
                    value: currentValue
                });
            }
            node.template = Panels.adjustVerticalLines(node.template, outcomeList, node.template.fontStyles);
            //#####################################################
            //##################### OUTCOMES ######################
            //### IMPORTANT!: CREATE OUTCOMES BEFORE NODE NAME ####
            //#####################################################
            var o = d3.select("#" + nodeId).append('g').attr('id', this.getStatesId(nodeId));
            var charts = d3.select("#" + nodeId).append('g').attr('id', this.getChartsNewId(nodeId));
            var multidimensionalGExist = false;
            for (var j = (outcomes.length - 1); j >= 0; j--) {
                o.append('g').attr('id', this.getStateId(nodeId, j));
                if (node.isMultidimensional === true) {
                    if (multidimensionalGExist === false) {
                        o.append('g').attr('id', this.getMultidimensionalStateValueId(nodeId));
                        multidimensionalGExist = true;
                    }
                } else {
                    o.append('g').attr('id', this.getStateValueId(nodeId, j));
                }
                let textData = {
                    "text": Utilities.addBreakLines(Utilities.cutLongNameV3(outcomes[j][2], node.template.leftBottomPanel.rows[j].left.width, false, -1, node.template.fontStyles)),
                    "name": outcomes[j][2],
                    rect: node.template.leftBottomPanel.rows[j].left,
                    fontStyles: node.template.fontStyles,
                    color: node.textColor,
                    selector: this.getStateSelector(nodeId, j),
                    moveToGroup: true,
                    data: node,
                    textAnchor: "start",
                    verticalAlign: "middle",

                };
                allTextData.push(textData);

            }
            //#####################################################
            //################ CHARTS AND VALUES ##################
            //#####################################################
            if (node.isMultidimensional === true) {
                let textData = {
                        "text": 'The result is a multi-dimensional table; click Value to examine it.',
                        "name": 'The result is a multi-dimensional table; click Value to examine it.',
                        rect: node.template.rightBottomPanel,
                        fontStyles: node.template.fontStyles,
                        color: node.textColor,
                        selector: this.getMultidimensionalStateValueSelector(nodeId),
                        moveToGroup: true,
                        data: node,
                        textAnchor: "middle",
                        verticalAlign: "middle"
                    };
                allTextData.push(textData);
            } else if (node.temporalType === NODE_TEMPORAL_TYPE.PLATE){
                if (node.checkType(NODE_TYPE.EQUATION)) {
                        this.clearNodeForHistogram(node);
                        node.template = Panels.getTemplate3P(param, 1, GenieSymbols.BarChartSymbol.smallSymbolWidth, false);
                        this.drawErrorBarChart(nodeD3Self);

                    // return;
                } else {
                    for (var j = 0; j < outcomes.length; j++) {
                        if (outcomes[j][1] !== null) {
                            var areaX = node.template.rightBottomPanel.rows[j].x;
                            var areaY = node.template.rightBottomPanel.rows[j].y + (node.template.buttomTopRowMargin / 2);
                            var areaWidth = node.template.rightBottomPanel.rows[j].width;
                            var areaHeight = node.template.rightBottomPanel.rows[j].height - node.template.buttomTopRowMargin;
                            DynamicBN.AreaChart.createAreaChartOnly(charts, node, outcomes[j][0], areaX, areaY, areaWidth, areaHeight);
                        }
                    }
                }

            } else {
                for (var j = (outcomes.length - 1); j >= 0; j--) {
                    if (outcomes[j][1] !== null) {
                        var unit = "%";
                        var maxBarWidth = node.template.rightBottomPanel.rows[j].width;
                        var barValue = outcomes[j][1] * maxBarWidth / 100;
                        if (typeof rangeValue !== 'undefined') {//for nodes where value isn't percent
                            var per = 100 * (outcomes[j][1] - rangeValue.min) / (rangeValue.max - rangeValue.min);//percent value
                            barValue = per * maxBarWidth / 100;
                            unit = "";
                        }
                        let gradient = this.getBarChartGradient(j);
                        charts.append('rect')
                                .attr('id', this.getChartNewId(nodeId, outcomes[j][0]))
                                .attr('x', node.template.rightBottomPanel.rows[j].x)
                                .attr('y', node.template.rightBottomPanel.rows[j].y + (node.template.buttomTopRowMargin / 2))
                                .attr('width', barValue)
                                .attr('height', node.template.rightBottomPanel.rows[j].height - (node.template.buttomTopRowMargin))
                                .style('fill', gradient.fill)
                                .style('stroke', gradient.stroke)
                                .style("stroke-width", 0.5);
                        let textData = {
                            "text": Utilities.addBreakLines(Utilities.cutLongNameV3(outcomes[j][1] + unit, node.template.leftBottomPanel.rows[j].right.width, false, -1, node.template.fontStyles)),
                            "name": outcomes[j][1] + unit,
                            rect: node.template.leftBottomPanel.rows[j].right,
                            fontStyles: node.template.fontStyles,
                            color: node.textColor,
                            selector: this.getStateValueSelector(nodeId, j),
                            moveToGroup: true,
                            data: node,
                            textAnchor: "end",
                            verticalAlign: "middle",
                        };
                        allTextData.push(textData);
                    }
                }
            }
            //#####################################################
            //##################### NODE NAME #####################
            //#####################################################
            var nodeName = Utilities.cutLongNameV3(name, node.template.rightTopPanel.width, false, -1, node.template.fontStyles);
            if (d3.select("#" + nodeId).select('g#' + this.getNameNewId(nodeId)).size() === 0) {
                d3.select("#" + nodeId).append('g').attr('id', this.getNameNewId(nodeId));
            }
            //create node name based on new name (with extra spaces)
            let textNodeData = {
                "text": Utilities.addBreakLines(nodeName),
                "name": node.name,
                rect: node.template.rightTopPanel,
                fontStyles: node.template.fontStyles,
                color: node.textColor,
                selector: this.getNameSelector(nodeId),
                moveToGroup: true,
                data: node,
                textAnchor: "middle",
                verticalAlign: "middle",
            };
            allTextData.push(textNodeData);
            //#####################################################
            //##################### ADD ALL TEXTS #################
            //#####################################################
            //tem text group in node
            let textPerformanceGroup = d3.select(`#${nodeId}`)
                    .append("g")
                    .classed("textPerformanceGroup", true);
            Utilities.addTextBox(allTextData,
                    d => d.textAnchor,
                    d => d.verticalAlign,
                    {
                        x: d => d.rect.x,
                        y: d => d.rect.y,
                        width: d => d.rect.width,
                        height: d => d.rect.height
                    },
                    {
                        "font-size": d => d.fontStyles["font-size"],
                        "line-height": d => d.fontStyles["line-height"],
                        "font-weight": d => d.fontStyles["font-weight"]
                    },
                    d => d.color,
                    textPerformanceGroup.node());
            // move outcomes text inside correct group
            textPerformanceGroup.selectAll(`g[id^='d3plus-textBox-']`)
                    .filter(d => d.data.moveToGroup)
                    .each(function (d) {
                        delete d.data.moveToGroup;
                        d3.select(d.data.selector).append(() => this).datum(d);
                    });
            textPerformanceGroup.remove();
            //#####################################################
            //##################### UNDERLINE EVIDENCE #####################
            //#####################################################
            // TODO - update selector - it should work with intervals and point values
            //if(typeof node.evidence !== "undefined" && !Array.isArray(node.evidence)){
            //    d3.select(Network.getSVG_CSS_SELECTOR()).select(this.getStateSelector(nodeId, node.evidence) + " text").attr("text-decoration", "underline");
            //}
            //############################################################
            //##################### SMALL NODE SYMBOL ####################
            //############################################################
            var transformY = (node.template.leftTopPanel.height - GenieSymbols.BarChartSymbol.smallSymbolHeight) / 2;
            switch (node.nodeType) {
                case NODE_TYPE.CPT:
                case NODE_TYPE.NOISY_MAX:
                case NODE_TYPE.NOISY_ADDER:
                    GenieSymbols.BarChartSymbol.smallEllipse(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeId, transformY);
                    break;
                case NODE_TYPE.DECISION:
                    GenieSymbols.BarChartSymbol.smallRectangle(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeId, transformY);
                    break;
                case NODE_TYPE.UTILITY:
                case NODE_TYPE.MAU:
                    GenieSymbols.BarChartSymbol.smallHexagon(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeId, transformY);
                    break;
                case NODE_TYPE.TRUTH_TABLE:
                    GenieSymbols.BarChartSymbol.smallRing(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeId, transformY);
                    break;
                case NODE_TYPE.EQUATION:
                    GenieSymbols.BarChartSymbol.smallEquationEllipse(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeId, transformY);
                    break;
                case NODE_TYPE.DEMORGAN:
                    GenieSymbols.BarChartSymbol.smallCircleWithColors(node.template.leftTopPanel.x, node.template.leftTopPanel.y, nodeId, transformY);
                    break;
                default:
                    console.error("Bad node type.");
                    break;
            }

            //############################################################
            //##################### HORIZONTAL LINE ######################
            //############################################################
            //add horizontal line separate
            d3.select(this.getDividerHorizontalSelector(nodeId)).remove();
            d3.select("#" + nodeId).append('line')
                    .attr('x1', node.template.horizontalLine.x1)
                    .attr('y1', node.template.horizontalLine.y1)
                    .attr('x2', node.template.horizontalLine.x2)
                    .attr('y2', node.template.horizontalLine.y2)
                    .attr('id', this.getDividerHorizontalNewId(nodeId))
                    .style("stroke-width", node.template.padding / 3)
                    .style("stroke", node.borderColor);
            //############################################################
            //##################### VERTICAL LINE ########################
            //############################################################
            d3.select(this.getDividerVerticalSelector(nodeId)).remove();
            d3.select("#" + nodeId).append('line')
                    .attr('x1', node.template.verticalLine.x1)
                    .attr('y1', node.template.verticalLine.y1)
                    .attr('x2', node.template.verticalLine.x2)
                    .attr('y2', node.template.verticalLine.y2)
                    .attr('id', this.getDividerVerticalNewId(nodeId))
                    .style("stroke-width", node.template.padding / 3)
                    .style("stroke", node.borderColor);
            //#####################################################
            //####### BarChart improving position #################
            //#####################################################
            var newPosition = this.getBarChartBestPosition(node);
            var transformX = newPosition[0] - node.x;
            var transformY = newPosition[1] - node.y;
            nodeD3Self.attr("transform", Utilities.getTransformString(transformX, transformY));
        },
        create: function (nodes) {
            const rectHeight = (NodeShape.FONT_SIZE + NodeShape.TEXT_LINE_HEIGHT) * 2 + (NodeShape.FONT_SIZE + NodeShape.TEXT_LINE_HEIGHT + this.MARGIN_BAR_TOP_BOTTOM * 2) * 2;//2 text lines for node name + 2 text lines for all outcomes
            nodes.each(data => {
                var param = {
                    x: data.x,
                    y: data.y,
                    width: data.barChartRectWidth,
                    height: data.barChartRectHeight,
                    border: data.borderWidth / 2,
                    padding: 1,
                    fontStyles: Utilities.getFontStyles(data.fontSize, data.bold)
                };
                data.template = Panels.getTemplate4P(param, data.outcome.length, GenieSymbols.BarChartSymbol.smallSymbolWidth);
            });
            var shapes = nodes.append("rect")
                    .classed("mainRect", true)
                    .attr('x', data => data.template.x)
                    .attr('y', data => data.template.y)
                    .attr('width', data => data.template.width)
                    .attr('height', data => data.template.height)
                    .style("fill", data => data.bgColor)
                    .style("stroke-width", data => data.template.border)
                    .style("stroke", data => data.borderColor)
                    .attr('highlight', 'true');//node highlight when mouse over
            if(typeof BayesBoxEngine !== "undefined"){
                shapes.attr(NodeShape.MAIN_SHAPE_BAYES_TEAM_ATTR, true);
            }
            //add geometric function in parameter for calculating arcs [FLYWEIGHT]
            nodes.each(n => n.flyweight = FlyWeightFactory.get(FlyWeightFactory.FlyweightTypeEnum.rectangle));

            //add all desriptions for nodes based on jsonData
            nodes.each((node, index, divs) => {
                //add outcomes name for node
                var outcomesForDrawing = [];
                for (var j = 0; j < node.outcome.length; j++) {
                    outcomesForDrawing.push([node.outcome[j], null, node.outcome[j]]);
                }
                this.drawOutcomes(d3.select(divs[index]), node.name, outcomesForDrawing);
            });
        }
    }
};

export default NodeShape;
