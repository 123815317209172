import Node from "./node";
/* global Node */

var Log = {
    /**
     * Clean log info
     */
    clean: function () {
        $("#appInfo").text("");
    },
    /**
     * Add log info
     * @param {String} txt - log info
     */
    write: function (txt) {
        document.getElementById('appInfo').innerHTML = txt;
    },
    showErrorModal: function (titleError, messageError) {
        $("#errorTitle").html(titleError);
        $("#errorContent").html(messageError);
        $("#loading").css('display', 'none');
        $('#errorModal').modal('show');
    },
    /**
     * Show error update and undo a network update
     * @param {String} titleError - title of the error
     * @param {String} messageError - content of the error
     */
    setEvidenceFailed: function (titleError, messageError) {
        Node.Evidence.retractLastEvidence();
        this.showErrorModal(titleError, messageError);
        this.clean();
        $("#waiting").hide();
    }
};

export default Log;
