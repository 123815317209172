import config from "./config";

var BayesBoxAjax = (function () {
    function replaceUndefinedWithNoop(functionSignature) {
        return typeof functionSignature === "undefined" ? function() {} : functionSignature;
    }

    function fixUrl(url) {
        if (typeof(config.serverUrl) !== 'undefined') {
            return config.serverUrl + url;
        }
        return url;
    }
    function request(payload, done, fail, always) {
        if (typeof(config.serverUrl) !== 'undefined') {
            payload.url = config.serverUrl + payload.url;
            payload.crossDomain = true;
            payload.xhrFields = {withCredentials: true};
        }
        $.ajax(payload)
            .done(replaceUndefinedWithNoop(done))
            .fail(replaceUndefinedWithNoop(fail))
            .always(replaceUndefinedWithNoop(always));
    }
    function jsonRequest(payload, json, done, fail, always) {
        payload.data = JSON.stringify(json);
        payload.contentType = "application/json";
        request(payload, done, fail, always);
    }
    return {
        fixUrl,
        request,
        jsonRequest
    };
})();

export default BayesBoxAjax;
