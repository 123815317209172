import 'jquery-ui-dist/jquery-ui';
import * as d3base from 'd3';
import tip from 'd3-tip';
import {
    BAYESBOX_MODE,
    CARDS_TYPE,
    DISPLAY_NODE_TYPE, LABEL_OPTION,
    NODE_DIAG_TYPE,
    NODE_TEMPORAL_TYPE,
    NODE_TYPE
} from "./constantsMapping";
import User from "./user";
import Submodel from "./submodel";
import PieChart from "./pieChart";
import {LineChart, LineChartDBN} from "./lineChart";
import Network from "./network";
import Footnote from "./footnote";
import HorizontalBarChart from "./horizontalBarChart";
import Node from "./node";
import Utilities from "./utilities";
import Blur from "./blur";
import Cases from "./cases";
import StartApp from "./startAppInfo";
import RightColumn from "./rightColumn";
import DynamicBN from "./dynamicBN";
import Toasts from "./toasts";
import WarningCloseBrowser from "./warningCloseBrowser";
import 'hummingbird-treeview';
import BayesBoxBBtags from "./ownBBtags";
import BBCodeParser from 'bbcode-parser';
import Request from "./request";
import NodeShape from "./nodeShape";
import Histogram from "./histogram";
import * as math from "mathjs";


const d3 = Object.assign(d3base, {tip});
/* global MODE, BAYESBOX_MODE, d3, BBCodeParser, BayesBoxBBtags, User, Submodel, CARDS_TYPE, PieChart, DISPLAY_NODE_TYPE, LineChart, Footnote, NODE_TEMPORAL_TYPE, HorizontalBarChart, Node, Network, Utilities, NODE_TYPE, WarningCloseBrowser, Blur, Cases, StartApp, RightColumn, LineChartDBN, DynamicBN, AUTHENTICATION_TYPE */

var MultiDashboard = null;

if (BAYESBOX_MODE.isDashboard()) {
    MultiDashboard = (function () {
        var multiDashboardBtn = null;
        function detectUnion(networks) {
            var unionMap = new Map();
            networks.forEach(net => {
                net.submodels.forEach(submodel => {
                    submodel.nodes.forEach(node => {
                        //set network
                        node.network = net.owner;
                        if (unionMap.has(node.id)) {
                            unionMap.get(node.id).push(node);
                        } else {
                            unionMap.set(node.id, [node]);
                        }
                    });
                });
            });
            return unionMap;
        }
        function detectUnionFromRightColumn() {
            var unionMap = new Map();
            var nodes = d3.select("#rightColumn").selectAll('div[type="nodeCard"]').data();
            nodes.forEach(node => {
                //set network
                if (unionMap.has(node.id)) {
                    unionMap.get(node.id).push(node);
                } else {
                    unionMap.set(node.id, [node]);
                }
            });
            return unionMap;
        }
        function addRightColumnToUnionMap(unionMap) {
            //detect conflicts between existing and incoming nodes
            var unionMapWithExistingNodes = new Map();
            unionMap.forEach((value, key) => {
                var valArray = [];
                for (var i = 0; i < value.length; i++) {
                    valArray.push(Utilities.deepCopy(value[i]));
                }
                unionMapWithExistingNodes.set(key, valArray);
            });
            d3.selectAll("div[type=nodeCard]").data()
                    .forEach(node => {
                        if (unionMapWithExistingNodes.has(node.id)) {
                            unionMapWithExistingNodes.get(node.id).push(node);
                        } else {
                            unionMapWithExistingNodes.set(node.id, [node]);
                        }
                    });
            return unionMapWithExistingNodes;
        }
        function detectConflicts(unionMap, keepOldNodes, networks) {
            var conflictsList = [];
            var mapForDetect = unionMap;
            //detect incompatibles networks
//            var incompatibleNetworks = new Set();
            var stepCountFromNetwork = new Map();
            if(DynamicBN.stepCount !== 0){
                let nets = [];
                nets.push(Network.getCurrentNetworkID());
                stepCountFromNetwork.set(DynamicBN.stepCount, nets);
            }
            networks.forEach(n => {
                if (typeof n.temporalPlate !== "undefined") {
                    if (stepCountFromNetwork.has(n.temporalPlate.steps)) {
                        stepCountFromNetwork.get(n.temporalPlate.steps).push(Network.getNetworkName(n.owner));
                    } else {
                        let nets = [];
                        nets.push(Network.getNetworkName(n.owner));
                        stepCountFromNetwork.set(n.temporalPlate.steps, nets);
                    }
                }
            });
            if(stepCountFromNetwork.size > 1){
                let error = "Dynamic networks have different steps count: ";
                stepCountFromNetwork.forEach((val, key)=>{
                    let netSteps = "[";
                    val.forEach(s => netSteps += s + ",");
                    netSteps += "] = " + key + " steps,";
                    error += netSteps;
                });
                conflictsList.push(error);
            }
            if(conflictsList.length > 0){
                return conflictsList;
            }
            //detect conflicts between existing and incoming nodes
            if (typeof keepOldNodes !== "undefined" && keepOldNodes === true) {
                mapForDetect = addRightColumnToUnionMap(unionMap);
            }
            mapForDetect.forEach((value, key, map) => {
                for (var i = 0; i < value.length - 1; i++) {
                    var arr1 = value[i].outcome;
                    var arr2 = value[i + 1].outcome;
                    if (arr1.length !== arr2.length) {//outcomes size check
                        let networkDat1 = Network.getNetworkData(value[i].network);
                        let networkDat2 = Network.getNetworkData(value[i + 1].network);
                        conflictsList.push(`Node ${value[i].name} from network ${networkDat1.category}/${networkDat1.filename} 
                                            and node ${value[i + 1].name} from network ${networkDat2.category}/${networkDat2.filename} 
                                            have different outcome count ${arr1.length} and ${arr2.length}`);
                    } else {//outcomes check
                        let networkDat1 = Network.getNetworkData(value[i].network);
                        let networkDat2 = Network.getNetworkData(value[i + 1].network);
                        let conflictMessage = "";
                        let isConflicted = false;
                        for (var j = 0; j < arr1.length; j++) {
                            if(arr1[j] !== arr2[j]){
                                if (conflictMessage === "") {
                                    let networkDat1 = Network.getNetworkData(value[i].network);
                                    let networkDat2 = Network.getNetworkData(value[i + 1].network);
                                    conflictMessage = `Node ${value[i].name} from network ${networkDat1.category}/${networkDat1.filename} 
                                            and node ${value[i + 1].name} from network ${networkDat2.category}/${networkDat2.filename} 
                                            have different outcomes: `;
                                }
                                let networkDat1 = Network.getNetworkData(value[i].network);
                                let networkDat2 = Network.getNetworkData(value[i + 1].network);
                                conflictMessage += `{${arr1[j]} and ${arr2[j]}}`;
                                isConflicted = true;
                            }
                        }
                        if(isConflicted){
                            conflictsList.push(conflictMessage);
                        }

                    }
                }
            });
            return conflictsList;
        }
        function addMultidashboardModalTemplate() {
            d3.select("body")
                    .append("div")
                    .classed("modal fade", true)
                    .attr("id", "multidashboardNetworks")
                    .attr("tabindex", "-1")
                    .attr("role", "dialog")
                    .attr("aria-labelledby", "multidashboardModalTitle")
                    .attr("aria-hidden", "true")
                    .html(`<div class="modal-dialog modal-dialog-scrollable" role="document">
                                <div class="modal-content modalBayesbox">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="multidashboardModalTitle">Multidashboard</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="hummingbird-treeview">
                                        <ul class="hummingbird-base">
                                            <li>
                                                <i class="fa fa-plus"></i>
                                                <label>
                                                    <input id="allNetworksTreeView" bayesbox-humminbird-type="rootInput" data-id="allNodesTree" type="checkbox" /> All networks
                                                </label>
                                                <ul bayesbox-humminbird-type="list">
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                      <button id="addNetworksMulti" type="button" class="btn btn-primary">OK</button>
                                    </div>
                                </div>
                            </div>`);
            var createNetworksListTree = function (dat) {
                var cardRoot = d3.select("#multidashboardNetworks");
                var ul_list_root = cardRoot.select('ul[bayesbox-humminbird-type="list"]');
                ul_list_root.selectAll("li").remove();
                var groupIdPrefix = "net_";//tvg - trre view group
                var n = ul_list_root.selectAll("li")
                        .data(dat)
                        .enter()
                        .append("li")
                        .attr("id", d => groupIdPrefix + Utilities.getValidId(d.category));
                n.append("i")
                        .attr("class", "fa fa-plus")
                        .style("font-size", "1rem")
                        .append("span")
                        .html("&nbsp;");
                var label = n.append("label");
                label.append("input")
                        .attr("id", d => "selectNetwork_" + Utilities.getValidId(d.category))
                        .attr("data-id", d => d.category)
                        .attr("type", "checkbox");
                label.append("span").html(d => d.category);
                var netLabel = n.append("ul").selectAll("li")
                        .data(d => {
                            let list = d.list;
                            list.forEach(l => l.category = d.category);
                            return list;
                        })
                        .enter()
                        .append("li")
                        .append("label");
                netLabel.append("input")
                        .attr("class", "hummingbird-end-node")
                        .attr("id", d => "handle_" + d.handle)
                        .attr("data-id", d => d.handle)
                        .attr("type", "checkbox");
                netLabel.append("span").text(d => d.filename);
                var hummingbird_root = cardRoot.select(".hummingbird-base");
                var rootListID = cardRoot.select('input[bayesbox-humminbird-type="rootInput"]').attr("id");
                $(hummingbird_root.node()).hummingbird("expandNode", {attr: "id", name: rootListID, expandParents: true});
                $(hummingbird_root.node()).hummingbird();
                var checkedNetworks = Network.getCurrentNetwork();
                for (var i = 0; i < checkedNetworks.length; i++) {
                    $("#multidashboardNetworks").hummingbird("checkNode", {attr: "data-id", name: checkedNetworks[i], expandParents: false});
                }
            };
            $('#multidashboardNetworks').on('show.bs.modal', (e) => {
                var networksList = StartApp.getNetworkList();
                var nets = [];
                Object.keys(networksList).forEach(function (key, index) {
                    let sortedList = networksList[key].sort(StartApp.networkListSortFun);
                    nets.push({category: key, list: sortedList});
                });
                createNetworksListTree(nets);
            });
            d3.select("#multidashboardNetworks")
                    .select("#addNetworksMulti")
                    .on("click", () => {
                        var newNetworks = {"id": [], "dataid": [], "text": []};
                        $("#multidashboardNetworks").hummingbird("getChecked", {list: newNetworks, onlyEndNodes: true, onlyParents: false, fromThis: false});
                        var currentNetworks = Array.from(Network.getCurrentNetwork());
                        currentNetworks = currentNetworks.map(d => Number(d));//to int
                        var newNetworksInt = newNetworks.dataid.map(d => Number(d));//to int
                        var networksToRemove = Utilities.difference(currentNetworks, newNetworksInt);
//                        removeInexistentNetworks(networksToRemove);
                        Network.setCurrentNetwork(newNetworksInt, undefined, true, () => removeInexistentNetworks(networksToRemove));
                        Network.runIfRendered(() => Network.update(undefined, CurrentDashboard.update));
                        $("#multidashboardNetworks").modal('hide');
                    });
        }
        function addMultidashboardBtn() {
            multiDashboardBtn = d3.select("*[user-menu]")
                    .select("li[user-menu-slot='4']")
                    .attr("id", "addMultidashboard")
                    .classed("nav-item text-truncate", true)
                    .attr("data-toggle", "collapse")
                    .attr("data-target", ".navbar-collapse.show")
                    .attr("title", "Multidashboard")
                    .style("display", null)
                    .append("a")
                    .attr("href", "javascript:void(0)")
                    .attr("data-toggle", "modal")
                    .attr("data-target", "#multidashboardNetworks")
                    .classed("nav-link headerRight", true);

            multiDashboardBtn.append("i")
                    .classed("fa fa-layer-group-solid", true);
            multiDashboardBtn.append("span")
                    .text(" Multidashboard");
            addMultidashboardModalTemplate();
        }
        function removeInexistentNetworks(networksArrayHandle) {
            //removeInexistentCards
            var nodesToRemove = d3.selectAll("div[type=nodeCard]")
                    .filter(d => networksArrayHandle.some(element => element === d.network));

            Radial.removeInexistentCards(nodesToRemove.data());
            PieChart.removeInexistentCards(nodesToRemove.data());
            LineChart.removeInexistentCards(nodesToRemove.data());
            TopN.removeInexistentCards(nodesToRemove.data());
            UtilityCards.removeInexistentCards(nodesToRemove.data());

            nodesToRemove.remove();
        }
        function hiddenMirroredNodes() {
            d3.selectAll("div[type=nodeCard]")
                    .classed("hiddenMirrored", false);
            var mapForDetect = addRightColumnToUnionMap(new Map());
            mapForDetect.forEach((value, key) => {
                if(value.length > 1){
                    for(var i = 1; i < value.length; i++){
                        var nodeID = Node.getNodeIdFromObject(value[i]);
                        d3.select("#" + nodeID).classed("hiddenMirrored", true);
                    }
                }
            });
        }
        function fillReturnNetworks(networksHandle) {
            var networks = networksHandle.map(n => Network.getNetworkData(n));
            d3.select("#networkDropdownList")
                    .selectAll("*").remove();
            d3.select("#networkDropdownList")
                    .selectAll("a")
                    .data(networks)
                    .enter()
                    .append("a")
                    .classed("dropdown-item", true)
                    .attr("href", "javascript:void(0)")
                    .on("click", (d) => {
                        Network.redirectToGraph(d.handle);
                    })
                    .text(n => `${n.filename.replace(".xdsl", "")} (${n.category})`);
            var mainSplitBtn = d3.select("#redirectToGraphBtn");
            if (networks.length > 1) {
                mainSplitBtn.on("click", null).attr("data-toggle", "dropdown");
                $("#redirectToGraphBtn").dropdown('update');
            } else {
                mainSplitBtn.attr("data-toggle", null).on("click", () => Network.redirectToGraph(networks[0].handle));
                $("#redirectToGraphBtn").dropdown('dispose');
            }
        }
        return {
            detectUnion: function (networks) {
                return detectUnion(networks);
            },
            detectConflicts: function (unionMap, keepOldNodes, networks) {
                return detectConflicts(unionMap, keepOldNodes, networks);
            },
            addMultidashboardBtn: function () {
                addMultidashboardBtn();
            },
            removeMultidashboardBtn: function () {
                if(multiDashboardBtn !== null){
                    multiDashboardBtn.remove();
                    multiDashboardBtn = null;
                }
            },
            hiddenMirroredNodes: function (unionMap, keepOldNodes) {
                hiddenMirroredNodes(unionMap, keepOldNodes);
            },
            fillReturnNetworks: function(networksHandle) {
                fillReturnNetworks(networksHandle);
            },
            detectUnionFromRightColumn: function () {
                return detectUnionFromRightColumn();
            }
        };
    })();
    var CardsManager = (function () {
//        var additionalCards = [];
        var columnCount = null;
        var dashboardStructure = null;
        var treeviewIDPrefix = "treeview";
        var cardTextDefaultFontSize = null;
        var cardTitleDefaultFontSize = null;
        var cardAnnotationDefaultFontSize = null;
        var NODE_TITLE_FONT_CLASS = "nodeTitleCardsCaptionFont";
        var NODE_CAPTION_FONT_CLASS = "nodeCardsCaptionFont";
        var TEXT_FONT_CLASS = "textCardsFont";
        var ANNOTATION_FONT_CLASS = "annotationCardsFont";
        var bbcodeParser = new BBCodeParser(BayesBoxBBtags.get());
        var MIN_CARDS_HEIGHT = 261;

        function getFontSizeFromPerc(sizePerc) {
            var ret = {
                value: 0,
                caption: 0,
                annotation: 0,
                cardText: 0,
                footnote: 0
            };
            var size = getDefaultCardsFontLazy();
//            if(size.cardTitle === 0){
//                return ret;
//            }
            ret = {
                value: sizePerc * size.cardTitle / 100,
                caption: sizePerc * size.cardText / 100,
                annotation: sizePerc * size.cardAnnotation / 100,
                cardText: sizePerc * size.cardText / 100,
                footnote: sizePerc * size.footnote / 100
            };
            return ret;
        }

        function updateValueGaugeFontSize(sizePerc) {
            var size = getDefaultCardsFontLazy();
            if(size.cardTitle === 0){
                return ;
            }
            Utilities.createCSSSelector("."+NODE_TITLE_FONT_CLASS, "font-size: " + getFontSizeFromPerc(sizePerc).value + "px!important");
        }
        function updateCaptionFontSize(sizePerc) {
            var size = getDefaultCardsFontLazy();
            if(size.cardText === 0){
                return ;
            }
            Utilities.createCSSSelector("."+NODE_CAPTION_FONT_CLASS, "font-size: " + getFontSizeFromPerc(sizePerc).caption + "px!important");
        }
        function updateTextFontSize(sizePerc) {
            var size = getDefaultCardsFontLazy();
            if(size.cardText === 0){
                return ;
            }
            Utilities.createCSSSelector("."+TEXT_FONT_CLASS, "font-size: " + getFontSizeFromPerc(sizePerc).cardText + "px!important");
        }
        function updateAnnotationFontSize(sizePerc) {
            var size = getDefaultCardsFontLazy();
            if(size.cardAnnotation === 0){
                return ;
            }
            Utilities.createCSSSelector("."+ANNOTATION_FONT_CLASS, "font-size: " + getFontSizeFromPerc(sizePerc).annotation + "px!important");
        }
        function updateFootnoteFontSize(sizePerc) {
            var size = getDefaultCardsFontLazy();
            if(size.footnote === 0){
                return ;
            }
            Footnote.setFontSize(getFontSizeFromPerc(sizePerc).footnote);
        }
        function resetCardColumnsClass() {
            d3.select("#gauges").style("grid-template-columns", null);
        }
        function setColumns(count) {
            columnCount = count;
            setColumnsLight(count, true);
        }
        function setColumnsLight(count, setSlideValue) {
            setSlideValue = typeof setSlideValue === "undefined" ? false : setSlideValue;
            resetCardColumnsClass();
            if(count === null || count < 0){
                return ;
            }
            d3.select("#gauges").style('grid-template-columns', `repeat(${count},1fr)`);
        }
        function createTreeView(cardRoot, nodes, getCheckboxIdFunction, options) {
            var conf = {
                showNetworks: true,
                showOutcomes: true,
                removeCheckboxexLevel: [],
                checkedNodes: null
            };
            var prop = undefined;
            for (prop in options) {
                conf[prop] = options[prop];
            }
            var ul_list_root = cardRoot.select('ul[bayesbox-humminbird-type="list"]');
            ul_list_root.selectAll("li").remove();
            var groupIdPrefix = "tvg_";//tvg - trre view group
            var networkIdPrefix = "tvgn_";//tvg - trre view group network

            var level1 = null;
            var level2 = null;
            var level3 = null;
            //level 1 - Networks
            var isMultidashboard = Network.getCurrentNetwork().length > 1;
            if (conf.showNetworks && isMultidashboard) {
                //network - nodes - outcomes
                let networksGroupsMap = new Map();
                nodes.forEach((n => {
                    networksGroupsMap.has(n.network) ? networksGroupsMap.get(n.network).push(n) : networksGroupsMap.set(n.network, [n]);
                }));
                let networksGroups = [];
                networksGroupsMap.forEach((val, key) => {
                    let net = Network.getNetworkData(key);
                    net.nodes = val;
                    networksGroups.push(net);
                });

                level1 = ul_list_root.selectAll("li")
                        .data(networksGroups)
                        .enter()
                        .append("li")
                        .classed("autoFilterParent", true)
                        .attr("id", d => networkIdPrefix + d.handle);
                level1.append("i")
                        .attr("class", "fa fa-plus")
                        .style("font-size", "1rem")
                        .style("padding-right", "1rem");
                var label_LV1 = level1.append("label");
                var checkbox_LV1_hide = conf.removeCheckboxexLevel.some(l => l === 1);
                label_LV1.append("input")
                        .attr("id", d => "selectGaugeNetwork_" + d.handle)
                        .attr("data-id", d => d.handle)
                        .style("display", d => checkbox_LV1_hide? "none" : null)
                        .attr("type", "checkbox");
                label_LV1.append("span").text(d => d.category + "/" + d.filename);
                level2 = level1.append("ul").selectAll("li")
                        .data(d => d.nodes.map(n => {
                            return {
                                nodeID: Node.getNodeIdFromObject(n),
                                name: n.name,
                                outcomes: Node.getStateLabels(n), //n.outcome,
                                bgColor: n.bgColor
                            };
                        }))
                        .enter()
                        .append("li")
                        .attr("id", d => groupIdPrefix + d.nodeID)
                        .classed("autoFilterNode", true);
            } else {
                level2 = ul_list_root.selectAll("li")
                        .data(nodes.map(n => {
                            return {
                                nodeID: Node.getNodeIdFromObject(n),
                                name: n.name,
                                outcomes: Node.getStateLabels(n), //n.outcome,
                                bgColor: n.bgColor
                            };
                        }))
                        .enter()
                        .append("li")
                        .attr("id", d => groupIdPrefix + d.nodeID)
                        .classed("autoFilterNode", true);
            }
            level2.append("i")
                    .attr("class", "fa fa-plus")
                    .style("font-size", "1rem")
                    .style("visibility", d => {
                        if (d.outcomes.length === 0 || !conf.showOutcomes) {
                            return "hidden";
                        }
                    })
                    .append("span")
                    .html("&nbsp;");
            var label = level2.append("label");
            var checkbox_LV2_hide = conf.removeCheckboxexLevel.some(l => l === 2);
            var checkboxes = label.append("input")
                    .attr("id", d => "selectGaugeNode_" + d.nodeID)
                    .attr("data-id", d => d.nodeID)
                    .attr("type", "checkbox")
                    .style("display", d => checkbox_LV2_hide? "none" : null)
                    .attr("class", d => {
                        if (d.outcomes.length === 0 || !conf.showOutcomes) {
                            return "hummingbird-end-node";
                        }
                    });
            if (conf.checkedNodes !== null) {
                checkboxes.filter(d => {
                    for (var i = 0; conf.checkedNodes.length > i; i++) {
                        if (d.nodeID === conf.checkedNodes[i]) {
                            return true;
                        }
                    }
                    return false;
                }).attr("checked", "true");
            }
            label.append("span").html(d => {
                return d.outcomes.length === 0 ? d.name : '<div class="circleTreeView" style="background-color:' + d.bgColor + '"></div>' + d.name;
            });
            //level 3 - States
            if (conf.showOutcomes) {
                level3 = level2.append("ul").selectAll("li")
                        .data(d => {
                            return d.outcomes.map((o, idx) => {
                                return {
                                    outcome: o,
                                    nodeID: d.nodeID,
                                    groupID: groupIdPrefix + d.nodeID,
                                    stateIndex: idx
                                };
                            });
                        })
                        .enter()
                        .append("li")
                        .append("label");
                var checkbox_LV3_hide = conf.removeCheckboxexLevel.some(l => l === 3);
                level3.append("input")
                        .attr("class", "hummingbird-end-node")
                        .attr("id", d => getCheckboxIdFunction(d.nodeID, d.stateIndex))
                        .style("display", d => checkbox_LV3_hide? "none" : null)
                        .attr("data-id", d => d.outcome)
                        .attr("type", "checkbox");
                level3.append("span").text(d => d.outcome);
            }
            var hummingbird_root = cardRoot.select(".hummingbird-base");
            if (conf.removeCheckboxexLevel.some(l => l === 0)) {
                cardRoot.select('input[bayesbox-humminbird-type="rootInput"]').style("display", "none");
            } else {
                cardRoot.select('input[bayesbox-humminbird-type="rootInput"]').style("display", null);
            }
            var rootListID = cardRoot.select('input[bayesbox-humminbird-type="rootInput"]').attr("id");
            $(hummingbird_root.node()).hummingbird("expandNode", {attr: "id", name: rootListID, expandParents: true});
            $(hummingbird_root.node()).hummingbird();
        }
        function getVisibleCardsData() {
            var cards = {
                config: {
                    pieChart: {
                        colors: {}
                    },
                    areaChart: {

                    },
                    gauge: {

                    }
                }
            };
            var gaugesGlobalConfig = Radial.getConfig();
            cards.config.gauge.arcColorFn = gaugesGlobalConfig.arcColorFn;
            cards.config.gauge.ticks = gaugesGlobalConfig.ticks;
            cards.config.gauge.precision = Radial.getPrecision();
            cards.config.pieChart.colors = PieChart.getColor();
            cards.config.pieChart.sort = PieChart.isSort();
            cards.config.areaChart.colors = AreaChartCards.getColor();

            cards.list = [];

            d3.selectAll("div[type=gaugeCard]").each((d, index) => {
                switch (d.type) {
                    case CARDS_TYPE.SINGLE_STATE_NODE:
                        cards.list.push( Radial.getShortData(d, index));
                        break;
                    case CARDS_TYPE.TEXT:
                        cards.list.push(TextGauge.getShortData(d, index));
                        break;
                    case CARDS_TYPE.TOPN:
                        cards.list.push(TopN.getShortData(d, index));
                        break;
                    case CARDS_TYPE.DISTRIBUTION:
                        cards.list.push(PieChart.getShortData(d, index));
                        break;
                    case CARDS_TYPE.CUMULATIVE:
                        cards.list.push(LineChart.getShortData(d, index));
                        break;
                    case CARDS_TYPE.UTILITY:
                        cards.list.push(UtilityCards.getShortData(d, index));
                        break;
                    default:
                        console.log("Undefined type of card");
                }
            });
            return cards;
        }
        function addAllCards(data) {
            if (data.length > 0) {
                $("#selectGaugagesFirst").hide();
            } else {
                $("#selectGaugagesFirst").show();
            }
            d3.select('#gauges').selectAll("div[type=gaugeCard]").remove();
            var cards = d3.select('#gauges').selectAll("div[type=gaugeCard]")
                    .data(data)
                    .enter()
                    .append("div")
                    .attr('class', 'card')
                    .attr('type', 'gaugeCard')
                    .attr('id', d => {
                        switch (d.type) {//other type edit card
                            case CARDS_TYPE.TEXT:
                                return "text";
                                break;
                            case CARDS_TYPE.TOPN:
                                return "topn";
                                break;
                            case CARDS_TYPE.SINGLE_STATE_NODE:
                                return d.selectedStateIndex ? 'gauge_' + Node.getNodeIdFromObject(d.node) + "_" + d.selectedStateIndex : 'gauge_' + Node.getNodeIdFromObject(d.node);
                                break;
                            case CARDS_TYPE.DISTRIBUTION:
                                return 'distribution_' + Node.getNodeIdFromObject(d.node);
                                break;
                            case CARDS_TYPE.CUMULATIVE:
                                return 'cumulative_' + Node.getNodeIdFromObject(d.node);
                                break;
                            case CARDS_TYPE.UTILITY:
                                return 'utility_' + Node.getNodeIdFromObject(d.node);
                                break;
                            default:
                                console.log("Undefined type of card while add all cards");
                                break;
                        }
                    });
            cards.each((data, index, elements) => {
                data.cardRoot = elements[index];
            });
            if (User.isAdmin() || User.isExplicitCategoryAccess() || StartApp.isDemoDashboardMode() || (!StartApp.isDashboardAdminOnly() && User.isLogged())) {
                var settingsBtn = cards.append("div")
                        .attr("id", "cardTopMenu")
                        .attr("title","Drag and drop to reorder dashboard cards")
                        .attr("class", "d-flex justify-content-end not_remove")
                        .style("padding",".2rem")
                        .on('mouseover', (d)=>{
                            d3.select(d.cardRoot).style("box-shadow","0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)");
                        })
                        .on('mouseout', (d)=>{
                            d3.select(d.cardRoot).style("box-shadow", null);
                        });
                settingsBtn.append("i")
                        .attr("class", "fas fa-cog cardSetting")
                        .attr("title", "Click here to configure this card")
                        .on("click", (dat, index, nodes) => {
                            DefineCardsModal.show({
                                isEdit: true,
                                data: dat
                            });
                        });
                settingsBtn.append("i")
                        .attr("class", "fas fa-times cardSetting removeCard")
                        .attr("title", "Click here to delete this card")
                        .on("click", (dat, index, nodes) => {
                            var removePanel = d3.select(dat.cardRoot)
                                    .data([dat])
                                    .append("div")
                                    .classed("removeCardConfirm", true)
                                    .append("div")
                                    .classed("d-flex h-100 justify-content-center align-items-center", true)
                                    .append("div");
                            removePanel.append("div")
                                    .classed("col-12", true)
                                    .text("Are you sure you want to remove this card?");
                            var removeBtns = removePanel.append("div")
                                    .classed("d-flex justify-content-center", true);
                            removeBtns.append("button")
                                    .attr("type", "button")
                                    .classed("btn btn-secondary", true)
                                    .on("click", d => {
                                        d3.select(d.cardRoot).select(".removeCardConfirm").remove();
                                    })
                                    .text("Cancel");
                            removeBtns.append("button")
                                    .attr("type", "button")
                                    .classed("btn btn-danger", true)
                                    .on("click", d => {
                                        d3.select(d.cardRoot).remove();
                                        CurrentDashboard.updateLocal({updateCards: false});
                                        WarningCloseBrowser.enable();
                                    })
                                    .text("Delete");
                        });
            }
            addCardBody(cards);
//            cards.append("div")
//                    .attr("class", "card-body text-center")
//                    .append("p")
//                    .attr("class", "card-text");
            if (cards.size() === 1 && columnCount === null) {
                setColumns(2);
            } else if (columnCount === null) {
                setColumns(null);
            }
        }

        function addCardBody(cards) {
            return cards.append("div")
                    .classed("cardBody h-100", true)
                    .append("div")
                    .classed("h-100 d-flex justify-content-center align-items-center", true)
                    .append("div")
                    .classed("centerBody w-100", true);
        }

        function detectValuesType(cards) {
            var getIndexingParentsCount = function (card) {
                if (typeof card.node.indexingParentsIds === "undefined") {
                    return 0;
                }
                return card.node.indexingParentsIds.length > 0 ? card.node.indexingParentsIds.length - 1 : 0;
            };
            var ret = {
                radial: cards.filter(d => d.type === CARDS_TYPE.SINGLE_STATE_NODE
                            && !d.node.isMultidimensional
                           // && typeof d.node.values[0].outcomeIndex !== 'undefined'
                            && d.node.temporalType !== NODE_TEMPORAL_TYPE.PLATE
                ),
                radialUtility: cards.filter(d => d.type === CARDS_TYPE.UTILITY
                            && !d.node.isMultidimensional
                            && typeof d.node.values[0].outcomeId !== 'undefined'
                            && d.node.values.length === 1
                            && typeof d.node.indexingParentsIds !== "undefined"
                            && getIndexingParentsCount(d) === 0
                            && ((!d.node.isEquation && typeof CardsManager.getLocalData(d).minMaxMauExpression === "undefined") || (d.node.isEquation && typeof CardsManager.getLocalData(d).minMaxMauExpression !== "undefined"))
                ),
                histogram: cards.filter(d => d.type === CARDS_TYPE.DISTRIBUTION
                            && !d.node.isMultidimensional
                            && ((typeof d.node.values[0].range !== 'undefined'
                            && d.node.temporalType !== NODE_TEMPORAL_TYPE.PLATE) || (d.node.values.length === 2
                        && d.node.temporalType === NODE_TEMPORAL_TYPE.PLATE))
                            && d.node.checkType(NODE_TYPE.EQUATION)
                ),
                horizontalBarPlot: cards.filter(d => d.type === CARDS_TYPE.DISTRIBUTION
                            && !d.node.isMultidimensional
                            && typeof d.node.values[0].outcomeId !== 'undefined'
                            && d.node.values.length > 1
                            && d.node.temporalType !== NODE_TEMPORAL_TYPE.PLATE
                ),
                constant: cards.filter(d =>
                    (d.type === CARDS_TYPE.DISTRIBUTION || d.type === CARDS_TYPE.UTILITY)
                    && !d.node.isMultidimensional
                    && typeof d.node.values[0].outcomeId !== 'undefined'
                    && d.node.values.length === 1
                    && (
                            (
                                   d.type === CARDS_TYPE.DISTRIBUTION
                                && d.node.temporalType !== NODE_TEMPORAL_TYPE.PLATE
                            )
                            ||
                            (
                                d.type === CARDS_TYPE.UTILITY
                                && typeof d.node.indexingParentsIds !== "undefined"
                                && getIndexingParentsCount(d) === 0
                                && d.node.isEquation
                                && typeof CardsManager.getLocalData(d).minMaxMauExpression === "undefined"
                            )
                       )
                ),
                multidimensional: cards.filter(d =>
                    (d.type === CARDS_TYPE.UTILITY || d.type === CARDS_TYPE.DISTRIBUTION || d.type === CARDS_TYPE.SINGLE_STATE_NODE)
                    && d.node.temporalType !== NODE_TEMPORAL_TYPE.PLATE
                    &&
                    (
                        d.type === CARDS_TYPE.UTILITY
                        || d.type === CARDS_TYPE.DISTRIBUTION
                        ||
                        (
                            d.type === CARDS_TYPE.SINGLE_STATE_NODE
                            && getIndexingParentsCount(d) > 1
                        )
                    )
                    &&
                    (
                        d.node.isMultidimensional
                        ||
                        (
                            d.type === CARDS_TYPE.UTILITY
                            && d.node.isEquation
                            && typeof CardsManager.getLocalData(d).minMaxMauExpression === "undefined"
                            && getIndexingParentsCount(d) > 0
                        )
                    )
                ),
                horizontalBarPlotUtility: cards.filter(d =>
                    d.type === CARDS_TYPE.UTILITY
                    && !d.node.isMultidimensional
                    && getIndexingParentsCount(d) === 1
                    &&
                    (
                        (
                            !d.node.isEquation
                            && typeof CardsManager.getLocalData(d).minMaxMauExpression === "undefined"
                        )
                        ||
                        (
                            d.node.isEquation
                            && typeof CardsManager.getLocalData(d).minMaxMauExpression !== "undefined"
                        )
                    )
                ),
                horizontalBarPlotGauge: cards.filter(d =>
                    d.type === CARDS_TYPE.SINGLE_STATE_NODE
                            && getIndexingParentsCount(d) === 1
                            && d.node.isMultidimensional
                ),
                text: cards.filter(d => d.type === CARDS_TYPE.TEXT),
                topN: cards.filter(d => d.type === CARDS_TYPE.TOPN),
                distribution: cards.filter(d =>
                    d.type === CARDS_TYPE.DISTRIBUTION
                            && Node.getStateLabels(d.node) !== 'undefined'
                            && Node.getStateLabels(d.node).length > 0
                            && !d.node.isMultidimensional
                            && d.node.temporalType !== NODE_TEMPORAL_TYPE.PLATE
                ),
                lineChart: cards.filter(d => d.type === CARDS_TYPE.CUMULATIVE),
                lineChartDBN: cards.filter(d =>
                    d.type === CARDS_TYPE.SINGLE_STATE_NODE
                            && !d.node.isMultidimensional
                            && typeof d.node.values[0].outcomeIndex !== 'undefined'
                            && d.node.temporalType === NODE_TEMPORAL_TYPE.PLATE
                ),
                areaChart: cards.filter(d =>
                    d.type === CARDS_TYPE.DISTRIBUTION
                            && d.node.temporalType === NODE_TEMPORAL_TYPE.PLATE
                    && d.node.nodeType !== NODE_TYPE.EQUATION
                )
            };
            var all = new Set();
            for (const [key, value] of Object.entries(ret)) {
                value.each(d => all.add(d));
            }
            ret.errorCards = cards.filter(d => !all.has(d));
            return ret;
        }

        function getBottomTitle(data, originalTitleFunction) {
            var network = Network.getCurrentNetwork().length > 1 && typeof data.node !== "undefined" && typeof data.node.network !== "undefined" ? Network.getNetworkName(data.node.network)+" - " : "";
            return {
                original: network + originalTitleFunction(data),
                custom: CardsManager.getLocalData(data).title
            };
        }

        function getCurrentTitle() {
            var title = this.getBottomTitle();
            if (typeof title.custom !== "undefined" && title.custom !== null) {
                return title.custom;
            }
            return title.original;
        }
        var getNotNullData = function (v) {
            return typeof v !== "undefined" && v !== null ? v : undefined;
        };
        function decodeCache(list) {
            var selectedGauges = [];
            list.forEach(d => {
                switch (d[0]) {
                    case CARDS_TYPE.SINGLE_STATE_NODE:
                        selectedGauges.push( Radial.decodeShortData(d));
                        break;
                    case CARDS_TYPE.TEXT:
                        selectedGauges.push(TextGauge.decodeShortData(d));
                        break;
                    case CARDS_TYPE.TOPN://[d.type, d.title, index, d.topn, shortRankList]
                        selectedGauges.push(TopN.decodeShortData(d));
                        break;
                    case CARDS_TYPE.DISTRIBUTION:
                        selectedGauges.push(PieChart.decodeShortData(d));
                        break;
                    case CARDS_TYPE.CUMULATIVE:
                        selectedGauges.push(LineChart.decodeShortData(d));
                        break;
                    case CARDS_TYPE.UTILITY:
                        selectedGauges.push(UtilityCards.decodeShortData(d));
                        break;
                    default:
                        console.log("Undefined type of card!");
                        break;
                }
            });
            return selectedGauges;
        }

        function showAdminLinkToInitDashboard() {
            $("#selectGaugagesFirst").show();
            if (d3.selectAll("#selectGaugagesFirst a").size() === 0) {
                d3.select("#selectGaugagesFirst")
                        .append("a")
                        .attr("href", "javascript:void(0)")
                        .attr("data-toggle", "modal")
                        .attr("data-target", "#addCardsModal")
                        .text("Add cards...");
            }
        }
        function showUserInfoNoDefinedDashboard() {
            $("#selectGaugagesFirst").show();
            if (d3.selectAll("#selectGaugagesFirst span").size() === 0) {
                d3.select("#selectGaugagesFirst")
                        .append("span")
                        .text("Dashboard not defined...");
            }
        }

        function getDefaultCardsFontLazy() {
            var drawSimpleTemplate = function () {
                var card = d3.select('#gauges')
                        .selectAll(".exampleCard")
                        .data([{getCurrentTitle: () => "title"}])
                        .enter()
                        .append("div")
                        .attr('class', 'card exampleCard')
                        .attr('type', 'gaugeCard');
                CardsManager.drawFooterCard(card, "", {showValue: true, valueFunction: () => "0%"});
                return card;
            };
            var cardText = d3.select("#gauges .card-text");
            var simpleTemplate = null;
            if(cardText.size() === 0){
                simpleTemplate = simpleTemplate === null ? drawSimpleTemplate() : simpleTemplate;
                cardText = d3.select("#gauges .card-text");
            }
            if (cardTextDefaultFontSize === null) {
                let fontCSS = $(cardText.node()).css("font-size");
                let defFont = parseFloat(fontCSS);
                cardTextDefaultFontSize = isNaN(defFont) ? null : defFont;
            }
            var cardTitle = d3.select("#gauges .card-title");
            if(cardTitle.size() === 0){
                simpleTemplate = simpleTemplate === null ? drawSimpleTemplate() : simpleTemplate;
                cardTitle = d3.select("#gauges .card-title");
            }
            if (cardTitleDefaultFontSize === null) {
                let fontCSS = $(cardTitle.node()).css("font-size");
                let defFont = parseFloat(fontCSS);
                cardTitleDefaultFontSize = isNaN(defFont) ? null : defFont;
            }
            var cardAnnotation = d3.select("#gauges .card-annotation");
            if(cardAnnotation.size() === 0){
                simpleTemplate = simpleTemplate === null ? drawSimpleTemplate() : simpleTemplate;
                cardAnnotation = d3.select("#gauges .card-annotation");
            }
            if (cardAnnotationDefaultFontSize === null) {
                let fontCSS = $(cardAnnotation.node()).css("font-size");
                let defFont = parseFloat(fontCSS);
                cardAnnotationDefaultFontSize = isNaN(defFont) ? null : defFont;
            }
            simpleTemplate !== null ? simpleTemplate.remove() : null;
            return {
                cardText: cardTextDefaultFontSize === null ? 0 : cardTextDefaultFontSize,
                cardTitle: cardTitleDefaultFontSize === null ? 0 : cardTitleDefaultFontSize,
                cardAnnotation: cardAnnotationDefaultFontSize === null ? 0 : cardAnnotationDefaultFontSize,
                footnote: Footnote.getDefaultFontSize() === null ? 0 : Footnote.getDefaultFontSize()
            };
        }

        function detectDisplayNodeType(cards, expectedType) {
            return {
                equals: cards.filter(d => (typeof d.displayNodeType !== "undefined" && d.displayNodeType === expectedType)),
                different: cards.filter(d => (typeof d.displayNodeType === "undefined" || d.displayNodeType !== expectedType))
            };
        }
        function getDashboardFromURL(list) {
            let dashboardID = Utilities.getURLParameterByName("id");
            return list.find(d => d.id === dashboardID);
        }

        function addToLocalDataAndRemoveDefault(cardData, dataToAdd) {
            createLocalFieldIfNotDefined(cardData);
            addToLoacal(cardData, dataToAdd);
            removeDefaultLocalValues(cardData);
        }

        function createLocalFieldIfNotDefined(card) {
            if (typeof card.local === "undefined") {
                card.local = {};
            }
        }

        function addToLoacal(cardData, dataToAdd) {
            Object.keys(dataToAdd).forEach(prop => {
                cardData.local[prop] = dataToAdd[prop];
            });
        }

        function removeDefaultLocalValues(cardData) {
            removeDefaultLocalTitle(cardData);
            removeDefaultLocalHideLegend(cardData);
            removeDefaultLocalMinMaxUtility(cardData);
            removeDefaultLocalTicksAndReverseColors(cardData);
        }

        function removeDefaultLocalTitle(cardData) {
            if(!bottomTitleFunctionDefined(cardData)){
                return;
            }
            var title = cardData.getBottomTitle();
            if (cardData.local.title === title.original) {
                delete cardData.local.title;
            }
        }

        function bottomTitleFunctionDefined(cardData) {
            return typeof cardData.getBottomTitle !== "undefined";
        }

        function removeDefaultLocalHideLegend(cardData) {
            if (!cardData.local.hideLegend) {
                delete cardData.local.hideLegend;
            }
        }

        function removeDefaultLocalMinMaxUtility(cardData) {
            if (!cardData.local.minMaxMauExpression) {
                delete cardData.minMaxMauExpression;
            }
        }

        function removeDefaultLocalTicksAndReverseColors(cardData) {
            if(!cardData.local.reverseColor && cardData.local.ticks && Radial.ticksEqualsDefault(cardData.local.ticks)){
                delete cardData.local.ticks;
                delete cardData.local.reverseColor;
            }
        }

        function getDefinedFields(obj) {
            let defined = Utilities.deepCopy(obj);
            Object.keys(obj).filter(f => !Utilities.fieldIsDefinietAndNotNull(obj[f])).forEach(f => delete defined[f]);
            return defined;
        }

        function dashboardNotExist() {
            return dashboardStructure !== null ? Object.keys(dashboardStructure).length === 0 : true;
        }

        return {
            detectDisplayNodeType: function (cards, expectedType) {
                return detectDisplayNodeType(cards, expectedType);
            },
            getTreeViewPrefix: function () {
                return treeviewIDPrefix;
            },
            setColumnCount: function (count) {
                if (typeof count === "undefined") {
                    setColumns(columnCount);
                } else {
                    setColumns(count);
                }
            },
            getNotNullData: function (v) {
                return getNotNullData(v);
            },
            drawFooterCard: function (cards, ownClass, value) {
                var div = cards.append("div")
                        .classed(ownClass + " text-center pb-1", true);
                if (typeof value !== 'undefined' && value.showValue) {
                    div.append("p")
                            .classed("card-title " + CardsManager.getNodeTitleClass(), true)
                            .text(d => value.valueFunction(d));
                }
                div.append("p")
                        .classed("card-text " + CardsManager.getNodeCaptionClass(), true)
                        .style("margin-bottom", 0)
                        .text(d => d.getCurrentTitle());
                div.append("p")
//                        .classed("card-annotation " + CardsManager.getNodeCaptionClass(), true)
                        .classed("card-annotation " + CardsManager.getAnnotationClass() , true)
                        .html(d => {
                            if(typeof d.annotation === 'undefined' || d.annotation === null){
                                return "";
                            }
                            return bbcodeParser.parseString(d.annotation);
                        });
            },
            setColumnCountTmp: function (count) {
                setColumnsLight(count);
            },
            getColumnCount: function () {
                return columnCount;
            },
            getVisibleCardsData: function () {
                return getVisibleCardsData();
            },
            detectValuesType: function (cards) {
                return detectValuesType(cards);
            },
            redrawAll: function () {
                var allGauges = d3.select('#gauges').selectAll("div[type=gaugeCard]");
//                allGauges.selectAll("div:not(.not_remove)").remove();

                var values = detectValuesType(allGauges);

                values.radial.each(d => d.getCurrentTitle = getCurrentTitle);
                values.radial.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.node.name + " - " + Node.getStateLabels(d.node)[d.selectedStateIndex];
                        });
                    });
                values.radialUtility.each(d => d.getCurrentTitle = getCurrentTitle);
                values.radialUtility.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.node.name;
                        });
                    });
                values.multidimensional.each(d => d.getCurrentTitle = getCurrentTitle);
                values.multidimensional.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            if (typeof d.selectedStateIndex === 'undefined' || d.selectedStateIndex === null) {
                                return d.node.name;
                            }
                            return d.node.name + " - " + Node.getStateLabels(d.node)[d.selectedStateIndex];
                        });
                    });
                values.constant.each(d => d.getCurrentTitle = getCurrentTitle);
                values.constant.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.node.name;
                        });
                    });
                values.horizontalBarPlot.each(d => d.getCurrentTitle = getCurrentTitle);
                values.horizontalBarPlot.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.node.name;
                        });
                    });
                values.horizontalBarPlotUtility.each(d => d.getCurrentTitle = getCurrentTitle);
                values.horizontalBarPlotUtility.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.node.name;
                        });
                    });
                values.horizontalBarPlotGauge.each(d => d.getCurrentTitle = getCurrentTitle);
                values.horizontalBarPlotGauge.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.node.name + " - " + Node.getStateLabels(d.node)[d.selectedStateIndex];
                        });
                    });
                values.topN.each(d => d.getCurrentTitle = getCurrentTitle);
                values.topN.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.title;
                        });
                    });
                values.areaChart.each(d => d.getCurrentTitle = getCurrentTitle);
                values.areaChart.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.node.name;
                        });
                    });
                MultidimensionalTable.addAll(values.multidimensional);
                Radial.addAll(values.radial);
                RadialUtility.addAll(values.radialUtility);
                AreaChartCards.addAll(values.areaChart);
                ConstantGauge.addAll(values.constant);
                HorizontalBarChart.addAll(values.horizontalBarPlot);
                HorizontalBarChart.addAllCustomDomain(values.horizontalBarPlotUtility, function () {
                    var domainX = [0, 0];
                    let x = this.node.values.map(v => v.value);
                    let localData = CardsManager.getLocalData(this);
                    if (this.node.isEquation && typeof localData.minMaxMauExpression !== "undefined") {
                        domainX = localData.minMaxMauExpression;
                    } else {
                        let minMax = Node.minMaxUtilityHelper(this.node);
                        domainX = [minMax.minUtility, minMax.maxUtility];
//                        domainX[0] = Math.min(...x);
//                        domainX[1] = Math.max(...x);
                    }
                    return {
                        x: x,
                        y: Node.Outcome.getOutcomeIndexingParents(this.node, 0),
                        domainX: domainX
                    };
                }, true);
                HorizontalBarChart.addAllCustomDomain(values.horizontalBarPlotGauge, function () {
                    var indexingParentId = Node.getNodeIdFromHandle(this.node.indexingParentsIds[0].node,this.node.network);
                    var indexingParent = d3.select("#" + indexingParentId).data()[0];//outcome
                    var x = [];
                    for (var i = this.selectedStateIndex; i < this.node.values.length; i += this.node.outcome.length) {
                        x.push(this.node.values[i].value);
                    }
                    return {
                        x: x,
                        y: indexingParent.outcome
                    };
                }, true);
                TopN.addAll(values.topN);
                TextGauge.addAll(values.text);

                values.histogram.each(d => d.getCurrentTitle = getCurrentTitle);
                values.histogram.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (data) {
                            return data.node.name;
                        });
                    });
                HistogramGauge.addAll(values.histogram);
                values.distribution.each(d => d.getCurrentTitle = getCurrentTitle);
                values.distribution.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.node.name;
                        });
                    });
                PieChart.addAll(values.distribution);
                values.lineChart.each(d => d.getCurrentTitle = getCurrentTitle);
                values.lineChart.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return d.node.name;
                        });
                    });
                values.lineChartDBN.each(d => d.getCurrentTitle = getCurrentTitle);
                values.lineChartDBN.each(d => d.getBottomTitle = function () {
                        return getBottomTitle(d, function (d) {
                            return `${d.node.name}-${Node.getStateLabels(d.node)[d.selectedStateIndex]}`;
                        });
                    });
                LineChart.addAll(values.lineChart);
                LineChartDBN.addAll(values.lineChartDBN);
                getDefaultCardsFontLazy();
                ErrorCards.addAll(values.errorCards);
            },
            restoreAllCards: function (list) {
                var selectedGauges = decodeCache(list);
                addAllCards(selectedGauges.sort((a, b) => a.index - b.index));
            },
            init: function () {
                if (!dashboardNotExist()) {
                    CurrentDashboard.set(dashboardStructure);
                }
                if (StartApp.isDemoDashboardMode() && !User.isAdmin() && !User.isExplicitCategoryAccess() && (StartApp.isDashboardAdminOnly() || (StartApp.isDemoDashboardMode() && !User.isLogged()))) {
                    $("#changesNotSaved").show();
                    setTimeout(() => {
                        d3.select("#changesNotSaved").transition()
                                .duration(400)
                                .style("opacity", 0)
                                .remove();
                    }, 60*1000);
                } else {
                    d3.select("#changesNotSaved").remove();
                }
                var netHandle = StartApp.getStartAppNetwork();
                if (netHandle === null) {
                    return;
                }
                Network.setCurrentNetwork(netHandle);
                Network.runIfRendered(function () {
                    Blur.disableUI();
                    let startAppEvidence = StartApp.getStartAppEvidence();
                    var cases = {
                        name: "",
                        id: "",
                        evidence: startAppEvidence
                    };
                    Cases.apply(cases, false);
                    //gauges from db
                    if (CurrentDashboard.get().jsonParse.cards.list.length !== 0) {
                        CardsManager.restoreAllCards(CurrentDashboard.get().jsonParse.cards.list);
                    } else if(User.isAdmin() || User.isExplicitCategoryAccess() || StartApp.isDemoDashboardMode() || (!StartApp.isDashboardAdminOnly() && User.isLogged())) {
                        $('#addCardsModal').modal('show');
                        showAdminLinkToInitDashboard();
                        CurrentDashboard.get().name = CurrentDashboard.get().name === "" ? Submodel.submodel_tree.get(Submodel.MAIN_SUBMODEL).name : CurrentDashboard.get().name;
                        CurrentDashboard.updateGlobal();
                    } else {
                        showUserInfoNoDefinedDashboard();
                        CurrentDashboard.get().name = CurrentDashboard.get().name === "" ? Submodel.submodel_tree.get(Submodel.MAIN_SUBMODEL).name : CurrentDashboard.get().name;
                        CurrentDashboard.updateGlobal();
                    }
                });
                RightColumn.Filter.autoDetectTreeviewFilter();
                $("#gauges").sortable({
                    opacity: 0.5,
                    handle: "#cardTopMenu"
                });//https://api.jqueryui.com/sortable/#event-sort
            },
            loadDashboardStructure: function (queryString, calbackPresent, calbackAbsent) {
                if (dashboardStructure === null) {
                    let done = (data, textStatus, jqXHR) => {
                        if (jqXHR.status === 200) {
                            dashboardStructure = data;
                            let validJsonVersion = CurrentDashboard.validJsonVersion(dashboardStructure);

                            if(validJsonVersion && Utilities.isFunction(calbackPresent)){
                                calbackPresent();
                            }else if(!validJsonVersion && Utilities.isFunction(calbackAbsent)){
                                calbackAbsent();
                            }
                        }
                    };
                    let fail = (jqXHR) => {
                        dashboardStructure = {};
                        if (Utilities.isFunction(calbackAbsent)) {
                            calbackAbsent();
                        }
                    };
                    Request.getDashboard(queryString, done, fail);
                } else if (dashboardNotExist()) {
                    if (Utilities.isFunction(calbackAbsent)) {
                        calbackAbsent();
                    }
                } else {
                    if (Utilities.isFunction(calbackPresent)) {
                        calbackPresent();
                    }
                }
            },
            dashboardNotExist,
            getDashboardFromURL: function (list) {
                return getDashboardFromURL(list);
            },
            getDashboardStructure: function () {
                return dashboardStructure;
            },
            createTreeView: function (cardRoot, nodes, getCheckboxIdFunction, options) {
                createTreeView(cardRoot, nodes, getCheckboxIdFunction, options);
            },
            getNodeTitleClass: function () {
                return NODE_TITLE_FONT_CLASS;
            },
            getNodeCaptionClass: function () {
                return NODE_CAPTION_FONT_CLASS;
            },
            getTextClass: function () {
                return TEXT_FONT_CLASS;
            },
            getAnnotationClass: function () {
                return ANNOTATION_FONT_CLASS;
            },
            updateCardsFonts: function (fonts) {
                var conf = {
                    value: 100,
                    gauges: 100,
                    text: 100,
                    annotation: 50,
                    footnote: 50
                };
                var prop = undefined;
                for (prop in fonts) {
                    conf[prop] = fonts[prop];
                }
                updateValueGaugeFontSize(conf.value);
                updateCaptionFontSize(conf.gauges);
                updateTextFontSize(conf.text);
                updateAnnotationFontSize(conf.annotation);
                updateFootnoteFontSize(conf.footnote)
            },
            getFontSizeFromPerc: function (sizePerc) {
                return getFontSizeFromPerc(sizePerc);
            },
            clearCrads: function (cards) {
                cards.selectAll("div:not(.not_remove)").remove();
                return addCardBody(cards);
            },
            getCardBody: function (cards) {
                return cards.select(".centerBody");
            },
            addToLocalDataAndRemoveDefault,
            addToLocalDataAndRemoveDefaultIfDefined: function (cardData, dataToAdd) {
                let definedFields = getDefinedFields(dataToAdd);
                if(Object.keys(definedFields).length > 0){
                    addToLocalDataAndRemoveDefault(cardData, definedFields);
                }
            },
            getLocalData: function (card) {
                if(typeof card === "undefined"){
                    return card;
                }
                return typeof card.local !== "undefined" ? card.local : {};
            },
            parseAnnotation(cardData) {
                return typeof cardData.annotation !== 'undefined' && cardData.annotation !== null ? cardData.annotation : "";
            }
        };
    })();
    var HistogramGauge = (function () {
        function addAll(cards) {
            var scrollCards = CardsManager.getCardBody(cards);
            scrollCards.each((data, index, divs) => {
                data.displayNodeType = DISPLAY_NODE_TYPE.HISTOGRAM;
                let container = d3.select(divs[index]);
                let histogramContainer = container.append('div')
                        .classed('svg-container', true);
                var gradient = NodeShape.BarChart.getBarChartGradient(0, {
                    colors: [{start: 'RGB(0, 0, 255)', stop: "RGB(0, 255, 255)"}],
                    direction: {
                        x1: "0%",
                        y1: "0%",
                        x2: "0%",
                        y2: "100%"
                    },
                    colorIdFunction: (prefix, number, direction) => "histogramGauge" + number + direction.x1.replace("%", "") + direction.y1.replace("%", "") + direction.x2.replace("%", "") + direction.y2.replace("%", "")
                });
                if (data.node.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                    Histogram.createResponsiveErrorBarChart(histogramContainer.node(), data.node, "100%", Node.getNodeIdFromObject(data.node), "", "axisHistogramColorInCard");
                } else {
                    Histogram.createResponsiveHistogram(histogramContainer.node(), data.node.values, "100%", Node.getNodeIdFromObject(data.node), "fill: " + gradient.fill, "axisHistogramColorInCard");
                }

                //remove cursor=pointer and mousedown from histogram
                container.select(".svg-container > div").style("cursor", null).on('mousedown', null);
            });
        }
        return {
            update: function (cards) {
                CardsManager.clearCrads(cards);
                addAll(cards);
            },
            addAll: function (cards) {
                addAll(cards);
            }
        };
    })();
    var DefineCardsModal = (function () {
        var $modalTitle = $("#defineCardsModalTitle");
        var editMode = {
            isEdit: false,
            getEditData: null,
            type: null,
            data: null
        };

        function resetEditObject() {
            editMode = {
                isEdit: false,
                type: null,
                data: null,
                getEditData: null
            };
        }

        $("#ticksPosition").slider({
            range: true,
            min: 0,
            max: 100,
            step: 1,
            values: [25, 75],
//        create: function (event, ui) {
//            var config = Radial.getConfig();
//            var ticks = config.ticks;
//            $("#ticksPosition").slider("option", "values", [ticks[0] * 100, ticks[1] * 100]);
//        },
            slide: function (event, ui) {
                var data = DefineCardsModal.getDataFromEditSingleGaugeCard();
                d3.select("#ticksPosiotionText").select("#firstTickPositionText").text(ui.values[ 0 ]);
                d3.select("#ticksPosiotionText").select("#secondTickPositionText").text(ui.values[ 1 ]);
                var start = parseFloat(ui.values[ 0 ]) / 100;
                var stop = parseFloat(ui.values[ 1 ]) / 100;
                if (DefineCardsModal.isSingleCardEdit()) {//if single edit card}
                    Radial.updateTemplateGaugeWithoutSave({arcColorFn: data.arcColorFn, ticks: [start, stop]}, '#gaugeTemplate');
                } else {
                    Radial.updateTemplateGauge({ticks: [start, stop]}, '#gaugeTemplate');
                }
            }
        });
        $("#reverseGaugesColors").change(function () {
            var data = DefineCardsModal.getDataFromEditSingleGaugeCard();
            var config = {arcColorFn: data.arcColorFn};
            if (DefineCardsModal.isSingleCardEdit()) {//if single edit card
                config.ticks = data.ticks;
                Radial.updateTemplateGaugeWithoutSave(config, '#gaugeTemplate');
            } else {
                Radial.updateTemplateGauge(config, '#gaugeTemplate');
            }
        });

        $("#addGaugeBtn").click(function () {
            showPanel({
                gauge: {visible: true, local: false}
            });
        });
        $("#addTopNBtn").click(function () {
            showPanel({
                topn: {visible: true, local: false}
            });
        });
        $("#addTextBtn").click(function () {
            showPanel({
                text: {visible: true, local: false}
            });
        });
        $("#addDistributionBtn").click(function () {
            showPanel({
                distribution: {visible: true, local: false}
            });
        });
        $("#addCumulativeBtn").click(function () {
            showPanel({
                cumulative: {visible: true, local: false}
            });
        });
        $("#addUtilityBtn").click(function () {
            showPanel({
                utility: {visible: true, local: false}
            });
        });

        function getDataFromEditSingleGaugeCard() {
            var dat = {};
            dat.arcColorFn = Radial.getReverseColors(document.getElementById('reverseGaugesColors').checked);
            dat.ticks = $("#ticksPosition").slider("option", "values").map(d => parseFloat(d) / 100);
            dat.bottomTitle = document.getElementById("cartBottomTitle").value;
            dat.annotation = d3.select(".gaugesPanel .annotation").property("value");
            dat.originalNode = d3.select("#cartBottomTitle").data()[0];
            return dat;
        }

        function showMainModalPanel() {
            showPanel({
                main: {visible: true}
            });
        }

        function updateColumnCount() {
            if (CardsManager.getColumnCount() !== null) {
                var columnCount = CardsManager.getColumnCount();
                $("#gridColumns").val(columnCount);
            } else {
                  $("#gridColumns").val(-1);
            }
        }

        function showEditCardText(data) {
            showPanel({
                text: {visible: true, local: true}
            });
            editMode.getEditData = TextGauge.getTextCardsFromModal;
            d3.select(".textCardsPanel #textCards").html("");
            TextGauge.addEmpty(data, true);
        }

        function showEditCardTopN(data) {
            TopN.addEmpty(data, true);
            showPanel({
                topn: {visible: true, local: true}
            });
            editMode.getEditData = TopN.getTopNCardsFromModal;
        }

        function showEditCardGauge(dat) {
            showPanel({
                gauge: {visible: true, local: true}
            });
            editMode.getEditData = DefineCardsModal.getDataFromEditSingleGaugeCard;
            var config = Radial.getConfig();
            var ticks = config.ticks;
            let localData = CardsManager.getLocalData(dat);
            if (typeof localData.reverseColor !== "undefined" && typeof localData.ticks !== "undefined" && Array.isArray(localData.ticks)) {
                Radial.updateTemplateGaugeWithoutSave({arcColorFn: Radial.getReverseColors(localData.reverseColor), ticks: localData.ticks}, '#gaugeTemplate');
                $("#reverseGaugesColors").prop("checked", localData.reverseColor);
                ticks = localData.ticks;
            } else {
                Radial.updateTemplateGauge({}, '#gaugeTemplate');
                $("#reverseGaugesColors").prop("checked", Radial.isReverse(config.arcColorFn));
            }
//            document.getElementById("cartBottomTitle").value = editMode.data.getBottomTitle();
            d3.select("#cartBottomTitleFormGroup").selectAll("textarea").remove();
            d3.select("#cartBottomTitleFormGroup")
                    .data([dat])
                    .append("textarea")
                    .attr("id", "cartBottomTitle")
                    .classed("form-control", true)
                    .attr("rows", "3")
                    .text(d => d.getCurrentTitle());
            $("#ticksPosition").slider("option", "values", [ticks[0] * 100, ticks[1] * 100]);
            d3.select("#ticksPosiotionText").select("#firstTickPositionText").text((ticks[0] * 100).toFixed());
            d3.select("#ticksPosiotionText").select("#secondTickPositionText").text((ticks[1] * 100).toFixed());
            d3.select(".gaugesPanel .annotation").property("value", CardsManager.parseAnnotation(dat));
        }

        function showEditCardOnlyCaption(dat) {
            showPanel({
                gaugeNoRadial: {visible: true}
            });
            editMode.getEditData = DefineCardsModal.getDataFromEditSingleGaugeCard;
            d3.select("#gaugeTemplate").selectAll("*").remove();
            d3.select("#cartBottomTitleFormGroup").selectAll("textarea").remove();
            d3.select("#cartBottomTitleFormGroup")
                    .data([dat])
                    .append("textarea")
                    .attr("id", "cartBottomTitle")
                    .classed("form-control", true)
                    .attr("rows", "3")
                    .text(d => d.getCurrentTitle());
            d3.select(".gaugesPanel .annotation").property("value", CardsManager.parseAnnotation(dat));
        }

        function showEditCardDistribution(data) {
            showPanel({
                distribution: {visible: true, local: true}
            });
            editMode.getEditData = PieChart.getDataFromEditLocalCard;
            d3.select("#distibutionBottomTitle")
                    .text(data.getCurrentTitle());
            d3.select(".distributionPanel #showLegendPCh").property("checked", !PieChart.isHideLegendInCard(data));
            d3.select(".distributionPanel .annotation").property("value", CardsManager.parseAnnotation(data));
        }

        function showEditCardCumulative(data) {
            showPanel({
                cumulative: {visible: true, local: true}
            });
            editMode.getEditData = LineChart.getDataFromEditLocalCard;
            d3.select(".cumulativePanel #cumulativeBottomTitle")
                    .text(data.getCurrentTitle());
            d3.select(".cumulativePanel #cumulativeInverse").property("checked", LineChart.isInverseInCard(data));
            d3.select(".cumulativePanel .annotation").property("value", CardsManager.parseAnnotation(data));
        }

        function showUtilityExpressions(data) {
            editMode.getEditData = UtilityCards.getDataFromEditLocalCard;
            showPanel({
                utilityExxpressionPanel: {visible: true}
            });
            let localData = CardsManager.getLocalData(data);
            if (localData.minMaxMauExpression) {
                document.getElementById("showAsRadial").checked = true;
                document.getElementById("min_ue").readOnly = false;
                document.getElementById("max_ue").readOnly = false;
                d3.select(".utilityExpressions #min_ue").property("value", localData.minMaxMauExpression[0]);
                d3.select(".utilityExpressions #max_ue").property("value", localData.minMaxMauExpression[1]);
            } else {
                document.getElementById("showAsRadial").checked = false;
                document.getElementById("min_ue").readOnly = true;
                document.getElementById("max_ue").readOnly = true;
                d3.select(".utilityExpressions #min_ue").property("value", 0);
                d3.select(".utilityExpressions #max_ue").property("value", 0);
            }
            d3.select(".utilityExpressions #title_ue")
                    .datum(data)
                    .text(d => d.getCurrentTitle());
            d3.select(".utilityExpressions .annotation").property("value", CardsManager.parseAnnotation(data));
        }

        $("#restoreDefaults").click(()=>{
            console.log(editMode);
            let local = editMode.data.local;
            delete editMode.data.local;
            showCorrectEditModal();
            editMode.data.local = local;
        });

        function showPanel(panel) {
            var config = {
                main: {visible: false},
                gauge: {visible: false, local: false},
                gaugeNoRadial: {visible: false},
                text: {visible: false, local: false},
                topn: {visible: false, local: false},
                distribution: {visible: false, local: false},
                cumulative: {visible: false, local: false},
                utility: {visible: false, local: false},
                utilityExxpressionPanel: {visible: false}
            };
            var prop = undefined;
            for (prop in panel) {
                config[prop] = panel[prop];
            }
            var mainPanel = function (visible) {
                $modalTitle.text("Add cards");
                if (visible) {
                    $(".mainCardsPanel").show();
                    $("#saveCard").hide();
                    $("#restoreDefaults").hide();
                } else {
                    $(".mainCardsPanel").hide();
                    $("#saveCard").show();
                }
            };
            var gaugePanel = function (visible, local) {
                if (!visible) {
                    $(".gaugesPanel .local").hide();
                    $(".gaugesPanel .global").hide();
                    $(".gaugesPanel .localNoRadial").hide();
                    $(".gaugesPanel").hide();
                } else if (local) {
                    $modalTitle.text("Configure card");
                    $(".gaugesPanel .global").hide();
                    $(".gaugesPanel .local").show();
                    $(".gaugesPanel").show();
                    $("#restoreDefaults").show();
                } else {
                    $modalTitle.text("Gauge");
                    $(".gaugesPanel #gaugeTemplate").html("");
                    $(".gaugesPanel .local").hide();
                    $(".gaugesPanel .global").show();
                    $(".gaugesPanel").show();
                }
            };
            var gaugeNoRadial = function (visible) {
                if (!visible) {
                    $(".gaugesPanel .local").hide();
                    $(".gaugesPanel .global").hide();
                    $(".gaugesPanel .localNoRadial").hide();
                    $(".gaugesPanel").hide();
                } else {
                    $modalTitle.text("Configure card");
                    $(".gaugesPanel .global").hide();
                    $(".gaugesPanel .local").hide();
                    $(".gaugesPanel .localNoRadial").show();
                    $(".gaugesPanel").show();
                    $("#restoreDefaults").show();
                }
            };
            var textPanel = function (visible, local) {
                if (!visible) {
                    $(".textCardsPanel .global").hide();
                    $(".textCardsPanel .local").hide();
                    $(".textCardsPanel").hide();
                } else if (local) {
                    $modalTitle.text("Configure card");
                    $(".textCardsPanel .global").hide();
                    $(".textCardsPanel .local").show();
                    $(".textCardsPanel").show();
                    $("#restoreDefaults").hide();
                } else {
                    $modalTitle.text("Text");
                    $(".textCardsPanel .local").hide();
                    $(".textCardsPanel .global").show();
                    $(".textCardsPanel").show();
                }
            };
            var topNPanel = function (visible, local) {
                if (!visible) {
                    $(".topNPanel").hide();
                } else if (local) {
                    $modalTitle.text("Configure card");
                    $(".textCardsPanel").hide();
                    $(".topNPanel").show();
                    $(".topNPanel .global").hide();
                    $("#restoreDefaults").hide();
                } else {
                    $modalTitle.text("TopN");
                    $(".topNPanel .local").hide();
                    $(".topNPanel").show();
                    $(".topNPanel .global").show();
                }
            };
            var distributionPanel = function (visible, local) {
                if (!visible) {
                    $(".distributionPanel .local").hide();
                    $(".distributionPanel .global").hide();
                    $(".distributionPanel").hide();
                } else if (local) {
                    $modalTitle.text("Configure card");
                    $(".distributionPanel .global").hide();
                    $(".distributionPanel .local").show();
                    $(".distributionPanel").show();
                    $("#restoreDefaults").show();
                } else {
                    $modalTitle.text("Distribution");
                    $(".distributionPanel .local").hide();
                    $(".distributionPanel .global").show();
                    $(".distributionPanel").show();
                }
            };
            var cumulativePanel = function (visible, local) {
                if (!visible) {
                    $(".cumulativePanel .local").hide();
                    $(".cumulativePanel .global").hide();
                    $(".cumulativePanel").hide();
                } else if (local) {
                    $modalTitle.text("Configure card");
                    $(".cumulativePanel .global").hide();
                    $(".cumulativePanel .local").show();
                    $(".cumulativePanel").show();
                    $("#restoreDefaults").show();
                } else {
                    $modalTitle.text("Cumulative");
                    $(".cumulativePanel .local").hide();
                    $(".cumulativePanel .global").show();
                    $(".cumulativePanel").show();
                }
            };
            var utilitiesPanel = function (visible, local) {
                if (!visible) {
                    $(".utilityPanel .local").hide();
                    $(".utilityPanel .global").hide();
                    $(".utilityPanel").hide();
                } else if (local) {
                    $modalTitle.text("Configure card");
                    $(".utilityPanel .global").hide();
                    $(".utilityPanel .local").show();
                    $(".utilityPanel").show();
                    $("#restoreDefaults").show();
                } else {
                    $modalTitle.text("Utility");
                    $(".utilityPanel .local").hide();
                    $(".utilityPanel .global").show();
                    $(".utilityPanel").show();
                }
            };
            var utilityExxpressionPanel = function (visible) {
                if (!visible) {
                    $(".utilityExpressions .local").hide();
                    $(".utilityExpressions .global").hide();
                    $(".utilityExpressions").hide();
                } else {
                    $modalTitle.text("Configure card");
                    $(".utilityExpressions .global").hide();
                    $(".utilityExpressions .local").show();
                    $(".utilityExpressions").show();
                    $("#restoreDefaults").show();
                }
            };
            $("#saveCard").show();
            var allPanels = [mainPanel, gaugePanel, gaugeNoRadial, textPanel, topNPanel, distributionPanel, cumulativePanel, utilitiesPanel, utilityExxpressionPanel];
            //hide all panels
            allPanels.forEach(f => f(false));
            //show selected panel
            config.main.visible ? mainPanel(config.main.visible) : null;
            config.gauge.visible ? gaugePanel(config.gauge.visible, config.gauge.local) : null;
            config.gaugeNoRadial.visible ? gaugeNoRadial(config.gaugeNoRadial.visible) : null;
            config.text.visible ? textPanel(config.text.visible, config.text.local) : null;
            config.topn.visible ? topNPanel(config.topn.visible, config.topn.local) : null;
            config.distribution.visible ? distributionPanel(config.distribution.visible, config.distribution.local) : null;
            config.cumulative.visible ? cumulativePanel(config.cumulative.visible, config.cumulative.local) : null;
            config.utility.visible ? utilitiesPanel(config.utility.visible, config.utility.local) : null;
            config.utilityExxpressionPanel.visible ? utilityExxpressionPanel(config.utilityExxpressionPanel.visible) : null;
        }

        function getVisiblePanel() {
            return {
                gaugesPanel: {
                    visible: $('.gaugesPanel').css('display') !== 'none',
                    dataList: getTreeviewChecked($(".gaugesPanel #treeview"))
                },
                topNPanel: {
                    visible: $('.topNPanel').css('display') !== 'none',
                    dataList: [TopN.getTopNCardsFromModal()]
                },
                textCardsPanel: {
                    visible: $('.textCardsPanel').css('display') !== 'none',
                    dataList: [TextGauge.getTextCardsFromModal()]
                },
                mainCardsPanel: {
                    visible: $('.mainCardsPanel').css('display') !== 'none',
                    dataList: []
                },
                distributionPanel: {
                    visible: $('.distributionPanel').css('display') !== 'none',
                    dataList: getTreeviewChecked($(".distributionPanel #treeviewDistribution"))
                },
                cumulativePanel: {
                    visible: $('.cumulativePanel').css('display') !== 'none',
                    dataList: getCumulativeDataFromModal($(".cumulativePanel #treeviewCumulative"))
                },
                utilityPanel: {
                    visible: $('.utilityPanel').css('display') !== 'none',
                    dataList: getTreeviewChecked($(".utilityPanel #treeviewUtility"))
                }
            };
        };

        function getTreeviewChecked($treeview) {
            var list = {"id": [], "dataid": [], "text": []};
            $treeview.hummingbird("getChecked", {list: list, onlyEndNodes: true});
            var allCards = [];
            //clear old gauges
            d3.selectAll("div[type=nodeCard]").each(d => {
                if (d.observeOutcome) {
                    delete d.observeOutcome;
                }
            });
            list.id.forEach(n => {
                let checkbox = d3.select("#" + n).data()[0];
                let nodeData = d3.select("#" + checkbox.nodeID).data()[0];
                if (!checkbox.outcomes && Array.isArray(nodeData.observeOutcome)) {
                    nodeData.observeOutcome.push(checkbox.outcome);
                } else if (!checkbox.outcomes) {
                    nodeData.observeOutcome = [checkbox.outcome];
                }
                let gaugeData = {
                    node: nodeData
                };
                if (!checkbox.outcomes) {
                    gaugeData.selectedStateIndex = checkbox.stateIndex;
                }
                allCards.push(gaugeData);
            });
//            allCards = allCards.concat(TextGauge.getTextCardsFromModal());
//            allCards = allCards.concat(TopN.getTopNCardsFromModal());
            return allCards;
        }
        function getCumulativeDataFromModal($treeview) {
            var selected = getTreeviewChecked($treeview);
            if(selected.length === 0){
                return [];
            }
            //only one node must be selected
            var nodeForCheck = Node.getNodeIdFromObject(selected[0].node);
            if(selected.filter(f => Node.getNodeIdFromObject(f.node) === nodeForCheck).length !== selected.length){
                console.log("Only one node can be selected for cumulative card!");
                return [];
            }
            var node = selected[0];
            node.cumulativeList = selected.map(f => f.selectedStateIndex);
            return [node];
        }

        function saveEditModal() {
            const d = editMode.getEditData();
            switch (editMode.data.type) {//other type edit card
                case CARDS_TYPE.TEXT:
                    d.originalNode.text = d.text;
                    break;
                case CARDS_TYPE.TOPN:
                    var data = editMode.getEditData();
                    data.originalNode.type = data.type;
                    data.originalNode.title = data.title;
                    data.originalNode.topn = data.topn;
                    data.originalNode.rankList = data.rankList;
                    data.originalNode.labelOption = data.labelOption;
                    data.originalNode.annotation = data.annotation;
                    break;
                case CARDS_TYPE.SINGLE_STATE_NODE:
                    var data = editMode.getEditData();
                    var cardData = d3.select("#cartBottomTitle").data()[0];
                    cardData.annotation = data.annotation;

                    CardsManager.addToLocalDataAndRemoveDefault(cardData, {
                        title: data.bottomTitle,
                        ticks: data.ticks,
                        reverseColor: Radial.isReverse(data.arcColorFn)
                    });
                    break;
                case CARDS_TYPE.DISTRIBUTION:
                    var data = editMode.getEditData();
                    editMode.data.annotation = data.annotation;

                    CardsManager.addToLocalDataAndRemoveDefault(editMode.data, {
                        title: data.bottomTitle,
                        hideLegend: data.hideLegend
                    });
                    break;
                case CARDS_TYPE.CUMULATIVE:
                    var data = editMode.getEditData();
                    editMode.data.annotation = data.annotation;
                    CardsManager.addToLocalDataAndRemoveDefault(editMode.data, {
                        title: data.bottomTitle,
                        inverseChart: data.inverse
                    });
                    break;
                case CARDS_TYPE.UTILITY:
                    var data = editMode.getEditData();
                    var cardData = data.originalNode;
                    editMode.data.annotation = data.annotation;

                    CardsManager.addToLocalDataAndRemoveDefault(cardData, {
                        title: data.bottomTitle,
                        minMaxMauExpression: data.minMaxMauExpression
                    });
                    break;

                default://only card caption
                    var data = editMode.getEditData();
                    var cardData = d3.select("#cartBottomTitle").data()[0];
                    editMode.data.annotation = data.annotation;

                    CardsManager.addToLocalDataAndRemoveDefault(cardData, {
                        title: data.bottomTitle
                    });
            }
            CurrentDashboard.updateLocal();
        }
        function checkCheckboxesInGaugesPanel() {
            d3.selectAll("div[type=gaugeCard]").filter(d => typeof d.node !== 'undefined' && d.type !== CARDS_TYPE.DISTRIBUTION).each(d => {
                if (typeof d.selectedStateIndex === 'undefined' || d.selectedStateIndex === null) {
                    $(".gaugesPanel #treeview").hummingbird("checkNode", {attr: "id", name: "selectGaugeNode_" + Node.getNodeIdFromObject(d.node), expandParents: false});
                } else {
                    $(".gaugesPanel #treeview").hummingbird("checkNode", {attr: "id", name: CardsManager.getTreeViewPrefix() + Node.getStateSelector(d.node, d.selectedStateIndex), expandParents: false});
                }
            });
        }

        function removeChecked() {
            var checkedParents = new Map();
            var checkedParentsList = {"id": [], "dataid": [], "text": []};
            $(".gaugesPanel #treeview").hummingbird("getChecked", {list: checkedParentsList, onlyEndNodes: false, onlyParents: true});
            checkedParentsList.id.forEach(id => {
                let parent = d3.select("#" + id).data()[0];
                if (typeof parent !== "undefined") {
                    checkedParents.set(parent.nodeID, {
                        hummingbirdID: id,
                        outcomes: parent.outcomes.length,
                        checkedOutcomes: 0
                    });
                }
            });
            var endNodesChecked = {"id": [], "dataid": [], "text": []};
            $(".gaugesPanel #treeview").hummingbird("getChecked", {list: endNodesChecked, onlyEndNodes: true, onlyParents: false});
            endNodesChecked.id.forEach(id => {
                let checkedNode = d3.select("#" + id).data()[0];
                let parentNode = checkedParents.get(checkedNode.nodeID);
                if (typeof parentNode !== "undefined") {
                    parentNode.checkedOutcomes++;
                }
                $(".gaugesPanel #treeview").hummingbird('removeNode', {attr: 'id', name: id});
            });
            checkedParents.forEach((value, key, map) => {
                if (value.outcomes === value.checkedOutcomes) {
                    $(".gaugesPanel #treeview").hummingbird('removeNode', {attr: 'id', name: value.hummingbirdID});
                }
            });
        }
        function removeAddedCumulative() {
            d3.selectAll("div[type='gaugeCard']").filter(d => d.type === CARDS_TYPE.CUMULATIVE)
                    .each(d => {
                        let inputId = d3.select(".cumulativePanel #treeviewCumulative").select("input[data-id='" + Node.getNodeIdFromObject(d.node) + "']").node().id;
                        $(".cumulativePanel #treeviewCumulative").hummingbird('removeNode', {attr: 'id', name: inputId});
                    });
        }
        function fillGaugesTree() {
            var gaugeFilter = function (d) {
                return d.checkType([NODE_TYPE.CPT, NODE_TYPE.TRUTH_TABLE, NODE_TYPE.NOISY_MAX, NODE_TYPE.NOISY_ADDER, NODE_TYPE.DEMORGAN]);
            };
            var nodes = [];
            var targets = d3.selectAll("div[type=nodeCard]").filter(d => d.isTarget);
            if (targets.size() > 0) {
                targets.filter(d => gaugeFilter(d)).each(d => nodes.push(d));
            } else {
                d3.selectAll("div[type=nodeCard]").filter(d => gaugeFilter(d)).each(d => nodes.push(d));
            }
            if (targets.size() > 0) {
                nodes.sort((a, b) => a.name > b.name);
            }
            var cardRoot = d3.select(".gaugesPanel");
            CardsManager.createTreeView(cardRoot, nodes, function (node, outcome) {return CardsManager.getTreeViewPrefix() + node + outcome;});
            checkCheckboxesInGaugesPanel();
            removeChecked();
        }
        function fillCumulativeTree() {
            var cumulativeFilter = function (d) {
                return (d.temporalType !== NODE_TEMPORAL_TYPE.PLATE) && d.checkType([NODE_TYPE.CPT, NODE_TYPE.TRUTH_TABLE, NODE_TYPE.NOISY_MAX, NODE_TYPE.NOISY_ADDER, NODE_TYPE.DEMORGAN]);
            };
            var nodes = [];
            var targets = d3.selectAll("div[type=nodeCard]").filter(d => d.isTarget);
            if (targets.size() > 0) {
                targets.filter(d => cumulativeFilter(d)).each(d => nodes.push(d));
            } else {
                d3.selectAll("div[type=nodeCard]").filter(d => cumulativeFilter(d)).each(d => nodes.push(d));
            }
            if (targets.size() > 0) {
                nodes.sort((a, b) => a.name > b.name);
            }
            var cardRoot = d3.select(".cumulativePanel");
            CardsManager.createTreeView(cardRoot, nodes, function (node, outcome) {return "cumulative" + node + outcome;}, {removeCheckboxexLevel: [0,1]});
            oneGrupCheckEvent();
            removeAddedCumulative();
        }
        function fillDistributionsTree() {
            var distributionNodeFilter = function (d) {
                return !d.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU, NODE_TYPE.DECISION]);
            };
            var nodes = [];
            var addedBefore = d3.selectAll("div[type=gaugeCard]").filter(n => n.type === CARDS_TYPE.DISTRIBUTION).data().map(n => Node.getNodeIdFromObject(n.node));
            var targets = d3.selectAll("div[type=nodeCard]").filter(d => d.isTarget);
            if (targets.size() > 0) {
                targets.filter(d => distributionNodeFilter(d) && !addedBefore.some(e => e === Node.getNodeIdFromObject(d))).each(d => nodes.push(d));
            } else {
                d3.selectAll("div[type=nodeCard]")
                        .filter(d => distributionNodeFilter(d) && !addedBefore.some(e => e === Node.getNodeIdFromObject(d)))
                        .each(d => nodes.push(d));
            }
            if (targets.size() > 0) {
                nodes.sort((a, b) => a.name > b.name);
            }
            var distributionTreeRoot = d3.select(".distributionPanel");
            CardsManager.createTreeView(distributionTreeRoot, nodes, function (node, outcome) {return CardsManager.getTreeViewPrefix() + "distribution" + node + outcome;}, {showOutcomes: false});
        }
        function fillUtilityTree() {
            var utilityNodeFilter = function (d) {
                return d.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU]);
            };
            var nodes = [];
            var addedBefore = d3.selectAll("div[type=gaugeCard]").filter(n => n.type === CARDS_TYPE.UTILITY).data().map(n => Node.getNodeIdFromObject(n.node));
            var targets = d3.selectAll("div[type=nodeCard]").filter(d => d.isTarget);
            if (targets.size() > 0) {
                targets.filter(d => utilityNodeFilter(d) && !addedBefore.some(e => e === Node.getNodeIdFromObject(d))).each(d => nodes.push(d));
            } else {
                d3.selectAll("div[type=nodeCard]")
                        .filter(d => utilityNodeFilter(d) && !addedBefore.some(e => e === Node.getNodeIdFromObject(d)))
                        .each(d => nodes.push(d));
            }
            if (targets.size() > 0) {
                nodes.sort((a, b) => a.name > b.name);
            }
            var utilityTreeRoot = d3.select(".utilityPanel");
            CardsManager.createTreeView(utilityTreeRoot, nodes, function (node) {return CardsManager.getTreeViewPrefix() + "utility" + node;}, {showOutcomes: false});
        }
        function oneGrupCheckEvent() {
            $("#treeviewCumulative").on("nodeChecked", function (d, selectedIDs) {
                var oneSelectedLi = selectedIDs.split(",")[0];
                var group = d3.select("#" + oneSelectedLi).data()[0];
                var treeView = d3.select(d.target);
                var checkedGroup = treeView.selectAll("#" + group.groupID + " .hummingbird-end-node:checked").nodes();
                var checkedAll = treeView.selectAll(".hummingbird-end-node:checked").nodes();
                var nodesToUnchecked = checkedAll.filter(x => !checkedGroup.includes(x));
                nodesToUnchecked.forEach((n) => {
                    $(treeView.node()).hummingbird("uncheckNode",{attr:"id",name: n.id,collapseChildren:false});
                    let parent = d3.select("#"+ n.id).data()[0];
                    //only one group to be opened at a time
                    var groupID = d3.select("#"+parent.groupID+" > label > input").node().id;
                    $(treeView.node()).hummingbird("collapseNode",{attr:"id",name: groupID,collapseChildren:true});
                });
            });
        }
        function createDefineCardsModal() {
            fillGaugesTree();
            fillDistributionsTree();
            fillCumulativeTree();
            fillUtilityTree();
            //textCardsPanel
            TextGauge.addEmpty(undefined, true, true);
            //topNPanel
            if (Utilities.isInfluenceDiagram()) {
                d3.select("#addTopNBtn").style("display", "none");
            } else {
                d3.select("#addTopNBtn").style("display", null);
                TopN.addEmpty(undefined, true);
            }
            hideEmptyAddCardsInModal();
        }

        function hideEmptyAddCardsInModal() {
            d3.select("#addCardsModal")
                    .selectAll("a[sourcepanel]")
                    .each((dat, index, divs) => {
                        var sourceClass = d3.select(divs[index]).attr("sourcepanel");
                        var treeView = d3.select("#addCardsModal ." + sourceClass + " .hummingbird-treeview");
                        var divsData = treeView.selectAll("li").data();
                        var divsDatIsObject = divsData.some((element, index, array) => typeof element === 'object' && element !== null);
                        if(!divsDatIsObject){
                            d3.select(divs[index]).style("display", "none");
                        } else if (divsData.length === 1) {
                            //additional check if divsData is real node in tree view
                            if(typeof treeView.selectAll("input[type=checkbox]").attr("bayesbox-humminbird-type") !== "undefined"){
                                d3.select(divs[index]).style("display", "none");
                            }
                        }
                    });
        }

        function resetHideAddCards() {
            d3.select("#addCardsModal")
                    .selectAll("a[sourcepanel]")
                    .style("display", null);
        }

        $("#saveCard").on("click", () => {
            if (editMode.isEdit) {
                saveEditModal();
            } else {
                let visiblePanel = getVisiblePanel();
                var cardList = CurrentDashboard.get().jsonParse.cards.list;
                visiblePanel.gaugesPanel.visible ? visiblePanel.gaugesPanel.dataList.forEach(d => cardList.push( Radial.getShortData(d))) : null;
                visiblePanel.topNPanel.visible ? visiblePanel.topNPanel.dataList.forEach(d => cardList.push(TopN.getShortData(d))) : null;
                visiblePanel.textCardsPanel.visible ? visiblePanel.textCardsPanel.dataList.forEach(d => cardList.push(TextGauge.getShortData(d))) : null;
                visiblePanel.distributionPanel.visible ? visiblePanel.distributionPanel.dataList.forEach(d => cardList.push(PieChart.getShortData(d))) : null;
                visiblePanel.cumulativePanel.visible && visiblePanel.cumulativePanel.dataList.length > 0 ? visiblePanel.cumulativePanel.dataList.forEach(d => cardList.push(LineChart.getShortData(d))) : null;
                visiblePanel.utilityPanel.visible  ? visiblePanel.utilityPanel.dataList.forEach(d => cardList.push(UtilityCards.getShortData(d))) : null;
                CurrentDashboard.updateGlobal();
            }
            WarningCloseBrowser.enable();
            $('#addCardsModal').modal('hide');
        });
        $('#addCardsModal').on('show.bs.modal', function (e) {
            showCorrectEditModal();
        });
        function showCorrectEditModal() {
            if (!editMode.isEdit) {//show all type of cards (no edit single gauge!)
                createDefineCardsModal();
                showMainModalPanel();
            } else if (editMode.data.type === CARDS_TYPE.SINGLE_STATE_NODE && (editMode.data.node.isMultidimensional || typeof editMode.data.node.values[0].outcomeIndex === 'undefined')) {//no radial edit card
                showEditCardOnlyCaption(editMode.data);
            } else {
                switch (editMode.data.type) {//other type edit card
                    case CARDS_TYPE.TEXT:
                        showEditCardText(editMode.data);
                        break;
                    case CARDS_TYPE.TOPN:
                        showEditCardTopN(editMode.data);
                        break;
                    case CARDS_TYPE.SINGLE_STATE_NODE:
                        editMode.data.node.temporalType === NODE_TEMPORAL_TYPE.PLATE ? showEditCardOnlyCaption(editMode.data) : showEditCardGauge(editMode.data);
                        break;
                    case CARDS_TYPE.DISTRIBUTION:
                        if (editMode.data.displayNodeType === DISPLAY_NODE_TYPE.PIE_CHART || editMode.data.displayNodeType === DISPLAY_NODE_TYPE.AREA_CHART) {
                            showEditCardDistribution(editMode.data);
                        } else {
                            showEditCardOnlyCaption(editMode.data);
                        }
                        break;
                    case CARDS_TYPE.CUMULATIVE:
                        showEditCardCumulative(editMode.data);
                        break;
                    case CARDS_TYPE.UTILITY:
                        if (editMode.data.node.isEquation) {
                            showUtilityExpressions(editMode.data);
                            break;
                        }
                        showEditCardOnlyCaption(editMode.data);
                        break;
                    default:
                        showEditCardOnlyCaption(editMode.data);
                }

            }
        }
        $('#addCardsModal').on('hide.bs.modal', function (e) {
            CardsManager.setColumnCount();
        });
        $('#addCardsModal').on('hidden.bs.modal', function (e) {
            resetEditObject();
            resetHideAddCards();
        });

        return {
            updateColumnCount: function () {
                updateColumnCount();
            },
            show: function (edit) {
                if (edit.hasOwnProperty("data") && edit.hasOwnProperty("isEdit")) {
                    editMode = edit;
                } else {
                    console.log("bad edit object");
                }
                $('#addCardsModal').modal('show');
            },
            isSingleCardEdit: function () {
                return editMode.isEdit;
            },
            getDataFromEditSingleGaugeCard: function () {
                return getDataFromEditSingleGaugeCard();
            }
        };
    })();
    var TextGauge = (function () {
        var bbcodeParser = new BBCodeParser(BayesBoxBBtags.get());

        $("#addNewTextCardBtn").click(function () {
            createTextarea();
        });

        function createTextarea(data, hideButtonRemove, clearBefore) {
            if (typeof data === "undefined") {
                data = {text: "Enter your text here."};
            }
            clearBefore = typeof clearBefore === "undefined" ? false : true;
            if (clearBefore) {
                d3.select("#textCards").html("");
            }
            var body = d3.select(".textCardsPanel #textCards")
                    .append("div")
                    .datum(data);
            if (!hideButtonRemove) {
                body.append("button")
                        .attr("type", "button")
                        .attr("class", "close")
                        .attr("aria-label", "Close")
                        .on("click", (event, index, tags) => {
                            d3.select(tags[0].parentNode).remove();
                        })
                        .append("span")
                        .attr("aria-hidden", "true")
                        .style("color", "red")
                        .text("×");
            }
            var textarea = body.append("div")
                    .attr("class", "form-group");
            textarea.append("label")
                    .attr("for", "textCard")
                    .text("Text");
            textarea.append("textarea")
                    .attr("class", "form-control")
                    .attr("rows", "3")
                    .text(d => d.text);
        }

        function addAll(cards) {
            cards.each(d => d.type = CARDS_TYPE.TEXT);
            cards.append("p")
                    .classed("text-center " + CardsManager.getTextClass(), true)
                    .html(d => bbcodeParser.parseString(d.text));
        }
        function updateValue(cards) {
            cards.selectAll(".card-text")
                    .html(d => bbcodeParser.parseString(d.text));
        }
        return {
            update: function (cards) {
                var cardsGroup = CardsManager.detectDisplayNodeType(cards, CARDS_TYPE.TEXT);
                updateValue(cardsGroup.equals);
                var scrollContent = CardsManager.clearCrads(cardsGroup.different);
                if (scrollContent.size() > 0) {
                    addAll(scrollContent);
                }
            },
            addAll: function (cards) {
                var scrollContent = CardsManager.getCardBody(cards);
                addAll(scrollContent);
            },
            addEmpty: function (data, hideButtonRemove, clearBefore) {
                if(typeof hideButtonRemove === "undefined"){
                    hideButtonRemove = false;
                }
                createTextarea(data, hideButtonRemove, clearBefore);
            },
            getShortData: function (data, index) {
                return [data.type, data.text, index];
            },
            decodeShortData: function (shortData) {
                return {
                    type: shortData[0],
                    text: shortData[1],
                    index: CardsManager.getNotNullData(shortData[2])
                };
            },
            getTextCardsFromModal: function () {
                return {
                    type: CARDS_TYPE.TEXT,
                    text: d3.select("#addCardsModal #textCards textarea").property("value"),
                    originalNode: d3.select("#addCardsModal #textCards textarea").data()[0]
                };
            }
        };
    })();

    var ErrorCards = (function () {
        function addAll(cards) {
            cards.append("div")
                    .classed("card-body text-center", true)
                    .append("div")
                    .classed("alert alert-danger card-text", true)
                    .attr("role","alert")
                    .text(d => `Node [${d.node.name}] display issue`);
            cards.each(d => console.error(d));
        }
        return {
            addAll: function (cards) {
                addAll(cards);
            }
        };
    })();
    var AreaChartCards = (function () {
        function addAll(cards) {
            cards.size() !== 0 ? initTip() : null;
            cards.each(d => d.displayNodeType = DISPLAY_NODE_TYPE.AREA_CHART);
            var scrollCards = CardsManager.getCardBody(cards);
            addAllCharts(scrollCards);
            addLegend(scrollCards);
            CardsManager.drawFooterCard(cards, "", {showValue: false});
        }
        function addAllCharts(cards) {
            var containers = [];
            cards.each((d, index, divs) => {
                var container = d3.select(divs[index])
                        .append("div")
                        .classed("svg-container", true)
                        .style("padding-bottom","100%");
                var chartData = DynamicBN.AreaChart.createAreaChart(container, d.node, {
                    showLegend: false,
                    inRightColumn: true,
                    containerCouldInvisible: false,
                    height: 200,
                    showYAxis: true,
                    showExtraValueLine: true,
                    margin: {
                        right: 15,
                        bottom: 20,
                        left: 15,
                        between: 0
                    },
                    labelFont: {
                        color: "black",
                        size: "1em"
                    }
                });
                container.selectAll("svg").each(svg => svg.areaChartDat = chartData);
                containers.push(container);
            });
            return containers;
        }
        var customTip = null;
        function initTip() {
            if (customTip !== null)
                return customTip;
            customTip = d3.select("#content")
                    .append("div")
                    .attr("id", "pChTooltip")
                    .classed("d3-pie-tip n", true)
                    .style("position", "absolute")
                    .style("opacity", 1)
                    .style("overflow", "scroll")
                    .style("max-height", "80vh")
                    .style("pointer-events", "none")
                    .style("top", 0)
                    .style("left", 0)
                    .style("z-index", "1100")
                    .style("box-sizing", "border-box")
                    .style("visibility", "hidden")
                    .style("transform", "translateY(-20px)");
            return customTip;
        }
        var tooltipTimeout = null;
        function showTooltipLegend(html, x, y) {
            clearTimeout(tooltipTimeout);
            customTip.style("visibility", "visible")
                    .style("pointer-events", null)
                    .style("overflow", "auto")
                    .style("top", y + "px")
                    .style("left", x + "px");
            customTip.html(html);
            customTip.on("mouseenter", (d, index, divs) => {
//                    customTip.style("visibility", "visible");
                return clearTimeout(tooltipTimeout);
            })
                    .on("mouseleave", (d, index, divs) => {
                        return customTip.style("visibility", "hidden");
                    });
        }
        function addLegend(cards) {
            cards.each((card, index, divs) => {
                var thisCard = d3.select(divs[index]);
                var stackColor = DynamicBN.AreaChart.getStackColorFunction(card.node);
                var stack = stackColor.stack;
                var color = stackColor.color;
                if (!hideLegendInCard(card)) {
                    //legend
                    var legendItem = thisCard.append("div")
                            .classed("d-flex justify-content-center flex-wrap legendBody", true)
                            .selectAll(".legendItem")
                            .data(stack)
                            .enter()
                            .append("div")
                            .classed("p-2 bd-highlight legendItem", true);
                    legendItem.append("div")
                            .classed("rectangleLegend", true)
                            .style("background-color", d => color(d.index));
                    legendItem.append("span")
                            .classed("legendValue", true)
                            .text(d => d.key);
                } else {
                    thisCard.select(".svg-container")
                            .append("i")
                            .classed("fa fa-info-circle", true)
                            .style("position", "absolute")
                            .style("bottom", "5px")
                            .style("right", "5px")
                            .on("mouseover", (d, index, divs) => {
                                var html = "<div style='position: relative;height: 100%;padding-right: 16px;'>";
                                for (var i = 0; i < stack.length; i++) {
                                    html += "<div style='white-space: nowrap;'>" +
                                            "<div class='rectangleLegend' style='background-color: " + color(stack[i].index) + ";'></div>" +
                                            '<span class="legendValue">' + stack[i].key + '</span>' +
                                            '</div>';
                                }
                                html += '</div>';
                                showTooltipLegend(html, event.pageX, event.pageY);
                                var translate = Utilities.fitToContainer("#content", "#pChTooltip", event.pageX, event.pageY);
                                customTip.style("left", (translate.x) + "px")
                                        .style("top", (translate.y) + "px");
                            })
                            .on("mouseout", function (d, index, divs) {
                                tooltipTimeout = setTimeout(() => customTip.style("visibility", "hidden"), 1000);
//                            return customTip.style("visibility", "hidden");
                            });
                }
            });
        }
        function updateValue(cards) {
            cards.each((d, index, divs) => {
                var stack = DynamicBN.AreaChart.getStackGenerator(d.node);
                var t = d3.transition()
                        .duration(500)
                        .ease(d3.easeLinear);
                d3.select(divs[index])
                        .select(`.svg-container svg g[class=areaChart]`)
                        .selectAll("path")
                        .data(stack)
                        .transition(t)
                        .attr("d", d.areaChartDat.area);
            });
        }
        function generateRandomNumber(min, max) {
            return Math.random() * (max - min) + min;
        }
        function generateRandomValues(outcomeNumber, slice) {
            var randomValues = [];
            var index = 0;
            for (var i = 0; i < slice; i++) {
                var first = 0;
                var other = 0;
                for (var j = 0; j < outcomeNumber; j++) {
                    if (outcomeNumber === 1) {
                        randomValues.push({
                            outcomeIndex: index++,
                            value: 1
                        });
                    } else {
                        //first value is random
                        if (j === 0) {
                            first = generateRandomNumber(0, 0.9);
                            other = (1 - first) / (outcomeNumber - 1);
                            randomValues.push({
                                outcomeIndex: index++,
                                value: first
                            });
                        } else {
                            randomValues.push({
                                outcomeIndex: index++,
                                value: other
                            });
                        }
                    }
                }
            }
            return randomValues;
        }
        function generateValue(outcomeNumber, slice) {
            var values = [];
            var index = 0;
            var equalsVal = 1 / outcomeNumber;
            for (var i = 0; i < (slice * outcomeNumber); i++) {
                values.push({
                    outcomeIndex: index++,
                    value: equalsVal
                });
            }
            return values;
        }
        function updateExampleColors(container, colorProp) {
            var card = container.data()[0];
            var stack = DynamicBN.AreaChart.getStackGenerator(card.node);
            var color = d3.scaleOrdinal()
                            .domain(stack.map(d => d.index))
                            .range(stack.map(d => DynamicBN.AreaChart.colorRange(d.index, stack.length, colorProp)));
            container.selectAll("path[class='area']").style("fill", d => color(d.index));
        }
        function hideLegendInCard(cardData) {
            let localData = CardsManager.getLocalData(cardData);
            return Utilities.fieldIsDefinietAndNotNull(localData.hideLegend) ? localData.hideLegend : false;
        }
        return{
            addAll: function (cards) {
                addAll(cards);
            },
            update: function (cards) {
                var cardsGroup = CardsManager.detectDisplayNodeType(cards, DISPLAY_NODE_TYPE.AREA_CHART);
                updateValue(cardsGroup.equals);
                CardsManager.clearCrads(cardsGroup.different);
                if (cardsGroup.different.size() > 0) {
                    addAll(cardsGroup.different);
                }
            },
            addExampleAreaChart: function (divId, id) {
                var exampleOutcomesNumber = 5;
                var dataExample = {
                    node: {
                        values: generateRandomValues(exampleOutcomesNumber, DynamicBN.stepCount),
                        outcome: [],
                        temporalType: NODE_TEMPORAL_TYPE.PLATE
                    }
                };
                //generate outcomes
                for (var i = 0; i < exampleOutcomesNumber; i++) {
                    dataExample.node.outcome.push("example_AC_" + i);
                }
                d3.selectAll("#" + id).remove();
                var cards = d3.selectAll(divId)
                        .data([dataExample]);
                var svgContainer = addAllCharts(cards);
                svgContainer.forEach(d => d.attr("id", id));
            },
            updateExampleColors: function (id, colorProp) {
                var container = d3.select("#" + id + " svg");
                updateExampleColors(container, colorProp);
            },
            getColorSpread: function () {
                return DynamicBN.AreaChart.config.color.spread;
            },
            getColorSpreadPerc: function (value) {
                value = typeof value === "undefined" ? DynamicBN.AreaChart.config.color.spread : value;
                var format = d3.format(".0%");
                return format(value / 360);
            },
            getColorStart: function () {
                return DynamicBN.AreaChart.config.color.start;
            },
            getColorStartPerc: function (value) {
                value = typeof value === "undefined" ? DynamicBN.AreaChart.config.color.start : value;
                var format = d3.format(".0%");
                return format(value / 360);
            },
            getColorBrightness: function () {
                return DynamicBN.AreaChart.config.color.brightness;
            },
            getColorBrightnessPerc: function (value) {
                value = typeof value === "undefined" ? DynamicBN.AreaChart.config.color.brightness : value;
                var format = d3.format(".0%");
                return format(value);
            },
            createColorObject: function (spread, start, brightness) {
                if (!DynamicBN.AreaChart.config.color.hasOwnProperty("spread") || !DynamicBN.AreaChart.config.color.hasOwnProperty("start") || !DynamicBN.AreaChart.config.color.hasOwnProperty("brightness")) {
                    console.log("Color object structure is different!");
                }
                spread = typeof spread === 'undefined' ? DynamicBN.AreaChart.config.color.spread : spread;
                start = typeof start === 'undefined' ? DynamicBN.AreaChart.config.color.start : start;
                brightness = typeof brightness === 'undefined' ? DynamicBN.AreaChart.config.color.brightness : brightness;
                return {
                    spread: spread,
                    start: start,
                    brightness: brightness
                };
            },
            setColor: function (colorProp) {
                if (colorProp === 'undefined' || colorProp === null) {
                    return;
                }
                var prop = undefined;
                for (prop in colorProp) {
                    DynamicBN.AreaChart.config.color[prop] = colorProp[prop];
                }
            },
            getColor: function () {
                return DynamicBN.AreaChart.config.color;
            }
        };
    })();
    var ConstantGauge = (function () {
        var PRECISION = 2;
        function detectNaN(cards) {
            cards.selectAll(".constantValue").attr("class", d => isNaN(d.node.values[0].value) ? d3.select(d.cardRoot).select(".constantValue").attr("class") + " errorValue" : d3.select(d.cardRoot).select(".constantValue").attr("class"));
        }
        function addAll(cards) {
            cards.each(d => d.displayNodeType = DISPLAY_NODE_TYPE.CONSTANT);
            var existTitle = cards.select(".cardNodeTitle");
            if (existTitle.size() > 0 && existTitle.text() === name) {
                return;
            }
            existTitle.remove();
            var scrollContent = CardsManager.getCardBody(cards);
            scrollContent.append("p")
                    .classed(`${CardsManager.getNodeTitleClass()} text-center constantValue`, true)
                    .text(d => isNaN(d.node.values[0].value) ? d.node.values[0].value : math.round(d.node.values[0].value, PRECISION));
            CardsManager.drawFooterCard(cards, "cardNodeTitle", {showValue: false});
            detectNaN(scrollContent);
//            var div = cards.append("div")
//                    .classed("cardNodeTitle card-body text-center", true);
//            div.append("h1")
//                    .classed("card-title " + CardsManager.getNodeTitleClass(), true)
//                    .text(d => math.round(d.node.values[0].value, PRECISION));
//            div.append("p")
//                    .classed("card-text " + CardsManager.getNodeCaptionClass(), true)
//                    .text(d => d.getCurrentTitle());
        }
        function updateValue(cards) {
            cards.selectAll(".constantValue")
                    .text(d => {
                        return isNaN(d.node.values[0].value) ? d.node.values[0].value : math.round(d.node.values[0].value, PRECISION);
                    });
            detectNaN(cards);
        }
        return {
            update: function (cards) {
                var cardsGroup = CardsManager.detectDisplayNodeType(cards, DISPLAY_NODE_TYPE.CONSTANT);
                updateValue(cardsGroup.equals);
                CardsManager.clearCrads(cardsGroup.different);
                if(cardsGroup.different.size() > 0){
                    addAll(cardsGroup.different);
                }
            },
            addAll: function (cards) {
                addAll(cards);
            }
        };
    })();
    var UtilityCards = (function () {
        return {
            getShortData: function (data, index) {
                var netData = Network.getNetworkData(data.node.network);
                let localData = CardsManager.getLocalData(data);
                if(localData.minMaxMauExpression){
                    return [CARDS_TYPE.UTILITY, [netData.category, netData.filename, data.node.id], index, CardsManager.getLocalData(data).title, data.annotation, localData.minMaxMauExpression];
                }
                return [CARDS_TYPE.UTILITY, [netData.category, netData.filename, data.node.id], index, CardsManager.getLocalData(data).title, data.annotation];
            },
            decodeShortData: function (shortDat) {
                let nodeIDDat = shortDat[1];
                let networkHandle = Network.getNetworkHandleData(nodeIDDat[0], nodeIDDat[1]);
                let id = Node.getNodeIdFromString(nodeIDDat[2], networkHandle);
                let datNode = d3.select("#" + id).data()[0];
                var decodedDat = {
                    type: shortDat[0],
                    node: datNode,
                    index: CardsManager.getNotNullData(shortDat[2]),
                    annotation: shortDat[4]
                };
                CardsManager.addToLocalDataAndRemoveDefaultIfDefined(decodedDat, {
                    title: CardsManager.getNotNullData(shortDat[3]),
                    minMaxMauExpression: CardsManager.getNotNullData(shortDat[5])
                });
                return decodedDat;
            },
            removeInexistentCards: function (nodesToRemove) {
                for (var i = 0; i < nodesToRemove.length; i++) {
                    d3.select("#gauges").selectAll('div[type="gaugeCard"]')
                            .filter(d =>
                                d.type === CARDS_TYPE.UTILITY &&
                                        d.node.handle === nodesToRemove[i].handle &&
                                        d.node.id === nodesToRemove[i].id &&
                                        d.node.network === nodesToRemove[i].network)
                            .remove();
                }
            },
            getDataFromEditLocalCard: function () {
                var dat = {};
                dat.originalNode = d3.select(".utilityExpressions #title_ue").data()[0];
                dat.bottomTitle = d3.select(".utilityExpressions #title_ue").property("value");
                dat.annotation = d3.select(".utilityExpressions #annotation_ue").property("value");
                if (document.getElementById("showAsRadial").checked) {
                    let minExpression = d3.select(".utilityExpressions #min_ue").property("value");
                    let maxExpression = d3.select(".utilityExpressions #max_ue").property("value");
                    dat.minMaxMauExpression = [Number(minExpression), Number(maxExpression)];
                }
                return dat;
            }
        };
    })();
    var MultidimensionalTable = (function () {
        function addMultidimensionalTable(cards) {
//            cards.selectAll("*").remove();
            cards.each(d => d.displayNodeType = DISPLAY_NODE_TYPE.TABLE);
            var scrollContent = CardsManager.getCardBody(cards);
            scrollContent.append('div')
                    .attr('class', 'multiDDivTable')
                    .style('padding', '0')
                    .style('margin', '.4rem')
                    .append('table')
                    .attr('class', 'table-sm multiDTable')
                    .html(d => Node.Outcome.constructMultidimensionalTable(d.node, d.selectedStateIndex));
            CardsManager.drawFooterCard(cards, "", {showValue: false});
        }

        return {
            update: function (cards) {
                CardsManager.clearCrads(cards);
                cards.each(d => d.displayNodeType = DISPLAY_NODE_TYPE.TABLE);
                addMultidimensionalTable(cards);
            },
            addAll: function (cards) {
                addMultidimensionalTable(cards);
            }
        };
    })();
    var RadialUtility = (function () {
        var radialConfig = {
            ticks: [],
            arcColorFn: ["blue"],
            minMaxFunction: function (card) {
                let localData = CardsManager.getLocalData(card);
                if(localData.minMaxMauExpression){
                    return localData.minMaxMauExpression;
                }
                let minMax = Node.minMaxUtilityHelper(card.node);
                return [minMax.minUtility, minMax.maxUtility];
            },
            showLabels: true
        };
        function temporayChangeRadialConfig(functionToDo) {
            var originalConfig = Utilities.deepCopy(Radial.getConfig());
            Radial.updateConfigure(radialConfig);
            functionToDo();
            Radial.setConfig(originalConfig);
        }
        return {
            addAll: function (cards) {
                temporayChangeRadialConfig(() => Radial.addAll(cards));
            },
            update: function (cards) {
                temporayChangeRadialConfig(() => Radial.update(cards));
            }
        };
    })();
    var Radial = (function () {
        var RED = '#ff0000';
        var defaultArcColorFn = ['#00ff00', '#ffff00' , RED];
        var config = {
            size: 260,
            clipWidth: 260,
            clipHeight: 140,
            ringInset: 15,
            ringWidth: 20,

            pointerWidth: 10,
            pointerTailLength: 5,
            pointerHeadLengthPercent: 0.95,

            minValue: 0,
            maxValue: 1,
//            minMaxFunction: function(node){return [minValue, maxValue]},//if set then minValue and maxValue aren't needed

            minAngle: -90,
            maxAngle: 90,

            transitionMs: 1000,

            showLabels: false,
            majorTicks: 5,
            precision: 2,
            labelInset: 15,
            labelFontSize: "0.75rem",

            arcColorFn: defaultArcColorFn,

            ticks: [1/3, 2/3]
        };

        var range = undefined;
        var r = undefined;
        var pointerHeadLength = undefined;

        var svg = undefined;
        var arc = undefined;
        var scale = undefined;
        var pointer = undefined;
        var valuesText = undefined;
        var elements = null;
        var globalGaugeConfigured = false;

        var tip = d3.tip()
                .attr('class', 'd3-tip')
                .offset([-10, 0])
                .html(function (d) {
                    if(typeof d !== "undefined" && !d.node.isMultidimensional){
                        switch (d.node.nodeType) {
                            case NODE_TYPE.UTILITY:
                            case NODE_TYPE.MAU:
                                return "<strong>Value:</strong> <span>" + d.node.values[0].value + "</span>";
                                break;

                            default:
                                return "<strong>Value:</strong> <span>" + d.node.values[d.selectedStateIndex].value + "</span>";
                                break;
                        }
                    }
                    return "";
                });

        function deg2rad(deg) {
            return deg * Math.PI / 180;
        }

        function newAngle(d) {
            var ratio = 0;
            switch (d.node.nodeType) {
                case NODE_TYPE.DECISION:
                    ratio = (d.node.values[d.selectedStateIndex].value - Node.valueRangeDecision.minUtility) / (Node.valueRangeDecision.maxUtility - Node.valueRangeDecision.minUtility);
                    break;
                case NODE_TYPE.UTILITY:
                case NODE_TYPE.MAU:
                    ratio = d.node.values[0].value;
                    break;
                default:
                    ratio = d.node.values[d.selectedStateIndex].value;
                    break;
            }
            ratio = getScale(d)(ratio);
            var newAngle = config.minAngle + (ratio * range);

            return newAngle;
        }
        function getDomainFunction() {
            return Utilities.isFunction(config.minMaxFunction) ? config.minMaxFunction : () => [config.minValue, config.maxValue];
        }
        // a linear scale that maps domain values to a percent from 0..1
        function getScale(card) {
            return d3.scaleLinear()
                    .range([0, 1])
                    .domain(getDomainFunction()(card));
        }

        function configure(configuration) {
            var prop = undefined;
            for (prop in configuration) {
                config[prop] = configuration[prop];
            }
            range = config.maxAngle - config.minAngle;
            r = config.size / 2;
            pointerHeadLength = Math.round((r - config.ringInset) * config.pointerHeadLengthPercent);

            arc = d3.arc()
                    .innerRadius(r - config.ringWidth - config.ringInset)
                    .outerRadius(r - config.ringInset)
                    .startAngle(function (d, i) {
                        var ratio = d.compartments[0] * range / (config.maxValue - config.minValue);
                        return deg2rad(config.minAngle + ratio);
                    })
                    .endAngle(function (d, i) {
                        var ratio = d.compartments[1] * range / (config.maxValue - config.minValue);
                        return deg2rad(config.minAngle + ratio);
                    });
        }

        function centerTranslation() {
            return 'translate(' + r + ',' + r + ')';
        }

        function getCustomSettings(config, dat) {
            var tic = config.ticks;
            var acrColors = config.arcColorFn;
            let localData = CardsManager.getLocalData(dat);
            if (typeof dat !== "undefined" && typeof localData.ticks !== "undefined" && typeof localData.reverseColor !== "undefined") {
                tic = localData.ticks;
                acrColors = Radial.getReverseColors(localData.reverseColor);
            }
            return {
                ticks: tic,
                arcColors: acrColors
            };
        }

        function addGauges(containerDataList, saveInObject) {
            if(typeof saveInObject === 'undefined'){
                saveInObject = true;
            }
            if (range === undefined) {
                configure();
            }
            var svgTmp, pointerTmp;

            svgTmp = containerDataList.append('div')
                    .attr('class', 'svg-container')
                    .style('padding-bottom', '55%')
                    .append('svg:svg')
                    .attr('class', 'svg-content')
                    .attr('viewBox', '0 0 ' + config.clipWidth + ' ' + config.clipHeight)
                    .attr('preserveAspectRatio', 'xMinYMin meet');
            svgTmp.call(tip);

            var centerTx = centerTranslation();

            var arcs = svgTmp.append('g')
                    .attr('class', 'arc')
                    .attr('transform', centerTx);

            arcs.selectAll('path')
                    .data((d)=>{
                        var settings = getCustomSettings(config, d);
                        var compartments = d3.pairs(settings.ticks.concat(config.minValue, config.maxValue).sort(d3.ascending));

                        return compartments.map(c => {
                            return {
                                arcColors: settings.arcColors,
                                compartments: c
                            };
                        });
                    })
                    .enter().append('path')
                    .attr('fill', function (d, i) {
                        return d.arcColors[i];
                    })
                    .attr('d', arc);

            var lineData = [[config.pointerWidth / 2, 0],
                [0, -pointerHeadLength],
                [-(config.pointerWidth / 2), 0],
                [0, config.pointerTailLength],
                [config.pointerWidth / 2, 0]];

            var pointerLine = d3.line().curve(d3.curveMonotoneX);

            var pg = svgTmp.append('g')
                    .attr('class', 'pointer')
                    .attr('transform', centerTx);

            pointerTmp = pg.append('path')
                    .attr('d', pointerLine(lineData)/*function(d) { return pointerLine(d) +'Z';}*/)
                    .attr('transform', 'rotate(' + config.minAngle + ')')
                    .on('mouseover', tip.show)
                    .on('mouseout', tip.hide);
            if (config.showLabels) {
                var lg = svgTmp.append('g')
                        .attr('class', 'label')
                        .attr('transform', centerTx);
                lg.selectAll('text')
                        .data(d => {
                            var settings = getCustomSettings(config, d);
                            var domain = getDomainFunction()(d);
                            var dat = [{val: domain[0], self: d}];
                            for (var i = 0; i < settings.ticks.length; i++) {
                                dat.push({
                                    val: (domain[1] - domain[0]) * settings.ticks[i],
                                    self: d
                                });
                            }
                            dat.push({
                                val: domain[1],
                                self: d
                            });
                            return dat;
                        })
                        .enter().append('text')
                        .attr('transform', function (d) {
                            var ratio = getScale(d.self)(d.val);
                            var newAngle = config.minAngle + (ratio * range);
                            var translateX = 0;
                            if (newAngle === config.maxAngle) {
                                var text = d.val;
                                translateX = (-1) * Utilities.measure(text, Utilities.getLazyMeasureContext(), {"font-size": config.labelFontSize});
                            }
                            return 'rotate(' + newAngle + ') translate('+translateX+',' + (config.labelInset - r) + ')';
                        })
                        .attr("font-size",config.labelFontSize)
                        .text(d => d.val);
            }
            if (saveInObject) {
                svg = svgTmp;
                pointer = pointerTmp;
            }
        }

        function render(cards) {
            elements = cards;
            svg = undefined;
            cards.each(d => d.displayNodeType = DISPLAY_NODE_TYPE.RADIAL);
            if(cards.size() > 0){
                var scrollContent = CardsManager.getCardBody(cards);
                addGauges(scrollContent);
                //add value
                scrollContent.append("p")
                        .classed(`radialValue text-center ${CardsManager.getNodeTitleClass()}`, true)
                        .text("0%");
            }

            cards.attr("gaugeType", "radial");

            CardsManager.drawFooterCard(cards, "", {showValue: false});

            update();
        }

        function getColorBaseValue(d) {
            if (d.node.values && config.ticks.length !== 0) {
                let val = d.node.values[d.selectedStateIndex].value;
                let config = CurrentDashboard.get().jsonParse.cards.config.gauge;
                //local reverse
                let localData = CardsManager.getLocalData(d);
                if (typeof localData.ticks !== 'undefined') {
                    var arrayColors = getReverseColor(localData.reverseColor);
                    if (arrayColors[arrayColors.length - 1] === RED) {
                        if (val >= localData.ticks[localData.ticks.length - 1]) {
                            return RED;
                        }
                    }
                } else if (config.arcColorFn[config.arcColorFn.length - 1] === RED) {//global
                    if (val >= config.ticks[config.ticks.length - 1]) {
                        return RED;
                    }
                }
            }
            return null;
        }
        function opacitySVG(svg, isSet) {
            isSet ? svg.style("opacity", "25%") : svg.style("opacity", null);
        }
        function update(newConfiguration) {
            if (newConfiguration !== undefined) {
                configure(newConfiguration);
            }
            if (typeof svg === 'undefined') {
                return;
            }
            var pointers = svg.select(".pointer path");
            valuesText = svg.select(d => d.cardRoot).select(".radialValue");

            pointers.transition()
                    .duration(config.transitionMs)
                    .ease(d3.easeElastic)
                    .attr('transform', function (d, index, divs) {
                        var newAngleVal = newAngle(d);
                        if (newAngleVal < config.minAngle) {
                            opacitySVG(d3.select(d.cardRoot).select("svg"), true);
                            return 'rotate(' + (config.minAngle - 3) + ')';
                        } else if (newAngleVal > config.maxAngle) {
                            opacitySVG(d3.select(d.cardRoot).select("svg"), true);
                            return 'rotate(' + (config.maxAngle + 3) + ')';
                        }
                        opacitySVG(d3.select(d.cardRoot).select("svg"), false);
                        if(typeof d.node.values !== 'undefined')
                            return 'rotate(' + newAngleVal + ')';
                        return 'rotate(0)';
                    });
            valuesText.text(d => {
                if(d.node.values){
                    var format = d3.format(getLabelFormat());
                    if(d.node.checkType(NODE_TYPE.DECISION)){
                        format =  d3.format('.2');
                    }
                    var newAngleVal = newAngle(d);
                    if (newAngleVal < config.minAngle) {
                        return 'Offscale low';
                    } else if (newAngleVal > config.maxAngle) {
                        return 'Offscale high';
                    }
                    switch (d.node.nodeType) {
                        case NODE_TYPE.UTILITY:
                        case NODE_TYPE.MAU:
                            var val = d.node.values[0].value;
                            if (isNaN(d.node.values[0].value)) {
                                opacitySVG(d3.select(d.cardRoot).select("svg"), true);
                            } else {
                                val = math.round(d.node.values[0].value, config.precision);
                                opacitySVG(d3.select(d.cardRoot).select("svg"), false);
                            }
                            return val;
                            break;

                        default:
                            return format(d.node.values[d.selectedStateIndex].value);
                            break;
                    }
                }
            }).style("color", (d) => {
                return getColorBaseValue(d);
            }).attr("class", d => {
                var newAngleVal = newAngle(d);
                if (newAngleVal < config.minAngle || newAngleVal > config.maxAngle || (d.node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU]) && isNaN(d.node.values[0].value))) {
                    return d3.select(d.cardRoot).select(".radialValue").attr("class") + " errorValue";
                }
                return d3.select(d.cardRoot).select(".radialValue").attr("class");
            });

            var cardCaptions = svg.select(d => d.cardRoot).select(".card-text");
            cardCaptions.style("color", (d) => getColorBaseValue(d));
        }
        function addAll(cards) {
            if (!globalGaugeConfigured) {
                configure(CurrentDashboard.get().jsonParse.cards.config.gauge);
                globalGaugeConfigured = true;
            }
            if (cards.size() !== 0) {
                elements = cards;
                svg = undefined;
                render(elements);
//                    render(elements.filter(d=>d.node.checkType(NODE_TYPE.DECISION)));
            }
        }

        function getReverseColor(isReverse) {
            return isReverse ? [RED, '#ffff00', '#00ff00'] : defaultArcColorFn;
        }

        function getLabelFormat() {
            return '.'+config.precision+'%';
        }
        return {
            update: function (cards) {
                var cardsGroup = CardsManager.detectDisplayNodeType(cards, DISPLAY_NODE_TYPE.RADIAL);
                svg = cardsGroup.equals;
                update();
                CardsManager.clearCrads(cardsGroup.different);
                if(cardsGroup.different.size() > 0){
                    addAll(cardsGroup.different);
                }
            },
            addAll: function (cards) {
                addAll(cards);
            },
            updateTemplateGauge: function (configuration, gaugeCSSselector) {
                configure(configuration);
                globalGaugeConfigured = true;
                $(gaugeCSSselector).html("");
                addGauges(d3.select(gaugeCSSselector), false);
            },
            updateTemplateGaugeWithoutSave: function (configuration, gaugeCSSselector) {
                var originalConfig = Utilities.deepCopy(config);
                configure(configuration);
                $(gaugeCSSselector).html("");
                addGauges(d3.select(gaugeCSSselector), false);
                configure(originalConfig);
            },
            updateConfigure: function (configuration) {
                configure(configuration);
                globalGaugeConfigured = true;
            },
            getConfig: function () {
                return config;
            },
            setConfig: function (newConfig) {
                config = newConfig;
            },
            getReverseColors: function (isReverse) {
                return getReverseColor(isReverse);
            },
            isReverse: function (colors) {
                return colors[0] !== defaultArcColorFn[0];
            },
            getShortData: function (data, index) {
                var reverseColor, ticks;
                let localData = CardsManager.getLocalData(data);
                if (typeof localData.reverseColor !== "undefined" && typeof localData.ticks !== "undefined") {
                    reverseColor = localData.reverseColor ? 1 : 0;
                    ticks = localData.ticks;
                }
                var netData = Network.getNetworkData(data.node.network);
                return [CARDS_TYPE.SINGLE_STATE_NODE, [netData.category, netData.filename, data.node.id], data.selectedStateIndex, index, CardsManager.getLocalData(data).title, reverseColor, ticks, data.annotation];
            },
            decodeShortData: function (shortData) {
                let type = shortData[0];
                let nodeIDDat = shortData[1];
                let networkHandle = Network.getNetworkHandleData(nodeIDDat[0], nodeIDDat[1]);
                let nodeID = Node.getNodeIdFromString(nodeIDDat[2], networkHandle);
                let nodeData = d3.select("#" + nodeID).data()[0];
                if (Array.isArray(nodeData.observeOutcome) && typeof shortData[2] !== "undefined" && shortData[2] !== null) {
                    nodeData.observeOutcome.push(shortData[2]);
                } else {
                    nodeData.observeOutcome = typeof shortData[2] !== "undefined" && shortData[2] !== null ? [shortData[2]] : [];
                }
                var dat = {
                    type: type,
                    node: nodeData,
                    selectedStateIndex: CardsManager.getNotNullData(shortData[2]),
                    index: CardsManager.getNotNullData(shortData[3]),
                    annotation: shortData[7]
                };
                CardsManager.addToLocalDataAndRemoveDefaultIfDefined(dat, {
                    title: CardsManager.getNotNullData(shortData[4]),
                    reverseColor: Utilities.fieldIsDefinietAndNotNull(shortData[5]) ? shortData[5] === 1 : undefined,
                    ticks: CardsManager.getNotNullData(shortData[6])
                });
                return dat;
            },
            removeInexistentCards: function (nodesToRemove) {
                for (var i = 0; i < nodesToRemove.length; i++) {
                    d3.select("#gauges").selectAll('div[type="gaugeCard"]')
                            .filter(d =>
                                d.type === CARDS_TYPE.SINGLE_STATE_NODE &&
                                d.node.handle === nodesToRemove[i].handle &&
                                d.node.id === nodesToRemove[i].id &&
                                d.node.network === nodesToRemove[i].network)
                            .remove();
                }
            },
            setPrecision: function (precision) {
                if(typeof  precision === 'undefined'){
                    return;
                }
                config.precision = precision;
            },
            getPrecision: function () {
                return config.precision;
            },
            ticksEqualsDefault: function (ticksToCompare) {
                if (!Array.isArray(ticksToCompare)) {
                    throw 'Parameter is not an array!';
                }
                const tolerance = 0.001;
                return Math.abs(config.ticks[0] - ticksToCompare[0]) < tolerance
                        && Math.abs(config.ticks[1] - ticksToCompare[1]) < tolerance;
            }
        };
    })();
    var TopN = (function () {
        var TREE_VIEW_CHECKBOXES_ID = "treeview_select_states_to_topn";
        $("#addNewTopNCardBtn").click(function () {
            add();
        });
        function sortTopNList(list, topn) {
            list.sort((a, b) => {
                const aIdx = a.node.outcome.indexOf(a.selectedOutcome);
                const bIdx = b.node.outcome.indexOf(b.selectedOutcome);
                return  b.node.values[bIdx].value - a.node.values[aIdx].value;
            });
            return list.slice(0, topn);
        }
        function getCheckboxID(nodeID, outcome) {
            return "topN-" + nodeID + outcome;
        }
        function nodeWithStatesFilter(d) {
            return (d.temporalType !== NODE_TEMPORAL_TYPE.PLATE) && d.checkType([NODE_TYPE.CPT, NODE_TYPE.TRUTH_TABLE, NODE_TYPE.NOISY_ADDER, NODE_TYPE.NOISY_MAX]);
        }
        function add(data, clearBefore) {
            if (typeof data === "undefined") {
                data = {
                        title: "TopN",
                        topn: 0
                };
            }
            clearBefore = typeof clearBefore === "undefined" ? false : true;
            if(clearBefore){
                d3.select("#addCardsModal")
                        .select("#topNCards")
                        .html("");
            }
            var nodesWithStates = [];
            var allNodes = d3.selectAll("div[type=nodeCard]");
            if (Utilities.targetsExist(allNodes)) {
                allNodes.filter(d => nodeWithStatesFilter(d))
                        .filter(d => d.isTarget)
                        .each(d => nodesWithStates.push(d));
            } else {
                allNodes.filter(d => nodeWithStatesFilter(d))
                        .each(d => nodesWithStates.push(d));
            }
                var cardsBox = d3.select("#addCardsModal")
                    .select("#topNCards")
                    .append("div")
                    .datum(data)
                    .classed("cardTN", true);
                cardsBox.each((data, index, elements) => {
                    data.root = elements[index];
                });
                var inputNumber = cardsBox.append("div")
                        .classed("form-group row", true);
                inputNumber.append("label")
                        .attr("for", "topNStatesCount")
                        .classed("col-sm-3 col-form-label", true)
                        .text("Top states: ");
                var inputNumberFieldGroup = inputNumber.append("div")
                        .classed("col-sm-9", true)
                        .style("padding","0");
                inputNumberFieldGroup.append("input")
                        .classed("form-control", true)
                        .attr("type", "number")
                        .attr("value", d => d.topn)
                        .attr("min", "1")
                        .attr("step", "1")
                        .attr("id", "topNStatesCount")
                        .on('keyup mouseup', (data, index, elements) => {
                            let enteredValue = parseInt(d3.select(elements[index]).property("value"));
                            let nodeChecked = {"id": [], "dataid": [], "text": []};
                            $(data.root).find("#" + TREE_VIEW_CHECKBOXES_ID).hummingbird("getChecked", {list: nodeChecked, onlyEndNodes: true});
                            let max = nodeChecked.id.length;
                            if (enteredValue < 0 || enteredValue > max) {
//                                d3.select(elements[index]).property("value", max);
                                $(data.root).find(".invalid-feedback").show();
                            } else {
                                $(data.root).find(".invalid-feedback").hide();
                            }
                        });
                inputNumberFieldGroup.append("div")
                        .classed("invalid-feedback", true)
                        .text("The number of states must be positve and not greater than the number of selected states.");
                var selectLabelVisibility = cardsBox.append("div")
                        .classed("form-group row", true);
                selectLabelVisibility.append("label")
                        .attr("for", "topNLabelVisibility")
                        .classed("col-sm-3 col-form-label", true)
                        .text("Labels: ");
                var options = selectLabelVisibility.append("select")
                        .classed("form-control col-sm-9", true)
                        .attr("id", "topNLabelVisibility");
                let nodeStateOpt = options.append("option")
                        .attr("data-label_option", LABEL_OPTION.NODE_STATE)
                        .text("Node and state");
                let nodeOpt = options.append("option")
                        .attr("data-label_option", LABEL_OPTION.NODE)
                        .text("Node only");
                let stateOpt = options.append("option")
                        .attr("data-label_option", LABEL_OPTION.STATE)
                        .text("State only");
                switch (data.labelOption) {
                    case LABEL_OPTION.NODE:
                        nodeOpt.attr("selected","");
                        break;
                     case LABEL_OPTION.STATE:
                        stateOpt.attr("selected","");
                        break;
                    default:
                        nodeStateOpt.attr("selected","");
                        break;
                }
                var inputTitle = cardsBox.append("div")
                        .classed("form-group row", true);
                inputTitle.append("label")
                        .attr("for", "topNStatesCount")
                        .classed("col-sm-3 col-form-label", true)
                        .text("Title: ");
                inputTitle.append("input")
                        .classed("form-control col-sm-9", true)
                        .attr("type", "text")
                        .attr("value", d => d.title)
                        .attr("id", "topNCardTitle");
                var inputAnnotation = cardsBox.append("div")
                        .classed("form-group row", true);
                inputAnnotation.append("label")
                        .attr("for", "annotation_t")
                        .classed("col-sm-3 col-form-label", true)
                        .text("Annotation: ");
                inputAnnotation.append("input")
                        .classed("form-control col-sm-9 annotation", true)
                        .attr("type", "text")
                        .attr("value", d => CardsManager.parseAnnotation(d))
                        .attr("id", "annotation_t");
                cardsBox.append("hr");
                cardsBox.append("div")
                        .classed("text-center col-md-12", true)
                        .append("h4")
                        .text("Select outcomes");
                var filter = cardsBox.append("div")
                        .classed("col-md-12", true)
                        .append("div")
                        .classed("input-group input-group-sm col-md-12 filterForm", true)
                        .attr("autoFilter", "true");
                filter.append("input")
                        .classed("form-control py-2 border-right-0 border", true)
                        .attr("type", "search")
                        .attr("placeholder", "search")
                        .attr("autoFilterListId","treeviewTopN");
                filter.append("span")
                        .classed("input-group-append blueButtonGroup", true)
                        .append("button")
                        .classed("btn btn-outline-secondary border-left-0 border", true)
                        .attr("type", "button")
                        .attr("autoFilterClear","true")
                        .append("i")
                        .classed("fas fa-times", true);
                var treeview = cardsBox.append("div")
                        .classed("hummingbird-treeview", true)
                        .attr("id", TREE_VIEW_CHECKBOXES_ID)
                        .append("ul")
                        .classed("hummingbird-base", true)
                        .attr("id", "treeviewTopN")
                        .append("li");
                treeview.append("i")
                        .classed("fa fa-plus", true);
                var chechboxAll = treeview.append("label");
                chechboxAll.append("input")
                        .attr("id", "allNodesTreeVewTopN")
                        .attr("data-id", "allNodesTree")
                        .attr("bayesbox-humminbird-type", "rootInput")
                        .attr("type", "checkbox");
                chechboxAll.append("span")
                        .text("All nodes");
                treeview.append("ul")
                        .attr("id", "statesToSelectTopN")
                        .attr("bayesbox-humminbird-type", "list");
                //add current tree view checkboxes
                cardsBox.each((dat, index, nodes) => {
                    var cardRoot = d3.select(nodes[index]);
                    CardsManager.createTreeView(cardRoot, nodesWithStates, getCheckboxID);
                });
                RightColumn.Filter.autoDetectTreeviewFilter(filter);
//            d3.select("#addCardsModal")
//                    .select("#topNCards")
//                    .selectAll(".card")
//                    .remove();

        }
        function checkCheckboxes() {
            d3.select(".topNPanel")
                    .select("#topNCards")
                    .selectAll("#" + TREE_VIEW_CHECKBOXES_ID)
                    .each((data, index, elements) => {
                        data.rankList.forEach(n => {
                            $(elements[index]).hummingbird("checkNode", {attr: "id", name: getCheckboxID(Node.getNodeIdFromObject(n.node), n.selectedOutcome), expandParents: false});
                        });
                    });
        }
        function addAll(data) {
            data.each(d => sortTopNList(d.rankList, d.topn));
                data.each(d => d.type = CARDS_TYPE.TOPN);
                HorizontalBarChart.addAllCustomDomain(data, function () {
                    var domain_x = [];
                    var domain_y = this.rankList.map((o, index) => {
                        let prefix = (index + 1) + ". ";
                        switch (this.labelOption) {
                            case LABEL_OPTION.NODE:
                                return prefix + o.node.name;
                                break;
                            case LABEL_OPTION.STATE:
                                return prefix + o.selectedOutcome;
                                break;
                            default:
                                return prefix + o.node.name + " - " + o.selectedOutcome;
                                break;
                        }
                    }).slice(0, this.topn);
                    for (var i = 0; i < this.topn; i++) {
                        var index = -1;
                        for (var j = 0; j < this.rankList[i].node.outcome.length; j++) {
                            if (this.rankList[i].node.outcome[j] === this.rankList[i].selectedOutcome) {
                                index = j;
                            }
                        }
                        domain_x[i] = this.rankList[i].node.values[index].value;
                    }
                    return {
                        y: domain_y,
                        x: domain_x
                    };
                });
        }
        return {
            update: function (cards) {
                CardsManager.clearCrads(cards);
                addAll(cards);
            },
            removeInexistentCards: function (nodesToRemove) {
                for (var i = 0; i < nodesToRemove.length; i++) {
                    d3.select("#gauges").selectAll('div[type="gaugeCard"]')
                            .filter(d =>
                                d.type === CARDS_TYPE.TOPN &&
                                        d.rankList.some(r => r.node.handle === nodesToRemove[i].handle &&
                                                    r.node.id === nodesToRemove[i].id &&
                                                    r.node.network === nodesToRemove[i].network
                                        ))
                            .remove();
                }
            },
            addAll: function (data) {
                addAll(data);
            },
            addEmpty: function (data, clearBefore) {
                add(data, clearBefore);
                if (typeof data !== "undefined") {
                    checkCheckboxes();
                }
            },
            getShortData: function (data, index) {
                var shortRankList = [];
                data.rankList.forEach(s => {
                    let netData = Network.getNetworkData(s.node.network);
                    shortRankList.push([[netData.category, netData.filename, s.node.id], s.selectedOutcome]);
                });
                return [data.type, data.title, index, data.topn, shortRankList, data.labelOption, data.annotation];
            },
            decodeShortData: function (shortDat) {
                var rankList = [];
                shortDat[4].forEach(s => {
                    /**
                     * Compatibility with node ID contains network handle ex. Monkey-11, where Monkey is node ID and 11 is network handle
                     * @returns {undefined}
                     */
                    const backwardCompatibility = function (n) {
                        if(!Array.isArray(n[0])){
                            return d3.select("#" + n[0]).data()[0];
                        }
                        return null;
                    };
                    let nodeData = backwardCompatibility(s);
                    if (nodeData === null) {
                        let nodeIDDat = s[0];
                        let networkHandle = Network.getNetworkHandleData(nodeIDDat[0], nodeIDDat[1]);
                        let nodeID = Node.getNodeIdFromString(nodeIDDat[2], networkHandle);
                        nodeData = d3.select("#" + nodeID).data()[0];
                    }

                    rankList.push({
                        node: nodeData,
                        selectedOutcome: s[1]
                    });
                });
                return {
                    type: shortDat[0],
                    title: shortDat[1],
                    index: CardsManager.getNotNullData(shortDat[2]),
                    topn: shortDat[3],
                    rankList: rankList,
                    labelOption: shortDat[5],
                    annotation: shortDat[6]
                };
            },
            getTopNCardsFromModal: function () {
                var list = {"id": [], "dataid": [], "text": []};
                $("#topNCards #treeviewTopN").hummingbird("getChecked", {list: list, onlyEndNodes: true});
                var topNSizeField = d3.select("#topNCards #topNStatesCount");
                var topn = topNSizeField.size() > 0 ? parseInt(topNSizeField.property("value")) : 0;
                var topNCardTitleField = d3.select("#topNCards #topNCardTitle");
                var title = topNCardTitleField.size() > 0 ? d3.select("#topNCards #topNCardTitle").property("value") : "";
                var annotation_tField = d3.select("#topNCards #annotation_t");
                var annotation = annotation_tField.size() > 0 ? d3.select("#topNCards #annotation_t").property("value") : "";
                var selectTag = $("#topNCards").find("#topNLabelVisibility").get(0);
                var labelOption = $(selectTag).find("option").toArray().filter((d, index, nodes) => $(nodes[index]).text().toLowerCase() === $(selectTag).val().toLowerCase());
                let topNData = [];
                list.id.forEach(n => {
                    let checkbox = d3.select("#" + n).data()[0];
                    let nodeData = d3.select("#" + checkbox.nodeID).data()[0];
                    topNData.push({
                        node: nodeData,
                        selectedOutcome: checkbox.outcome
                    });
                });
                topn = isNaN(topn) ? 0 : topn;
                topn = topn < 0 ? 0 : topn;
                topn = topn > topNData.length ? topNData.length : topn;

                return {
                    type: CARDS_TYPE.TOPN,
                    title: title,
                    topn: topn,
                    rankList: topNData,
                    labelOption: labelOption.length !== 0 ? parseInt(labelOption[0].dataset.label_option) : 0,
                    annotation: annotation,
                    originalNode: d3.select("#topNCards #topNCardTitle").data()[0]
                };
            }
        };
    })();
    var CurrentDashboard = (function () {
        var DATA_SOURCE = {
            OBJECT_STRING: 0,
            OBJECT: 1,
            VISIBLE_CARDS: 2
        };
        var dashboard = {
            jsonParse: {
                objectVersion: 5,
                valueFontPerc: 140,
                gaugeFontPerc: 100,
                textFontPerc: 100,
                annotationFontPerc: 50,
                footnoteFontPerc: 50,
                pins: [],
                footnote: "",
//                visibleNodesRColumn: [],//if array is empty then all nodes are visible otherwise only nodes from array are visible
                cards: {
                    config: {
                        gauge: {
                            ticks: Radial.getConfig().ticks,
                            arcColorFn: Radial.getConfig().arcColorFn,
                            precision: Radial.getPrecision()
                        },
                        pieChart: {
                            colors: PieChart.createColorObject(),
                            sort: PieChart.isSort()
                        },
                        areaChart: {
                            colors: AreaChartCards.createColorObject()
                        }
                    },
                    list: []
                }
            },
            name: "",//empty string as default value is important
            id: ""
        };
        function columnAreHiddenCards() {
            return !(typeof dashboard.jsonParse.visibleNodesRColumn === 'undefined' || dashboard.jsonParse.visibleNodesRColumn === null);
        }
        function update(option) {
            switch (option.dataSource){
                case DATA_SOURCE.OBJECT_STRING:
                    dashboard.jsonParse = JSON.parse(dashboard.json);
                    break;
                case DATA_SOURCE.OBJECT:
                    dashboard.json = JSON.stringify(dashboard.jsonParse);
                    break;
                case DATA_SOURCE.VISIBLE_CARDS:
                    dashboard.jsonParse.cards = CardsManager.getVisibleCardsData();
                    dashboard.json = JSON.stringify(dashboard.jsonParse);
                    break;
            }
            let otherNodes = [];
            let observationNodes = [];
            d3.selectAll("div[type=nodeCard]").each((n, index, divs)=> {
                if (n.diagType === NODE_DIAG_TYPE.OBSERVATION) {
                    observationNodes.push(divs[index]);
                } else {
                    otherNodes.push(divs[index]);
                }
            });
            if (observationNodes.length > 0) {
                RightColumn.Filter.PermanentlyHiddenNodes.hiddeBySystem(otherNodes);
            }
            if (columnAreHiddenCards()) {
                RightColumn.Filter.PermanentlyHiddenNodes.showVisibleNodesRColumn(dashboard.jsonParse.visibleNodesRColumn);
            }
            if (option.updateOnlyPermanentlyHiddenCards) {
                return;
            }
            StartApp.setAppTitle(dashboard.name);
            CardsManager.setColumnCount(dashboard.jsonParse.columnCount);
            var newGaugeConfiguration = {};
            newGaugeConfiguration.ticks = dashboard.jsonParse.cards.config.gauge.ticks;
            newGaugeConfiguration.arcColorFn = dashboard.jsonParse.cards.config.gauge.arcColorFn;
            Radial.updateConfigure(newGaugeConfiguration);
            PieChart.setColor(dashboard.jsonParse.cards.config.pieChart.colors);
            PieChart.setSort(dashboard.jsonParse.cards.config.pieChart.sort);
            AreaChartCards.setColor(dashboard.jsonParse.cards.config.areaChart.colors);
            Radial.setPrecision(dashboard.jsonParse.cards.config.gauge.precision);
            if (option.updateCards) {
                CardsManager.restoreAllCards(dashboard.jsonParse.cards.list);
                CardsManager.redrawAll();
                $("#otherAccordion").show();
            }
            //set default
            dashboard.jsonParse.annotationFontPerc = typeof dashboard.jsonParse.annotationFontPerc === "undefined" ? 50 : dashboard.jsonParse.annotationFontPerc;
            dashboard.jsonParse.footnoteFontPerc = typeof dashboard.jsonParse.footnoteFontPerc === "undefined" ? 50 : dashboard.jsonParse.footnoteFontPerc;
            CardsManager.updateCardsFonts( {
                value: dashboard.jsonParse.valueFontPerc,
                gauges: dashboard.jsonParse.gaugeFontPerc,
                text: dashboard.jsonParse.textFontPerc,
                annotation: dashboard.jsonParse.annotationFontPerc,
                footnote: dashboard.jsonParse.footnoteFontPerc
            });
            if(dashboard.jsonParse.footnote !== "undefined" && dashboard.jsonParse.footnote !== null){
                Footnote.set(dashboard.jsonParse.footnote);
            }
            if (typeof dashboard.jsonParse.errorBarChartLineWidth !== "undefined" && typeof dashboard.jsonParse.errorBarChartColor !== "undefined") {
                const v = dashboard.jsonParse.errorBarChartLineWidth;
                const c = dashboard.jsonParse.errorBarChartColor;
                const sheet = new CSSStyleSheet();
                sheet.replaceSync(`.cardBody svg .histogramLine { stroke-width: ${v}px !important; stroke: hsl(${c}, 100%, 50%)} .cardBody svg .bar { stroke: hsl(${c}, 100%, 50%); fill: hsl(${c}, 100%, 50%)}`);
                document.adoptedStyleSheets.push(sheet);
            }
        }
        function getAllPins() {
            //pins
            var pins = [];
            d3.selectAll("div[type=nodeCard]")
                    .filter(d => d.pin)
                    .each(d => pins.push(d.id));
            return [...new Set(pins)];//remove duplicates
        }
        function save() {
            dashboard.jsonParse.cards = CardsManager.getVisibleCardsData();//set correct cards order
            dashboard.jsonParse.pins = getAllPins();
            dashboard.json = JSON.stringify(dashboard.jsonParse);
            let body = JSON.stringify({
                id: dashboard.id,
                dashboard: dashboard.jsonParse,
                name: dashboard.name,
                netHandle: Network.getCurrentNetwork()
            });
            let done = (id, textStatus, jqXHR) => {
                try {
                    CurrentDashboard.set(JSON.parse(jqXHR.responseText));
                    checkDashboardIdInURL();
                    Toasts.add("Dashboard", "Dashboard was saved");
                } catch (e) {
                    Toasts.add("Dashboard", "Error while saving dashboard");
                }
                WarningCloseBrowser.disable();
            };
            let fail = function (jqXHR, textStatus, errorThrown) {
                console.log(jqXHR);
                Toasts.add("Dashboard", "Error while saving dashboard");
            };
            if (dashboard.id === "") {
                Request.addDashboard(body, done, fail);
            } else {
                Request.updateDashboard(body, done, fail);
            }
        }

        function checkDashboardIdInURL() {
            const getQueryString = function () {
                var href = window.location.href;
                return href.replace(window.location.origin, "").replace(window.location.pathname, "");
            };
            if (dashboard.id === "" || !history.pushState) {
                return;
            }
            let queryString = getQueryString();
            var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
            if (queryString === "") {
                newurl += `?id=${dashboard.id}`;
                window.history.pushState({path: newurl}, '', newurl);
            } else if(!queryString.includes("id=")) {
                newurl += `&id=${dashboard.id}`;
                window.history.pushState({path: newurl}, '', newurl);
            }
        }

        function validJsonVersion(dashbr) {
            var newDashboardDescription = JSON.parse(dashbr.json);
            if (typeof newDashboardDescription.objectVersion !== "undefined" && newDashboardDescription.objectVersion !== null && (dashboard.jsonParse.objectVersion !== newDashboardDescription.objectVersion)) {
                StartApp.setStartAppData(dashbr.handle, []);
                console.log("Dashboard version from DB isn't valid");
                Toasts.add("DB", "Dashboard version from DB isn't valid");
                return false;
            }
            return true;
        }
        return {
            set: function (dashbr) {
                var newDashboardDescription = JSON.parse(dashbr.json);
                if (typeof newDashboardDescription.objectVersion === "undefined" || newDashboardDescription.objectVersion === null) {
                    dashbr.json = JSON.stringify(dashboard.jsonParse);
                } else if (!validJsonVersion(dashbr)) {
                    dashboard["id"] = dashbr["id"];
                    return false;
                }
                var prop = undefined;
                for (prop in dashbr) {
                    dashboard[prop] = dashbr[prop];
                }
//                dashboard = dashbr;
                update({dataSource: DATA_SOURCE.OBJECT_STRING, updateCards: false});
                return true;
            },
            validJsonVersion: function (dashbr) {
                return validJsonVersion(dashbr);
            },
            get: function () {
                return dashboard;
            },
            updateGlobal: function () {
                update({dataSource: DATA_SOURCE.OBJECT, updateCards: true});
            },
            updateLocal: function (options) {
                var p = {
                    dataSource: DATA_SOURCE.VISIBLE_CARDS,
                    updateCards: true,
                    updateOnlyPermanentlyHiddenCards: false
                };
                var prop = undefined;
                for (prop in options) {
                    p[prop] = options[prop];
                }
                update(p);
            },
            columnAreHiddenCards: function () {
                return columnAreHiddenCards();
            },
            getVisibleNodes: function () {
                return dashboard.jsonParse.visibleNodesRColumn;
            },
            setVisibleNodes: function (visibleNodesRColumn) {
                dashboard.jsonParse.visibleNodesRColumn = visibleNodesRColumn;
            },
            save: function () {
                save();
            },
            update: function () {
                var cards = d3.select("#gauges").selectAll('div[type="gaugeCard"]');
                var cardsByTypes = CardsManager.detectValuesType(cards);
                Radial.update(cardsByTypes.radial);
                RadialUtility.update(cardsByTypes.radialUtility);
                HorizontalBarChart.update(cardsByTypes.horizontalBarPlot);
                HorizontalBarChart.addAllCustomDomain(cardsByTypes.horizontalBarPlotUtility, function () {
                    var domainX = [0,0];
                    let localData = CardsManager.getLocalData(this);
                    if (this.node.isEquation && typeof localData.minMaxMauExpression !== "undefined") {
                        domainX = localData.minMaxMauExpression;
                    } else {
                        let minMax = Node.minMaxUtilityHelper(this.node);
                        domainX = [minMax.minUtility, minMax.maxUtility];
                    }

                    return {
                        x: this.node.values.map(v => v.value),
                        y: Node.Outcome.getOutcomeIndexingParents(this.node, 0),
                        domainX: domainX
                    };
                }, true);
                HorizontalBarChart.addAllCustomDomain(cardsByTypes.horizontalBarPlotGauge, function () {
                    var indexingParentId = Node.getNodeIdFromHandle(this.node.indexingParentsIds[0].node,this.node.network);
                    var indexingParent = d3.select("#" + indexingParentId).data()[0];//outcome
                    var startIndex = this.selectedStateIndex;
                    var x = [];
                    for (var i = startIndex; i < this.node.values.length; i += this.node.outcome.length) {
                        x.push(this.node.values[i].value);
                    }
                    return {
                        x: x,
                        y: indexingParent.outcome
                    };
                }, true);
                MultidimensionalTable.update(cardsByTypes.multidimensional);
                ConstantGauge.update(cardsByTypes.constant);
                TopN.update(cardsByTypes.topN);
                TextGauge.update(cardsByTypes.text);
                HistogramGauge.update(cardsByTypes.histogram);
                PieChart.update(cardsByTypes.distribution);
                LineChart.update(cardsByTypes.lineChart);
                LineChartDBN.update(cardsByTypes.lineChartDBN);
                AreaChartCards.update(cardsByTypes.areaChart);
            },
            getPins: function () {
                return typeof dashboard.jsonParse.pins !== "undefined" ? dashboard.jsonParse.pins : [];
            }
        };
    })();
    var SettingsDashboard = (function () {
        var MODAL_ID = "#settingsModal";
        var GAUGE_TEMPLATE_ID = '#globalGaugeTemplate';
        var $TICK_POSITION_SLIDER = $("#globalTicksPosition");
        var reverseColorObj = document.getElementById('globalReverseGaugeColor');
        var PIE_CHART_EXAMPLE_ID = "colorSpreadPieChartExample";
        var AREA_CHART_EXAMPLE_ID = "colorSpreadAreaChartExample";
        var globalSettingsBtn = null;

        $TICK_POSITION_SLIDER.slider({
            range: true,
            min: 0,
            max: 100,
            step: 1,
            values: [25, 75],
            slide: function (event, ui) {
                var arcColorFn = Radial.getReverseColors(reverseColorObj.checked);
                d3.select("#globalTicksPositionText").select("#firstGlobalTick").text(ui.values[ 0 ]);
                d3.select("#globalTicksPositionText").select("#secondGlobalTick").text(ui.values[ 1 ]);
                var start = parseFloat(ui.values[ 0 ]) / 100;
                var stop = parseFloat(ui.values[ 1 ]) / 100;
                Radial.updateTemplateGaugeWithoutSave({arcColorFn: arcColorFn, ticks: [start, stop]}, GAUGE_TEMPLATE_ID);
            }
        });
        $('#gridColumns').on('change', function () {
            var columns = parseInt(this.value);
            if (columns < 0) {
                CardsManager.setColumnCountTmp(null);
            } else {
                CardsManager.setColumnCountTmp(columns);
            }
        });
        $(reverseColorObj).change(function () {
            var arcColorFn = Radial.getReverseColors(reverseColorObj.checked);
            var ticks = $TICK_POSITION_SLIDER.slider("option", "values").map(d => parseFloat(d) / 100);
            var config = {arcColorFn: arcColorFn};
            config.ticks = ticks;
            Radial.updateTemplateGaugeWithoutSave(config, GAUGE_TEMPLATE_ID);
        });
        function getColorPropFromSliders(cardID, ManageObject) {
            var spread = Number.parseInt(d3.select(`#${cardID} #colorSpreadInput`).property("value"));
            var start = Number.parseInt(d3.select(`#${cardID} #startColorInput`).property("value"));
            var brightness = Number.parseInt(d3.select(`#${cardID} #brightnesColorInput`).property("value")) / 100;
            return ManageObject.createColorObject(spread, start, brightness);
        }
        function colorSlidersInit(cardID, ManageObject, exampleID) {
            //color spread
            d3.select(`#${cardID} #colorSpreadInput`)
                    .property("value", () => {
                        var currentColorSpread = ManageObject.getColorSpread();
                        return currentColorSpread;
                    })
                    .on("input", () => {
                        ManageObject.updateExampleColors(exampleID, getColorPropFromSliders(cardID, ManageObject));
                        d3.select(`#${cardID} label[for="colorSpreadInput"] val`).text(ManageObject.getColorSpreadPerc(d3.event.target.value));
                    });
            d3.select(`#${cardID} label[for="colorSpreadInput"] val`).text(ManageObject.getColorSpreadPerc());
            //start color
            d3.select(`#${cardID} #startColorInput`)
                    .property("value", () => {
                        var currentColorSpread = ManageObject.getColorStart();
                        return currentColorSpread;
                    })
                    .on("input", () => {
                        ManageObject.updateExampleColors(exampleID, getColorPropFromSliders(cardID, ManageObject));
                        d3.select(`#${cardID} label[for="startColorInput"] val`).text(ManageObject.getColorStartPerc(d3.event.target.value));
                    });
            d3.select(`#${cardID} label[for="startColorInput"] val`).text(ManageObject.getColorStartPerc());
            //brightness
            d3.select(`#${cardID} #brightnesColorInput`)
                    .property("value", () => {
                        var currentColorSpread = ManageObject.getColorBrightness() * 100;
                        return currentColorSpread;
                    })
                    .on("input", () => {
                        ManageObject.updateExampleColors(exampleID, getColorPropFromSliders(cardID, ManageObject));
                        d3.select(`#${cardID} label[for="brightnesColorInput"] val`).text(ManageObject.getColorBrightnessPerc(d3.event.target.value/100));
                    });
            d3.select(`#${cardID} label[for="brightnesColorInput"] val`).text(ManageObject.getColorBrightnessPerc());
        }
        $(MODAL_ID).on('show.bs.modal', function (e) {
            $("#collapseOne").collapse('show');//show first card
            DefineCardsModal.updateColumnCount();
            $("#titleDashboard").val(CurrentDashboard.get().name);
            $("#urlDashboard").text(window.location.origin + window.location.pathname + "?id=" + CurrentDashboard.get().id);
            $("#footnoteDashboard").val(CurrentDashboard.get().jsonParse.footnote);
            CurrentDashboard.get().id === "" ? $("#urlDashboardField").hide() : $("#urlDashboardField").show();
            Radial.updateTemplateGauge({}, GAUGE_TEMPLATE_ID);
            $(reverseColorObj).prop("checked", Radial.isReverse(CurrentDashboard.get().jsonParse.cards.config.gauge.arcColorFn));
            var config = Radial.getConfig();
            var ticks = config.ticks;
            var start = parseInt(ticks[ 0 ] * 100);
            var stop = parseInt(ticks[ 1 ] * 100);
            d3.select("#globalTicksPositionText").select("#firstGlobalTick").text(start);
            d3.select("#globalTicksPositionText").select("#secondGlobalTick").text(stop);
            $TICK_POSITION_SLIDER.slider( "values", [start, stop] );
            let valueFont = CurrentDashboard.get().jsonParse.valueFontPerc;
            valueFont > 0 ? d3.select("#valueCardFontSize").property("value", valueFont) : d3.select("#valueCardFontSize").property("value", 100);
            let gaugeFont = CurrentDashboard.get().jsonParse.gaugeFontPerc;
            gaugeFont > 0 ? d3.select("#captionFontSizeInput").property("value", gaugeFont) : d3.select("#captionFontSizeInput").property("value", 100);
            let textFont = CurrentDashboard.get().jsonParse.textFontPerc;
            textFont > 0 ? d3.select("#textCardFontSize").property("value", textFont) : d3.select("#textCardFontSize").property("value", 100);
            let annotationFont = CurrentDashboard.get().jsonParse.annotationFontPerc;
            annotationFont > 0 ? d3.select("#annotationFontSizeInput").property("value", annotationFont) : d3.select("#annotationFontSizeInput").property("value", 100);
            let footnoteFont = CurrentDashboard.get().jsonParse.footnoteFontPerc;
            footnoteFont > 0 ? d3.select("#footnoteFontSizeInput").property("value", footnoteFont) : d3.select("#footnoteFontSizeInput").property("value", 50);
            let gaugePrecision = CurrentDashboard.get().jsonParse.cards.config.gauge.precision;
            gaugePrecision > 0 ? d3.select("#valuePrecision").property("value", gaugePrecision) : d3.select("#valuePrecision").property("value", 2);
            //pie chart
            colorSlidersInit("collapseFourPieChart", PieChart, PIE_CHART_EXAMPLE_ID);
            //sort radio button
            d3.select("#sortPieChart").select("#sortByValuePCh").property("checked", PieChart.isSort());
            //lorem ipsum footnote
            setLoremIpsumSize(d3.select("#footnoteFontSizeInput").node());
            colorSlidersInit("collapseFiveAreaChart", AreaChartCards, AREA_CHART_EXAMPLE_ID);
        });
        $('#collapseFourPieChart').on('shown.bs.collapse', function () {
            //pie charts settings
            PieChart.addExamplePieChart("#colorSpreadRow", PIE_CHART_EXAMPLE_ID);
            PieChart.updateExampleColors(PIE_CHART_EXAMPLE_ID, getColorPropFromSliders("collapseFourPieChart", PieChart));
        });
        $('#collapseFiveAreaChart').on('shown.bs.collapse', function () {
            //area charts settings
            if(!DynamicBN.isDynamicNetwork){
                DynamicBN.stepCount = 5;
            }
            AreaChartCards.addExampleAreaChart("#colorExample", AREA_CHART_EXAMPLE_ID);
            AreaChartCards.updateExampleColors(AREA_CHART_EXAMPLE_ID, getColorPropFromSliders("collapseFiveAreaChart",AreaChartCards));
        });
        const inputFn = () => {
            let v = $("#errorBarChartLineWidthInput").val();
            let c = $("#errorBarChartColorInput").val();
            const sheet = new CSSStyleSheet();
            sheet.replaceSync(`#errorBarChartExample > svg .histogramLine { stroke-width: ${v}px !important; stroke: hsl(${c}, 100%, 50%)} #errorBarChartExample > svg .bar { stroke: hsl(${c}, 100%, 50%); fill: hsl(${c}, 100%, 50%)}`);
            document.adoptedStyleSheets.push(sheet);
        }
        $("#errorBarChartLineWidthInput").on("input", inputFn);
        $("#errorBarChartColorInput").on("input", inputFn);
        $('#collapseErrorBarChart').on('shown.bs.collapse', function () {
            //area charts settings
            let lineWidth = CurrentDashboard.get().jsonParse.errorBarChartLineWidth ?? 1;
            let errorBarChartColor = CurrentDashboard.get().jsonParse.errorBarChartColor ?? 211;
            const sheet = new CSSStyleSheet();
            sheet.replaceSync(`.cardBody svg .histogramLine { stroke-width: ${lineWidth}px !important; stroke: hsl(${errorBarChartColor}, 100%, 50%)} .cardBody svg .bar { stroke: hsl(${errorBarChartColor}, 100%, 50%); fill: hsl(${errorBarChartColor}, 100%, 50%)}`);
            document.adoptedStyleSheets.push(sheet);
            $("#errorBarChartLineWidthInput").val(lineWidth);
            $("#errorBarChartColorInput").val(errorBarChartColor);
            let meanArr = [40];
            let stdDevArr = [3];
            for (let i = 1; i < 40; i += 1) {
                meanArr.push(Math.random() * 10 - 5 + meanArr[i - 1]);
                stdDevArr.push(Math.random() * 2);
            }
            const stub = {
                values: [{ value: meanArr}, { value: stdDevArr}]
            }

            d3.selectAll("#errorBarChartExample > svg").remove()
            let svgGroup = d3.select("#errorBarChartExample").append("svg").append("g");
            Histogram.drawErrorBarChart(svgGroup, stub, 300, 200, "", "");

            // AreaChartCards.addExampleAreaChart("#errorBarChartExample", AREA_CHART_EXAMPLE_ID);
            // AreaChartCards.updateExampleColors(AREA_CHART_EXAMPLE_ID, getColorPropFromSliders("collapseErrorBarChart",AreaChartCards));
        });
        //font example
        function resetLoremIpsum() {
            d3.selectAll(".exampleFontSize > span").style("font-size", null);
        }
        function setLoremIpsumSize(self) {
            var loremIpsumID = self.getAttribute("forexample");
            var value = parseInt(self.value);
            var currFontSize = CardsManager.getFontSizeFromPerc(value);
            d3.select("#" + loremIpsumID + "> span").style("font-size", currFontSize.caption + "px");
        }
        var fontIDinputs = ["#captionFontSizeInput", "#annotationFontSizeInput", "#footnoteFontSizeInput", "#valueCardFontSize", "#textCardFontSize"];
        for (var i = 0; i < fontIDinputs.length; i++) {
            $(fontIDinputs[i]).on("input", function () {
                setLoremIpsumSize(this);
            });
        }
        $(MODAL_ID).on('hide.bs.modal', function (e) {
            CardsManager.setColumnCount();
            resetLoremIpsum();
            if(!DynamicBN.isDynamicNetwork){
                DynamicBN.stepCount = 0;
            }
        });
        function getData() {
            var dat = {};
            dat.name = $("#titleDashboard").val();
            dat.columnCount = parseInt($("#gridColumns").val());
            dat.ticks = $TICK_POSITION_SLIDER.slider('values');
            dat.reverseColor = reverseColorObj.checked;
            var valueFontPerc = parseFloat(d3.select("#valueCardFontSize").property("value"));
            dat.valueFontPerc = isNaN(valueFontPerc) ? 100 : valueFontPerc;
            var gaugeFontPerc = parseFloat(d3.select("#captionFontSizeInput").property("value"));
            dat.gaugeFontPerc = isNaN(gaugeFontPerc) ? 100 : gaugeFontPerc;
            var textFontPerc = parseFloat(d3.select("#textCardFontSize").property("value"));
            dat.textFontPerc = isNaN(textFontPerc) ? 100 : textFontPerc;
            var annotationFontPerc = parseFloat(d3.select("#annotationFontSizeInput").property("value"));
            dat.annotationFontPerc = isNaN(annotationFontPerc) ? 50 : annotationFontPerc;
            var footnoteFontPerc = parseFloat(d3.select("#footnoteFontSizeInput").property("value"));
            dat.footnoteFontPerc = isNaN(footnoteFontPerc) ? 50 : footnoteFontPerc;
            var gaugePrecision = parseInt(d3.select("#valuePrecision").property("value"));
            dat.gaugePrecision = isNaN(gaugePrecision) ? 2 : gaugePrecision;
            dat.pieChart = {};
            dat.pieChart.colors = getColorPropFromSliders("collapseFourPieChart", PieChart);
            var sortPch = d3.select("#sortPieChart").select("#sortByValuePCh");
            if (sortPch.size() !== 0) {
                dat.pieChart.sort = sortPch.property("checked");
            } else {
                dat.pieChart.sort = PieChart.isSort();
            }
            dat.areaChart = {};
            dat.areaChart.colors = getColorPropFromSliders("collapseFiveAreaChart", AreaChartCards);
            dat.footnote = $("#footnoteDashboard").val();
            const errorBarChartLineWidth = parseFloat(d3.select("#errorBarChartLineWidthInput").property("value"));
            dat.errorBarChartLineWidth = isNaN(errorBarChartLineWidth) ? 1 : errorBarChartLineWidth;
            const errorBarChartColor = parseFloat(d3.select("#errorBarChartColorInput").property("value")); // hue
            dat.errorBarChartColor = isNaN(errorBarChartColor) ? 1 : errorBarChartColor;
            return dat;
        }
        $("#saveDashboardSettings").on("click", function () {
            var d = getData();
            CurrentDashboard.get().name = d.name;
            CurrentDashboard.get().jsonParse.columnCount = d.columnCount;
            CurrentDashboard.get().jsonParse.cards.config.gauge = {};
            CurrentDashboard.get().jsonParse.cards.config.gauge.ticks = d.ticks.map(t => t / 100);
            CurrentDashboard.get().jsonParse.cards.config.gauge.arcColorFn = Radial.getReverseColors(d.reverseColor);
            CurrentDashboard.get().jsonParse.valueFontPerc = d.valueFontPerc;
            CurrentDashboard.get().jsonParse.gaugeFontPerc = d.gaugeFontPerc;
            CurrentDashboard.get().jsonParse.textFontPerc = d.textFontPerc;
            CurrentDashboard.get().jsonParse.annotationFontPerc = d.annotationFontPerc;
            CurrentDashboard.get().jsonParse.footnoteFontPerc = d.footnoteFontPerc;
            CurrentDashboard.get().jsonParse.cards.config.pieChart = {};
            CurrentDashboard.get().jsonParse.cards.config.pieChart.colors = d.pieChart.colors;
            CurrentDashboard.get().jsonParse.cards.config.pieChart.sort = d.pieChart.sort;
            CurrentDashboard.get().jsonParse.cards.config.areaChart = {};
            CurrentDashboard.get().jsonParse.cards.config.areaChart.colors = d.areaChart.colors;
            CurrentDashboard.get().jsonParse.cards.config.gauge.precision = d.gaugePrecision;
            CurrentDashboard.get().jsonParse.footnote = d.footnote;
            CurrentDashboard.get().jsonParse.errorBarChartLineWidth = d.errorBarChartLineWidth;
            CurrentDashboard.get().jsonParse.errorBarChartColor = d.errorBarChartColor;
            CurrentDashboard.updateGlobal();
            WarningCloseBrowser.enable();
            $("#settingsModal").modal('hide');
        });
        function addAdminBtn() {
            globalSettingsBtn = d3.select("*[user-menu]")
                    .select("li[user-menu-slot='2']")
                    .classed("nav-item text-truncate", true)
                    .attr("data-toggle", "collapse")
                    .attr("data-target", ".navbar-collapse.show")
                    .attr("title", "Settings")
                    .style("display", null)
//                    .lower()
                    .append("a")
                    .attr("href", "javascript:void(0)")
                    .attr("data-toggle", "modal")
                    .attr("data-target", "#settingsModal")
                    .classed("nav-link headerRight", true);

            globalSettingsBtn.append("i")
                    .classed("fas fa-cog", true);
            globalSettingsBtn.append("span")
                    .text(" Settings");
        }
        function removeAdminBtn() {
            if(globalSettingsBtn !== null){
                globalSettingsBtn.remove();
                globalSettingsBtn = null;
            }
        }
        StartApp.runWhenReady(() => {
            if (StartApp.isDemoDashboardMode() || User.loggedAndAdminOrExplicitCategoryAccess() || (!StartApp.isDashboardAdminOnly() && User.isLogged())) {
                addAdminBtn();
            } else {
                removeAdminBtn();
            }
            RightColumn.Filter.PermanentlyHiddenNodes.remove();
            User.runIfAdminOrExpCategoryAccess(RightColumn.Filter.PermanentlyHiddenNodes.init);
        }, false, "settingsAndRightColumnFilter");
    });
    SettingsDashboard();
    var DashboardsAdminManager = (function () {
        var addCardsBtn = null;
        var saveDashboardBtn = null;
        /*
        * IMPORTANT: Mark menu by 'user-menu' tag
        */
        function addSaveDashboardBtn() {
            //save dashboard
            saveDashboardBtn = d3.select("*[user-menu]")
                    .select("li[user-menu-slot='3']")
                    .attr("id", "saveDashboardBtn")
                    .classed("nav-item text-truncate", true)
                    .attr("title", "Save Dashboard")
                    .style("display", null)
//                    .lower()
                    .append("a")
                    .attr("href", "javascript:void(0)")
                    .classed("nav-link headerRight", true)
                    .on("click", () => {
                        CurrentDashboard.save();
                    });

            saveDashboardBtn.append("i")
                    .classed("far fa-save-regular", true);
            saveDashboardBtn.append("span")
                    .text(" Save");
            d3.select("#addCardsBtnMenu").size() > 0 ? d3.select("#addCardsBtnMenu").lower() : null;//move 'Add' btn before 'Save' btn
        }
        /*
        * IMPORTANT: Mark menu by 'user-menu' tag
        */
        function createAddCardsBtn() {
            //edit dashboard
            addCardsBtn = d3.select("*[user-menu]")
                    .select("li[user-menu-slot='1']")
                    .attr("id","addCardsBtnMenu")
                    .classed("nav-item text-truncate", true)
                    .attr("data-toggle", "collapse")
                    .attr("data-target", ".navbar-collapse.show")
                    .attr("title", "Add card")
                    .style("display", null)
//                    .lower()
                    .append("a")
                    .attr("href", "javascript:void(0)")
                    .attr("data-toggle", "modal")
                    .attr("data-target", "#addCardsModal")
                    .classed("nav-link headerRight", true);

            addCardsBtn.append("i")
                    .classed("fa fa-plus-circle-solid", true);
            addCardsBtn.append("span")
                    .text(" Add");
        }

        function removeBtn(btn) {
            if(btn !== null){
                btn.remove();
                btn = null;
            }
        }

        StartApp.runWhenReady(() => {
            removeBtn(addCardsBtn);
            removeBtn(saveDashboardBtn);
            MultiDashboard.removeMultidashboardBtn();

            if (StartApp.isDemoDashboardMode() || User.loggedAndAdminOrExplicitCategoryAccess() || (!StartApp.isDashboardAdminOnly() && User.isLogged())) {
                createAddCardsBtn();
                MultiDashboard.addMultidashboardBtn();
            }

            if (User.loggedAndAdminOrExplicitCategoryAccess() || (User.isLogged() && !StartApp.isDashboardAdminOnly())) {
                addSaveDashboardBtn();
            }
        }, false, "addCardsAndSaveDashboardAndMulti");
    })();
}

export {MultiDashboard, DashboardsAdminManager, CardsManager, AreaChartCards, CurrentDashboard, ConstantGauge, DefineCardsModal, ErrorCards, HistogramGauge, MultidimensionalTable, Radial, RadialUtility, TextGauge, SettingsDashboard, TopN, UtilityCards};
