/* global d3, Utilities, CardsManager */

import * as d3base from 'd3';
import Utilities from "./utilities";
import {CardsManager} from "./gauge";
import NodeShape from "./nodeShape";
import {DISPLAY_NODE_TYPE} from "./constantsMapping";
import Tooltip from "./tooltip";
import tip from "d3-tip";

const d3 = Object.assign(d3base, {tip});

var HorizontalBarChart = (function () {
    var margin = {top: 20, right: 30, bottom: 10, left: 90},
                width = 460 - margin.left - margin.right,
                height = 400 - margin.top - margin.bottom;


    function underlineToSpace(text = ""){
        return text.replace(/_/g," ");
    }
    //https://stackoverflow.com/a/39848896
    function wrap(textNode, width, height) {
        var saveMargin = 15;
        var self = d3.select(textNode);
        var textCutted = Utilities.cutLongNameV3(underlineToSpace(self.data()[0]), (width - saveMargin));
        var x = self.attr("x");
        var dy = parseFloat(self.attr("dy"));
        var lineHeight = 1.1; // ems
        var lineNumber = 0;
        var currentBBox = null;
        var lastTspan = null;
        for(var i =0; i < textCutted.length; i++){
            let tspan = self.append("tspan")
                    .attr("x", x)
                    .attr("y", 0)
                    .attr("dy", (lineNumber++) * lineHeight + dy + "em")
                    .text(textCutted[i]);
            currentBBox = self.node().getBBox();
            if(currentBBox.height > height){
                tspan.remove();
                lastTspan.text(Utilities.addPerfectSizeDots(textCutted[i-1]));
                lineNumber--;
                break;
            }
            lastTspan = tspan;
        }
        var translateY = -1 * (currentBBox.height / 2 - currentBBox.height / lineNumber * 0.5);
        self.attr("transform", "translate(0," + translateY + ")");
//        var self = d3.select(this),
//                textLength = self.node().getComputedTextLength(),
//                text = self.text();
//        while (textLength > (margin.left - saveMargin) && text.length > 0) {
//            text = text.slice(0, -1);
//            self.text(text + '...');
//            textLength = self.node().getComputedTextLength();
//        }
    }

    function initAll(cards) {
        if(cards.size() === 0){
            return;
        }
        var scrollContent = CardsManager.getCardBody(cards);
        let boudingRect = scrollContent.nodes()[0].getBoundingClientRect();
        width = boudingRect.width - margin.left - margin.right;
        scrollContent.append('div')
                .attr('class', 'svg-container')
                .style('padding-bottom', '100%')
                .append("svg")
                .attr("viewBox", "0 0 " + (width + margin.left + margin.right) + " " + (height + margin.top + margin.bottom))
                .attr("preserveAspectRatio", "xMinYMin meet")
                .classed("svg-content pt-2", true)// true meaning - add class
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        CardsManager.drawFooterCard(cards, "cardNodeTitle", {showValue: false});
    }
    function offscaleInfo(card, isSet, isOffscaleLow) {
        var offscaleClass = "offscaleValue";
        if (isSet) {
            var text = isOffscaleLow ? "Offscale low" : "Offscale high";
            var scrollContent = CardsManager.getCardBody(card);
            scrollContent.append("p")
                    .classed(offscaleClass + " text-center errorValue " + CardsManager.getNodeTitleClass(), true)
                    .text(text);
        } else {
            card.select("."+offscaleClass).remove();
        }
    }
    function opacitySVG(svg, isSet) {
        isSet ? svg.style("opacity", "25%") : svg.style("opacity", null);
    }
    function updateAll(cards) {
        cards.each((data, index, cardsList) => {
            if(typeof data.rankList !== "undefined" && typeof data.topn !== "undefined" && (data.rankList.length === 0 || data.topn === 0)){
                return;
            }
            var svg = d3.select(cardsList[index]).select("svg");
            var domainProperty = data.getDomain();
            var domain = domainProperty.y;
            var tip = d3.tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html(function (d) {
                        return "<strong>Value:</strong> <span>" + d.originalValue + "</span>";
                    });
            var x = d3.scaleLinear()
                    .domain(typeof domainProperty.domainX !== "undefined" ? domainProperty.domainX : [0, 1])
                    .nice()
                    .range([0, width]);

            var y = d3.scaleBand()
                    .range([0, height])
                    .domain(domain)
                    .padding(.1);
            //Bars
            var bars = svg.selectAll(".horizontalRect")
                    .data(d => {
                        var v = d.getDomain();
                        var ret = [];
                        for (var i = 0; i < v.x.length; i++) {
                            let outcomeId = v.y[i];
                            let value = v.x[i];
//                            if (isOneIndexinParent) {
//                                let minMax = Node.minMaxUtilityHelper(d.node);
//                                outcomeId = domain[i];
//                                value = (d.node.values[i].value - minMax.minUtility) / (minMax.maxUtility - minMax.minUtility);
//                            }
                            ret.push({
                                value: isNaN(value) ? 0 : value,
                                originalValue: v.x[i],
                                outcomeId: outcomeId,
                                cardRoot: d.cardRoot,
                                y: d3.scaleBand()
                                        .range([0, height])
                                        .domain(domain)
                                        .padding(.1)
                            });
                        }
                        return ret;
                    })
                    .enter()
                    .append("g");
            bars.append("rect")
                    .attr("class", "borderBar")
                    .attr("x", x.range()[0])
                    .attr("y", function (d) {
                        return d.y(d.outcomeId);
                    })
                    .attr("width", x.range()[1])
                    .attr("height", d => d.y.bandwidth())
                    .attr("transform", "translate(" + margin.left + ",0)");
            var gradient = NodeShape.BarChart.getBarChartGradient(0, {
                colors: [{start: 'RGB(0, 0, 255)', stop: "RGB(0, 255, 255)"}],
                direction: {
                    x1: "100%",
                    y1: "0%",
                    x2: "0%",
                    y2: "0%"
                },
                colorIdFunction: (prefix, number, direction) => "horizontalBarsChar" + number + direction.x1.replace("%", "") + direction.y1.replace("%", "") + direction.x2.replace("%", "") + direction.y2.replace("%", "")
            });
            var isOffscale = {
                offscale: false,
                offscaleLow: false
            };
            bars.append("rect")
                    .attr("class", "horizontalRect bar")
                    .attr("x", x.range()[0])
                    .attr("y", function (d) {
                        return d.y(d.outcomeId);
                    })
                    .attr("width", function (d) {
                        var w = x(d.value);
                        if (w < x.range()[0]) {
                            opacitySVG(d3.select(d.cardRoot).select("svg"), true);
                            isOffscale.offscale = true;
                            isOffscale.offscaleLow = true;
                            return x(x.domain()[0]);
                        } else if (w > x.range()[1]) {
                            opacitySVG(d3.select(d.cardRoot).select("svg"), true);
                            isOffscale.offscale = true;
                            isOffscale.offscaleLow = false;
                            return x(x.domain()[1]);
                        }
                        isOffscale.offscale = false;
                        opacitySVG(d3.select(d.cardRoot).select("svg"), false);
                        return x(d.value);
                    })
                    .attr("height", d => d.y.bandwidth())
                    .attr("transform", "translate(" + margin.left + ",0)")
                    .style("fill",gradient.fill)
                    .on('mouseover', tip.show)
                    .on('mouseout', tip.hide);
            bars.call(tip);
            offscaleInfo(d3.select(cardsList[index]), isOffscale.offscale, isOffscale.offscaleLow);
            // Add X axis
            svg.append("g")
                    .classed('axisHistogramColorInCard', true)
                    .attr("transform", "translate(" + margin.left + "," + height + ")")
                    .call(d3.axisBottom(x))
                    .selectAll("text")
                    .attr("transform", "translate(-10,0)rotate(-45)")
                    .style("text-anchor", "end");
            // Y axis
            var YstrockeWidth = 1;
            svg.append("g")
                    .classed('axis--y axisHistogramColorInCard', true)
                    .style("stroke-width", YstrockeWidth + "px")
                    .attr("transform", "translate(" + margin.left + ",0)")
                    .call(d3.axisLeft(y));
            //ellipsis labels
            var yAxis = svg.select(".axis--y");
            yAxis.selectAll(".tick")
                    .selectAll("text")
                    .html("")
                    .on("mouseover", d => Tooltip.nodeNameTooltip(d))
                    .on("mouseout", () => Tooltip.setMouseout())
                    .each((node, index, nodes) => wrap(nodes[index], margin.left, y.bandwidth()));

            //update svg padding
            let svgDivBCRect = d3.select(cardsList[index]).select(".svg-container").node().getBoundingClientRect();
            let svgBCRect = svg.node().getBoundingClientRect();
            let padding = 100 * svgBCRect.height / svgDivBCRect.height;
            d3.select(cardsList[index]).select(".svg-container").style("padding-bottom", padding + "%");
        });
    }
    function update_all(cards) {
        cards.each(d => d.displayNodeType = DISPLAY_NODE_TYPE.HORIZONTAL_BAR_PLOT);
        cards.each(d => d.getDomain = function () {
                return {
                    y: this.node.values.map(v => v.outcomeId),
                    x: this.node.values.map(v => v.value)
                };
            });
        initAll(cards);
        updateAll(cards);
    }
    return{
        update: function (cards) {
            CardsManager.clearCrads(cards);
            update_all(cards);
        },
        addAll: function (cards) {
            update_all(cards);
        },
        addAllCustomDomain: function (cards, getDomainFunction, clearCard) {
            if(typeof clearCard !== "undefined" && clearCard){
                CardsManager.clearCrads(cards);
            }
            cards.each(d => d.displayNodeType = DISPLAY_NODE_TYPE.HORIZONTAL_BAR_PLOT);
            cards.each(d => d.getDomain = getDomainFunction);
            initAll(cards);
            updateAll(cards);
        }
    };
})();

export default HorizontalBarChart;
