/* global CurrentNetwork, d3, Network, User, StartApp, OAuth */
import * as d3 from 'd3';
import StartApp from "./startAppInfo";
import {AUTHENTICATION_TYPE} from "./constantsMapping";
import Network from "./network";
import OAuth from "./oAuth";
import User from "./user";

var DashboardsDropdownList = (function () {
    function loadAvailableDashboards(networkHandle, callback) {
        let done = (dashboardsFromServer, textStatus, jqXHR) => {
            callback(dashboardsFromServer);
        };
        let fail = function (jqXHR, textStatus, errorThrown) {
            console.error(jqXHR);
        };
        Request.getDashboardsList(networkHandle, done, fail);
    }
    function fillDashboardCallback(dashboards) {
        var dropdownDashboards = d3.select("#dashboardDropdownList");
        dropdownDashboards.selectAll("*")
                .remove();
        dropdownDashboards.selectAll(".dropdown-item")
                .data(dashboards)
                .enter()
                .append("a")
                .classed("dropdown-item", true)
                .on("click", d => {
                    Network.redirectToDashboard(d.id);
                })
                .attr("href", "javascript:void(0)")
                .text(d => d.name);

        if (User.isAdmin() || User.isExplicitCategoryAccess() || !StartApp.isDashboardAdminOnly()) {
            dropdownDashboards.append("div")
                    .classed("dropdown-divider", true);
            dropdownDashboards.append("a")
                    .classed("dropdown-item", true)
                    .attr("href", "javascript:void(0)")
                    .attr("title", "New dashboard")
                    .text("Add New")
                    .on("click", () => {
                        if (StartApp.isDemoDashboardMode() || User.isLogged()) {
                            $('#dashboardTitleModal').modal("show");
                        } else {
                            showOAuthModal();
                        }
                    });
            d3.select("#dashboardTitleModal form")
                    .on("submit", () => putEmptyDashboardToDB());
            addEventsToAskingLoginBtns();
            $('#dashboardTitleModal').on('shown.bs.modal', function (e) {
                d3.select("#dashboardTitleModal #dashboardNameInput").property("value", Network.getCurrentNetworkID());
            });
            $('#dashboardTitleModal').on('show.bs.modal', (e) => {
                if (!StartApp.isDashboardAdminOnly() && !User.isLogged()) {
                    showLoginAskingDiv();
                    if (StartApp.isDemoDashboardMode()) {
                        prepareAskingBody();
                    } else {
                        prepareMustLoginBody();
                    }
                }
            });
            $('#dashboardTitleModal').on('hidden.bs.modal', e => showInputForDashboardNameDiv());
        } else if (StartApp.isDemoDashboardMode()) {
            dropdownDashboards.append("a")
                    .classed("dropdown-item", true)
                    .attr("href", "javascript:void(0)")
                    .on("click", d => Network.redirectToDashboard(null))
                    .text("Empty dashboard");
        } else {
            d3.select("#dashboardTitleModal").remove();
        }

        function addEventsToAskingLoginBtns() {
            resetEventsToAskingLoginBtns();
            d3.select("#loginAskBeforeCreateDashboard")
                    .select("#continueWL")
                    .on("click", d => Network.redirectToDashboard(null));
            d3.select("#loginAskBeforeCreateDashboard")
                    .select("#logIn")
                    .on("click", () => showOAuthModal());
        }

        function showOAuthModal() {
            OAuth.showButtons($("#oauthLoginButtons"), StartApp.getOAuthClients(), "");
            User.setCallbackAfterStandardLogIn(DashboardsDropdownList.callbackAfterOauthLogIn);
            $('#dashboardTitleModal').modal('hide');
            $('#oAuthLoginModal').modal('show');
        }

        function resetEventsToAskingLoginBtns() {
            d3.select("#loginAskBeforeCreateDashboard")
                    .select(".modal-footer")
                    .selectAll("button")
                    .on("click", null);
        }

        function showInputForDashboardNameDiv() {
            d3.select("#loginAskBeforeCreateDashboard").classed("d-none", true);
            d3.select("#newDashboardTitleInput").classed("d-none", false);
        }

        function showLoginAskingDiv() {
            d3.select("#newDashboardTitleInput").classed("d-none", true);
            d3.select("#loginAskBeforeCreateDashboard").classed("d-none", false);
        }

        function prepareAskingBody(){
            d3.select("#askLoginText").classed("d-none", false);
            d3.select("#continueWL").classed("d-none", false);
            d3.select("#loginText").classed("d-none", true);
        }

        function prepareMustLoginBody(){
            d3.select("#askLoginText").classed("d-none", true);
            d3.select("#continueWL").classed("d-none", true);
            d3.select("#loginText").classed("d-none", false);
        }
    }
    function putEmptyDashboardToDB() {
        let name = d3.select("#dashboardTitleModal #dashboardNameInput").property("value");
        let body = JSON.stringify({
            dashboard: {},
            name: name,
            netHandle: Array.isArray(Network.getCurrentNetwork()) ? Network.getCurrentNetwork() : [Network.getCurrentNetwork()]
        });
        let done = (dashboard, textStatus, jqXHR) => {
            window.location.replace("/dashboard.html?id=" + dashboard.id);
        };
        let fail = function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR);
        };
        Request.addDashboard(body, done, fail);
    }
    return {
        fillList: function (network) {
            StartApp.runWhenReady(() => {
                if (StartApp.getAuthenticationType() === AUTHENTICATION_TYPE.NONE) {
                    d3.select("#dashboardsBtn").remove();
                } else {
                    loadAvailableDashboards(network, fillDashboardCallback);
                }
            }, false, "fillDashboardList");
        },
        putEmptyDashboardToDB,
        callbackAfterOauthLogIn: function () {
            $('#dashboardTitleModal').modal('show');
        }
    };
})();

export default DashboardsDropdownList;
