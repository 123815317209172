import * as d3 from 'd3';
import Network from "./network";
import Utilities from "./utilities";
import ZoomNetwork from "./zoomNetwork";
import LeftColumn from "./leftColumn";
import RightColumn from "./rightColumn";
import Logo from "./logo";
import {BAYESBOX_MODE} from "./constantsMapping";
import Footnote from "./footnote";
import * as SimpleBar from './simplebar-5.0.4/simplebar';

/* global LeftColumn, RightColumn, ZoomNetwork, d3, Network, Utilities */
var ScrollBar = (function () {
    var SCROLL_WIDTH_HEIGHT = 11;//from CSS SimpleBar
    var leftBar = null;
    var middleBar = null;
    var rightBar = null;

    /**
     * Set scroll bars
     */
    function init_scroll_bars(filter) {
        if (typeof filter === 'undefined') {
            filter = [true, true, true];
        }
        if (filter.length !== 3) {
            console.log("initScrollBars() arg length error (expected length = 3)!");
            return;
        }
        if (filter[0]) {
            leftBar = new SimpleBar($(LeftColumn.getCSSSelector())[0], {autoHide: true});
        }
        if (filter[1]) {
            middleBar = new SimpleBar($(`#${Network.getSvgContentId()}`)[0], {
                autoHide: true,
                scrollbarMinSize: SCROLL_WIDTH_HEIGHT
            });
        }
        if (filter[2]) {
            rightBar = new SimpleBar($(RightColumn.Accordion.CSSselector)[0], {autoHide: true});
        }

        Logo.verify();
        if(BAYESBOX_MODE.isDashboard()){
            Footnote.add();
        }
    };
    function remove_scroll_bars() {
        if(leftBar !== null){
            leftBar.unMount();
        }
        if(middleBar !== null){
            middleBar.unMount();
        }
        if(rightBar !== null){
            rightBar.unMount();
        }
    }
    function scrollVertical(y) {
        if(middleBar !== null){
            middleBar.contentWrapperEl.scrollTop = y;
        }
    };
    function scrollHorizontal(x) {
        if(middleBar !== null){
            middleBar.contentWrapperEl.scrollLeft = x;
        }
    };
    // elm - ex. Node.selectNodeFromObject(node).node()
    function get_invisible_position(elm) {
        var position = {
            horizontal: 0,
            vertical: 0
        };
        if(middleBar === null){
            return position;
        }
        // window size visible to the user
        var viewSize = d3.select(`#${Network.getSvgContentId()}`).node().getBoundingClientRect();
        var breadcrumb = Utilities.getBreadcrumbRect();
        var svgplateMargin = Network.getSvgplateMargin();
//        viewSize.height += breadcrumb.height;

        // current scroll LEFT
        var currentScrollLeft = middleBar.contentWrapperEl.scrollLeft;
        // current scroll RIGHT
        var currentScrollTop = middleBar.contentWrapperEl.scrollTop;

        // node position and width and height on svg
        var nodePosition = elm.getBBox();

        // scale node position and width and height
        nodePosition.x *= ZoomNetwork.getLast_K();
        nodePosition.y *= ZoomNetwork.getLast_K();
        nodePosition.width *= ZoomNetwork.getLast_K();
        nodePosition.height *= ZoomNetwork.getLast_K();

        // window size range visible to the user
        /**
         * viewWindow
         *
         * |invisible|visible|invisible|
         * |---------|-------|---------|
         * |        l|  top  |r        |
         * |        e|       |i        |
         * |        f|       |g        |
         * |        t|       |h        |
         * |         | bottom|t        |
         * |---------|-------|---------|
         */
        var viewWindow = {
            left: currentScrollLeft,
            right: viewSize.width + currentScrollLeft,
            top: currentScrollTop,
            bottom: viewSize.height + currentScrollTop
        };

        /**
         * SCROLL LEFT DETECT
         */
        // if node is invisible on the left side
        /**
         * |invisible|visible|invisible|
         * |---------|-------|---------|
         * |         |       |         |
         * |     o   |       |         |
         * |---------|-------|---------|
         */
        if (nodePosition.x < viewWindow.left) {
            position.horizontal = nodePosition.x;
        }
        // if node is invisible on the right side
        /**
         * |invisible|visible|invisible|
         * |---------|-------|---------|
         * |         |       |         |
         * |         |       |    o    |
         * |---------|-------|---------|
         */
        if ((nodePosition.x + nodePosition.width) > viewWindow.right) {
            position.horizontal = nodePosition.x - viewWindow.right + viewWindow.left + nodePosition.width;
        }

        /**
         * SCROLL TOP DETECT
         */
        // if node is invisible on the top side
        /**
         * |-------|
         * |   o   |invisible
         * |       |
         * |-------|-
         * |       |visible
         * |       |
         * |-------|-
         * |       |
         * |       |invisible
         * |-------|
         */
        if (nodePosition.y < viewWindow.top) {
            position.vertical = nodePosition.y;
        }
        // if node is invisible on the bootom side
        /**
         * |-------|
         * |       |invisible
         * |       |
         * |-------|-
         * |       |visible
         * |       |
         * |-------|-
         * |    o  |
         * |       |invisible
         * |-------|
         */
        if ((nodePosition.y + nodePosition.height) > viewWindow.bottom) {
            position.vertical = nodePosition.y - viewWindow.bottom + viewWindow.top + nodePosition.height + breadcrumb.height + svgplateMargin.top;
        }
        return position;
    };
    /**
     * Scroll to node if invisible
     * @param {D3 data Object} node - node
     */
    function show_dom_element(elm) {
        var position = get_invisible_position(elm);
        if(position.horizontal !== 0){
            scrollHorizontal(position.horizontal);
        }
        if(position.vertical !== 0){
            scrollVertical(position.vertical);
        }
    };
    return {
        getScrollWidthHeight: function () {
            return SCROLL_WIDTH_HEIGHT;
        },
        initScrollBars: function (filter) {
            init_scroll_bars(filter);
        },
        removeScrollBars: function () {
            remove_scroll_bars();
        },
        schowDOMElement: function (elm) {
            show_dom_element(elm);
        },
        getInvisiblePosition: function (node) {
            return get_invisible_position(Node.selectNodeFromObject(node).node());
        },
        /*
         * panel:
         * 1 - left
         * 2 - middle
         * 3 - right
         */
        recalculate: function (panel) {
            switch (panel) {
                case 1:
                    leftBar.recalculate();
                    break;
                case 2:
                    middleBar.recalculate();
                    break;
                case 3:
                    rightBar.recalculate();
                    break;
            }
        }
    };
})();

export default ScrollBar;
