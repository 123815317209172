import 'bootstrap';
//import 'jquery-ui';
import * as d3 from 'd3';
import Utilities from "./utilities";
import HideColumns from "./hideColumns";
import User from "./user";
import PortraitAlert from "./portraitAlert";
import {AUTHENTICATION_TYPE, BAYESBOX_MODE, NODE_TEMPORAL_TYPE} from "./constantsMapping";
import Request from "./request";
import Tutorial from "./tutorial";
import Network from "./network";
import Cooke from "./cooke";
import ClipboardJS from "clipboard";
import Toasts from "./toasts";

/* global d3, BAYESBOX_MODE, HideColumns, Toasts, Cooke, Network, Tutorial, User, PortraitAlert, Utilities, Request, Node, NODE_TEMPORAL_TYPE, AUTHENTICATION_TYPE */

var StartApp = (function () {
    var ready = false;
    //BRAND
    var headTitle = "";
    var navBarTitle = "";
    var helloModalTitle = "";
    var helloModalDescription = "";
    //LICENSE
    var license = "";
    var validUntil = 0;
    var licId = "";
    var licNote = "";
    //VERSION
    var version = "";
    //CASES MANAGER INFO
    var visible = "";
    var oAuthClients = [];
    //START APP NETWORK
    var startAppNetwork = null;
    var startAppEvidence = null;
    var startCallbackAfterEvidenceLoaded = null;
    //show update
    var showUpdate = false;
    //network
    var defaultNetwork = null;
    var networkList = null;
    var networksMap = new Map();
    //dashboard
    var isRedirectFromDashboard = null;
    var orgCookeNetwork = null;
    var orgCookeEvidence = null;
    var dashboardAdminOnly = false;
    //permalink
    var isPermalink = false;
    var permalinkHandle = null;

    var demoDashboardMode = false;//for repo and demo page

    var authenticationType = null;

    function setHeadTitle(title) {
        headTitle = title;
        d3.select("title").text(headTitle);
    }
    function setNavBarTitle(title) {
        navBarTitle = title;
        $("#navbarBrandTitle")
            .attr("title", navBarTitle)
            .text(navBarTitle);
    }
    function setHelloModalTitle(title) {
        helloModalTitle = title;
        $("#helloModalTitle")
            .attr("title", title)
            .text(helloModalTitle);
    }
    function setHelloModalDescription(description) {
        helloModalDescription = description;
        d3.select("#helloDescription").html(helloModalDescription);
    }
    function setLicense(lic_Id, lic_Org, lic_Note, valid_Until, app_version) {
        license = lic_Org;
        licId = lic_Id;
        licNote = lic_Note;
        validUntil = valid_Until;
        version = app_version;
        var textHTML = 'This website is powered by BayesBox, &copy; <a href="https://www.bayesfusion.com"><b>BayesFusion, LLC.</b></a>' +
            '<br/>' +
            'BayesBox version: ' + version +
            '<br/>' +
            'Licensed to: ' + license +
            '<br/>' +
            'License id: ' + licId +
            '<br/>' +
            'Expires on: ' +
            new Date(validUntil * 1000).toLocaleDateString();
        d3.select("#licenseDescription").html(textHTML);
    }
    function setBrand(brand) {
        setHeadTitle(brand.faviconTitle);
        setNavBarTitle(brand.title);
        setHelloModalTitle(brand.title);
        setHelloModalDescription(brand.helloDescription);
    }
    function setCasesManagerInfo(casesManager) {
        visible = casesManager.visible;
        oAuthClients =  JSON.parse(casesManager.oAuthClients);
    }
    //left and right panel
    function setColumnInfo(column) {
        if(BAYESBOX_MODE.isAdminPage()){
            return ;
        }
        HideColumns.showRight = !column.hideRightColumn;
        if (!BAYESBOX_MODE.isDashboard()) {
            HideColumns.showLeft = !column.hideLeftColumn;
            HideColumns.showButtonsForHiding(!column.hideLeftButton, !column.hideRightButton);
        }
    }
    function setPermalinkHandle(link) {
        if(typeof link !== "undefined"){
            permalinkHandle = link;
        }
    }
    function setVisiblePermalink(visible) {
        if(!BAYESBOX_MODE.isGraph()){
            isPermalink = false;
            return;
        }
        isPermalink = visible;
        if (!isPermalink) {
            d3.select("permalink").remove();
        } else {
            let clipboard = new ClipboardJS('.clickToCopyAction');
            let options = {
                duration: 300,
                easing: "linear"
            };
            clipboard.on('success', function (e) {
                Toasts.add("URL", "Copied to clipboard.");
                $("#permaLinkURL").show(options);
            });

            clipboard.on('error', function (e) {
                $("#permaLinkURL").show(options);
            });
        }
    }
    function setStartAppInfo() {
        if (isRedirectFromDashboard === null) {
            if (typeof Cooke === "undefined") {
                console.log("Cookie is not defined");
            } else {
                isRedirectFromDashboard = Cooke.checkCookie(Network.getRedirectFromDashboardCookeName()) ? Cooke.getCookie(Network.getRedirectFromDashboardCookeName()) : false;
                Cooke.deleteCooke(Network.getRedirectFromDashboardCookeName());
            }
        }
        let done = function (data) {
            authenticationType = data.authenticationType;
            demoDashboardMode = data.dashboardDemoMode;
            dashboardAdminOnly = data.dashboardAdminOnly;
            checkStartNetworkFromCooke();
            defaultNetwork = data.defaultNetwork;
            networkList = data.networksList;
            if (BAYESBOX_MODE.isGraph() || BAYESBOX_MODE.isDashboard()) {
                Network.setMappingNetwork(convertToNetworkMap(networkList));
            }
            setLicense(data.brand.licId, data.brand.licOrg, data.brand.licNote, data.brand.validUntil, data.version);
            setBrand(data.brand);
            setCasesManagerInfo(data.caseManager);
            setColumnInfo(data.columnInfo);
            !BAYESBOX_MODE.isDashboard() ? setVisiblePermalink(data.isPermalink) : null;
            setPermalinkHandle(data.permalinkHandle);
            if (data.hasOwnProperty("startNetwork")) {
                startAppNetwork = data.startNetwork;
            }
            if (data.hasOwnProperty("startEvidence")) {
                startAppEvidence = data.startEvidence;
            }
            if (data.hasOwnProperty("startCallbackAfterEvidenceLoaded")) {
                startCallbackAfterEvidenceLoaded = data.startCallbackAfterEvidenceLoaded;
            }
            showUpdate = data.showUpdate;
            if (typeof Tutorial !== 'undefined') {
                Tutorial.activate(data.tutorial);
            }
            if (!BAYESBOX_MODE.isAdminPage()) {
                if (!PortraitAlert.isSmallMobile() && !User.isLoginFromOauth() && !BAYESBOX_MODE.isDashboard() && !isRedirectFromDashboard) {//!MPORTANT User.isLoginFromOauth()must be before set startAppNetwork and startAppEvidence
                    $('#helloModal').modal('show');
                }
            }
            User.setUser(data.user);
        };
        let fail = function (jqXHR) {
            Utilities.redirectToLogin(jqXHR.status);
        };
        let always = function () {
            refreshFunctions.execute();
            ready = true;
        };
        Request.getAppInfo(done, fail, always);
    }
    function checkStartNetworkFromCooke() {
        if(BAYESBOX_MODE.isAdminPage()){
            return ;
        }
        var netHandle = Cooke.getCookie("network");
        if (netHandle === "") {
            return;
        }
        orgCookeNetwork = netHandle;
        orgCookeEvidence = Cooke.getCookie("evidence");
        setStartAppData(parseInt(netHandle, 10), Cooke.checkCookie("evidence")? JSON.parse(Cooke.getCookie("evidence")) : []);
        Cooke.deleteCooke("network");
        Cooke.deleteCooke("evidence");
    }

    function setStartAppData(networkHandle, evidences) {
        startAppNetwork = networkHandle;
        startAppEvidence = JSON.stringify({
            networkHandle: startAppNetwork,
            evidences: evidences
        });
    }

    function retractOrgCooke() {
        if (orgCookeNetwork !== null) {
            Cooke.setSessionCooke("network", orgCookeNetwork);
        }
        if (orgCookeEvidence !== null) {
            Cooke.setSessionCooke("evidence", orgCookeEvidence);
        }
    }

    function isString(val) {
        return (typeof val === 'string' || val instanceof String);
    }

    function handleToIDs(networkHandle) {
        let startAppNet = JSON.parse(startAppEvidence);
        let evidences = startAppNet.evidences.map(ev => {
            var nodeID = Node.getNodeIdFromHandle(parseInt(ev.node, 10), networkHandle);
            if(typeof nodeID === 'undefined'){
                return ev;
            }
            var node = d3.select("#" + nodeID).data()[0];
            if (isString(ev.evidence)) {
                return {
                    node: nodeID,
                    evidence: ev.evidence
                };
            }
            return {
                node: nodeID,
                evidence: node.temporalType === NODE_TEMPORAL_TYPE.PLATE ? ev.evidence : node.outcome[parseInt(ev.evidence, 10)]
            };
        });
        return JSON.stringify({
            networkHandle: startAppNetwork,
            evidences: evidences
        });
    }
    function convertToNetworkMap(networkObject) {
        networksMap = new Map();
        Object.keys(networkObject).forEach(function (key, index) {
            let sortedList = networkObject[key].sort(StartApp.networkListSortFun);
            networksMap.set(key, sortedList);
        });
        return networksMap;
    }

    var refreshFunctions = (function () {
        var functions = [];

        function add(calback, oneOff, id) {
            id = typeof id === "undefined" ? Utilities.getRandomId() : id;
            let data = {
                fun: calback,
                id: id,
                oneOff: oneOff
            };
            let existFunctionIndex = functions.findIndex(f => f.id === id);
            if(existFunctionIndex >= 0){
                functions[existFunctionIndex] = data;
            }
            functions.push(data);
        }

        function removeOneOffFunctions() {
            var i = functions.length;
            while (i--) {
                if (functions[i].oneOff) {
                    functions.splice(i, 1);
                }
            }
        }

        function execute() {
            functions.forEach(f => f.fun());
            removeOneOffFunctions();
        }

        return {
            add,
            execute
        };
    })();

    return {
        loadAppInfo: function () {
            setStartAppInfo();
        },
        getCasesManagerInfo: function () {
            return {
                visible: visible,
                oAuthClients: oAuthClients
            };
        },
        getOAuthClients: function () {
            return oAuthClients;
        },
        getStartAppNetwork: function () {
            return startAppNetwork;
        },
        getStartAppEvidence: function () {
            return startAppEvidence;
        },
        getStartAppFunctionAfterEvidenceLoaded: function () {
            let callbackFunction = function () {
                Utilities.executeFunctionByName(startCallbackAfterEvidenceLoaded, window);
            };
            return typeof startCallbackAfterEvidenceLoaded !== "undefined" &&
            startCallbackAfterEvidenceLoaded !== null
            && startCallbackAfterEvidenceLoaded !== "" ? callbackFunction : function (){};
        },
        cleanStartAppNetworkInfo: function () {
            startAppEvidence = null;
            startAppNetwork = null;
        },
        getStartAppEvidenceIDs: function (networkHandle) {
            return handleToIDs(networkHandle);
        },
        getDefaultNetwork: function () {
            return defaultNetwork;
        },
        getNetworkList: function () {
            return networkList;
        },
        networkListSortFun: function (a, b) {
            if (a.filename.toUpperCase() < b.filename.toUpperCase())
                return -1;
            if (a.filename.toUpperCase() > b.filename.toUpperCase())
                return 1;
            return 0;
        },
        showUpdate: function () {
            return showUpdate;
        },
        runWhenReady: function (calback, oneOff, id) {
            oneOff = typeof oneOff === "undefined" ? false : oneOff;
            if (ready) {
                calback();
                if (!oneOff) {
                    refreshFunctions.add(calback, oneOff, id);
                }
            } else {
                refreshFunctions.add(calback, oneOff, id);
            }
        },
        refreshProvidedFunctions: function () {
            refreshFunctions.execute();
        },
        isLoadedComplete: function () {
            return ready;
        },
        isRedirectFromDashboard: function () {
            return isRedirectFromDashboard;
        },
        getVersion: function () {
            return version;
        },
        retractOrgCooke: function () {
            retractOrgCooke();
        },
        getAllCookeValues: function () {
            return {
                network: orgCookeNetwork,
                evidence: orgCookeEvidence
            };
        },
        setStartAppData: function (networkHandle, evidences) {
            setStartAppData(networkHandle, evidences);
        },
        setAppTitle: function (title) {
            setNavBarTitle(title);
            setHelloModalTitle(title);
        },
        isDemoDashboardMode: function () {
            return demoDashboardMode;
        },
        isPermalink: function () {
            return isPermalink;
        },
        getPermalinkHandle: function () {
            return permalinkHandle;
        },
        getCategories: function () {
            return Object.keys(networkList);
        },
        getAuthenticationType: function () {
            return authenticationType;
        },
        isDashboardAdminOnly: function () {
            return (authenticationType === AUTHENTICATION_TYPE.ADMIN || authenticationType === AUTHENTICATION_TYPE.FULL) && dashboardAdminOnly;
        }
    };
})();


export default StartApp;
