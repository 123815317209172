import * as d3 from 'd3';
import Utilities from "./utilities";
import Network from "./network";
import HideColumns from "./hideColumns";
import StartApp from "./startAppInfo";

/* global Utilities, d3, Network, HideColumns */
var LeftColumn = (function () {
    var CSSselector = "#leftColumn";

    function getGroupData(handle) {
        var selection = d3.selectAll("#accordionNetworks > div:not(:first-child)").select("a[handle='"+handle+"']");
        if(selection.size() === 0){
            return d3.selectAll("#accordionNetworks").select("a[handle='"+handle+"']").data()[0];
        }
        return selection.data()[0];
    }

    function initHandlers() {
        $('.dropdown-accordion').on('show.bs.dropdown', function (event) {
            // Collapse accordion every time dropdown is shown
            var accordion = $(this).find($(this).data('accordion'));
            accordion.find('.collapse.show').removeClass('show');
            //expand category with current network
            var groupData = getGroupData(Network.getCurrentNetwork());
            d3.select('#' + groupData.groupID).classed('show', true);
        });

        // Prevent dropdown to be closed when we click on an accordion link
        $('.dropdown-accordion').on('click', 'button[data-toggle="collapse"]', function (event) {
            event.preventDefault();
            event.stopPropagation();
            $($(this).data('parent')).find('.collapse.show').collapse('hide');
            if ($($(this).attr('data-target')).hasClass('show') === true) {
                $($(this).attr('data-target')).collapse('hide');
            } else {
                $($(this).attr('data-target')).collapse('show');
            }

        });
    }

    /**
     * Add new group for menu with networks
     * @param {String} groupName
     * @returns {D3 Object}
     */
    function addNewGroup(groupName) {
        var groupSelectorName = Utilities.getValidId(groupName);
        var divCard = d3.select('#accordionNetworks')
                .append('div')
                .attr('class', 'card netListCard');
        divCard.append('div')
                .attr('class', 'card-header netListCard-header')
                .attr('id', 'heading' + groupSelectorName)
                .append('h5')
                .attr('class', 'mb-0')
                .append('button')
                .attr('class', 'btn btn-link netListBtn')
                .attr('data-toggle', 'collapse')
                .attr('data-target', '#collapse' + groupSelectorName)
                .attr('aria-expanded', 'true')
                .attr('aria-controls', 'collapse' + groupSelectorName)
                .attr('title', groupName)
                .html(groupName + " (<avaliableModelsGroup id='number_" + groupSelectorName + "'></avaliableModelsGroup>)");
        var linkList = divCard.data([{groupID: 'collapse' + groupSelectorName}])
                .append('div')
                .attr('id', 'collapse' + groupSelectorName)
                .attr('class', 'collapse show')
                .attr('aria-labelledby', 'heading' + groupSelectorName)
                .attr('data-parent', '#accordionNetworks')
                .append('div')
                .attr('class', 'card-body')
                .style('padding', '.5rem .5rem')
                .attr('id', 'files' + groupSelectorName);

        return linkList;
    }
    /**
     * Add new network for existing group
     * @param {String} groupName - existing group name
     * @param {String} fileName - new file name
     * @param {int} handle - network handle
     * @returns {D3 Object}
     */
    function addNetworkForGroup(groupName, fileName, handle) {
        var divGroup;
        var groupSelectorName = Utilities.getValidId(groupName);
        if (d3.select('#files' + groupSelectorName).size() === 0) {
            divGroup = addNewGroup(groupName);
        } else {
            divGroup = d3.select('#files' + groupSelectorName);
        }
        divGroup.append('a')
                .attr('class', 'dropdown-item my-dropdown-item')
                .attr('href', 'javascript:void(0)')
                .attr('title', fileName)
                .attr("handle", handle)
                .attr('onclick', 'Network.setCurrentNetwork("' + handle + '","' + fileName + '")')
                .text(fileName);
        let numberOfNetworks = divGroup.selectAll("a.my-dropdown-item").size();
        d3.select("#number_" + groupSelectorName).text(numberOfNetworks);
        return divGroup;
    }
    /**
     * Set title in list witch networks
     * @param {type} title
     */
    function setDropdownMenuTitle(title) {
        d3.select("#dropdownMenuButton").text(title);
    }
    function addNetworkList(data) {
        var allNet = [];
        var nets = [];
        Object.keys(data).forEach(function (key, index) {
            let sortedList = data[key].sort(StartApp.networkListSortFun);
            allNet = [...allNet, ...sortedList];// contact two arrays ES6
            nets.push({key: key, list: sortedList});
        });
        if(allNet.length === 1){
            return;
        }
        $("#dropdownNetworkList").removeClass("d-none");
        let allNetSorted = allNet.sort(StartApp.networkListSortFun);
        $("avaliablemodels").text(allNetSorted.length);
        // All models
        if (StartApp.getCategories().length > 1) {
            allNetSorted.forEach(n => addNetworkForGroup("All Models", n.filename.replace(".xdsl", ""), n.handle));
        }
        nets.sort(function (a, b) {
            if (a.key.toUpperCase() < b.key.toUpperCase())
                return -1;
            if (a.key.toUpperCase() > b.key.toUpperCase())
                return 1;
            return 0;
        });
        // Grouped models
        nets.forEach(o => {
            o.list.forEach(n => {
                addNetworkForGroup(o.key, n.filename.replace(".xdsl", ""), n.handle);
            });
        });
        setDropdownMenuTitle(Network.getNetworkName(parseInt(Network.getCurrentNetwork(), 10)));
        HideColumns.positionLeft();
    }
    return {
        getCSSSelector: function () {
            return CSSselector;
        },
        initHandlers: function () {
            initHandlers();
        },
        setDropdownMenuTitle: function (title) {
            setDropdownMenuTitle(title);
        },
        addNetworkList: function (data){
            addNetworkList(data);
        }
    };
})();

export default LeftColumn;
